<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-album-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-title">编辑报告</span>
              </div>
            </template>
            <div>
              <el-form
                ref="formRef"
                :model="createParams"
                :rules="createRule"
                label-width="120px"
                class="form-box mg-t-24"
              >
                <el-form-item label="标题" prop="title">
                  <el-input
                    v-model="createParams.title"
                    placeholder="请输入"
                    style="width: 440px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="报告内容" prop="content">
                  <tinymceEditor
                    :tinymceHtml="createParams.content"
                    @saveContent="getContent"
                    v-if="!isLoading"
                  />
                </el-form-item>
                <el-form-item label="作者" prop="author_name">
                  <el-input
                    v-model="createParams.author_name"
                    placeholder="请输入作者名称"
                    style="width: 440px"
                  ></el-input>
                </el-form-item>
                <!-- <el-form-item label="名称" prop="title">
                  <el-input
                    v-model="createParams.title"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item> -->
                <el-form-item label="摘要" prop="description">
                  <el-input
                    v-model="createParams.description"
                    maxlength="120"
                    show-word-limit
                    type="textarea"
                    placeholder="请输入摘要"
                    style="width: 440px"
                  ></el-input>
                </el-form-item>
                <div>
                  <el-form-item label="报告封面" prop="cover_img" required>
                    <div>
                      <p>只支持.jpg、.png、.jpeg 格式, 建议318*449像素</p>
                      <el-upload
                        class="avatar-uploader"
                        :action="`${baseUrl}admin/upload/image`"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="
                          (file) => {
                            return avatarUploadSuccess(file, 'cover_img');
                          }
                        "
                        :headers="{ Authorization: 'Bearer ' + adminToken }"
                        ref="upload"
                        name="image"
                        accept="image/png,image/jpg,image/jpeg"
                        :on-progress="
                          (event, file, fileList) => {
                            return handleProgress(
                              event,
                              file,
                              fileList,
                              'cover_img'
                            );
                          }
                        "
                        :on-change="
                          (file, fileList) => {
                            return handlechangeFile(file, fileList, 'cover_img');
                          }
                        "
                      >
                        <img
                          v-if="createParams.cover_img"
                          :src="createParams.cover_img"
                          class="avatar"
                        />
                        <el-icon v-else class="avatar-uploader-icon"
                          ><plus
                        /></el-icon>
                      </el-upload>
                      <el-progress
                        :percentage="bannerProgressCover"
                        v-if="bannerProgressCover"
                      />
                    </div>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="顶部banner" prop="banner" required>
                    <div>
                      <p>只支持.jpg、.png、.jpeg 格式, 建议750*400像素</p>
                      <el-upload
                        class="avatar-uploader"
                        :action="`${baseUrl}admin/upload/image`"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="
                          (file) => {
                            return avatarUploadSuccess(file, 'banner');
                          }
                        "
                        :headers="{ Authorization: 'Bearer ' +  adminToken }"
                        ref="upload"
                        name="image"
                        accept="image/png,image/jpg,image/jpeg"
                        :on-progress="
                          (event, file, fileList) => {
                            return handleProgress(
                              event,
                              file,
                              fileList,
                              'banner'
                            );
                          }
                        "
                        :on-change="
                          (file, fileList) => {
                            return handlechangeFile(file, fileList, 'banner');
                          }
                        "
                      >
                        <img
                          v-if="createParams.banner"
                          :src="createParams.banner"
                          class="avatar"
                        />
                        <el-icon v-else class="avatar-uploader-icon"
                          ><plus
                        /></el-icon>
                      </el-upload>
                      <el-progress
                        :percentage="bannerProgress"
                        v-if="bannerProgress"
                      />
                    </div>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="海报banner" prop="poster_banner" required>
                    <div>
                      <p>只支持.jpg、.png、.jpeg 格式, 建议750*400像素</p>
                      <el-upload
                        class="avatar-uploader"
                        :action="`${baseUrl}admin/upload/image`"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="
                          (file) => {
                            return avatarUploadSuccess(file, 'poster_banner');
                          }
                        "
                        :headers="{ Authorization:'Bearer ' + adminToken }"
                        ref="upload"
                        name="image"
                        accept="image/png,image/jpg,image/jpeg"
                        :on-progress="
                          (event, file, fileList) => {
                            return handleProgress(
                              event,
                              file,
                              fileList,
                              'poster_banner'
                            );
                          }
                        "
                        :on-change="
                          (file, fileList) => {
                            return handlechangeFile(file, fileList, 'poster_banner');
                          }
                        "
                      >
                      
                        <img
                          v-if="createParams.poster_banner"
                          :src="createParams.poster_banner"
                          class="avatar"
                        />
                        <el-icon v-else class="avatar-uploader-icon"
                          ><plus
                        /></el-icon>
                      </el-upload>
                      <el-progress
                        :percentage="posterBannerProgress"
                        v-if="posterBannerProgress"
                      />
                    </div>
                  </el-form-item>
                </div>
                <el-form-item
                  label="报告类型"
                  prop="report_type"
                  required
                  class="report_type"
                >
                  <el-radio-group v-model="createParams.report_type">
                    <el-radio :label="1">简报</el-radio>
                    <el-radio :label="2">深度</el-radio>
                  </el-radio-group>
                </el-form-item>
                <!-- <el-form-item label="首页滚动" prop="index_scroll">
                  <el-radio-group v-model="createParams.index_scroll">
                    <el-radio :label="2">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                  </el-radio-group>
                </el-form-item> -->
                <el-form-item label="行业领域" prop="label_id">
                  <!-- <el-form-item label="行业领域" prop="label_id" required> -->
                  <checkTag
                    ref="checkTag"
                    width="440px"
                    @getTagList="getTagList"
                  ></checkTag>
                </el-form-item>

                <el-form-item label="自定义领域" prop="domain">
                  <el-tag
                    v-for="tag in createParams.domain"
                    :key="tag"
                    class="common-tag"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    v-if="inputInstance.inputVisible"
                    ref="InputRef"
                    v-model="inputInstance.inputValue"
                    style="width: 80px"
                    size="small"
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag ml-1"
                    size="small"
                    @click="showInput"
                  >
                    + 新标签
                  </el-button>
                </el-form-item>


                <!-- <el-form-item label="" prop="domain">
                  <el-input
                    v-model="createParams.domain"
                    placeholder="请输入行业领域,多个用逗号隔开"
                    style="width: 440px"
                  ></el-input>
                </el-form-item> -->
                <el-form-item label="发布时间" prop="publish_time">
                  <el-date-picker
                    value-format="YYYY-MM-DD HH:mm"
											format="YYYY-MM-DD HH:mm"
                    v-model="createParams.publish_time"
                    type="datetime"
                    placeholder="选择日期"
                    style="width: 440px"
                  />
                </el-form-item>
                <el-form-item label="相关专辑" prop="ablum_ids">
                  <el-select
                    v-model="createParams.ablum_ids"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入专辑名称查找"
                    :remote-method="remoteMethodAlbum"
                    style="width: 440px"
                    @change="albumChange"
                  >
                    <el-option
                      v-for="item in albumList"
                      :key="item.id"
                      :label="item.title"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="相关品牌" prop="company_ids">
                  <el-select
                    v-model="createParams.company_ids"
                    value-key="name"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入品牌名称查找"
                    :remote-method="remoteMethodCompany"
                    style="width: 440px"
                  >
                    <el-option
                      v-for="item in companyList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="上传附件"
                  prop="file_link"
                  v-if="createParams.report_type === 2"
                >
                  <div>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      :action="`${baseUrl}admin/upload/image`"
                      :on-success="
                        (file) => {
                          return avatarUploadSuccess(file, 'file_link');
                        }
                      "
                      :headers="{ Authorization: 'Bearer ' + adminToken }"
                      name="image"
                      accept=".PDF,.ppt, image/png,image/jpg,image/jpeg"
                      :on-progress="
                        (event, file, fileList) => {
                          return handleProgress(event, file, fileList, 'file');
                        }
                      "
                      :on-change="
                        (file, fileList) => {
                          return handlechangeFile(file, fileList, 'file');
                        }
                      "
                    >
                      <img
                        v-if="
                          createParams.file_link &&
                          createParams.file_link.slice(-3) !== 'pdf' &&
                          createParams.file_link.slice(-3) !== 'ppt'
                        "
                        :src="createParams.file_link"
                        class="avatar"
                      />
                      <el-icon v-else class="avatar-uploader-icon"
                        ><plus
                      /></el-icon>
                    </el-upload>
                    <el-progress
                      :percentage="fileProgress"
                      v-if="fileProgress"
                    />
                    <!-- <p>只支持.jpg 格式</p> -->
                    <div
                      v-if="
                        (createParams.file_link &&
                          createParams.file_link.slice(-3) !== 'pdf') ||
                        (createParams.file_link &&
                          createParams.file_link.slice(-3) !== 'ppt')
                      "
                      class="update_img_box"
                    >
                      <div>{{ createParams.file_link }}</div>
                      <div class="close_tip" @click="deleteFile"> <el-icon ><Close /></el-icon> </div>
                      
                    </div>
                  </div>
                </el-form-item>
                <!-- <el-form-item label="发布时间" prop="push_time">
                  <el-date-picker
                    v-model="createParams.push_time"
                    style="width: 400px"
                    type="datepush_time"
                    placeholder="选择时间"
                  >
                  </el-date-picker>
                </el-form-item> -->
                <!-- <el-form-item label="首页推荐" prop="recommend_type">
                  <el-radio-group v-model="createParams.recommend_type">
                    <el-radio :label="2">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                  </el-radio-group>
                </el-form-item> -->
              </el-form>
            </div>
          </el-card>
          <div class="submit-box pd-16 mg-t-12">
            <el-row justify="end">
              <el-col :span="6">
                <el-button size="small" plain @click="handleSave(0)"
                  >保存为草稿</el-button
                >
                <el-button size="small" plain @click="handleSave(1)"
                  >预览</el-button
                >
                <el-button size="small" type="primary" @click="handleSave(2)"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <perviewPage
        v-if="previewFalg"
        :showData="showData"
        :type="'article'"
        @closeShow="closeShow"
      ></perviewPage>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  toRefs,
  reactive,
  nextTick,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Plus, Close } from "@element-plus/icons-vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
// import checkTag from '@/components/CheckTag'
import perviewPage from "@/components/previewPage/preview.vue";
import checkTag from "@/components/CheckTagNew";
import tinymceEditor from "@/components/tinymceEditor/index.vue";
import { configData } from "../../../utils";
import { ElMessage } from 'element-plus'
export default {
  components: {
    Plus,
    Close,
    Header,
    BreadCrumb,
    checkTag,
    tinymceEditor,
    perviewPage,
  },
  setup() {
    const previewFalg = ref(false); // 预览蒙层
    const showData = ref({
      companyArr: [],
      paperArr: [],
      personArr: [],
      title: "",
      author: "",
      description: "",
      nowDate: "",
      content: "",
    });
    const currentCtx = getCurrentInstance().ctx;
    const router = useRouter();
    const route = useRoute();
    const reportId = ref(route.params.id);
    const { proxy } = getCurrentInstance();
    const isLoading = ref(true);
    const state = reactive({
      breadList: [
        {
          name: "报告",
          path: {
            name: "reportsList",
          },
        },
        {
          name: "编辑报告",
        },
      ],
      adminToken: localStorage.getItem("adminToken"),
      inputInstance: {
        inputVisible: false,
        inputValue: "",
      },
      baseUrl: window.env.baseUrl,
      createParams: {
        title: "",
        type: 0,
        summary: "",
        banner: "",
        cover_img:"",
        label_id: [],
        publish_time: "",
        recommend_type: "",
        company_ids: "",
        ablum_ids: "",
        domain: [],
      },
      createRule: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [
          { required: true, message: "请输入报告内容", trigger: "blur" },
        ],
        author_name: [
          { required: true, message: "请输入作者", trigger: "blur" },
        ],
        report_type: [
          { required: true, message: "请选择报告类型", trigger: "change" },
        ],
        banner: [
          { required: true, message: "请上传banner图", trigger: "change" },
        ],
        cover_img: [
          { required: true, message: "请上传封面图", trigger: "change" },
        ],
      },
    });
    const formRef = ref();
    const bannerProgress = ref(0);
    const bannerProgressCover = ref(0);
    const posterBannerProgress = ref(0);
    const fileProgress = ref(0);
    const uploadRef = ref();
    const isBannerSuccess = ref(false);
    const albumList = ref([]);
    const companyList = ref([]);
    const checkTagValue = ref([]);
    
    onMounted(() => {
      route.params.id && getInfo();
      if (!route.params.id) {
        isLoading.value = false;
      }
    });

    const getAlbumList = async (keyword) => {
      const res = await Ajax.POST({
        url: "/admin/mini/ablum/serach",
        data: {
          keyword,
          page: 1,
          page_size: 100,
        },
      });
      // console.log('res===', res)
      if (res.data.code == 200) {
        albumList.value = res.data.data.items;
      }
    };
    const remoteMethodAlbum = (query) => {
      if (query) {
        getAlbumList(query);
      } else {
        albumList.value = [];
      }
    };

    const getCompanyList = async (keyword) => {
      const res = await Ajax.POST({
        url: "/admin/brand/search",
        data: {
          name: keyword,
          page: 1,
          page_size: 100,
        },
      });
      // console.log('res===', res)
      if (res.data.code == 200) {
        companyList.value = res.data.data;

        console.log(companyList.value);
      }
    };

    const remoteMethodCompany = (query) => {
      if (query) {
        getCompanyList(query);
      } else {
        companyList.value = [];
      }
    };
    // 上传图片

    const beforeUpload = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        ElMessage.error('上传文件大小不能超过 5MB!')
        this.$refs.upload.clearFiles();
      }
      return isLt2M
    };
    const avatarUploadSuccess = (e, type) => {
      // console.log('type', e, type)
      debugger
      state.createParams[type] = e.data.url;
      if (e.code === 200) {
        if (type === "banner") {
          isBannerSuccess.value = true;
        }else if(type === "cover_img"){
          bannerProgressCover.value = true;
        } else {
          fileProgress.value = 100;
        }
      }
    };

    const handleProgress = (event, file, fileList, type) => {
      console.log('type', type)
      // debugger
      // console.log('上传(event, file, fileList)', event.loaded/event.total*100, event, file, fileList)
      if (type === "banner" ) {
        bannerProgress.value = Math.floor((event.loaded / event.total) * 100);
      } else if (type === "cover_img") {
        bannerProgressCover.value = Math.floor((event.loaded / event.total) * 100);
      }else if (type === "poster_banner"){
        posterBannerProgress.value = Math.floor((event.loaded / event.total) * 100);
      } else {
        fileProgress.value = Math.floor((event.loaded / event.total) * 100);
      }
    };

    const handlechangeFile = (file, fileList, type) => {
      // console.log('bannerProgress.value====', bannerProgress.value)
      isBannerSuccess.value = false;
      if (type === "banner") {
        // bannerProgress.value = isBannerSuccess.value ? 100 :  0
        bannerProgress.value = 0;
      } else if (type === "cover_img") {
        // bannerProgress.value = isBannerSuccess.value ? 100 :  0
        bannerProgressCover.value = 0;
      } else if (type === "poster_banner"){
        posterBannerProgress.value = 0;
      }  else {
        fileProgress.value = 0;
      }
    };

    const handleSuccess = (response, file, fileList) => {
      console.log("handleSuccesshandleSuccess", bannerProgress.value);
      if (type === "banner") {
        isBannerSuccess.value = true;
      } else if ( type === "cover_img") {
        isBannerSuccess.value = true;
      } else {
        fileProgress.value = 0;
      }
    };
    //删除文件
    const deleteFile =()=>{
      state.createParams.file_link=""
    }

    const handleExceed = (file) => {
      // console.log('upload', upload.value)
      uploadRef.value.clearFiles();
      console.log("删除后uploadRef.value", uploadRef.value);
      uploadRef.value.handleStart(file[0]);
      // console.log('files===', files)
    };
    // 删除标签
    const handleClose = (tag) => {
      // console.log("state", state.createParams.label_id);
      // let index = state.createParams.label_id.indexOf(tag);
      // state.createParams.label_id.splice(index, 1);
      state.createParams.domain.splice(
        state.createParams.domain.indexOf(tag),
        1
      );
    };

    // 增加标签
    const showInput = () => {
      state.inputInstance.inputVisible = true;
      // nextTick(() => {
      //   let ipt = currentCtx.$refs.InputRef;
      //   ipt.focus();
      // });
    };

    // 确认添加标签
    const handleInputConfirm = () => {
      let {
        inputInstance,
        createParams: { domain },
      } = state;
      if (inputInstance.inputValue) {
        domain.push(inputInstance.inputValue);
      }
      inputInstance.inputVisible = false;
      inputInstance.inputValue = "";
    };

    // 获取详情
    const getInfo = async (id) => {
      const reporID = route.params.id ? route.params.id : id
      const res = await Ajax.POST({
        url: "/admin/mini/report/details/" + reporID,
        data: state.queryData,
      });
      if (res.data.code == 200) {
        //自定义标签转数组
        if(res.data.data.domain==""){
          res.data.data.domain=[]
        }else if(res.data.data.domain!=""){
          res.data.data.domain=res.data.data.domain.split(",")
        }
        
        state.createParams = res.data.data;
        showData.value = res.data.data;
        if (res.data.data.brand_ids) {
          state.createParams.company_ids = res.data.data.brand_ids.map(Number);
        }
        if (res.data.data.ablum_ids) {
          state.createParams.ablum_ids = res.data.data.ablum_ids.map(Number);
        }
        albumList.value = res.data.data.ablums;
        companyList.value = res.data.data.companys;
        var list = res.data.data.label_list.map((item) => {
          return item.name;
        });
        state.createParams.label_id = list;
        if (res.data.data.label_list.length > 0) {
          checkTagValue.value = res.data.data.label_list;
          proxy.$refs.checkTag.setValue(checkTagValue.value);
        }
      }
      isLoading.value = false;
    };

    const albumChange = (e) => {
      console.log("e=====", e);
    };

    // 保存
    const handleSave = (status) => {
      console.log("status", status);
      let url = route.params.id
        ? `/admin/mini/report/update/${route.params.id}`
        : "/admin/mini/report/create";
      state.createParams.domain=state.createParams.domain.toString()
      const {
        title,
        content,
        author_name,
        description,
        banner,
        cover_img,
        publish_time,
        report_type,
        publish_status,
        file_link,
        company_ids,
        ablum_ids,
        domain,
        poster_banner
      } = state.createParams;
      console.log("state.createParams", state.createParams);
      let tag = ref([]);
      tag.value = proxy.$refs.checkTag.getValue();
      console.log(tag.value)
      // return
      formRef.value.validate(async (valid) => {
        if (valid) {
          const res = await Ajax.POST({
            url,
            data: {
              publish_status: Number(status) === 2 ? status : 0,
              label_id: tag.value ?  tag.value.split(",") : [],
              brand_ids: company_ids,
              title,
              content,
              author_name,
              description,
              banner,
              cover_img,
              report_type,
              file_link,
              publish_time,
              ablum_ids,
              domain,
              poster_banner
            },
          });
          if (res.data.code == 200) {
            if (Number(status) === 1) {
              getInfo(res.data.data.id);
              previewFalg.value = true;
            } else {
              route.params.id
                ? MessageTips.success("编辑成功")
                : MessageTips.success("创建成功");
              router.push({
                name: "reportsList",
                params: {
                  id: reportId.value? reportId.value: res.data.data.id,
                },
              });
            }
          }
        } else {
          return;
        }
      });
    };

    // 取消
    const handleCancel = () => {
      formRef.value.resetFields();
    };

    const getContent = (val) => {
      // console.log('富文本内容', val)
      state.createParams.content = val;
    };

    const getTagList = (val) => {
      // console.log('val====', val)
      state.createParams.label_id = val;
      formRef.value.validateField(["label_id"]);
    };

    const closeShow = () => {
      previewFalg.value = false;
      showData.value = {
        companyArr: [],
        paperArr: [],
        personArr: [],
        title: "",
        author: "",
        description: "",
        nowDate: "",
        content: "",
      };
    };
    return {
      ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      handleSave,
      handleCancel,
      avatarUploadSuccess,
      reportId,
      getContent,
      isLoading,
      getTagList,
      formRef,
      handleProgress,
      bannerProgress,
      fileProgress,
      handlechangeFile,
      uploadRef,
      handleExceed,
      handleSuccess,
      isBannerSuccess,
      bannerProgressCover,
      remoteMethodAlbum,
      getAlbumList,
      albumList,
      getCompanyList,
      remoteMethodCompany,
      companyList,
      beforeUpload,
      previewFalg,
      showData,
      closeShow,
      albumChange,
      deleteFile,
      posterBannerProgress
    };
  },
};
</script>

<style lang="less" scoped>
.create-album-container {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  .el-card {
    overflow-y: auto;
  }
  .form-box {
    width: 50%;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
    .avatar {
      width: 108px;
      height: 108px;
    }
    
  }

  .common-tag {
    margin-right: 8px;
  }
  .submit-box {
    background-color: #fff;
  }
  .report_type {
    margin-top: 70px;
  }
  .update_img_box{
      display: flex;
      align-items: center;
    }
    .close_tip{
      margin-left: 10px;
      align-items: center;
      display: flex;
      cursor: pointer;
    }
}
</style>
