<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="list-container">
          <div class="add-btn">
            <el-button  size="small" class="btn-add" type="primary" @click="addHandle">创建报告</el-button>
          </div>
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="pd-32" style="background: #fff">
                <el-table :data="ListData" border :max-height="650">
                  <el-table-column
                    name="title"
                    prop="title"
                    label="内容"
                  />
                  <el-table-column prop="update_time" label="更新时间" />
                  <el-table-column label="操作">
                    <template #default="scope">
                      <el-link
                          class="mg-r-12"
                          type="primary"
                          @click="handleDetail(scope.row.id)"
                          >预览</el-link
                      >
                      <el-link
                          class="mg-r-12"
                          type="primary"
                          @click="handleEdit(scope.row.id)"
                          >编辑</el-link
                      >
                      <el-link
                        class="mg-r-12"
                        type="primary"
                        @click="changeStatus(scope.row.id)"
                        >删除</el-link
                      >
                      <el-link
                        class="mg-r-12"
                        type="primary"
                        @click="handleEdit(scope.row.id, true)"
                        >发布</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    v-show="paginationData.total > paginationData.size"
                    :background="background"
                    :current-page.sync="paginationData.current"
                    :page-size.sync="paginationData.size"
                    :layout="layout"
                    :total="paginationData.total"
                    v-bind="$attrs"
                    @current-change="handleCurrentChange"
                    @pagination="getTableData"
                  />
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";

export default {
  components: {
    Header,
    BreadCrumb,
    SearchFilterBar,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      breadList: [
        {
          name: "草稿箱",
        },
      ],
      queryData: {
        size: 10,
        page: 1,
      },
      mainTableKey: 1,
    });

    const ListData = ref([]);
    const paginationData = ref({
      current: 1,
      size: state.queryData.size,
      total: 0,
    })

    const getTableData = async () => {
      const ret = await Ajax.POST({
        url: "/admin/mini/report/draft",
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        ListData.value = ret.data.data.items;
        paginationData.value.total = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const setStatus = async (id, status) => {
      const ret = await Ajax.POST({
        url: "/admin/mini/report/remove/" + id,
      });
      if (ret.data.code === 200) {
        getTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const changeStatus = async (id, status) => {
      ElMessageBox.confirm('确定删除该素材？', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setStatus(id);
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };

    const handleCurrentChange = (val) => {
      state.queryData.page = val;
      paginationData.value.current = val
      getTableData();
    };

    const handleSizeChange = () => {};

    // 详情
    const handleDetail = (item) => {
      router.push({
        name: "reportsDetail",
        params: { id: item },
      });
    };

    const addHandle = () => {
      router.push({
        name: "reportsListEdit",
      });
    }

    const handleEdit = (id, status) => {
      router.push({
        name: "reportsListEdit",
        params: { isPublish: status, id },
      });
    }
    onMounted(() => {
      getTableData();
    });

    return {
      ...state,
      changeStatus,
      handleCurrentChange,
      handleSizeChange,
      getTableData,
      ListData,
      paginationData,
      handleDetail,
      addHandle,
      handleEdit
    };
  },
  methods: {
    setgender(row, colum) {
      return row.report_type === 1 ? "简报": "深度";
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  height: 100%;
  .add-btn{
    display: flex;
    justify-content: flex-end;
  }
  .status-text {
    font-size: 14px;
    &.primary {
      color: #409eff;
    }
    &.info {
      color: #909399;
    }
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
