<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            @addClick="handleAdd"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="table-container pd-32">
                <el-table :data="tableData" border :max-height="650">
                  <el-table-column prop="id" label="消息ID" width="80" />
                  <el-table-column prop="title" label="标题" />
                  <el-table-column
                    prop="create_time"
                    label="推送时间"
                    width="160"
                  />
                  <el-table-column label="操作" width="100">
                    <template #default="scope">
                      <!-- {{ scope.row }} -->
                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="gotoPage(scope.row.id)"
                        >查看</el-link
                      >
                      <!-- <el-popconfirm title="确认删除该消息?" >
                                    <template #reference> -->
                      <el-link type="primary" @click="showDialog(scope.row.id)"
                        >删除</el-link
                      >
                      <!-- </template>
                                </el-popconfirm> -->
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

  <el-dialog v-model="isShowDialog" :width="500">
    <span>确认删除该消息?</span>
    <template #footer>
      <el-button @click="isShowDialog = false">取消</el-button>
      <el-button type="primary" @click="deleteMessage()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import SearchFilterBar from "../../components/SearchFilterBar/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../utils";
export default {
  components: {
    SearchFilterBar,
    Header,
    BreadCrumb,
  },
  setup() {
    const breadLists = ref([
      {
        name: "消息",
      },
    ]);
    const router = useRouter();
    const statusLabelsRef = ref([]);
    const isShowDialog = ref(false);
    const searchForm = ref({
      page_size: 10,
      page: 1,
      title: undefined,
    });
    const deleteID = ref();
    // 查询
    const handleSearch = (res) => {
      searchForm.value.title = res.searchValue;
      paginationDataRef.value.current = 1;
      searchForm.value.page = 1;
      getSearchData();
    };

    const getSearchData = () => {
      Ajax.GET({
        url: "/admin/message",
        data: null,
        params: searchForm.value,
      }).then((res) => {
        tableDataRef.value = res.data.data.list;
        paginationDataRef.value.total = res.data.data.page_info.total;
        statusLabelsRef.value[0].num = res.data.data.page_info.total;
      });
    };
    // 新增
    const handleAdd = (e) => {
      console.log("add-click", e);
      router.push({
        name: "createMessage",
      });
    };
    // 表格数据
    const tableDataRef = ref([]);
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      console.log(e, "sizeChange");
      searchForm.value.page_size = e;
      getSearchData();
    };
    const handleCurrentChange = (e) => {
      console.log(e, "currentChange");
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getSearchData();
    };
    const gotoPage = (id) => {
      router.push({
        name: "messageDetail",
        query: {
          id: id,
        },
      });
    };
    const showDialog = (id) => {
      deleteID.value = id;
      isShowDialog.value = true;
    };
    const deleteMessage = () => {
      Ajax.DELETE({
        url: `/admin/message/${deleteID.value}`,
      }).then((res) => {
        isShowDialog.value = false;
        if (res.data.code == 200) {
          MessageTips.success("删除成功");
          getSearchData();
        } else {
          MessageTips.error("删除失败");
        }
      });
    };
    onMounted(() => {
      statusLabelsRef.value = [
        {
          name: "全部",
          value: 0,
        },
      ];
      getSearchData();
    });
    return {
      breadLists,
      handleAdd,
      handleSearch,
      tableData: tableDataRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      statusLabels: statusLabelsRef,
      gotoPage,
      isShowDialog,
      showDialog,
      deleteMessage,
    };
  },
};
</script>

<style lang="less" scoped>
.person-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
}
</style>