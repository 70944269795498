<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">反馈信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="auditForm"
                :rules="feedBackInfoRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="反馈内容">
                  <span>{{ detail.desc }}</span>
                </el-form-item>
                <el-form-item label="用户">
                  <span>{{ detail.username }}</span>
                </el-form-item>
                <el-form-item label="联系方式">
                  <span>{{ detail.phone }}</span>
                </el-form-item>
                <el-form-item label="反馈时间">
                  <span>{{ detail.create_time }}</span>
                </el-form-item>
                <el-form-item label="状态">
                  <span v-if="detail.status === 1">待处理</span>
                  <span v-else-if="detail.status === 2">已处理</span>
                  <span v-else>已驳回</span>
                  <el-link
                    style="line-height: 16px"
                    type="primary"
                    class="mg-l-32"
                    :href="deskUrl"
                    target="black"
                    v-if="detail.client!=2"
                    >跳转前台页面</el-link
                  >
                </el-form-item>
                <el-form-item
                  label="回复内容"
                  required
                  class="mg-t-12"
                  prop="remark"
                >
                  <el-input
                    type="textarea"
                    v-model="auditForm.remark"
                    :rows="10"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-24">
            <el-row justify="end">
              <el-col :span="24" class="text-right">
                <el-button plain @click="cancelSubmit('formRef')"
                  >取消</el-button
                >
                <el-button plain @click="rejectSubmit('formRef')"
                  >驳回</el-button
                >
                <el-button type="primary" @click="submitForm('formRef')"
                  >采纳</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../utils";
// import {env} from '../../../public/js/env'
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const breadLists = ref([
      {
        name: "反馈",
        path: {
          name: "feedbacks",
        },
      },
      {
        name: "反馈详情",
      },
    ]);
    const { proxy } = getCurrentInstance();
    const id = router.currentRoute.value.query.id;
    const deskUrl = ref();
    const operationFormRef = ref({
      remark: "您的反馈我们已收到，感谢支持，我们会尽快进行处理，谢谢合作。",
    });
    const feedBackInfoRule = {
      remark: [
        {
          required: true,
          message: "请输入回复内容",
          trigger: "blur",
        },
      ],
    };
    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields()
      back();
    };
    const back = () => {
      router.go(-1);
    };
    //驳回
    const rejectSubmit = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          Ajax.PUT({
            url: `/admin/feedback/${id}`,
            data: {
              status: 3,
              remark: operationFormRef.value.remark,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("编辑成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          Ajax.PUT({
            url: `/admin/feedback/${id}`,
            data: {
              status: 2,
              remark: operationFormRef.value.remark,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("编辑成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };

    const detail = ref({
      desc: "",
      username: "",
      phone: "",
      create_time: "",
      status: "",
    });

    onMounted(() => {
      Ajax.GET({
        url: `/admin/feedback/${id}`,
      }).then((res) => {
        if (res.data.code == 200) {
          detail.value = res.data.data;
          if (detail.value.type == 1) {
            deskUrl.value = `${window.env.deskUrl}mechanism/detail?id=${detail.value.unique_id}`;
          } else if (detail.value.type == 2) {
            deskUrl.value = `${window.env.deskUrl}company/detail?id=${detail.value.unique_id}`;
          } else if (detail.value.type == 3) {
            deskUrl.value = `${window.env.deskUrl}person/detail?id=${detail.value.unique_id}`;
          }
        }
      });
    });
    return {
      breadLists,
      auditForm: operationFormRef,
      feedBackInfoRule,
      cancelSubmit,
      submitForm,
      rejectSubmit,
      detail,
      deskUrl,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 50%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
  }
}
::v-deep(.el-form-item) {
  margin-bottom: 0px;
}
</style>