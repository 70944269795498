import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import { Ajax, MessageTips } from "@/utils";
const router = createRouter({
  history: createWebHistory('/'),
  // history: createWebHistory('/sxiphiinsights-admin/'),
  routes
})


function getUrlParams(url) {
  // 通过 ? 分割获取后面的参数字符串
  let urlStr = url.split('?')[1]
  // 创建空对象存储参数
let obj = {};
  // 再通过 & 将每一个参数单独分割出来
  if(urlStr && urlStr.includes('&')){
    let paramsArr = urlStr.split('&')
    for(let i = 0,len = paramsArr.length;i < len;i++){
          // 再通过 = 将每一个参数分割为 key:value 的形式
      let arr = paramsArr[i].split('=')
      obj[arr[0]] = arr[1];
    }
  }

return obj
}
// console.log(getUrlParams(window.location.href))
// if(getUrlParams(window.location.href)['sessionId']){
// // setToken(getUrlParams(window.location.href)['token']||'')
// localStorage.setItem('sessionId',getUrlParams(window.location.href)['sessionId'])

// }
const baseUrl = window.env.baseUrlUni;
let  getNavBar = async () => {
  localStorage.setItem('sessionId',getUrlParams(window.location.href)['sessionId'])

  let res =await Ajax.GET({
    url: `${baseUrl}permission/getStaffSyStemList`,
    params:{session_id:getUrlParams(window.location.href)['sessionId']}
  })
// if(res.data.code == 10000){
//     navBar.value = res.data.data
// }
// let res = await getStaffSyStemList({session_id:getUrlParams(window.location.href)['sessionId']})
  if(res.data.code == 10000){
  res.data.data.forEach(item => {
    if(item.name.includes('生辉智库后台')){
      localStorage.setItem('navBar',JSON.stringify(res.data.data))
      localStorage.setItem('adminToken',item.token.split(' ')[1])
      window.location.href = item.url+'/index'
      item.isActive = true
    }else{
      item.isActive = false
    }
  })
  // localStorage.setItem('navBar',JSON.stringify(res.data))
  console.log('写入token')
  console.log(res.data.data)
}
}


router.beforeEach(async(to, from, next) => {
  if(getUrlParams(window.location.href)['sessionId'])await getNavBar()
  const token = localStorage.getItem('adminToken');
  // 设置标题
  if(to.meta.title) {
      document.title = to.query.pageName || to.meta.subTitle || to.meta.title;
  }
  if(to.name !== 'Login' && !token) {
    // next(window.location.href = 'https://login.dtinsights.cn/#/login')
    window.location.href = 'https://login.dtinsights.cn/#/login'
  }else {
    next()
  }
  // TODO 自动登录 保存token48个小时
  
})
export default router
