import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import router from './router'
import './styles/element/style.css'
import './utils/reset.css'
import './utils/common.less'
console.log(process.env.VUE_APP_BASE_API)
const app = createApp(App)
app.use(ElementPlus, {
    locale: zhCn
})
app.use(router)
app.mount('#app')