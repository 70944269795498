<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists" />
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="person-container">
					<SearchFilterBar
							:statusLabels="statusLabels"
							@statusChange="statusChange"
							@addClick="handleClick"
							@searchClick="handleSearch"
							batchUrl="admin/brand/import"
							:batchShow="true"
							:selectShow="false"
							:addShow="true"
							:moduleShow="true"
							:downUrl="'https://image.sciphi.cn/sciphi/import_temp/brand_temp.xlsx'"
					>
					</SearchFilterBar>
					<div class="table-con mg-t-12">
						<el-scrollbar>
							<div class="table-container pd-32">
								<el-table v-loading="tableLoading" :data="tableData" border :max-height="680">
									<el-table-column prop="id" label="品牌ID" width="90"/>
									<el-table-column prop="brand_name" label="品牌名称" width="90" show-overflow-tooltip></el-table-column>
									<el-table-column prop="company_name" label="主体名称" width="180" show-overflow-tooltip></el-table-column>
									<el-table-column prop="" label="国家地区" width="140">
										<template #default="scope">
											<span :title="scope.row.country_name + scope.row.province_name + scope.row.city_name">
										{{ showString(scope.row.country_name + scope.row.province_name + scope.row.city_name, 8) }}
											</span>
										</template>
									</el-table-column>
									<el-table-column width="100" prop="establish_time" label="成立时间" />
									<el-table-column prop="trade_arr" label="行业领域" :show-overflow-tooltip="true">
										<template #default="scope">
											<div v-for="(item,index) in scope.row.trade_arr" :key="index">
												<el-tag v-if="index < 1">{{item}} ...</el-tag>
											</div>
										</template>
									</el-table-column>
									<el-table-column prop="operator_name" label="最近录入人姓名" width="100"/>
									<el-table-column prop="publish_time" label="发布时间" width="140"/>
									<el-table-column prop="publish_status" width="70" label="状态">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.publish_status === 1">已发布</span>
											<span class="status-text danger" v-if="scope.row.publish_status === 2">未发布</span>
											<span class="status-text success" v-if="scope.row.publish_status === 3">已撤回</span>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="105">
										<template #default="scope">
											<el-link type="primary" class="mg-r-12" @click="toDetail(scope.row.id)">详情</el-link>
											<el-link type="primary" class="mg-r-12" v-if="scope.row.publish_status === 1" @click="cooperation(scope.row.id, 3)">撤回</el-link>
											<el-link type="primary" class="mg-r-12" v-else-if="[2, 3].includes(scope.row.publish_status)" @click="cooperation(scope.row.id, 1)">发布</el-link>
										</template>
									</el-table-column>
								</el-table>
								<div class="table-pagination-row mg-t-24">
									<el-pagination
											background
											:currentPage="paginationData.current"
											:page-sizes="paginationData.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="paginationData.total"
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
									>
									</el-pagination>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../components/SearchFilterBar/index.vue'
import { ref, onMounted, getCurrentInstance} from 'vue'
import {useRouter} from 'vue-router';
import { Ajax, MessageTips} from '../../../utils'
export default {
	components: {
		SearchFilterBar,
		Header,
		BreadCrumb
	},
	setup() {
		const breadListsRef = ref([
			{
				name: '品牌'
			}
		])
		const router = useRouter();
		const searchVal = ref('')
		const statusLabelsRef = [
			{
				name: '全部',
				value: 0,
			},
			{
				name: '已发布',
				value: 1,
			},
			{
				name: '未发布',
				value: 2,
			},
			{
				name: '已撤回',
				value: 3,
			}
		];
		const companyStatus = ref(0)
		const {proxy} = getCurrentInstance()

		// 搜索
		const statusChange = status => {
			companyStatus.value = status;
			paginationDataRef.value.current = 1;
			getList();
		}
		// 查询
		const handleSearch = value => {
			searchVal.value = value.searchValue;
			getList();
		}
		// 新增
		const handleClick = (e) => {
			router.push({
				name: 'CreateBrand'
			})
		};

		const toDetail = id => {
			router.push({
				name: 'BrandDetail',
				query: {
					id
				}
			})
		}

		const cooperation = (id, status) => {
			let str = status === 1 ? '发布' : '撤回'
			let type = status === 1 ? 'success' : 'error'
			proxy
					.$confirm('此操作将' + str + '该数据, 是否继续?', "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: type,
					})
					.then(async () => {
						Ajax.POST({
							url: '/admin/brand/publish/' + id,
							params: {
								id: id,
								publish_status: status
							}
						}).then(res => {
							if(res.data.code === 200) {
								MessageTips.success('成功');
								getList();
							}else {
								MessageTips.error(res.data.msg)
							}
						}).catch((err) => {
							MessageTips.error('失败')
						});
					})
		}

		// 表格数据
		const tableDataRef = ref([])
		const tableLoadingRef = ref(false)
		// 页码
		let paginationDataRef = ref({
			current: 1,
			pageSize: [10, 20, 50],
			page_size: 10,
			total: 0
		});
		const handleSizeChange = e => {
			paginationDataRef.value.page_size = e;
			getList()
		}
		const handleCurrentChange = e => {
			paginationDataRef.value.current = e;
			getList()
		}

		const showString = (string ,len) => {
			return string.length >len ? string.substring(0,len) + '...' : string
		}

		const getList = () => {
			tableLoadingRef.value = true;
			Ajax.POST({
				url: '/admin/brand',
				data: {
					page: paginationDataRef.value.current,
					page_size: paginationDataRef.value.page_size,
					name: searchVal.value,
					publish_status: companyStatus.value
				}
			}).then(res => {
				tableLoadingRef.value = false;
				if(res.data.code === 200) {
					tableDataRef.value = res.data.data.list;
					paginationDataRef.value.total = res.data.data.page_info.total;
					paginationDataRef.value.current = res.data.data.page_info.page;
				}
			})
		}
		onMounted(() => {
			getList();
			paginationDataRef.value.pageSize = [10, 20, 50]
		})
		return {
			breadLists: breadListsRef,
			statusLabels: statusLabelsRef,
			statusChange,
			handleClick,
			handleSearch,
			tableLoading: tableLoadingRef,
			tableData: tableDataRef,
			paginationData: paginationDataRef,
			handleSizeChange,
			handleCurrentChange,
			toDetail,
			cooperation,
			showString
		}
	}
};
</script>

<style lang="less" scoped>

.person-container {
	height: 100%;
.table-pagination-row {
	display: flex;
	justify-content: flex-end;
}
.table-con {
	height: 100%;
	max-height: calc(100% - 66px - 12px);
	background-color: #fff;
}
.table-container {
	padding-bottom: 16px;
}
}
</style>
