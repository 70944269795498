<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box" v-if="!isLoading">
      <div class="pd-20">
        <div class="album-detail-container">
          <el-card>
                <div class="basic-container">
                  <el-card class="mg-b-16">
                    <el-form
                      ref="formRef"
                      :model="DetailInfo"
                      label-width="120px"
                      class="form-box mg-t-24"
                    >
                    <img :src="DetailInfo.banner" alt=""  style="max-width: 100%;margin-bottom: 20px"/>
                    <el-form-item label="姓名:" prop="real_name">
                        {{ DetailInfo.real_name }}
                      </el-form-item>
                    <el-form-item label="手机号:" prop="mobile">
                        {{ DetailInfo.mobile }}
                      </el-form-item>
                      <el-form-item label="单位名称:">
                        <div v-html="DetailInfo.workplace"></div>
                      </el-form-item>

                      <el-form-item label="所属部门:">
                        {{ DetailInfo.department }}
                      </el-form-item>
                      <el-form-item label="职位:">
                        {{ DetailInfo.post }}
                      </el-form-item>
                      <el-form-item label="公司邮箱:">
                        {{ DetailInfo.company_email }}
                      </el-form-item>
                      <el-form-item label="个人邮箱:">
                        {{ DetailInfo.email }}
                      </el-form-item>
                      <el-form-item label="常驻城市:">
                        {{ DetailInfo.province_name }} {{ DetailInfo.city_name }}
                        <span v-if="DetailInfo.is_abroad==1">
                          海外
                        </span>
                      </el-form-item>
                      <el-form-item label="所在行业:">
                        {{ DetailInfo.industry }}
                      </el-form-item>
                      <el-form-item label="公司规模:">
                        {{ DetailInfo.scale }}
                      </el-form-item>
                      <el-form-item label="关注领域:">
                        {{ DetailInfo.research_label }}
                      </el-form-item>
                      <el-form-item label="报名时间:">
                        {{ DetailInfo.create_time }}
                      </el-form-item>
                      <el-form-item label="报名渠道:">
                        {{ DetailInfo.source_name }}
                      </el-form-item>
                      <el-form-item label="名片信息:">
                        <!-- <img v-if="DetailInfo.image!=null" class="business_card" :src="DetailInfo.image" alt=""> -->
                        <el-image
                        v-if="DetailInfo.image!=null"
                        class="business_card"
                        :preview-src-list="[DetailInfo.image]"
                        style="width: 400px; height: 200px"
                          :src="DetailInfo.image"
                          fit="cover"
                        />
                        <div v-else>暂无</div>
                        <br>
                        <el-button
                          v-if="DetailInfo.image!=null"
                          size="small"
                          type="primary"
                          @click="downloadFile(DetailInfo.image)"
                          >下载名片</el-button>
                        
                      </el-form-item>
                      <!-- <el-form-item label="相关排行:">
                        <span v-for="item in DetailInfo.ablums" :key="item.id" class="aboutitem">{{item.title}}</span>
                      </el-form-item> -->
                      <!-- <el-form-item label="相关品牌:">
                        <span v-for="item in DetailInfo.companys" :key="item.id" class="aboutitem">{{item.name}}</span>
                      </el-form-item> -->
                    </el-form>
                  </el-card>
                  <div class="submit-box pd-16 mg-t-12" v-if="DetailInfo.publish_status !== 2">
                    <el-row justify="end">
                      <el-col :span="3">
                        <el-button
                          size="small"
                          type="primary"
                          @click="goBack"
                          >返回</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
// import addCompany from "./addCompany.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from '@/components/BreadCrumb/index.vue'
import { ElMessageBox } from "element-plus";

export default {
  components: {
    Plus,
    // addCompany,
    Header,
    BreadCrumb
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadList = ref([
      {
        name: "报名",
        path: {
          name: "dtiApply",
        },
      },
      {
        name: "报名详情",
      },
    ]);
    const DetailInfo = ref();
    const isLoading = ref(true)
    const getInfo = async () => {
      const ret = await Ajax.GET({
        url: "/admin/mini/signup/detail/" + route.params.id,
      });
      
      if (ret.data.code === 200) {
        DetailInfo.value = ret.data.data;
        isLoading.value = false
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    onMounted(() => {
      getInfo();
    });
    //返回
    const goBack = ()=>{
      router.push({name:"dtiApply"})
    }
    // 编辑
    // const handleEdit = () => {
    //   router.push({
    //     name: "reportsListEdit",
    //     params: {
    //       id: route.params.id,
    //     },
    //   });
    // };

    return {
      breadList,
      // handleEdit,
      goBack,
      DetailInfo,
      isLoading
    };
  },
  methods: {
    showAdress(row, colum) {
      if (row.city_name) {
        return row.city_name;
      }
      if (row.province_name) {
        return row.province_name;
      }

      return "";
    },
    pushDate(row, colum) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row > 0) {
        var time = new Date(row * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y +
          "-" +
          add0(m) +
          "-" +
          add0(d) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    },

    downloadFile(src) {
      //下载文件
      let image = new Image();
      let imgName="名片";
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function (e) {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        // window.navigator.msSaveBlob(canvas.msToBlob(), 'image.jpg');
        let url = canvas.toDataURL("image/png");
        let a = document.createElement("a");
        let event = new MouseEvent("click");
        if (imgName) {
          a.download = imgName + ".jpg";
        } else {
          a.download = "down_load.jpg";
        }
        a.href = url;
        // 触发a的单击事件
        a.dispatchEvent(event);
      };
      // 获取img上的src值，赋值之后，完成之后会调用onload事件
      image.src = src;

    }
  },
};
</script>

<style lang="less" scoped>
.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  ::v-deep(.el-tabs__content) {
    flex: 1;
    overflow-y: auto;
  }
}
.basic-container {
  word-break: break-all;
  .form-box {
    width: 100%;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
  }
  .submit-box {
    background-color: #fff;
  }
  .aboutitem{
    margin-right: 20px;
  }
}
.content-container {
  height: 100%;
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
 .lable-item{
    margin-right: 10px;
  }
  .business_card{
    width: 400px;
    height: 200px;
  }
  .down_img{

  }
</style>
