<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="tags-container">
                    <SearchFilterBar :statusLabels="statusLabels" @statusChange="statusChange" @addClick="handleAdd" @searchClick="handleSearch" />
                    <div class="table-con mg-t-12">
                        <el-scrollbar>
                            <div v-loading="tableLoading" class="table-container pd-32">
                                <el-table :data="tableData" border :max-height="650">
                                    <el-table-column width="120" prop="id" label="数据分类树ID" />
                                    <el-table-column prop="name" label="数据分类树名称" />
                                    <el-table-column prop="desc" label="描述">
                                        <template #default="scope">
                                            <span>{{ scope.row.desc || '-' }}</span>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="update_time" label="最近编辑时间" /> -->
                                    <!-- <el-table-column prop="create_time" label="发布时间" /> -->
                                    <!-- <el-table-column width="90" prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column> -->
                                    <el-table-column width="120" label="操作">
                                        <template #default="scope">
                                            <el-link class="mg-r-12" type="primary" @click="toPage('TagsDetail', {id: scope.row.id})">详情</el-link>
                                            <el-link class="mg-r-12" type="primary" @click="toPage('TagsEdit', {id: scope.row.id, title: scope.row.name})">编辑</el-link>
                                            <!-- <el-link v-if="scope.row.status === 1" class="mg-r-12" type="primary" @click="handleOperation({id: scope.row.id, status: 4})">撤回</el-link> -->
                                            <!-- <el-link v-else-if="[3, 4].includes(scope.row.status)" class="mg-r-12" type="primary" @click="handleOperation({id: scope.row.id, status: 1})">发布</el-link> -->
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="table-pagination-row mg-t-24">
                                    <!-- <el-pagination
                                        background
                                        :currentPage="paginationData.current"
                                        :page-sizes="paginationData.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="paginationData.total"
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                    >
                                    </el-pagination> -->
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../Layout/components/Header.vue';
import BreadCrumb from '../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../components/SearchFilterBar/index.vue'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { Ajax, MessageTips } from '../../utils'
export default {
    components: {
        SearchFilterBar,
        Header,
        BreadCrumb
    },
    setup() {
        const router = useRouter();
        const breadListsRef = ref([
            {
                name: '数据分类'
            }
        ])
        const statusLabelsRef = ref([
            {
                value: 0,
                name: '全部'
            },
            {
                value: 1,
                name: '已发布'
            },
            {
                value: 4,
                name: '已撤回'
            }
        ]);
        const nowStatusRef = ref(null);
        const nowSearchValueRef = ref('');
        // 状态选择
        const statusChange = status => {
            nowStatusRef.value = status
            getList()
        }
        // 查询
        const handleSearch = searchObj => {
            nowSearchValueRef.value = searchObj.searchValue;
            getList()
        }
        // 新增
        const handleAdd = (e) => {
            router.push({
                name: 'CreateTags'
            })
        };
        // 表格数据
        const tableLoadingRef = ref(false);
        const tableDataRef = ref([])
        // 页码
        let paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            page_size: 10,
            total: 0
        });
        const handleSizeChange = e => {
            paginationDataRef.value.page_size = e;
            getList();
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList();
        }

        const toPage = (name, query) => {
            router.push({
                name,
                query
            })
        }

        // 发布/撤回操作
        const handleOperation = ({id, status}) => {
            Ajax.POST({
                url: '/admin/label_tree/status',
                data: {
                    id,
                    status
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('操作成功')
                    getList();
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }

        // 获取列表数据
        const getList = async () => {
            tableLoadingRef.value = true;
            const res = await Ajax.POST({
                // url: '/admin/label_tree',
                url:"/admin/tags",
                params: {
                    page: paginationDataRef.value.current,
                    page_size: paginationDataRef.value.page_size,
                    name: nowSearchValueRef.value || undefined,
                    status: nowStatusRef.value ? nowStatusRef.value : undefined
                }
            })
            tableLoadingRef.value = false;
            const resData = res.data
            if(resData.code === 200) {
                tableDataRef.value = resData.data;
                // paginationDataRef.value.current = resData.data.page_info.page;
                // paginationDataRef.value.total = resData.data.page_info.total;
            }
        }
        
        onMounted(() => {
            getList();
        })
        return {
            breadLists: breadListsRef,
            statusChange,
            handleAdd,
            handleSearch,
            tableLoading: tableLoadingRef,
            tableData: tableDataRef,
            paginationData: paginationDataRef,
            handleSizeChange,
            handleCurrentChange,
            statusLabels: statusLabelsRef,
            toPage,
            handleOperation
        }
    }
};
</script>

<style lang="less" scoped>
    .tags-container {
        height: 100%;
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
        .table-con {
            height: 100%;
            max-height: calc(100% - 66px - 12px);
            background-color: #fff;
        }
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
    }
</style>