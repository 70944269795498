<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists"/>
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="patent-detail-container">
					<el-card class="mg-b-16" v-loading="loading">
						<template #header>
							<div class="card-header">
								<span class="card-name">报道基本信息</span>
								<el-button type="primary" plain @click="goEditPage('ReportsEdit', '编辑基本信息')">编辑基本信息</el-button>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-form
										label-width="140px"
										class="user-form-box mg-t-24"
								>
									<el-form-item label="报道标题：" >
										{{detail.name}}
									</el-form-item>
									<el-form-item label="作者：" >
										<el-tag class="mg-r-12" v-for="item in detail.author"
														:key="item">{{item}}</el-tag>
									</el-form-item>
									<el-form-item label="来源：" >
										{{detail.source}}
									</el-form-item>
									<el-form-item label="报道时间：" >
										{{detail.report_time}}
									</el-form-item>
									<el-form-item label="链接：" >
										{{detail.link}}
									</el-form-item>
									<el-form-item label="封面图：" >
										<img style="width: 100px" :src="detail.image" alt="">
									</el-form-item>
									<el-form-item label="摘要：" >
										{{detail.profile}}
									</el-form-item>
									<el-form-item label="行业领域：" >
										<el-tag class="mg-r-12" v-for="item in detail.trade"
														:key="item">{{item}}</el-tag>
									</el-form-item>
									<el-form-item label="关键词：" >
										<el-tag class="mg-r-12" v-for="item in detail.keywords"
														:key="item">{{item}}</el-tag>
									</el-form-item>
									<el-form-item label="相关人物：" >
										<el-tag class="mg-r-12" v-for="item in detail.personage_label"
														:key="item.id">{{item.name}}</el-tag>
									</el-form-item>
									<el-form-item label="相关品牌：" >
										<el-tag class="mg-r-12" v-for="item in detail.brand_label"
														:key="item.id">{{item.name}}</el-tag>
									</el-form-item>
									<el-form-item label="相关机构：" >
										<el-tag class="mg-r-12" v-for="item in detail.org_label"
														:key="item.id">{{item.name}}</el-tag>
									</el-form-item>
									<el-form-item label="状态：" >
										<span class="status-text primary" v-if="detail.status === 1">已发布</span>
										<span class="status-text success" v-else-if="detail.status === 2">待完善</span>
										<span class="status-text green" v-else-if="detail.status === 3">已完善</span>
										<span class="status-text info" v-else>已撤回</span>
									</el-form-item>
									<el-form-item label="最新编辑时间：" >
										{{detail.update_time}}
									</el-form-item>
									<el-form-item label="发布时间：" >
										{{detail.create_time}}
									</el-form-item>
								</el-form>
							</el-col>
						</el-row>
					</el-card>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import {useRouter, useRoute} from 'vue-router'
import {ref, onMounted} from 'vue'
import {Ajax} from "../../../utils";

export default {
	components: {
		Header,
		BreadCrumb
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const breadListsRef = ref([
			{
				name: '报道',
				path: {
					name: 'Reports'
				}
			},
			{
				name: '报道详情'
			}
		])

		const detail = ref({})
		const loading = ref(true)

		// 去编辑页面
		const goEditPage = (pathName, pageName) => {
			router.push({
				name: pathName,
				query: {
					id: route.query.id
				}
			})
		}
		const getDetail = () => {
			Ajax.GET({
				url: `/admin/report/${route.query.id}`
			}).then(res => {
				loading.value = false
				if(res.data.code === 200) {
					detail.value = res.data.data
				}
			}).catch(res => {
				loading.value = false
			})
		}

		onMounted(() => {
			getDetail()
		})
		return {
			breadLists: breadListsRef,
			goEditPage,
			loading,
			detail
		}
	}
}
</script>

<style lang="less" scoped>
.card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

::v-deep(.el-descriptions__content) {
	color: rgb(16, 16, 16);
}
</style>
