<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="company-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">主体基本信息</span>
                                <el-button type="primary" plain @click="goEditPage('CompanyEdit', '编辑基本信息', 'edit-basic')">编辑基本信息</el-button>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-descriptions :column="2">
                                    <!-- <el-descriptions-item label-align="right" label="名称：">{{ companyBaseInfo.name }}</el-descriptions-item> -->
                                    <el-descriptions-item label-align="right" label="状态：">
                                        <span :class="`status-text ${statusClass[companyBaseInfo.status || 0]}`">{{ statusLabels[companyBaseInfo.status || 0] }}</span>
                                </el-descriptions-item>
                                <el-descriptions-item label-align="right" label=""></el-descriptions-item>
                                <el-descriptions-item label-align="right" label="全称：">{{ companyBaseInfo.register_name }}</el-descriptions-item>
                                <el-descriptions-item label-align="right" label="统一社会信用代码：">{{ companyBaseInfo.reg_no }}</el-descriptions-item>
                                    <el-descriptions-item label-align="right" label="地区：">{{ companyBaseInfo.country_name }}{{ companyBaseInfo.province_name?`-${companyBaseInfo.province_name}`:'' }} {{ companyBaseInfo.city_name?`-${companyBaseInfo.city_name}`:'' }}</el-descriptions-item>
                                    <el-descriptions-item label-align="right" label="最近编辑时间：">{{ companyBaseInfo.update_time }}</el-descriptions-item>
                                    <!-- <el-descriptions-item label-align="right" label="地区：">{{ companyBaseInfo.province_name }} {{ companyBaseInfo.city_name }}</el-descriptions-item> -->
                                    <el-descriptions-item label-align="right" label="成立时间：">{{ companyBaseInfo.establish_time }}</el-descriptions-item>
                                    <el-descriptions-item label-align="right" label="发布时间：" :span="2">{{  companyBaseInfo.publish_time || '-'}}</el-descriptions-item>
                                    <el-descriptions-item label-align="right" label="简介：" :span="2">{{ companyBaseInfo.profile }}</el-descriptions-item>
                                    <el-descriptions-item label-align="right" label="介绍：" :span="2">{{ companyBaseInfo.introduction }}</el-descriptions-item>
                                    <el-descriptions-item label-align="right" label="品牌：" :span="2">
                                        <template v-if="companyBaseInfo.brand && companyBaseInfo.brand.length">
                                            <span class="mg-r-12" v-for="item in companyBaseInfo.brand" :key="item.id">{{ item.name }}</span>
                                        </template>
                                    </el-descriptions-item>
                                    <!-- <el-descriptions-item label-align="right" label="行业领域：" :span="2">
                                        <template v-if="companyBaseInfo.trade_arr && companyBaseInfo.trade_arr.length">
                                            <span class="mg-r-12" v-for="item in companyBaseInfo.trade_arr" :key="item.id">{{ item.name }}</span>
                                        </template>
                                    </el-descriptions-item> -->
                                </el-descriptions>

                                <el-descriptions :column="3">
                                    <el-descriptions-item label="照片">
                                        <el-avatar style="vertical-align: middle;" :size="50" :src="companyBaseInfo.image || ''"></el-avatar>
                                    </el-descriptions-item>
                                    <el-descriptions-item label="官网">
                                        <!-- <iframe id="myiframe" scrolling="no">{{companyBaseInfo.link}}</iframe> -->

											<el-link  type="primary" @click="openLink(companyBaseInfo.link)">{{companyBaseInfo.link}}</el-link>
                                        <!-- <a v-if="companyBaseInfo.link" :href="companyBaseInfo.link" target="_blank" rel="noopener noreferrer">{{companyBaseInfo.link}}</a> -->
                                        <!-- <el-link v-if="companyBaseInfo.link" :href="companyBaseInfo.link" target="_blank" type="primary">{{companyBaseInfo.link}}</el-link> -->
                                    </el-descriptions-item>
                                    <el-descriptions-item label="联系方式"><span class="inline-block">电话：{{ companyBaseInfo.telephone || '-' }}<br />邮箱：{{ companyBaseInfo.email || '-' }}<br/>手机：{{ companyBaseInfo.mobile || '-' }}</span></el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">工商信息</span>
                                <!-- <el-button type="primary" plain @click="goEditPage('CompanyEdit', '编辑补充信息', 'edit-contact')">编辑补充信息</el-button> -->
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <ul class="business_box">
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">法定代表人</div>
                                            <div class="left_label">{{businessData.legal_person}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">经营状态</div>
                                            <div class="left_label">{{businessData.open_status}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">注册资本</div>
                                            <div class="left_label">{{businessData.reg_addr}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">实缴资本</div>
                                            <div class="left_label">{{businessData.paidin_capital}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">曾用名</div>
                                            <div class="left_label">{{businessData.prev_ent_name}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">所属行业</div>
                                            <div class="left_label">{{businessData.industry}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">统一社会信用代码</div>
                                            <div class="left_label">{{businessData.reg_no}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">纳税人识别号</div>
                                            <div class="left_label">{{businessData.tax_no}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">工商注册号</div>
                                            <div class="left_label">{{businessData.reg_code}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">组织机构代码</div>
                                            <div class="left_label">{{businessData.org_no}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">登记机关</div>
                                            <div class="left_label">{{businessData.authority}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">成立日期</div>
                                            <div class="left_label">{{businessData.start_date}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">企业类型</div>
                                            <div class="left_label">{{businessData.ent_type}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">营业期限</div>
                                            <div class="left_label">{{businessData.open_time}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right">
                                            <div class="right_label">行政区划</div>
                                            <div class="left_label">{{businessData.district}}</div>
                                        </div>
                                        <div class="business_left">
                                            <div class="right_label">核准日期</div>
                                            <div class="left_label">{{businessData.annual_date}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right business_bottom">
                                            <div class="right_label">参保人数</div>
                                            <div class="left_label">{{businessData.insurance_num}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right business_bottom">
                                            <div class="right_label">注册地址</div>
                                            <div class="left_label">{{businessData.reg_addr}}</div>
                                        </div>
                                    </li>
                                    <li class="busineww_item">
                                        <div class="business_right business_bottom">
                                            <div class="right_label">经营范围</div>
                                            <div class="left_label">{{businessData.scope}}</div>
                                        </div>
                                    </li>
                                </ul>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">主体成员（{{ companyBaseInfo.member && companyBaseInfo.member.length }}）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="companyBaseInfo.member">
                                    <el-table-column prop="id" label="人物ID" />
                                    <el-table-column prop="name" label="姓名" />
                                    <el-table-column prop="sex" label="性别">
                                        <template #default="scope">{{ scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : '-' }}</template>
                                    </el-table-column>
                                    <el-table-column prop="country" label="国家" />
                                    <el-table-column prop="province" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province }} {{ scope.row.city }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="type" label="人物类型">
                                        <template #default="scope">
                                            {{ personType[scope.row.type] }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="post" label="职位" />
                                    <el-table-column prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('member', '主体成员')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">对内融资（{{ getInvestList && getInvestList.length }}）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="getInvestList">

                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="round" label="轮次" />
                                    <el-table-column prop="finace_time" label="时间" />
                                    <el-table-column prop="amount" label="投资金额">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate ? scope.row.amount : scope.row.no_amount }}{{scope.row.amount_unit_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="all_investor_arr" label="投资方" />
                                    <el-table-column prop="appraise" label="估值">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate_app==1 ? scope.row.appraise : scope.row.no_appraise }}{{scope.row.appraise_unit_name}}
                                        </template>
                                    </el-table-column>

                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('financing-in', '对内融资')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">对外投资（{{ investList && investList.length }}）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="investList">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="finace_time" label="投资时间" />
                                    <el-table-column prop="company_name" label="名称" />
                                    <el-table-column prop="round" label="投资轮次" />
                                    <el-table-column prop="amount" label="投资金额">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate ? scope.row.amount : scope.row.no_amount }}{{scope.row.amount_unit_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="other_investor_arr" label="其他投资方" />
                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('financing-out', '对外投资')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card>
                    <!-- <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">竞合关系（{{ coopetitionList && coopetitionList.length }}）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="coopetitionList">
                                    <el-table-column prop="coopetition_company_id" label="主体ID" />
                                    <el-table-column prop="name" label="主体名称" />
                                    <el-table-column prop="province" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.country }} {{ scope.row.province }} {{ scope.row.city }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="establish_time" label="成立时间" />
                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('cooperation-agency', '竞合关系')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card> -->
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">软著列表（{{ worksList && worksList.length }}）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="worksList">
                                    <!-- <el-table-column prop="num" label="序号" /> -->
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="name" label="软件名称" />
                                    <el-table-column prop="profile" label="简介" />
                                    <el-table-column prop="version" label="版本号" />
                                    <el-table-column prop="classify" label="软著分类" />
                                    <el-table-column prop="trade" label="行业分类" />
                                    <el-table-column prop="register_time" label="等级日期" />
                                    <el-table-column prop="name" label="主体名称" />
                                    <el-table-column prop="province" label="详情">
                                        <template #default="scope">
                                            <el-link :href="scope.row.link" type="primary">详情</el-link>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('company-worksList', '软著列表')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card>
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">专利信息（{{ patentList && patentList.length }}）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="patentList">
                                    <el-table-column prop="apply_no" label="申请号" />
                                    <el-table-column prop="announce_time" label="公开(公告日)" />
                                    <el-table-column prop="name" label="名称" />
                                    <el-table-column prop="type" label="类型" />
                                    <el-table-column prop="announce_time" label="公布/公告日期" />
                                    <el-table-column prop="province" label="详情">
                                        <template #default="scope">
                                            <el-link :href="scope.row.link" type="primary">详情</el-link>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('company-patent', '专利信息')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card>

                    <!-- <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">相关报道（4）</span>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-table border :data="membersList">
                                    <el-table-column prop="num" label="序号" />
                                    <el-table-column prop="name" label="报道标题" />
                                    <el-table-column prop="target" label="来源" />
                                    <el-table-column prop="link" label="链接" />
                                    <el-table-column prop="status" label="状态">
                                        <template #default="scope">
                                            {{ scope.row }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-col>
                            <el-col>
                                <p class="text-right link-row mg-t-24">
                                    <span @click="toMore('reports', '相关报道')">更多</span>
                                </p>
                            </el-col>
                        </el-row>
                    </el-card> -->
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import { ref, onMounted, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Ajax, configData } from '../../../utils'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '主体',
                path: {
                    name: 'Company'
                }
            },
            {
                name: '主体详情'
            }
        ])
        const companyBaseInfoRef = ref({});
        const coopetitionListRef = ref([]);
        const worksListRef=ref([])
        const patentListRef=ref([])
        const businessDataRef = ref({});
        const route = useRoute();
        const statusLabels = configData.statusLabel;
        const statusClass=configData.statusClass
        const personType = configData.personType;
        onBeforeMount(() => {
            Ajax.GET({
                url: `/admin/company/${route.query.id}`
            }).then(res => {
                if(res.data.code === 200) {
                    companyBaseInfoRef.value = res.data.data;
                }
            })
            //获取竞合关系
            Ajax.GET({
                url: '/admin/company_coopetition',
                params: {
                    company_id: route.query.id,
                    page: 1,
                    page_size: 4
                }
            }).then(res => {
                if(res.data.code === 200) {
                    coopetitionListRef.value = res.data.data.list;
                }
            })
            // 获取软著列表
            Ajax.POST({
                url: '/admin/company/works',
                params: {
                    company_id: route.query.id,
                    page: 1,
                    page_size: 4
                }
            }).then(res => {
                if(res.data.code === 200) {
                    worksListRef.value = res.data.data.list;
                }
            })
            //获取专利列表
            Ajax.POST({
                url: '/admin/company/patent',
                params: {
                    company_id: route.query.id,
                    page: 1,
                    page_size: 4
                }
            }).then(res => {
                if(res.data.code === 200) {
                    patentListRef.value = res.data.data.list;
                }
            })
        })
        // 投资信息（对内对外）
        const getInvestListRef = ref([])
        const investListRef = ref([])
        onMounted(() => {
            Ajax.POST({
                url: `/admin/company/invest/${route.query.id}`,
                params: {
                    page: 1,
                    page_size: 4
                }
            }).then(res => {
                if(res.data.code === 200) {
                    // debugger
                    investListRef.value = res.data.data.list;
                }
            })
            Ajax.POST({
                url: `/admin/company/getinvest/${route.query.id}`,
                params: {
                    page: 1,
                    page_size: 4
                }
            }).then(res => {
                if(res.data.code === 200) {
                    // debugger
                    getInvestListRef.value = res.data.data.list;
                }
            })
            //工商信息
            Ajax.POST({
                url: `/admin/company/business`,
                params:{company_id:route.query.id}
            }).then(res => {
                if(res.data.code === 200) {
                    businessDataRef.value = res.data.data;
                }
            })
            //相关报道
            // Ajax.POST({
            //     url: `/admin/company/report`,
            //     params:{
            //         id:route.query.id,
            //         page: 1,
            //         page_size: 4
            //         }
            // }).then(res => {
            //     if(res.data.code === 200) {
            //         console.log(res.data)
            //         debugger
            //         // businessDataRef.value = res.data.data;
            //     }
            // })
        })

        // 创业经历
        const startExperienceRef = ref([]);
        onMounted(() => {
            startExperienceRef.value = [
                {
                    num: 1,
                    id: '123',
                    name: '主体名称',
                    date: '2020-01-12',
                    status: 1,
                },
                {
                    num: 2,
                    id: '123',
                    name: '主体名称',
                    date: '2020-01-12',
                    status: 2,
                },
                {
                    num: 3,
                    id: '123',
                    name: '主体名称',
                    date: '2020-01-12',
                    status: 3,
                }
            ]
        })

        const router = useRouter();
        // 去编辑页面
        const goEditPage = (pathName, pageName, type) => {
            // return ;
            router.push({
                name: pathName,
                query: {
                    pageName,
                    type,
                    id: route.query.id
                }
            })
        }
        const openLink=(link)=>{
            // window.location.href=link
            window.open(link,'_blank');
            // location.href=link
        }

        // 去相关列表页
        const toMore = (type, pageName) => {
            router.push({
                name: 'CompanyRelated',
                query: {
                    type,
                    pageName,
                    id: route.query.id
                }
            })
        }

        return {
            breadLists: breadListsRef,
            statusLabels,
            statusClass,
            personType,
            companyBaseInfo: companyBaseInfoRef,
            getInvestList: getInvestListRef,
            investList: investListRef,
            coopetitionList: coopetitionListRef,
            startExperience: startExperienceRef,
            goEditPage,
            toMore,
            businessData:businessDataRef,//工商信息
            worksList:worksListRef,//软著列表
            patentList:patentListRef,//专利列表
            openLink,
        }
    }
};
</script>

<style lang="less" scoped>
    .inline-block {
        display: inline-block;
        vertical-align: top;
    }
    .company-detail-container {
        height: 100%;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        ::v-deep(.el-descriptions__content) {
            color: rgb(16, 16, 16);
        }
        .no-data-tip {
            color: var(--el-color-primary);
            text-align: center;
            font-size: 14px;
        }
        .link-row {
            span {
                color: var(--el-color-primary);
                text-decoration: underline;
                cursor: pointer;
            }
        }
        ::v-deep(.el-avatar>img) {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }
    .business_box{
        width: 80%;
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
        .busineww_item{
                display: flex;
                font-size: 14px;
                color: #333;
                border-bottom: 1px solid #eee;
            .business_right,.business_left{
                width: 50%;
                display: flex;
                .right_label{
                    flex: 160px 0 0;
                    background: linear-gradient(180deg,#fff,#f7f3fe);
                    line-height: 18px;
                    padding: 16px 20px;
                    font-size: 13px;
                    color: #333;
                    display: flex;
                    align-items: center;
                }
                .left_label{
                    flex: 1 0 0;
                    line-height: 18px;
                    padding: 16px 20px;
                    font-size: 13px;
                    color: #333;
                    border-right: 1px solid #eee;
                }
            }
            .business_bottom{
                width: 100%;
            }
        }
    }
</style>
