<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists"/>
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="person-container">
					<SearchFilterBar :statusLabels="statusLabels" @addClick="handleAdd" @statusChange="statusChange"
													 @searchClick="handleSearch" selectShow :selectOptions="selectOptions" selectLable="类型" @typeChange="claimChange"/>
					<div class="table-con mg-t-12">
						<el-scrollbar>
							<div class="table-container pd-32">
								<el-table v-loading="tableLoading" :data="tableData" border :max-height="650">
									<el-table-column width="90" prop="id" label="人物ID"/>
									<el-table-column prop="name" label="姓名">
										<template #default="scope">
											{{ scope.row.name || '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="sex" label="性别" width="50">
										<template #default="scope">
											{{ scope.row.sex === 1 ? '男' : scope.row.sex === 2 ? '女' : '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="country_name" label="国家">
										<template #default="scope">
											{{ scope.row.country_name || '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="city" label="地区">
										<template #default="scope">
											{{ `${scope.row.province_name} ${scope.row.city_name}` || '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="type" label="人物类型" width="100">
										<template #default="scope">
											{{ scope.row.type == 1 ? '学者' : scope.row.type == 2 ? '创业者' : '投资人' }}
										</template>
									</el-table-column>
									<el-table-column prop="profile" label="人物简介" width="100">
										<template #default="scope">
											<el-popover effect="light" trigger="hover" placement="top" width="auto">
												<template #default>
													<div>人物简介: {{ scope.row.profile }}</div>
												</template>
												<template #reference>
													<div class="profile">{{ scope.row.profile }}</div>
												</template>
											</el-popover>
										</template>
									</el-table-column>
									<el-table-column prop="operator_name" label="最近录入人姓名" width="100"/>
									<el-table-column prop="update_time" label="最近编辑时间">
										<template #default="scope">
											{{ scope.row.update_time || '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="publish_time" label="发布时间">
										<template #default="scope">
											{{ scope.row.publish_time || '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="status" label="状态" width="80">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
											<span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="120">
										<template #default="scope">
											<el-link class="mg-r-12" type="primary" @click="toPage('/person/detail', {id: scope.row.id})">详情
											</el-link>
											<el-link class="mg-r-12" v-if="scope.row.status === 1" type="primary"
															 @click="operation(scope.row.id, 4)">撤回
											</el-link>
											<el-link class="mg-r-12" v-else-if="[3, 4].includes(scope.row.status)" type="primary"
															 @click="operation(scope.row.id, 1)">发布
											</el-link>
										</template>
									</el-table-column>
								</el-table>
								<div class="table-pagination-row mg-t-24">
									<el-pagination
											background
											:currentPage="paginationData.current"
											:page-sizes="paginationData.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="paginationData.total"
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
									>
									</el-pagination>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../components/SearchFilterBar/index.vue'
import {ref, onMounted, onBeforeMount} from 'vue'
import {useRouter} from 'vue-router'
import {Ajax, MessageTips} from '../../../utils'

export default {
	components: {
		SearchFilterBar,
		Header,
		BreadCrumb
	},
	setup() {
		const breadListsRef = ref([
			{
				name: '人物'
			}
		])
		const tableLoadingRef = ref(true)
		const router = useRouter();
		const statusLabelsRef = ref([]);
		const searchValuesRef = ref({
			searchValue: ''
		});
		const personStatus = ref(0)
		const selectOptions = ref([
			{value: '', label: '全部'},
			{value: 1, label: '认领'},
			{value: 2, label: '认证'}
		])
		const statusChange = status => {
			personStatus.value = status
			getPersonList()
		}
		// 查询
		const handleSearch = (value) => {
			console.log(value);
			searchValuesRef.value.searchValue = value.searchValue;
			searchValuesRef.value.claim_type = value.selectValue
			getPersonList()
		}
		// 新增
		const handleAdd = () => {
			router.push({
				name: 'CreatePerson'
			})
		};
		// 表格数据
		const tableDataRef = ref([])
		// 页码
		let paginationDataRef = ref({
			current: 1,
			pageSize: [10, 20, 50],
			page_size: 10,
			total: 0
		});
		const handleSizeChange = e => {
			console.log(e, 'sizeChange');
			paginationDataRef.value.page_size = e;
			getPersonList()
		}
		const handleCurrentChange = e => {
			console.log(e, 'currentChange')
			paginationDataRef.value.current = e;
			getPersonList()
		}

		const toPage = (path, query) => {
			const newpage = router.resolve({
				path, // 跳转的页面路由
				query
			})
      		window.open(newpage.href, '_blank') // 打开新的窗口(跳转路径，跳转类型)
		}

		// 下拉状态变化
		const claimChange = (e) => {
			// console.log('下拉', e)
			searchValuesRef.value.claim_type = e
			getPersonList()
		}
		// 操作
		const operation = (id, status) => {
			Ajax.POST({
				url: '/admin/personage/status',
				data: {
					id,
					status
				}
			}).then(res => {
				if (res.data.code === 200) {
					MessageTips.success('操作成功');
					getPersonList();
				} else {
					MessageTips.error(res.data.msg)
				}
			})
		}

		// 获取人物列表
		const getPersonList = () => {
			tableLoadingRef.value = true;
			Ajax.GET({
				url: '/admin/personage',
				params: {
					page: paginationDataRef.value.current,
					page_size: paginationDataRef.value.page_size,
					name: searchValuesRef.value.searchValue || undefined,
					status: personStatus.value ? personStatus.value : undefined,
					claim_type: searchValuesRef.value.claim_type
				}
			}).then(res => {
				if (res.data.code === 200) {
					tableDataRef.value = res.data.data.list
					paginationDataRef.value.current = res.data.data.page_info.page;
					paginationDataRef.value.total = res.data.data.page_info.total;
					tableLoadingRef.value = false;
				}
			})
		}

		onMounted(() => {
			getPersonList();
			statusLabelsRef.value = [
				{
					name: '全部',
					value: 0,
				},
				{
					name: '已发布',
					value: 1,
				},
				{
					name: '已完善',
					value: 3,
				},
				{
					name: '待完善',
					value: 2,
				},
				{
					name: '已撤回',
					value: 4,
				}
			]
		})
		return {
			breadLists: breadListsRef,
			tableLoading: tableLoadingRef,
			statusChange,
			handleAdd,
			handleSearch,
			tableData: tableDataRef,
			paginationData: paginationDataRef,
			handleSizeChange,
			handleCurrentChange,
			statusLabels: statusLabelsRef,
			toPage,
			operation,
			selectOptions,
			claimChange
		}
	}
};
</script>

<style lang="less" scoped>
.person-container {
	height: 100%;

.table-pagination-row {
	display: flex;
	justify-content: flex-end;
}

.table-con {
	height: 100%;
	max-height: calc(100% - 66px - 12px);
	background-color: #fff;
}

.status-text {
	font-size: 14px;

&
.primary {
	color: #409eff;
}

&
.success {
	color: #67c23a;
}

&
.danger {
	color: #f56c6c;
}

&
.info {
	color: #909399;
}

}
}
.profile {
	white-space: none;
	word-wrap: break-word;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
</style>
