<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-social-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">社会组织基本信息</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="orgBaseInfo"
                                :rules="orgBaseInfoRule"
                                label-width="120px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="名称" prop="name">
                                    <el-input v-model="orgBaseInfo.name" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="成立时间" prop="date">
                                    <el-date-picker value-format="YYYY-MM-DD" v-model="orgBaseInfo.establish_time" type="date" placeholder="选择日期" />
                                </el-form-item>
                                <el-form-item label="简介" prop="desc">
                                    <el-input v-model="orgBaseInfo.desc" maxlength="100" show-word-limit type="textarea" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { Ajax, MessageTips } from '../../../../utils'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '社会组织',
                path: {
                    name: 'OrganizationSocial'
                }
            },
            {
                name: '创建社会组织'
            }
        ])
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const orgBaseInfoRef = ref({
            name: '',
            establish_time: '',
            desc: ''
        })
        const orgBaseInfoRule = {
            name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }
            ]
        }
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        const submitForm = formName => {
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    Ajax.POST({
                        url: '/admin/social_org',
                        data: orgBaseInfoRef.value
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('新增成功')
                            router.back()
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }
        return {
            breadLists: breadListsRef,
            orgBaseInfo: orgBaseInfoRef,
            orgBaseInfoRule,
            cancelSubmit,
            submitForm
        }
    }
};
</script>

<style lang="less" scoped>
    .create-social-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
    }
</style>