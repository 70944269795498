<template>
  <el-dialog
    v-model="dialogShow"
    title="选择学者"
    width="800px"
    :close-on-click-modal="true"
    :show-close="false"
    destroy-on-close
    @close="handleBack"
  >
    <div class="dialo-content">
      <el-form ref="form" @submit.native.prevent>
        <el-form-item style="width: 200px">
          <el-input
            v-model="dialogSearchInp"
            placeholder="搜索学者"
            class="input-with-select"
            @keyup.enter.native="handleSearchStudent"
          >
          
            <template #append>
              <el-button :icon="Search" @click="handleSearchStudent"></el-button>
            </template>
          </el-input>
          <!-- <el-input v-model="dialogSearchInp" :placeholder="searchPlaceholder" @keyup.enter.native="handleSearchStudent" /> -->
        </el-form-item>
      </el-form>
      <el-table :data="dialogTable" border max-height="300">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="name" label="姓名" />
        <!-- <el-table-column prop="type" label="所属领域" /> -->
        <el-table-column label="所属领域">
          <template #default="scope">
            <div v-for="item in scope.row.invest_label_arr" :key="item">{{item}}</div>
          </template>
        </el-table-column>
        <el-table-column label="类型">
          <template #default="scope">
            <div >{{scope.row.type=='1'?'学者':scope.row.type=='2'?'创业者':'投资人'}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="unit_name" label="单位" />
        <el-table-column prop="post" label="职位" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-radio v-model="dialogRadio" :label="scope.row.id" @change="()=> handleRadioChange(scope.row.id)"
              >选择</el-radio
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination-row mg-t-24">
        <el-pagination
          background
          :currentPage="dialogPaginationData.current"
          :page-sizes="dialogPaginationData.pageSizes"
          :page-size="dialogPaginationData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dialogPaginationData.total"
          @size-change="dialogHandleSizeChange"
          @current-change="dialogHandleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <el-button plain @click="handleBack">返回</el-button>
      <el-button type="primary" @click="handleAddStudent">完成</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Plus, Search } from "@element-plus/icons-vue";
import { Ajax, MessageTips } from "@/utils";
export default {
  components: {
    Plus,
  },
  props: {
    isShowAddCompany: Boolean,
    companyId: String
  },

  setup(props, {emit}) {
    const route = useRoute();
    const router = useRouter();
    /* 弹窗选择 start */
    const dialogShow = ref(props.isShowAddCompany);
    const dialogSearchInpRef = ref("");
    const dialogTable = ref([]);
    const dialogRadioRef = ref("");
    const dialogPaginationData = ref({
      current: 1,
      pageSize: 10,
      pageSizes: [10, 20, 50],
      total: 0,
    });
    const object_id = ref('')
    const companyId = ref(props.companyId)
    onMounted(() => {
      // handleSearchStudent();
    });

    // 搜索学者
    const handleSearchStudent = async () => {
      // debugger
      let page = dialogPaginationData.value.current;
      let size = dialogPaginationData.value.pageSize;
      const res = await Ajax.GET({
        url: "/admin/personage",
        params: {
          name: dialogSearchInpRef.value,
          // type: 1, //1学者2创业者3投资人
          page,
          page_size:size,
          status:1
        },
      });
      if (res.data.code == 200) {
        dialogPaginationData.value.total = res.data.data.page_info.total;
        dialogTable.value = res.data.data.list
      }
    };

    // 添加学者
    const handleAddStudent = async (e) => {
      const res = await Ajax.POST({
        url: `/admin/album/createPersonage/${companyId.value}`,
        data: {
          object_id: object_id.value,
        },
      });
      console.log('res', res)
      if (res.data.code === 200){
        emit('closeDialog')
      } else {
        MessageTips.error(res.data.msg)
      }
    };

    const dialogHandleSizeChange = (e) => {
      dialogPaginationData.value.pageSize = e;
      dialogPaginationData.value.current = 1
      handleSearchStudent()
    };
    const dialogHandleCurrentChange = (e) => {
      dialogPaginationData.value.current = e;
      handleSearchStudent()
    };
    const handleBack = () => {
      dialogShow.value = false;
      emit('closeDialog')
    };
    /* 弹窗选择 end */
    const handleRadioChange = id => {
      object_id.value = id
    }
    return {
      // 弹窗
      dialogShow,
      dialogSearchInp: dialogSearchInpRef,
      dialogTable,
      dialogRadio: dialogRadioRef,
      dialogPaginationData,
      dialogHandleSizeChange,
      dialogHandleCurrentChange,
      handleBack,
      handleSearchStudent,
      Search,
      handleRadioChange,
      object_id,
      handleAddStudent,
      companyId
    };
  },
};
</script>

<style lang="less" scoped></style>
