<template>
    <div class="menu-container">
        <div class="logo-box">
            <!-- <img class="logo-pic" src="../../assets/images/logo-admin.png" alt=""> -->
            <span>生辉智库管理后台</span>
            <div v-if="baseUrl=='http://insights-api-test.sciphi.cn/'" style="color:#fff;font-size:18px;position: absolute;left: 172px;">(测试)</div>
        </div>
        <el-scrollbar class="menu-list">
            <el-menu 
                :default-active="menuActive"
                :router="true"
                unique-opened
                background-color="#0F012C" 
                text-color="#fff" 
            >
                <MenuTrees :list="menuList" />
            </el-menu>
        </el-scrollbar>
    </div>
</template>
 
<script>
import { Message, Setting, Menu } from '@element-plus/icons-vue'
import useMenuList from './useMenuList'
// import MenuTrees from './MenuTrees.vue'
import MenuTrees from '../../components/MenuTrees/MenuTrees.vue'
import { computed, onBeforeMount,reactive } from 'vue';
import { Aes } from '@/utils'
import { useRoute } from 'vue-router'
import moment from 'moment'
export default {
    components: {
        Message,
        Setting,
        Menu,
        MenuTrees
    },
    setup() {
        onBeforeMount(() => {
            if(localStorage['adminAutoLogin']) {
                let obj = JSON.parse(Aes.decrypt(localStorage['adminAutoLogin']))
                const diff = moment(new Date()).diff(moment(obj.time).add(2, 'day'), 'seconds')
                if(diff >= 0) {
                    localStorage['adminAutoLogin'] = ''
                }
            }
        })
        const state = reactive({
            baseUrl: window.env.baseUrl,
        });
        const route = useRoute();
        // 处理匹配路由
        const routeThird = ['/event/invest', '/event/exit', '/messages/push', '/organization/investment', '/organization/school', '/organization/social']; // 父级路由没有对应页面的路由，需要单独匹配
        const menuActive = computed(() => {
            const routePath = route.path;
            if(routeThird.includes(routePath)) {
                return routePath;
            }
            if(routePath.includes('/event/invest')) {
                return '/event/invest'
            }else if (routePath.includes('/event/exit')) {
                return '/event/exit'
            }else if(routePath.includes('/messages/push')) {
                return '/messages/push'
            }else if(routePath.includes('/organization/social')) {
                return '/organization/social'
            }else if(routePath.includes('/organization/investment')) {
                return '/organization/investment'
            }else if(routePath.includes('/organization/school')) {
                return '/organization/school'
            }else {
                return `/${routePath.split('/')[1]}`;
            }
        })
        
        return {
            ...useMenuList(),
            menuActive: menuActive,
            baseUrl:state.baseUrl
        }
    }
};
</script>

<style scoped lang="less">
    .menu-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        .logo-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: var(--layout-header-height);
            .logo-pic {
                display: block;
                width: 172px;
                height: 26px;
            }
        }
        .menu-list {
            height: calc(100% - var(--layout-header-height));
            ::v-deep(.el-menu) {
                border: none;
            }
            ::v-deep(.el-sub-menu) {
                .menu-title {
                    display: flex;
                    align-items: center;
                }
            }
            ::v-deep(.el-menu-item) {
                .menu-title {
                    display: flex;
                    align-items: center;
                }
            }
            ::v-deep(.el-menu-item.is-active) {
                position: relative;
                color: #fff;
                .menu-title {
                    position: relative;
                    z-index: 1;
                    display: flex;
                    align-items: center;
                }
                &::after {
                    z-index: 0;
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    width: 86%;
                    height: 42px;
                    transform: translateY(-50%);
                    border-top-right-radius: 21px;
                    border-bottom-right-radius: 21px;
                    background-color: var(--el-color-primary);
                }
            }
        }
    }
</style>