<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">机构信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="reserachForm"
                :rules="reserachFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="机构类型" prop="institution_type">
                    <el-select v-model="reserachForm.institution_type" placeholder="请选择机构类型">
                        <el-option :label="item.name" :value="item.id" v-for="item in institutionTypeArr" :key="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="账号名称" prop="name">
                  <el-input
                    style="width: 400px"
                    maxlength="50"
                    v-model="reserachForm.name"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="账号简介" prop="profile">
                  <el-input
                    v-model="reserachForm.profile"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 14 }"
                    maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="账号头像" prop="image">
                  <el-upload
                    class="avatar-uploader-box"
                    :class="{
                      border: !reserachForm.image,
                    }"
                    :show-file-list="false"
                    :http-request="uploadImage"
                    action="#"
                    accept="image/jpg, image/jpeg, image/png"
                    :before-upload="beforeUpload"
                  >
                    <div v-if="reserachForm.image" class="avatar-img-box">
                      <img :src="reserachForm.image" class="avatar" />
                    </div>
                    <div class="avatar-uploader" v-else>
                      <el-icon  class="avatar-uploader-icon">
                      <plus />
                      
                    </el-icon>
                    </div>
                  </el-upload>
                  <span>大小不可超过5M，支持JPG、PNG格式</span>
                </el-form-item>
                <el-form-item label="机构主体" prop="unit_name">
                  <el-autocomplete
                    v-model="reserachForm.unit_name"
                    :fetch-suggestions="querySearchUnit"
                    :trigger-on-focus="false"
                    clearable
                    value-key="name"
                    class="inline-input w-50 input_fill"
                    :class="['input_fill', mastTypeFlag ? 'input_fill_red' : '']"
                    placeholder="单位/机构名称"
                    @select="handleSelectWork"
                    maxlength="100"
                  />
                </el-form-item>

                <el-form-item label="机构领域" prop="name">
                  <!-- <el-input
                    style="width: 400px"
                    maxlength="100"
                    v-model="reserachForm.name"
                    placeholder="请输入"
                  ></el-input> -->
                  <div class="area_box" v-if="reserachForm.subject_list.length>0" @click="openAreaDialog">
                    <span v-for="(item, index) in reserachForm.subject_list" :key="index">{{item.subject_name}} | </span>
                  </div>
                  <div @click="openAreaDialog" v-else class="add_area">添加领域</div>
                </el-form-item>

                <el-form-item label="机构网站" prop="link">
                  <el-input
                    style="width: 400px"
                    maxlength="200"
                    v-model="reserachForm.link"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                
                <el-form-item label="所在地区" prop="area">
                  <el-cascader
                    v-model="reserachForm.area"
                    :options="provincesArrRef"
                    @change="provinceChange"
                    style="width: 300px"
                    :props="{
                      value: 'id',
                      label: 'name',
                      children: 'child',
                    }"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="机构成立时间" prop="">
                  <el-date-picker
                    style="width: 400px"
                    v-model="reserachForm.established_time"
                    type="year"
                    placeholder="请选择"
                    :value-format="'YYYY'"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="机构详细地址" prop="address">
                  <el-input
                    style="width: 400px"
                    maxlength="200"
                    v-model="reserachForm.address"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-24">
            <el-row justify="end">
              <el-col :span="4">
                <el-button plain @click="cancelSubmit('formRef')"
                  >取消</el-button
                >
                <el-button type="primary" @click="submitForm('formRef')"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <!-- 研究领域弹窗 -->
    <ResearchArea
      :dialogVisible="openArea"
      :subject="reserachForm.subject"
      :subjectlist="reserachForm.subject_list"
      @handleclose="closeArea"
      @handleconfirm="confirmArea"
    />

  </div>
  
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips, Rule } from "../../../utils";
import { Plus, Search } from "@element-plus/icons-vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import ResearchArea from "../../../components/ResearchArea/index.vue";
import Header from "../../../Layout/components/Header.vue";
export default {
  components: {
    Plus,
    BreadCrumb,
    Header,
    ResearchArea,
  },
  setup() {
    const router = useRouter();
    const breadLists = ref([
      {
        name: "期刊课题组列表",
        path: {
          name: "ResearchGroup",
        },
      },
      {
        name: "编辑期刊课题组",
      },
    ]);
    const openArea =ref(false)
    const basePramas =ref({
      subject:"",
      subject_list:[]
    })
    const { proxy } = getCurrentInstance();
    const reserachFormRef = ref({
      institution_type:undefined,
      name: undefined,
      profile: undefined,
      image: undefined,
      unit_type: 0,
      unit_id: 0,
      unit_name: undefined,
      subject:"",
      subject_list:[],
      link: undefined,
      country: undefined,
      province: undefined,
      city: undefined,
      area: [],
      established_time: undefined,
      address: undefined,
    });
    const reserachFormRule = {
      institution_type: [
        {
          required: true,
          message: "请选择机构类型",
          trigger: "change",
        },
      ],
      name: [
        {
          required: true,
          message: "请输入账号名称",
          trigger: "blur",
        },
      ],
      profile: [
        {
          required: true,
          message: "请输入简介",
          trigger: "blur",
        },
      ],
      image: [
        {
          required: true,
          message: "请输入上传头像",
          trigger: "blur",
        },
      ],
      unit_name: [
        {
          required: true,
          message: "请选择机构主体",
          trigger: "change",
        },
      ],
      subject: [
        {
          required: true,
          message: "请选择研究领域",
          trigger: "blur",
        },
      ],
      area: [
        {
          required: true,
          message: "请输入选择地区",
          trigger: 'change',
        },
      ],
      // established_time: [
      //   {
      //     required: true,
      //     message: "请输入成立时间",
      //     trigger: "blur",
      //   },
      // ],
    };

    const searchForm = ref({
      page: 1,
      page_size: 10,
      name: undefined,
    });
    
    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      back();
    };
    const back = () => {
      router.go(-1);
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(reserachFormRef.value)
          reserachFormRef.value.country=reserachFormRef.value.area[0]||''
          reserachFormRef.value.province=reserachFormRef.value.area[1]||''
          reserachFormRef.value.city=reserachFormRef.value.area[2]||''
          let url="";
          if(router.currentRoute.value.query.id){
            url=`/admin/institution/${router.currentRoute.value.query.id}`
            Ajax.PUT({
            url: url,
            data: reserachFormRef.value,
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("修改成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
          }else{
            url="/admin/institution"
            Ajax.POST({
            url: url,
            data: reserachFormRef.value,
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("新增成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
          }
          // Ajax.POST({
          //   url: url,
          //   data: reserachFormRef.value,
          // }).then((res) => {
          //   if (res.data.code == 200) {
          //     MessageTips.success("新增成功");
          //     back();
          //   } else {
          //     MessageTips.error(res.data.msg);
          //   }
          // });
        } else {
          console.log("error");
          return;
        }
      });
    };
  //修改领域
  const openAreaDialog = () => {
    openArea.value = true
  }
    //关闭领域弹窗
  const closeArea = () => {
    openArea.value = false
  }
  //保存领域弹窗
  const confirmArea = (value) => {
    reserachFormRef.value.subject = value.id.join(',')
    let subject_list = []
    if (value.label.length > 0) {
      value.label.forEach((item) => {
        subject_list.push({ id: item.id, subject_name: item.name })
      })
    }
    reserachFormRef.value.subject = value.id.join(',')
    reserachFormRef.value.subject_list = subject_list
    openArea.value = false
  }

    //机构类型
    const institutionTypeArr=ref([])
    const getType = ()=>{
      Ajax.GET({
            url: '/admin/getSelect',
            params: {type:'institutionType'}
        }).then(res => {
            if(res.data.code === 200) {
              institutionTypeArr.value = res.data.data
            }else {
                MessageTips.error(res.data.msg)
            }
        })
    }

    //选择地区
    const provincesArrRef = ref([])
    const provinceValRef = ref(null)
    const getArea = () => {
        Ajax.GET({
            url: '/admin/getSelect',
            params: {type:'region'}
        }).then(res => {
            if(res.data.code === 200) {
              console.log(res)
              provincesArrRef.value = res.data.data
            }else {
                MessageTips.error(res.data.msg)
            }
        })
    }
    //地区选择
    const provinceChange = (val) => {
      console.log("地区：--》", val);
      reserachFormRef.value.country=val[0]||''
      reserachFormRef.value.province=val[1]||''
      reserachFormRef.value.city=val[2]||''

    };


    //选择机构
    const restaurants = ref([])
    const querySearchUnit = async (queryString, cb) => {
      if (reserachFormRef.value.unit_name === '' || reserachFormRef.value.unit_name.length < 2) return
      let params = {
        name: reserachFormRef.value.unit_name,
        status: 1
      }
      let res = await Ajax.GET({
        url: "/admin/personage/search_unit",
        params,
      });
      if (res.data.code === 200) {
        restaurants.value = res.data.data
      }
      cb(restaurants.value)
    }
    //选中单位 机构
    const handleSelectWork = (data) => {
      reserachFormRef.value.unit_name = data.name
      reserachFormRef.value.unit_id = data.id
      reserachFormRef.value.unit_type = data.unit_type
    }
    
    // 上传照片
        const uploadImage = (data) => {
            const file = data.file;
            let formData = new FormData();
            formData.append("image", file);
            formData.append("restrict", "1000");
            Ajax.POST({
                url: "/admin/upload/image",
                contentType: "multipart/form-data",
                data: formData,
            }).then((res) => {
                if (res.data.code === 200) {
                reserachFormRef.value.image = res.data.data.url;
                } else {
                MessageTips.error(res.data.msg);
                }
            });
        };
    
    const getDetail =async (id)=>{
      
      let res = await Ajax.GET({
        url: `/admin/institution/${id}`,
      })
      if(res.data.code===200){
        reserachFormRef.value=res.data.data
        reserachFormRef.value.area=[]
        if(res.data.data.subject_list){
          let new_subject_list=[];
          res.data.data.subject_list
          Object.keys(res.data.data.subject_list).forEach(item=>{
            new_subject_list.push(res.data.data.subject_list[item])
          })
          res.data.data.subject_list = new_subject_list
        }
        if(res.data.data.country!=''){
          reserachFormRef.value.area.push(res.data.data.country)
        }
        if(res.data.data.province!=''){
          reserachFormRef.value.area.push(res.data.data.province)
        }
        if(res.data.data.city!=''){
          reserachFormRef.value.area.push(res.data.data.city)
        }
      }
    }
    
    
    onMounted(() => {
      getArea()
      getType()
      if(router.currentRoute.value.query.id){
        getDetail(router.currentRoute.value.query.id)
      }
    });
    return {
      breadLists,
      reserachForm: reserachFormRef,
      cancelSubmit,
      submitForm,
      reserachFormRule,
      // showDialog,
      // tagDialogShow: tagDialogShowRef,
      Rule,
      Search,

      provincesArrRef,
      provinceValRef,
      institutionTypeArr,
      openArea,
      basePramas,
      openAreaDialog,
      closeArea,
      confirmArea,
      querySearchUnit,
      handleSelectWork,
      uploadImage,
    };
  },
};
</script>

<style lang="less" scoped>
.add_area{
  cursor: pointer;
}
.avatar-img-box .avatar{
  width: 100px;
  height: 100px;
}
.avatar-uploader{
  width: 100px;
  height: 100px;
  line-height: 100px;
  border: 1px solid #ddd;
}
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 80%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
</style>