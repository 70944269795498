<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-album-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-title">创建专辑</span>
              </div>
            </template>
            <div>
              <el-form
                ref="formRef"
                :model="createParams"
                :rules="createRule"
                label-width="120px"
                class="form-box mg-t-24"
              >
                <el-form-item label="名称" prop="title">
                  <el-input
                    v-model="createParams.title"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="数据类别" prop="type">
                  <el-select v-model="createParams.type">
                    <el-option
                      v-for="item in dataTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="简介" prop="summary">
                  <el-input
                    v-model="createParams.summary"
                    maxlength="100"
                    show-word-limit
                    type="textarea"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="顶部banner" prop="banner">
                  <div>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      :action="`${baseUrl}admin/upload/image`"
                      :on-success="avatarUploadSuccess"
                      :headers="{ Authorization: 'Bearer ' + adminToken }"
                      name="image"
                    >
                      <img
                        v-if="createParams.banner"
                        :src="createParams.banner"
                        class="avatar"
                      />
                      <el-icon v-else class="avatar-uploader-icon"
                        ><plus
                      /></el-icon>
                    </el-upload>
                    <p>只支持.jpg 格式</p>
                  </div>
                </el-form-item>
                <el-form-item label="行业领域" prop="domain">
                  <el-tag
                    v-for="tag in createParams.domain"
                    :key="tag"
                    class="common-tag"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    v-if="inputInstance.inputVisible"
                    ref="InputRef"
                    v-model="inputInstance.inputValue"
                    style="width: 80px"
                    size="small"
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag ml-1"
                    size="small"
                    @click="showInput"
                  >
                    + 新标签
                  </el-button>
                </el-form-item>
                <el-form-item label="发布时间" prop="push_time">
                  <el-date-picker
                    v-model="createParams.push_time"
                    style="width: 400px"
                    type="datepush_time"
                    placeholder="选择时间"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="首页推荐" prop="recommend_type">
                  <el-radio-group v-model="createParams.recommend_type">
                    <el-radio :label="2">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="推荐理由" prop="reason">
                  <el-input
                    v-model="createParams.reason"
                    maxlength="120"
                    show-word-limit
                    type="textarea"
                    placeholder="请输入推荐理由"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="submit-box pd-16 mg-t-12">
            <el-row justify="end">
              <el-col :span="4">
                <el-button size="small" plain @click="handleCancel"
                  >取消</el-button
                >
                <el-button size="small" type="primary" @click="handleSave(formRef)"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { toRefs, reactive, nextTick, getCurrentInstance, ref } from "vue";
import { useRouter } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { Ajax, MessageTips } from "@/utils";
export default {
  components: {
    Header,
    Plus,
    BreadCrumb,
  },
  setup() {
    const currentCtx = getCurrentInstance().ctx;
    const router = useRouter();
    const state = reactive({
      breadList: [
        {
          name: "文献",
          path: {
            name: "Literatrue",
          },
        },
        {
          name: "创建文献",
        },
      ],
      adminToken: localStorage.getItem("adminToken"),
      inputInstance: {
        inputVisible: false,
        inputValue: "",
      },
      baseUrl: window.env.baseUrl,
      createParams: {
        title: "",
        type: 0,
        summary: "",
        banner: "",
        domain: [],
        push_time: "",
        recommend_type: "",
        reason: ''
      },
      createRule: {
        title: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择数据类别",
            trigger: "change",
          },
        ],
        banner: [
          {
            required: true,
            message: "请上传banner图",
          },
        ],
        recommend_type: [
          {
            required: true,
            message: "请选择是否首页推荐",
            trigger: "change",
          },
        ],
      },
      dataTypeList: [
        {
          label: "品牌",
          value: 0,
        },
        {
          label: "人物",
          value: 1,
          disabled: true,
        },
      ],
    });
    const formRef = ref()
    // 上传图片
    const avatarUploadSuccess = (e) => {
      console.log(e, "kkkk");
      state.createParams.banner = e.data.url;
    };

    // 删除标签
    const handleClose = (tag) => {
      state.createParams.domain.splice(state.createParams.domain.indexOf(tag), 1);
    };

    // 增加标签
    const showInput = () => {
      state.inputInstance.inputVisible = true;
      // nextTick(() => {
      //   let ipt = currentCtx.$refs.InputRef;
      //   ipt.focus();
      // });
    };

    // 确认添加标签
    const handleInputConfirm = () => {
      let {
        inputInstance,
        createParams: { domain },
      } = state;
      if (inputInstance.inputValue) {
        domain.push(inputInstance.inputValue);
      }
      inputInstance.inputVisible = false;
      inputInstance.inputValue = "";
    };

    // 保存
    const handleSave = (formEl) => {
      formEl.validate(async (valid) => {
        if (valid) {
          const res = await Ajax.POST({
            url: "admin/album/create",
            data: {
              ...state.createParams,
              domain: state.createParams.domain.join(","),
            },
          });
          if (res.data.code == 200) {
            MessageTips.success("新增成功");
            router.go(-1);
          }
        } else {
          return;
        }
      });
    };

    // 取消
    const handleCancel = () => {
      formRef.value.resetFields();
    };

    return {
      ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      handleSave,
      handleCancel,
      avatarUploadSuccess,
      formRef
    };
  },
};
</script>

<style lang="less" scoped>
.create-album-container {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  .form-box {
    width: 50%;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
    .avatar {
      width: 108px;
      height: 108px;
    }
  }
  .common-tag {
    margin-right: 8px;
  }
  .submit-box {
    background-color: #fff;
  }
}
</style>
