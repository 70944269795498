<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-person-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">创建人物</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="userForm"
                                :rules="userFormRule"
                                label-width="120px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="姓名" prop="name">
                                    <div style="display:flex">
                                        <el-input v-model="userForm.name" placeholder="请输入" ></el-input>
                                        <el-checkbox v-model="isDeceased" style="margin-left:10px">已故/不对外推荐</el-checkbox>
                                    </div>
                                </el-form-item>
                                <el-form-item label="性别" prop="sex">
                                    <el-radio-group v-model="userForm.sex">
                                        <el-radio :label="1">男</el-radio>
                                        <el-radio :label="2">女</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="国家" prop="country">
                                    <!-- <el-select 
                                        v-model="userForm.country" 
                                        placeholder="请选择"
                                        @change="coutryChange"
                                    >
                                        <el-option 
                                            v-for="option in countrys"
                                            :key="option.id"
                                            :label="option.name" 
                                            :value="option.id" 
                                        />
                                    </el-select> -->
                                    <el-select class="select w-100" placeholder="请选择"
                                        :popper-append-to-body="false" v-model="userForm.country"
                                        @change="coutryChange"
                                        filterable
                                        remote
                                        :reserve-keyword="false"
                                        :remote-method="countryRemoteMethod"
                                    >
                                        <el-option
                                            v-for="item in countrys"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        {{item.name}}
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="地区" prop="province">
                                    <el-cascader
                                        v-model="provinceVal"
                                        :options="provincesArr"
                                        @change="provinceChange"
                                        :props="{
                                            value: 'id',
                                            label: 'name',
                                            children: 'child'
                                        }"
                                    ></el-cascader>
                                </el-form-item>
                                <el-form-item label="人物标签" prop="type">
                                    <el-radio-group v-model="userForm.type">
                                        <el-radio label="1">学者</el-radio>
                                        <el-radio label="3">投资人</el-radio>
                                        <el-radio label="2">创业者</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="人物简介" prop="profile">
                                    <el-input v-model="userForm.profile" type="textarea" placeholder="请输入" :autosize="{ minRows: 2, maxRows: 14 }" maxlength="500" show-word-limit></el-input>
                                </el-form-item>
                                <el-form-item label="研究领域" prop="research_label" v-if="userForm.type === '1'">
                                    <checkTag ref="checkTag"></checkTag>
                                </el-form-item>
                                <el-form-item label="投资领域" prop="invest_label" v-else-if="userForm.type === '3'">
                                    <checkTag ref="checkTag"></checkTag>
                                </el-form-item>
                                <el-form-item label="创业领域" prop="startup_label" v-else-if="userForm.type === '2'">
                                    <checkTag ref="checkTag"></checkTag>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, onBeforeMount ,onMounted } from 'vue'
import { Ajax, MessageTips } from '../../../utils'
import {useRouter} from 'vue-router'
import checkTag from '../../../components/CheckTagNew'
import {debounce} from '@/utils/throttle.js'
export default {
    components: {
        checkTag,
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '人物',
                path: {
                    name: 'Person'
                }
            },
            {
                name: '创建人物'
            }
        ])
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const countrysRef = ref([]);
        const provincesArrRef = ref([]);
        const provinceValRef = ref(null);
        const isDeceased=ref(false);
        let regionArr = [];
        onBeforeMount(async () => {
            const adminRegions = localStorage.getItem('adminRegions');
            if(adminRegions) {
                const regs = JSON.parse(adminRegions);
                regionArr = regs;
                countrysRef.value = regionArr.map(({id, name}) => ({
                    id,
                    name
                }))
            }else {
                const res = await Ajax.GET({
                    url: '/admin/region'
                })
                const resData = res.data;
                if(resData.code === 200) {
                    regionArr = resData.data;
                    countrysRef.value = regionArr.map(({id, name}) => ({
                        id,
                        name
                    }))
                }
            }
        })
        // 国家选择
        const coutryChange = val => {
            const findObj = regionArr.find(item => item.id === val);
            userFormRef.value.country = val;
            provincesArrRef.value = findObj?findObj.child:[];
        }
        const countryRemoteMethod = query =>{
            if (query) {
                setTimeout(() => {
                countrysRef.value = regionArr.filter((item) => {
                    return item.name.includes(query)
                })
                }, 200)
            } else {
                countrysRef.value = []
            }
        }
        // 地区选择
        const provinceChange = val => {
            userFormRef.value.province = val[0]
            userFormRef.value.city = val[1]
        }
        
        const userFormRef = ref({
            name: '',
            sex: 1,
            country: null,
            province: null,
            city: null,
            type: '1',
            profile: '',
            research_label: undefined,
            invest_label: undefined,
            startup_label: undefined,
        })
        const userFormRule = {
            name: [
                {
                    required: true,
                    message: '请输入姓名',
                    trigger: 'blur'
                }
            ],
            profile: [
                {
                    required: true,
                    message: '请输入人物简介',
                    trigger: 'blur'
                }
            ]
        }
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        
        const submitForm = debounce(function(formName){
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    if(userFormRef.value.type === '1'){
                    let tag=ref([])
                    tag.value=proxy.$refs.checkTag.getValue()
                    userFormRef.value.research_label=tag.value?tag.value:''
                    // userFormRef.value.research_label=tag.value?tag.value.join(','):''
                    userFormRef.value.startup_label=undefined
                    userFormRef.value.invest_label=undefined
                    }else if(userFormRef.value.type === '2'){
                        let tag=ref([])
                        tag=proxy.$refs.checkTag.getValue()
                        userFormRef.value.startup_label=tag.value?tag.value.join(','):''
                        userFormRef.value.invest_label=undefined
                        userFormRef.value.research_label=undefined
                    }else if(userFormRef.value.type === '3'){
                        let tag=ref([])
                        tag=proxy.$refs.checkTag.getValue()
                        userFormRef.value.invest_label=tag.value?tag.value.join(','):''
                        userFormRef.value.startup_label=undefined
                        userFormRef.value.research_label=undefined
                    }
                    userFormRef.value.is_deceased=isDeceased.value?2:1
                    // debugger
                    Ajax.POST({
                        url: '/admin/personage',
                        data: {
                            ...userFormRef.value
                        }
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('新增成功');
                            router.back();
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                        })
                }else {
                    console.log('error')
                    return ;
                }
            })
        })
        return {
            breadLists: breadListsRef,
            countrys: countrysRef,
            countryRemoteMethod,
            coutryChange,
            provinceChange,
            provinceVal: provinceValRef,
            userForm: userFormRef,
            userFormRule,
            cancelSubmit,
            submitForm,
            provincesArr: provincesArrRef,
            isDeceased,
        }
    }
};
</script>

<style lang="less" scoped>
    .create-person-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        .form-container{
            overflow: auto;
        }
    }
    .dialog-search-row {
        position: relative;
        top: -24px;
        height: 52px;
        display: flex;
        align-items: center;
        span {
            position: relative;
            padding-left: 10px;
            &::before {
                content: '*';
                position: absolute;
                left: 0;
                top: 50%;
                display: inline-block;
                color: red;
                font-size: 16px;
                // line-height: 52px;
                transform: translateY(-50%);
            }
        }
        p {
            flex: 1;
            height: 46px;
            border-radius: 4px;
            border: 1px solid #bbb;
        }
    }
    .dialog-content {
        display: flex;
        .left-select-tree {
            width: 256px;
            ::v-deep(.el-menu-item) {
                height: 42px;
                &.is-active {
                    position: relative;
                    background-color: fadeout(@primary-color, 86%);
                    &::after {
                        content: '';
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 4px;
                        height: 100%;
                        // background-color: #3894FF;
                        background-color: @primary-color;
                    }
                }
            }
        }
        .right-tags-box {
            .tag-input {
                width: 100px;
                display: inline-block;
            }
        }
    }
</style>