<template>
    <template v-for="menuItem in list" :key="menuItem.index">
        <el-sub-menu :show-timeout="0" :hide-timeout="0" :index="menuItem.index" v-if="menuItem.children">
            <template #title>
                <div class="menu-title-box" v-if="isEdit" @mouseenter="showAddTag(menuItem.index)" @mouseleave="hideAddTag(menuItem.index)">
                    <span class="menu-title">
                        <el-icon v-if="menuItem.icon">
                            <component :is="menuItem.icon" /> 
                        </el-icon> {{ menuItem.title }}
                    </span>
                    <span class="operation" v-show="menuItem.showTag">
                        <template v-if="menuItem.level >= 3">
                            <el-link type="primary" :underline="false" @click="addTag(menuItem)">添加标签</el-link>
                        </template>
                        <template v-else>
                            <el-link type="primary" :underline="false" class="mg-r-12" @click="addSameLevel(menuItem)">添加同级</el-link>
                            <el-link type="primary" :underline="false" @click="addChildLevel(menuItem)">添加子级</el-link>
                        </template>
                    </span>
                </div>
                <span class="menu-title" v-else>
                    <el-icon v-if="menuItem.icon">
                        <component :is="menuItem.icon" /> 
                    </el-icon> {{ menuItem.title }}
                </span>
            </template>
            <MenuTrees 
                :list="menuItem.children" 
                :isEdit="isEdit" 
                @showAddTag="showAddTag" 
                @hideAddTag="hideAddTag"
                @handleAddTag="addTag"
                @addSameLevel="addSameLevel"
                @addChildLevel="addChildLevel"    
            />
        </el-sub-menu>
        <el-menu-item v-else :index="menuItem.index">
            <div class="menu-title-box" v-if="isEdit" @mouseenter="showAddTag(menuItem.index)" @mouseleave="hideAddTag(menuItem.index)">
                <span class="menu-title">
                    <el-icon v-if="menuItem.icon">
                        <component :is="menuItem.icon" /> 
                    </el-icon> {{ menuItem.title }}
                </span>
                <span class="operation" v-show="menuItem.showTag">
                    <template v-if="menuItem.level >= 3">
                        <el-link type="primary" :underline="false" @click="addTag(menuItem)">添加标签</el-link>
                    </template>
                    <template v-else>
                        <el-link type="primary" class="mg-r-12" :underline="false" @click="addSameLevel(menuItem)">添加同级</el-link>
                        <el-link type="primary" :underline="false" @click="addChildLevel(menuItem)">添加子级</el-link>
                    </template>
                </span>
            </div>
            <span class="menu-title" v-else>
                <el-icon v-if="menuItem.icon">
                    <component :is="menuItem.icon" /> 
                </el-icon> {{ menuItem.title }}
            </span>
        </el-menu-item>
    </template>
</template>

<script>
import { House, DataLine, PriceTag, User, Message, Clock, SetUp, Watermelon, Setting, Menu, Folder, MessageBox, Document } from '@element-plus/icons-vue'

export default {
    name: 'menuTrees',
    props: {
        list: {
            type: Array,
            default: []
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    components: {
        House,
        DataLine,
        PriceTag,
        User,
        Message,
        Clock,
        SetUp,
        Watermelon,
        Setting,
        Menu,
        Folder,
        MessageBox,
        Document
    },
    emits: ['showAddTag', 'hideAddTag', 'handleAddTag', 'addSameLevel', 'addChildLevel'],
    setup(props, ctx) {
        const showAddTag = index => {
            ctx.emit('showAddTag', index)
        }
        const hideAddTag = index => {
            ctx.emit('hideAddTag', index)
        }
        const addTag = item => {
            ctx.emit('handleAddTag', item)
        }
        const addSameLevel = item => {
            ctx.emit('addSameLevel', item)
        }
        const addChildLevel = item => {
            ctx.emit('addChildLevel', item)
        }
        return {
            showAddTag,
            hideAddTag,
            addTag,
            addSameLevel,
            addChildLevel
        }
    }
}
</script>

<style lang="less" scoped>
    .menu-title-box {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-right: 20px;
    }
</style>