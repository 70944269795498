<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-investment-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">投资机构基本信息</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="investmentBaseInfo"
                                :rules="investmentBaseInfoRule"
                                label-width="120px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="名称" prop="name">
                                    <el-input v-model="investmentBaseInfo.name" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="成立时间" prop="date">
                                    <el-date-picker value-format="YYYY-MM-DD" v-model="investmentBaseInfo.establish_time" type="date" placeholder="选择日期" />
                                </el-form-item>
                                

                                <el-descriptions :column="2">
                                    <el-descriptions-item label-align="right">
                                        <el-form-item label="国家" prop="country">
                                            <el-select class="select w-100" placeholder="请选择"
                                                :popper-append-to-body="false" v-model="investmentBaseInfo.country"
                                                @change="coutryChange"
                                                filterable
                                                remote
                                                :reserve-keyword="false"
                                                :remote-method="countryRemoteMethod"
                                            >
                                                <el-option
                                                    v-for="item in countrys"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                >
                                                {{item.name}}
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-descriptions-item>
                                    <el-descriptions-item label-align="left">
                                        <el-form-item label="地区" prop="province">
                                            <el-cascader
                                                v-model="provinceVal"
                                                :options="provincesArr"
                                                @change="provinceChange"
                                                :props="{
                                                    value: 'id',
                                                    label: 'name',
                                                    children: 'child'
                                                }"
                                            ></el-cascader>
                                        </el-form-item>
                                    </el-descriptions-item>
                                </el-descriptions>

                                <el-form-item label="简介" >
                                    <el-input v-model="investmentBaseInfo.introduction" :maxlength="50"  show-word-limit type="text" placeholder="一句话简介50字以内"></el-input>
                                </el-form-item>
                                <el-form-item label="介绍" prop="profile">
                                    <el-input v-model="investmentBaseInfo.profile" :maxlength="500" :autosize="{ minRows: 2, maxRows: 14 }" show-word-limit type="textarea" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="机构类型" prop="types">
                                    <el-select
                                        v-model="investmentBaseInfo.types"
                                        multiple
                                        collapse-tags
                                        placeholder="请选择"
                                    >
                                        <el-option
                                            v-for="phase in companyPhase"
                                            :key="phase.name"
                                            :label="phase.name" 
                                            :value="phase.id" 
                                        >
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item label="照片" prop="avator">
                                        <el-upload
                                            class="avatar-uploader"
                                            :class="{
                                                border: !investmentBaseInfo.image
                                            }"
                                            :show-file-list="false"
                                            action="#"
                                            :http-request="uploadImage"
                                            accept="image/jpg, image/jpeg, image/png"
                                            :before-upload="beforeUpload"
                                        >
                                            <div class="avatar-img-box" v-if="investmentBaseInfo.image">
                                                <img :src="investmentBaseInfo.image" class="avatar" />
                                            </div>
                                            <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
                                        </el-upload>
                                    </el-form-item>
                                    
                                    
                                    <el-descriptions :column="2">
                                        <el-descriptions-item label-align="right">
                                            <el-form-item label="官网" prop="unit_type">
                                                <el-input placeholder="请输入" v-model="investmentBaseInfo.link" />
                                            </el-form-item>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="left">
                                            <el-form-item label="电话" prop="telephone">
                                                <el-input placeholder="请输入" v-model="investmentBaseInfo.telephone"></el-input>
                                            </el-form-item>
                                        </el-descriptions-item>
                                    </el-descriptions>

                                    <el-descriptions :column="2">
                                        <el-descriptions-item label-align="right">
                                            <el-form-item label="邮箱" prop="email">
                                                <el-input placeholder="请输入" v-model="investmentBaseInfo.email"></el-input>
                                            </el-form-item>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="left">
                                            <el-form-item label="手机" prop="mobile">
                                                <el-input placeholder="请输入" v-model="investmentBaseInfo.mobile"></el-input>
                                            </el-form-item>
                                        </el-descriptions-item>
                                    </el-descriptions>

                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Ajax, MessageTips } from '../../../../utils'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '投资机构',
                path: {
                    name: 'OrganizationInvestment'
                }
            },
            {
                name: '新增投资机构'
            }
        ])
        const route = useRoute();
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const countrysRef = ref([]);
        const provincesArrRef = ref([]);
        const provinceValRef = ref(null);
        
        const companyPhaseRef = ref([
            {
                id: 1,
                name: '天使机构'
            },
            {
                id: 2,
                name: 'VC'
            },
            {
                id: 3,
                name: 'PE'
            },
            {
                id: 4,
                name: 'CVC'
            },
            {
                id: 5,
                name: 'FA'
            },
            {
                id: 6,
                name: 'FOF'
            },
            {
                id: 7,
                name: '金融机构'
            },
            {
                id: 8,
                name: '国资背景'
            },
            {
                id: 9,
                name: '家族办公室'
            },
            {
                id: 10,
                name: '对冲基金'
            },
            {
                id: 11,
                name: '天使个人'
            },
            {
                id: 12,
                name: '孵化器'
            },
            {
                id: 13,
                name: '其他'
            }
        ]);
        const investmentBaseInfoRef = ref({
            name: '',
            profile: '',
            introduction:"",
            country: null,
            province: null,
            city: null,
            establish_time: '',
            types: [],
            phase: '',
            image:"",
            link:"",
            telephone:"",
            email:"",
            mobile:"",
        })
        let regionArr = [];
        onBeforeMount(async () => {
            // Ajax.POST({
            //     url: '/admin/invest/rounds'
            // }).then(res => {
            //     if(res.data.code === 200) {
            //         companyPhaseRef.value = res.data.data;
            //     }
            // })
            const adminRegions = localStorage.getItem('adminRegions');
            if(adminRegions) {
                const regs = JSON.parse(adminRegions);
                regionArr = regs;
                countrysRef.value = regionArr.map(({id, name}) => ({
                    id,
                    name
                }))
            }else {
                const res = await Ajax.GET({
                    url: '/admin/region'
                })
                const resData = res.data;
                if(resData.code === 200) {
                    regionArr = resData.data;
                    countrysRef.value = regionArr.map(({id, name}) => ({
                        id,
                        name
                    }))
                }
            }
        })
        // 国家选择
        const coutryChange = val => {
            const findObj = regionArr.find(item => item.id === val);
            investmentBaseInfoRef.value.country = val;
            provincesArrRef.value = findObj?findObj.child:[];
        }
        const countryRemoteMethod = query =>{
            if (query) {
                setTimeout(() => {
                countrysRef.value = regionArr.filter((item) => {
                    return item.name.includes(query)
                })
                }, 200)
            } else {
                countrysRef.value = []
            }
        }
        // 地区选择
        const provinceChange = val => {
            investmentBaseInfoRef.value.province = val[0]
            investmentBaseInfoRef.value.city = val[1]
        }
        
        const investmentBaseInfoRule = {
            name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }
            ],
            profile: [
                {
                    required: true,
                    message: '请输入简介',
                    trigger: 'blur'
                }
            ]
        }
        // 限制文件大小
        const beforeUpload = file => {
            console.log(file)
            const isL1M = file.size / 1000 / 1000 <= 1;
            if(!isL1M) {
                MessageTips.error('文件上传不能大于1M')
                return false;
            }
            return true;
        }
        // 上传照片
        const uploadImage = data => {
            const file = data.file;
            let formData = new FormData();
            formData.append("image", file);
            formData.append("restrict", '1000');
            Ajax.POST({
                url: '/admin/upload/image',
                contentType: 'multipart/form-data',
                data: formData
            }).then(res => {
                if(res.data.code === 200) {
                    investmentBaseInfoRef.value.image = res.data.data.url;
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        const submitForm = formName => {
            console.log(investmentBaseInfoRef.value, 'investmentBaseInfo-value')
            if(investmentBaseInfoRef.value.types) {
                investmentBaseInfoRef.value.phase = investmentBaseInfoRef.value.types.join(', ');
            }
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    delete investmentBaseInfoRef.value.types
                    Ajax.POST({
                        url: '/admin/organization',
                        data: investmentBaseInfoRef.value
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('提交成功')
                            router.back();
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }
        return {
            breadLists: breadListsRef,
            countrys: countrysRef,
            provincesArr: provincesArrRef,
            provinceVal: provinceValRef,
            companyPhase: companyPhaseRef,
            coutryChange,
            countryRemoteMethod,
            provinceChange,
            investmentBaseInfo: investmentBaseInfoRef,
            investmentBaseInfoRule,
            cancelSubmit,
            submitForm,
            beforeUpload,
            uploadImage,
        }
    }
};
</script>

<style lang="less" scoped>
    .create-investment-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        .avatar-uploader {
            width: 108px;
            height: 108px;
            &.border {
                border: 1px dashed #d9d9d9;
            }
            ::v-deep(.el-upload) {
                width: 108px;
                height: 108px;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
            }
            .avatar-img-box {
                width: 100%;
                height: 100%;
                .avatar {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 108px;
                height: 108px;
                line-height: 108px;
                text-align: center;
            }
        }
    }
</style>