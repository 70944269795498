<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists"/>
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="investment-detail-container">
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">投资机构基本信息</span>
								<el-button type="primary" plain
													 @click="goEditPage('OrganizationEditInvestment', '编辑基本信息', 'edit-basic')">编辑基本信息
								</el-button>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-descriptions :column="2">
									<el-descriptions-item label-align="right" label="姓名：">{{
											investDetailInfo.name
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="状态：">
										<span :class="`status-text ${statusClass[investDetailInfo.status || 0]}`">{{
												statusLabel[investDetailInfo.status || 0]
											}}</span>
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="地区：">{{
											investDetailInfo.country_name
										}}{{
											investDetailInfo.province_name ? `-${investDetailInfo.province_name}` : ''
										}}{{ investDetailInfo.city_name ? `-${investDetailInfo.city_name}` : '' }}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="最近编辑时间：">{{
											investDetailInfo.update_time
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="成立时间：" :span="2">{{
											investDetailInfo.establish_time
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="发布时间：">{{
											investDetailInfo.publish_time
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="简介：" :span="2">{{
											investDetailInfo.introduction
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="介绍：" :span="2">{{
											investDetailInfo.profile
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="机构类型：" :span="2">{{
											investDetailInfo.phases
										}}
									</el-descriptions-item>
								</el-descriptions>
								<el-descriptions :column="3">
									<el-descriptions-item label="照片">
										<el-avatar style="vertical-align: middle;" v-if="investDetailInfo.image" :size="50"
															 :src="investDetailInfo.image"></el-avatar>
									</el-descriptions-item>
									<el-descriptions-item label="官网">
										<el-link v-if="investDetailInfo.link" :href="investDetailInfo.link" type="primary">
											{{ investDetailInfo.link }}
										</el-link>
									</el-descriptions-item>
									<el-descriptions-item label="联系方式"><span style="display: inline-block; vertical-align: top;">电话：{{
											investDetailInfo.telephone
										}}<br/>邮箱：{{ investDetailInfo.email }}<br/>手机：{{ investDetailInfo.mobile }}</span>
									</el-descriptions-item>
								</el-descriptions>
							</el-col>
						</el-row>
					</el-card>
					<!-- <el-card class="mg-b-16">
							<template #header>
									<div class="card-header">
											<span class="card-name">补充信息</span>
											<el-button type="primary" plain @click="goEditPage('OrganizationEditInvestment', '编辑补充信息', 'edit-contact')">编辑补充信息</el-button>
									</div>
							</template>
							<el-row>
									<el-col>

									</el-col>
							</el-row>
					</el-card>          -->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">机构成员（{{ investDetailInfo.member && investDetailInfo.member.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="investDetailInfo.member">
									<el-table-column width="90" prop="id" label="人物ID"/>
									<el-table-column prop="name" label="姓名"/>
									<el-table-column width="50" prop="sex" label="性别">
										<template #default="scope">
											{{ scope.row.sex === 1 ? '男' : scope.row.sex === 2 ? '女' : '-' }}
										</template>
									</el-table-column>
									<el-table-column prop="country" label="国家"/>
									<el-table-column prop="province" label="地区">
										<template #default="scope">
											{{ scope.row.province }} {{ scope.row.city }}
										</template>
									</el-table-column>
									<el-table-column prop="type_arr" label="人物类型"/>
									<el-table-column prop="post" label="职位"/>
									<el-table-column prop="status" label="状态">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
											<span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col>
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('member', '机构成员')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">投资事件（{{ investOrgList && investOrgList.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="investOrgList">
									<el-table-column prop="id" label="序号"/>
									<el-table-column width="90" prop="company_id" label="主体ID"/>
									<el-table-column prop="company_name" label="主体名称"/>
									<el-table-column prop="round" label="投资轮次"/>
									<el-table-column prop="amount" label="投资金额">
										<template #default="scope">
											{{ scope.row.is_accurate ? scope.row.amount : scope.row.no_amount }}
										</template>
									</el-table-column>
									<el-table-column prop="finace_time" label="时间"/>
									<el-table-column prop="status" label="状态">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col>
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('event-investment', '投资事件')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">退出事件（{{ quitOrgList && quitOrgList.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="quitOrgList">
									<el-table-column width="90" prop="id" label="序号"/>
									<el-table-column width="100" prop="company_id" label="退出事件ID"/>
									<el-table-column prop="company_name" label="主体名称"/>
									<el-table-column prop="withdraw_type" label="退出方式">
										<template #default="scope">
											<span v-if="scope.row.withdraw_type === 1">ipo</span>
											<span v-else-if="scope.row.withdraw_type === 2">兼并收购</span>
											<span v-else-if="scope.row.withdraw_type === 3">股权回购</span>
											<span v-else-if="scope.row.withdraw_type === 4">股权转让</span>
											<span v-else-if="scope.row.withdraw_type === 5">新三板交易</span>
											<span v-else-if="scope.row.withdraw_type === 6">清算</span>
										</template>
									</el-table-column>
									<el-table-column prop="amount" label="退出金额">
										<template #default="scope">
											{{ scope.row.is_accurate ? scope.row.amount : scope.row.no_amount }}
										</template>
									</el-table-column>
									<el-table-column prop="quit_time" label="退出时间"/>
									<el-table-column prop="target" label="投资方">
										<template #default="scope">
											<span class="mg-r-12" v-for="item in scope.row.investor_arr" :key="item">{{ item }}</span>
											<span class="mg-r-12" v-for="item in scope.row.company_arr" :key="item">{{ item }}</span>
											<!-- {{ scope.row.investor_arr }} {{ scope.row.company_arr }} -->
										</template>
									</el-table-column>
									<el-table-column prop="ret_multiple" label="回报倍数"/>
									<el-table-column prop="status" label="状态">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col>
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('event-exit', '退出事件')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">合作机构（{{ cooperList && cooperList.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="cooperList">
									<el-table-column width="110" prop="id" label="投资机构ID"/>
									<el-table-column prop="name" label="名称"/>
									<el-table-column prop="country_name" label="国家"/>
									<el-table-column prop="province_name" label="地区">
										<template #default="scope">
											{{ scope.row.province_name }} {{ scope.row.city_name }}
										</template>
									</el-table-column>
									<el-table-column prop="phaseArr" label="类型"/>
									<el-table-column prop="establish_time" label="成立时间"/>
									<el-table-column prop="status" label="状态">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 2">已完善</span>
											<span class="status-text danger" v-else-if="scope.row.status === 3">待完善</span>
											<span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
											<span class="status-text info" v-else>待审核</span>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col>
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('cooperation-agency', '合作机构')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">基金管理（{{ investDetailInfo.funds && investDetailInfo.funds.length }}）</span>
								<el-button type="primary" @click="toMore('fund-manage', '基金管理')" plain>编辑基金管理</el-button>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="investDetailInfo.funds">
									<el-table-column prop="filing_time" label="备案时间"/>
									<el-table-column prop="fund_name" label="名称"/>
									<el-table-column prop="register_capital" label="注册资本"/>
									<el-table-column prop="copartner" label="执行事务合伙人"/>
									<el-table-column prop="establish_time" label="成立时间"/>
									<!-- <el-table-column prop="status" label="状态">
											<template #default="scope">
													{{ scope.row }}
											</template>
									</el-table-column> -->
								</el-table>
							</el-col>
							<el-col>
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('fund-manage', '基金管理')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>

					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">相关报道（{{ reportListTotal }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="reportList">

									<el-table-column type="index" width="50" label="序号"/>
									<el-table-column prop="name" label="报道标题" :show-overflow-tooltip="true">
										<template #default="scope">
											<span  @click="goToReport(scope.row.id)"
														style="cursor: pointer;">{{ scope.row.name}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="author" label="作者" width="170" :show-overflow-tooltip="true">
										<template #default="scope">
											<span >{{ scope.row.author }}</span>
										</template>
									</el-table-column>
									<el-table-column prop="source" label="来源" width="170" :show-overflow-tooltip="true">
										<template #default="scope">
											<span >{{ scope.row.source}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="link" label="链接" width="80">
										<template #default="scope">
											<el-link :href="scope.row.link" type="primary" target="_blank">点击查看</el-link>
										</template>
									</el-table-column>
									<el-table-column prop="report_time" label="报道时间" width="110"/>
									<el-table-column prop="status" label="状态" width="70">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
										</template>
									</el-table-column>

								</el-table>
							</el-col>
							<el-col>
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('reports', '相关报道')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import {ref, onBeforeMount} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {Ajax, configData} from '../../../../utils'

export default {
	components: {
		Header,
		BreadCrumb
	},
	setup() {
		const breadListsRef = ref([
			{
				name: '投资机构',
				path: {
					name: 'OrganizationInvestment'
				}
			},
			{
				name: '投资机构详情'
			}
		])
		const route = useRoute();
		const statusLabel = configData.statusLabel;
		const statusClass = configData.statusClass;
		const investDetailInfoRef = ref({});
		const companyPhaseRef = ref([
			{
				id: 1,
				name: '天使机构'
			},
			{
				id: 2,
				name: 'VC'
			},
			{
				id: 3,
				name: 'PE'
			},
			{
				id: 4,
				name: 'CVC'
			},
			{
				id: 5,
				name: 'FA'
			},
			{
				id: 6,
				name: 'FOF'
			},
			{
				id: 7,
				name: '金融机构'
			},
			{
				id: 8,
				name: '国资背景'
			},
			{
				id: 9,
				name: '家族办公室'
			},
			{
				id: 10,
				name: '对冲基金'
			},
			{
				id: 11,
				name: '天使个人'
			},
			{
				id: 12,
				name: '孵化器'
			},
			{
				id: 13,
				name: '其他'
			}
		])
		const reportListTotal = ref(0)
		const investOrgListRef = ref([])
		const quitOrgListRef = ref([]);
		const cooperListRef = ref([])
		const reportListRef = ref([])
		onBeforeMount(() => {
			Ajax.GET({
				url: `/admin/organization/${route.query.id}`
			}).then(orgRes => {
				if (orgRes.data.code === 200) {
					const orgResData = orgRes.data.data
					let phaseArr = orgResData.phase.split(',')
					orgResData.phases = [];
					companyPhaseRef.value.forEach(item => {
						if (phaseArr.includes(String(item.id))) {
							orgResData.phases.push(item.name)
						}
					})
					orgResData.phases = orgResData.phases.join(', ')
					investDetailInfoRef.value = orgResData;
				}
			})
			Ajax.POST({
				url: `/admin/invest/orginvest/${route.query.id}`,
				data: {
					page: 1,
					page_size: 4
				}
			}).then(orginvestRes => {
				if (orginvestRes.data.code === 200) {
					investOrgListRef.value = orginvestRes.data.data.data;
				}
			})
			Ajax.POST({
				url: `/admin/quit/orginvest/${route.query.id}`,
				data: {
					page: 1,
					page_size: 4
				}
			}).then(orginvestRes => {
				if (orginvestRes.data.code === 200) {
					quitOrgListRef.value = orginvestRes.data.data.data;
				}
			})
			Ajax.POST({
				url: `/admin/organization/cooperation/${route.query.id}`,
				data: {
					page: 1,
					page_size: 4
				}
			}).then(cooper => {
				if (cooper.data.code === 200) {
					cooperListRef.value = cooper.data.data;
				}
			})

			Ajax.POST({
				url: `/admin/organization/report`,
				data: {
					id: route.query.id,
					page: 1,
					page_size: 4
				}
			}).then(report => {
				if (report.data.code === 200) {
					reportListRef.value = report.data.data.list;
					reportListTotal.value = report.data.data.page_info.total
				}
			})
			//如果是从热点详情跳转过来的 则修改主体左上角导航条
			if (route.query.hotNews) {
				breadListsRef.value = [
					{
						name: "热点",
						path: {
							name: "hotnews"
						}
					},
					{
						name: "热点详情",
						path: {
							name: "hotnewsDetail",
							query: {id: route.query.hotNewsId}
						},

					},
					{
						name: "机构详情",
					}
				]
			}
		})

		// 创业经历
		const startExperienceRef = ref([]);
		onBeforeMount(() => {
			startExperienceRef.value = [
				{
					num: 1,
					id: '123',
					name: '主体名称',
					date: '2020-01-12',
					status: 1,
				},
				{
					num: 2,
					id: '123',
					name: '主体名称',
					date: '2020-01-12',
					status: 2,
				},
				{
					num: 3,
					id: '123',
					name: '主体名称',
					date: '2020-01-12',
					status: 3,
				}
			]
		})

		const router = useRouter();
		// 去编辑页面
		const goEditPage = (pathName, pageName, type) => {
			router.push({
				name: pathName,
				query: {
					pageName,
					type,
					id: route.query.id
				}
			})
		}

		// 去相关列表页
		const toMore = (type, pageName) => {
			router.push({
				name: 'OrganizationInvestmentRelated',
				query: {
					type,
					pageName,
					id: route.query.id
				}
			})
		}
		//跳转报道页面
		const goToReport = (id) => {
			router.push({
				name: "ReportDetail",
				query: {id: id},
			});
		}
		const showString = (string, len) => {
			if (string) {
				return string.length > len ? string.substring(0, len) + "..." : string;
			}
			return '';

		};
		return {
			breadLists: breadListsRef,
			statusLabel,
			statusClass,
			investDetailInfo: investDetailInfoRef,
			startExperience: startExperienceRef,
			quitOrgList: quitOrgListRef,
			investOrgList: investOrgListRef,
			cooperList: cooperListRef,
			reportList: reportListRef,//报道列表
			goEditPage,
			toMore,
			showString,
			goToReport,
			reportListTotal
		}
	}
};
</script>

<style lang="less" scoped>
.investment-detail-container {
	height: 100%;

.card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

::v-deep(.el-descriptions__content) {
	color: rgb(16, 16, 16);
}

.no-data-tip {
	color: var(--el-color-primary);
	text-align: center;
	font-size: 14px;
}

.link-row {

span {
	color: var(--el-color-primary);
	text-decoration: underline;
	cursor: pointer;
}

}
.status-text {
	font-size: 14px;

&
.primary {
	color: #409eff;
}

&
.success {
	color: #67c23a;
}

&
.danger {
	color: #f56c6c;
}

&
.info {
	color: #909399;
}

}
::v-deep(.el-avatar>img) {
	width: 50px;
	height: 50px;
	object-fit: cover;
}

}
</style>
