<template>
    <div class="header-container">
        <div class="brand">
            <slot name="header-brand"></slot>
        </div>
        <!-- <el-dropdown trigger="click">
            <div class="user-container">
                <el-icon class="user-avator"><avatar /></el-icon>
                <span class="user-name">{{ userInfo.name }}</span>
                <el-icon><caret-bottom /></el-icon>
            </div>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="showPwdModal">修改密码</el-dropdown-item>
                    <el-dropdown-item @click="logoutShow = true">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown> -->
        <el-dialog title="退出登录" v-model="logoutShow" width="400px">
            确定要退出登录吗？
            <template #footer>
                <el-button @click="logoutShow = false">取消</el-button>
                <el-button type="primary" @click="logout">确定</el-button>
            </template>
        </el-dialog>
        <el-dialog title="修改密码" v-model="pwdShow" width="400px" destroy-on-close>
            <el-form
                ref="formRef"
                :model="form"
                :rules="formRule"
                label-width="88px"
                class="user-form-box mg-t-24"
            >
                <el-form-item label="原始密码" prop="old_pwd">
                    <el-input type="password" v-model="form.old_pwd" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="new_pwd">
                    <el-input type="password" v-model="form.new_pwd" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="new_pwd2">
                    <el-input type="password" v-model="form.new_pwd2" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="closeChangeDialog">取消</el-button>
                <el-button type="primary" @click="pwdSubmit">确定</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { CaretBottom } from '@element-plus/icons-vue'
import { useRouter } from 'vue-router'
import { ref, getCurrentInstance, onBeforeMount } from 'vue';
import { Avatar } from '@element-plus/icons-vue'
import { Ajax, MessageTips } from '@/utils'
export default {
    components: {
        CaretBottom,
        Avatar
    },
    setup(){
        const { proxy } = getCurrentInstance();
        const logoutShow = ref(false);
        const pwdShow = ref(false);
        const router = useRouter();

        const logout = () => {
            Ajax.GET({
                url: '/admin/logout'
            }).then(res => {
                if(res.data.code == 200) {
                    localStorage['adminToken'] = ''
                    router.replace({name: 'Login'})
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }
        const pwdSubmit = () => {
            proxy.$refs['formRef'].validate(valid => {
                if(valid) {
                    Ajax.POST({
                        url: '/admin/admin/update_pwd',
                        data: form.value
                    }).then(res => {
                        if(res.data.code == 200) {
                            MessageTips.success('修改成功');
                            closeChangeDialog()
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }
            })
        }

        const closeChangeDialog = () => {
            proxy.$refs['formRef'].resetFields()
            pwdShow.value = false
        }

        const checkPass = (rule, value, callback) => {
            if(form.value.new_pwd !== form.value.new_pwd2) {
                callback(new Error("请确认新密码是否一致"))
            }else {
                callback()
            }
        }

        const showPwdModal = () => {
            form.value = {
                old_pwd: null,
                new_pwd: null,
                new_pwd2: null
            }
            pwdShow.value = true;
        }

        const form = ref({
            old_pwd: null,
            new_pwd: null,
            new_pwd2: null
        })

        const formRule = {
            old_pwd: [
                {
                    required: true,
                    message: '请输入原始密码',
                    trigger: 'blur'
                }
            ],
            new_pwd: [
                {
                    required: true,
                    message: '请输入新密码',
                    trigger: 'blur'
                },
            ],
            new_pwd2: [
                {
                    required: true,
                    message: '请确认密码',
                    trigger: 'blur'
                },
                { validator: checkPass, trigger: 'blur' },
            ],
        }

        const userInfoRef = ref({})
        onBeforeMount(() => {
            const localUserInfo = localStorage.getItem('adminUserInfo') || '{}';
            const userInfo = JSON.parse(localUserInfo)
            userInfoRef.value = userInfo;
        })
        return {
            logoutShow,
            pwdShow,
            form,
            formRule,
            showPwdModal,
            logout,
            pwdSubmit,
            closeChangeDialog,
            userInfo: userInfoRef
        }
    }
    
};
</script>

<style lang="less" scoped>
    .header-container {
        width: 100%;
        height: 100%;
        padding-left: 24px;
        padding-right: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            .user-avator {
                font-size: 18px;
            }
            .user-name {
                margin-left: 8px;
                margin-right: 8px;
                font-size: 14px;
                line-height: 22px;
                color: fadeout(#000, 35%);
            }
        }
    }
</style>