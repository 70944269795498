<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">消息推送</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="messageForm"
                :rules="messageFormRule"
                label-width="80px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="标题" prop="title">
                  <el-input
                    style="width: 50%"
                    v-model="messageForm.title"
                    placeholder="请输入标题"
                  ></el-input>
                </el-form-item>
                <el-form-item label="推送内容" prop="content">
                  <el-input
                    v-model="messageForm.content"
                    type="textarea"
                    placeholder="请输入本次要推送的内容"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 10 }"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom-submit-box pd-16 mg-t-12">
              <el-row justify="end">
                <el-col :span="12" class="text-right">
                  <el-button plain @click="cancelSubmit()">取消</el-button>
                  <el-button type="primary" @click="submitForm()"
                    >发送</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Ajax, MessageTips } from "../../utils";
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const breadLists = ref([
      {
        name: "消息",
        path: {
          name: "Messages",
        },
      },
      {
        name: "消息推送",
      },
    ]);
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const messageFormRef = ref({
      title: "",
      content: "",
    });
    const messageFormRule = ref({
      title: [
        {
          required: true,
          message: "请输入标题",
          trigger: "blur",
        },
      ],
      content: [
        {
          required: true,
          message: "请输入推送内容",
          trigger: "blur",
        },
      ],
    });
    const cancelSubmit = () => {
      proxy.$refs["formRef"].resetFields();
      back();
    };
    const back = () => {
      router.go(-1);
    };
    const submitForm = () => {
      proxy.$refs["formRef"].validate((valid) => {
        if (valid) {
          Ajax.POST({
            url: "/admin/message",
            data: messageFormRef.value,
          }).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              MessageTips.success("新增成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
        }
      });
    };
    return {
      breadLists,
      messageForm: messageFormRef,
      cancelSubmit,
      submitForm,
      messageFormRule,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    // width: 50%;
    padding: 0px 180px;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
}
</style>