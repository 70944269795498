<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists" />
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="person-container">
					<div class="table-con mg-t-12">
						<el-scrollbar>
							<div class="table-container pd-32">
								<el-table v-loading="tableLoading" :data="tableData" border :max-height="680">
									<el-table-column type="index" label="序号" width="80" />
									<el-table-column prop="name" label="软件名称" width="130px"/>
									<el-table-column prop="profile" label="简介"/>
									<el-table-column prop="version" label="版本号" width="80px"/>
									<el-table-column prop="classify" label="软著分类" width="120px"/>
									<el-table-column prop="trade" label="行业分类" width="120px" />
									<el-table-column prop="register_time" label="登记日期" width="120px"/>
									<el-table-column prop="link" label="详情" width="80px">
										<template #default="scope">
											<el-link :href="scope.row.link" target="_blank" type="primary">详情</el-link>
										</template>
									</el-table-column>
								</el-table>
								<div class="table-pagination-row mg-t-24">
									<el-pagination
											background
											:currentPage="paginationData.current"
											:page-sizes="paginationData.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="paginationData.total"
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
									>
									</el-pagination>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../components/SearchFilterBar/index.vue'
import { ref, onMounted} from 'vue'
import {useRoute} from 'vue-router';
import { Ajax} from '../../../utils'
export default {
	components: {
		SearchFilterBar,
		Header,
		BreadCrumb
	},
	setup() {
		const breadListsRef = ref([
			{
				name: '品牌',
				path: {
					name: 'brand'
				}
			},
			{
				name: '品牌详情'
			}
		])
		const route = useRoute();

		// 表格数据
		const tableDataRef = ref([])
		const tableLoadingRef = ref(false)
		// 页码
		let paginationDataRef = ref({
			current: 1,
			pageSize: [10, 20, 50],
			page_size: 10,
			total: 0
		});
		const handleSizeChange = e => {
			paginationDataRef.value.page_size = e;
			getList()
		}
		const handleCurrentChange = e => {
			paginationDataRef.value.current = e;
			getList()
		}

		const getList = () => {
			tableLoadingRef.value = true;
			Ajax.POST({
				url: '/admin/company/works',
				data: {
					page: paginationDataRef.value.current,
					page_size: paginationDataRef.value.page_size,
					company_id: route.query.id,
				}
			}).then(res => {
				tableLoadingRef.value = false;
				if(res.data.code === 200) {
					tableDataRef.value = res.data.data.list;
					paginationDataRef.value.total = res.data.data.page_info.total;
					paginationDataRef.value.current = res.data.data.page_info.page;
				}
			})
		}
		onMounted(() => {
			getList();
			paginationDataRef.value.pageSize = [10, 20, 50]
		})
		return {
			breadLists: breadListsRef,
			tableLoading: tableLoadingRef,
			tableData: tableDataRef,
			paginationData: paginationDataRef,
			handleSizeChange,
			handleCurrentChange,
		}
	}
};
</script>

<style lang="less" scoped>

.person-container {
	height: 100%;
.table-pagination-row {
	display: flex;
	justify-content: flex-end;
}
.table-con {
	height: 100%;
	max-height: calc(100% - 66px - 12px);
	background-color: #fff;
}
.table-container {
	padding-bottom: 16px;
}
}
</style>
