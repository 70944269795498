<template>
        <Editor v-model="tinymceHtmlprops"
            :init="init"
            @saveContent="onSaveContent"
            @change="onChange"
            @SelectionChange="SelectionChange"
            >
        </Editor>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/icons/default/icons'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/contextmenu'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/colorpicker'
import 'tinymce/plugins/textcolor'
import 'tinymce/plugins/save'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullpage'
import 'tinymce/plugins/preview'
// import 'tinymce/plugins/paste'
import {useRouter} from 'vue-router'
import { onMounted, reactive, ref, computed, onUnmounted, getCurrentInstance, watch } from 'vue'
import { ElMessage } from 'element-plus';
import { ElMessageBox } from 'element-plus';
import axios from 'axios'
import { Ajax, MessageTips } from "@/utils";
export default {
    components: {
    Editor
  },
    data(){
        return {
        //初始化配置
        init: {
            language_url: `/tinymce/langs/zh_CN.js`,
            language: 'zh_CN',
            skin_url: `/tinymce/skins/ui/oxide`,
            content_css: `/tinymce/skins/content/default/content.css`,
            // plugins: this.plugins,
            height:'500px',
            width:'800px',
            branding: false, // 右下角技术支持
            menubar: false,  //一级菜单
            statusbar: false, // 底部的状态栏
            save_enablewhendirty : false,
            default_link_target: "_blank",
            target_list: [
                {title: '新窗口打开', value: '_blank'},
            ],
            content_style: "img {width:80%;} .mce-content-body {overflow-y: auto !important} pre{white-space: break-spaces}",
            save_onsavecallback: function (e) { console.log('save_onsavecallback',e); },
            plugins: 'link lists image colorpicker textcolor wordcount contextmenu save code preview kityformula-editor',
            toolbar: 'bold italic underline strikethrough | fontsizeselect | removeformat | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | image | link | code | numlist bullist | kityformula-editor | undo redo | ',
            fontsize_formats: "8px 10px 12px 14px 16px 18px 24px 36px",
            external_plugins: {
                'kityformula-editor': '/tinymce/plugins/kityformula-editor/plugin.min.js'
            },
            setup(editor){
                console.log('editor', editor)
            },
            // image_advtab: true,
            // image_title: false, // 是否开启图片标题设置的选择，这里设置否
            // automatic_uploads: true,
            //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
            //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
            images_upload_handler: (blobInfo, success, failure) => {
                this.uploadImage(blobInfo, success, failure)

            },
            onchange_callback: function (e) { console.log('onchange_callback',e); },
            paste_auto_cleanup_on_paste : true,
            paste_remove_styles: true,
            paste_remove_styles_if_webkit: true, 
            paste_strip_class_attributes: true,

        },
            placeholder: '请输入报告内容',
        }
  },
  props:{
        tinymceHtml:String
    },
    setup(props, {emit}) {

        // console.log('props.tinymceHtml', props.tinymceHtml)
        // 直接侦听一个 ref   一个数据源
        const tinymceHtmlprops = ref(props.tinymceHtml);
        watch(tinymceHtmlprops, (val, oldVal) => {
            // console.log(val, oldVal, tinymceHtmlprops.value)
            // if (oldVal !== val){
            //     debounce(sendData, 5000)
            // }
            emit("saveContent", tinymceHtmlprops.value)
        })

        const sendData = ()=> {
            let html = imgMaxWidth(tinymceHtmlprops.value)
            emit("save", tinymceHtmlprops.value)
        }

        let timer = reactive('')
        const debounce = (fn,timeout) =>{
            if (timer ) clearTimeout(timer )
            timer = setTimeout(() => {
                fn()
            }, timeout);
        }

        const imgMaxWidth = (tinymceHtml) => {
            // console.log('tinymceHtml', tinymceHtml)
            let rel = /style\s*?=\s*?([‘"])[\s\S]*?\1/g;
            let newHtml = "";
            if (tinymceHtml) {
                newHtml = tinymceHtml.replace(rel, "");
                // console.log('newHtml', newHtml)
            }
            let html = newHtml.replace(/\s*(&nbsp;)/g, "")
            // let html2 = html.replace(/(<\/?p.*?>)/gi, "")
            var regex1 = new RegExp("(i?)(<img)(?!(.*?style=['\"](.*)['\"])[^>]+>)","gmi");
            //给不含style="" 或 style='' 的img标签加上style=""
            html = html.replace(regex1, "$2 style=\"\"$3");
            // console.log("增加style=\"\"后的html字符串："+html);
            //正则匹配含有style的img标签
            var regex2 = new RegExp("(i?)(<img.*?style=['\"])([^>]+>)","gmi");
            //在img标签的style里面增加css样式(这里增加的样式：display:block;max-width:100%;height:auto;border:5px solid red;)
            html = html.replace(regex2, "$2display:block;max-width:80%;height: auto;$3");
            return html
        }

        const uploadImage = async(blobInfo, success, failure) => {
            // console.log('上传=====', blobInfo, success, failure)
            var data = new FormData();
                data.append('image', blobInfo.blob(), blobInfo.filename());
            const res = await Ajax.POST({
                url: '/admin/upload/image',
                // contentType: "multipart/form-data",
                data
            })
            if (res.data.code === 200){
                const img = 'data:image/jpeg;base64,' + blobInfo.base64()
                success(res.data.data.url)
                MessageTips.success('上传成功');
            } else {
                MessageTips.error('上传失败')
            }
            // console.log('上传图片res', res)
        }
        onMounted(() => {
            tinymce.init({})
        })

        return {
           tinymceHtmlprops, debounce, sendData, imgMaxWidth,
           uploadImage
        }
    },
    mounted(){

    },

    beforeUnmount(){

    },
    watch:{

    },
    computed:{

    },

    methods:{
        onSaveContent(e){

        },
        //富文本内容变化
        onChange(e){
            console.log('onChange', e)
        },


        //富文本实时变化
        SelectionChange(e){
            // console.log('SelectionChange', this.tinymceHtml)
        }


    },

}
</script>

<style lang='less' scoped>
    // *{
    //     box-sizing: border-box;
    // }

    // :deep(.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type)){
    //     border-right: none ;
    // }
    // :deep(.tox-tinymce){
    //     border-color: #F0F0F0;
    // }
    // :deep(.el-scrollbar__view){
    //     height: 100%;
    // }
    // :deep(.tinymce-editor){
    //     height: 500px;
    // }

    // :deep(.tox .tox-toolbar__primary) {
    //     background: none!important;
    //     background-color: #fff;
    // }
    // :deep(.tox-tinymce) {
    //     border: none!important;
    // }
    // :deep(.tox-sidebar-wrap) {
    //     margin-top: -15px;
    // }
    // :deep(.tox .tox-tbtn) {
    //     color: #8C8C8C;
    // }
    // :deep(.tox .tox-tbtn svg) {
    //     fill: #8C8C8C;
    // }
    // :deep(.tox .tox-tbtn--disabled, .tox .tox-tbtn--disabled:hover, .tox .tox-tbtn:disabled, .tox .tox-tbtn:disabled:hover) {
    //     color: rgba(34,47,62,.5);
    // }
    // :deep(.tox .tox-tbtn--select) {
    //     width: 70px;
    // }
    // :deep(.tox-dialog__footer-end){
    //     .tox-button{
    //         display: none !important;
    //     }
    // }
    .tox .tox-editor-container{
        width: 600px !important;
        height: 500px !important;
    }
</style>
