<template>
  <div class="tree-select">
    <el-tree
      v-show="true"
      class="tree"
      ref="tree"
      :data="dataList"
      node-key="id"
      :default-checked-keys="checkedKeys"
      @check="handleCheckChange"
      :filter-node-method="filterFn"
      :props="{ label: 'name', children: 'child', disabled: disabledFn }"
    >
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <span>{{ node.label }}</span>
          <span>
            <a class="btn" @click.stop="append(node, 'same-level')">添加同级</a>
            <!-- <a class="btn" v-if="node.level < 4" ><span @click.stop="append(node, 'child')">添加子级</span></a> -->
            <a class="btn" v-if="node.level < 6" ><span @click.stop="append(node, 'child')">添加子级</span></a>
            <a class="btn" v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
            <a class="btn">
              <span @click.stop="edit(node, data)">编辑</span>
            </a>
            <a class="btn del"
              ><span @click.stop="remove(node, data)"
                >删除</span
              ></a
            >
          </span>
        </span>
      </template>
    </el-tree>
    <div
      class="body-mask"
      v-show="treeShow"
      @click.stop="treeShow = false"
    ></div>
    <el-dialog title="添加新分类" v-model="modalShow" :width="400">
      <div class="add_box">
        <label class="add_label">名称：</label> <el-input v-model="form.name" placeholder="请输入" type="text"></el-input>
      </div>
      <div class="add_box">
        <label class="add_label">描述：</label> <el-input v-model="form.desc"  :autosize="{ minRows: 2 }" placeholder="请输入" type="textarea"></el-input>
      </div>
      
      <template #footer>
        <el-button @click="modalShow = false">取消</el-button>
        <el-button type="primary" @click="submit()">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElSelect } from "element-plus";
import { ElOption } from "element-plus";
import { ElTree } from "element-plus";
import { Ajax, MessageTips } from "../../utils";
export default {
    props: {
        dataList: {
            type: Array
        }
    },
  components: {
    ElSelect,
    ElOption,
    ElTree,
  },
  data() {
    return {
      treeShow: false,
      modalShow: false,
      treeOptions: [],
      checkedTag: [],
      checkedKeys: [],
      form: {
        name: undefined,
        tree_id: undefined,
        parent_id: undefined,
        desc:""
      },
      appendType: "",
      appendNode: null,
      dialogText: "",
    };
  },
  methods: {
    resetValue() {
      this.checkedKeys = [];
      this.$refs.tree.setCheckedKeys(this.checkedKeys);
    },
    getValue() {
      return this.$refs.tree.getCheckedKeys();
    },
    submit() {
      if (!this.form.name) {
        this.$message.error({
          message: "请输入分类名",
          showClose: true,
        });
        return false;
      }
      this.$emit('add', this.appendType, this.form)
    },
    remove(node, data) {
      // Ajax.DELETE(`/api/label/${node.data.id}`).then((res) => {
      //   if (res.code == 200) {
      //     let data = node.parent.data;
      //     if (data.child) {
      //       for (let i in data.child) {
      //         if (data.child[i].id == node.data.id) {
      //           data.child.splice(i, 1);
      //         }
      //       }
      //     } else {
      //       for (let i in data) {
      //         if (data[i].id == node.data.id) {
      //           data.splice(i, 1);
      //         }
      //       }
      //     }
      //     this.treeOptions = [... this.treeOptions]
      //     this.dialogText = "删除成功";
      //     this.$refs.dialog.show();
      //     this.checkedTag=[]
      //   } else {
      //     this.$message.error({
      //       message: res.msg,
      //       showClose: true,
      //     });
      //   }
      // });
      this.$emit('remove', node, data)
    },
    edit(node, data) {
      this.$emit('edit', node, data)
    },
    append(node, type) {
        console.log(node, 'node')
      this.appendType = type || "";
      this.form.name = undefined;
      this.appendNode = node;
      if (type == "child") {
        this.form.tree_id = node.data.tree_id;
        this.form.parent_id = node.data.id;
      } else {
        if (node.level == 1) {
          this.form.tree_id = node.data.tree_id;
          this.form.parent_id = node.data.parent_id;
        } else {
          this.form.tree_id = node.parent.data.tree_id;
          this.form.parent_id = node.parent.data.id;
        }
      }
      this.modalShow = true;
    },
    modalClose() {
      this.modalShow = false;
    },
    handleCheckChange() {
      this.checkedTag = this.$refs.tree.getCheckedNodes();
    },
    removeTag(id) {
      this.checkedKeys = [];
      for (let i in this.checkedTag) {
        if (this.checkedTag[i].id === id) {
          this.checkedTag.splice(i, 1);
        }
      }
      for (let i in this.checkedTag) {
        this.checkedKeys.push(this.checkedTag[i].id);
      }
      this.$refs.tree.setCheckedKeys(this.checkedKeys);
    },
    disabledFn(data) {
      if (data.child && data.child.length > 0) {
        return true;
      }
    },
    filterFn(data) {
      if (data.child.level > 4) {
        return true;
      }
    },
    getChild(id) {
      return Ajax.GET({
        url: `/admin/label_tree/${id}`,
      });
    },
  },
};
</script>

<style lang='less' scoped>
.input {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  box-shadow: inset 1px -1px 0px 0px rgba(238, 238, 238, 1),
    inset -1px 1px 0px 0px rgba(238, 238, 238, 1);
  &::-webkit-input-placeholder {
    color: #999;
  }
}
.tree-select {
  width: 100%;
  max-width: 100% !important;
  min-height: 36px;
  position: relative;
  padding: 6px 10px 0;
  cursor: pointer;
  height: 100%;
  .no-data {
    line-height: 24px;
    font-size: 14px;
    color: #999;
  }
  .tags {
    .tag {
      position: relative;
      display: inline-block;
      vertical-align: top;
      min-width: 80px;
      padding-left: 6px;
      padding-right: 20px;
      height: 24px;
      border: 1px solid @primary-color;
      border-radius: 2px;
      line-height: 22px;
      font-size: 14px;
      color: @primary-color;
      margin-right: 10px;
      margin-bottom: 6px;
      .icon {
        position: absolute;
        right: 6px;
        top: 5px;
        z-index: 8;
        cursor: pointer;
      }
    }
  }
}
.tree {
//   position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  border-radius: 4px;
  z-index: 8;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px;
  border: 1px solid #dedede;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    border-radius: 10px;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.btn {
  width: 48px;
  margin-left: 18px;
  font-size: 12px;
  color: @primary-color;
  &.del {
    width: 24px;
  }
}
/deep/.el-tree-node__content > label.el-checkbox.is-disabled {
  display: none;
}
.hidden {
  display: none;
}
.add_box{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .add_label{
    width: 60px;
  }
}

</style>