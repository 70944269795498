//节流
export function throttle(fn, delay = 500) {
    let can = true;
    return function (...arg) {
        if(!can) return ;
        can = false;
        setTimeout(() => {
            fn.apply(this, arg)
            can = true;
        }, delay)
    }
}

//防抖 
export function debounce(fn, delay = 500) {
    let timer = null;
    let func = function (...arg) {
        timer && clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(this,arg)
        }, delay)
    }
    return func
}