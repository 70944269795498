<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                  ref="formRef"
                  :model="baseNewsForm"
                  :rules="baseNewsFormRule"
                  label-width="120px"
                  class="user-form-box mg-t-24"
                >
                  <el-form-item label="名称" prop="title">
                    <el-input
                      v-model="baseNewsForm.title"
                      placeholder="请输入"
                      :maxlength="50"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="类型" prop="type">
                    <el-select
                      style="width: 100%"
                      v-model="baseNewsForm.type"
                      placeholder="请选择"
                    >
                      <el-option label="品牌" value="1"></el-option>
                      <el-option label="人物" value="2"></el-option>
                      <el-option label="机构" value="3"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="简介" prop="summary">
                    <el-input
                      v-model="baseNewsForm.summary"
                      type="textarea" placeholder="请输入" 
                      :autosize="{ minRows: 2, maxRows: 14 }" maxlength="100" 
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="行业领域" prop="tags">
                    <checkTag ref="checkTag"></checkTag>
                  </el-form-item>
                  <el-form-item label="发布时间" prop="publish_time">
                    <el-date-picker value-format="YYYY-MM-DD" v-model="baseNewsForm.publish_time" type="date" placeholder="选择日期" />
                  </el-form-item>
                </el-form>
            </div>
            <div class="bottom-submit-box pd-16 mg-t-24">
              <el-row justify="end">
                <el-col :span="24" class="text-right">
                  <el-button plain @click="cancelSubmit('formRef')"
                    >取消</el-button
                  >
                  <el-button type="primary" @click="submitForm('formRef')"
                    >保存</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance } from "vue";
import { Ajax, MessageTips, Rule } from "../../utils";
import { useRouter } from "vue-router";
import checkTag from '../../components/CheckTagNew'
export default {
  components: {
    Header,
    BreadCrumb,
    checkTag
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const breadLists = ref([
      {
        name: "热点",
        path: {
          name: "hotnews",
        },
      },
      {
        name: "新增热点",
      },
    ]);
    const baseNewsFormRef = ref({
      title: undefined,
      type: undefined,
      summary: undefined,
      tags: undefined,
      publish_time: undefined,
    });
    const baseNewsFormRule = {
      title: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      
      type: [
        {
          required: true,
          message: "请选择",
          trigger: "change",
        },
      ],
    };
    const back = () => {
      router.go(-1);
    };

    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      back();
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
           let tag=ref([])
            tag.value=proxy.$refs.checkTag.getValue()
            baseNewsFormRef.value.tags=tag.value
          Ajax.POST({
            url: "/admin/hot/create",
            data: {
              title:baseNewsFormRef.value.title,
              type:baseNewsFormRef.value.type,
              summary:baseNewsFormRef.value.summary,
              tags:baseNewsFormRef.value.tags,
              publish_time:baseNewsFormRef.value.publish_time,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("新增成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };
    return {
      breadLists,
      baseNewsForm: baseNewsFormRef,
      baseNewsFormRule,
      cancelSubmit,
      submitForm,
      Rule,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 50%;
    // padding: 0px 180px;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
}
</style>