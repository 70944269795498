<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="social-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">社会组织基本信息</span>
                                <el-button type="primary" plain @click="toEdit">编辑基本资料</el-button>
                            </div>
                        </template>
                        <div class="detail-container">
                            <el-row>
                                <el-col>
                                    <el-descriptions :column="2">
                                        <el-descriptions-item label-align="right" label="名称：">{{ socialInfo.name }}</el-descriptions-item>
                                        <el-descriptions-item label-align="right" label="状态：">
                                            <span :class="`status-text ${statusClass[socialInfo.status || 0]}`">{{ statusLabel[socialInfo.status || 0] }}</span>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="right" label="发布时间：">{{ socialInfo.publish_time }}</el-descriptions-item>
                                        <el-descriptions-item label-align="right" label="最近编辑时间：">{{ socialInfo.update_time}}</el-descriptions-item>
                                        <el-descriptions-item label-align="right" :span="2" label="简介：">{{ socialInfo.desc }}</el-descriptions-item>
                                    </el-descriptions>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { ref, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Ajax, configData } from '../../../../utils'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '社会组织',
                path: {
                    name: 'OrganizationSocial'
                }
            },
            {
                name: '社会组织详情'
            }
        ])
        const socialInfoRef = ref({})
        const statusLabel = configData.statusLabel;
        const statusClass = configData.statusClass;
        onBeforeMount(() => {
            Ajax.GET({
                url: `/admin/social_org/${route.query.id}`
            }).then(res => {
                if(res.data.code === 200) {
                    socialInfoRef.value = res.data.data;
                }
            })
        })
        const route = useRoute();
        const router = useRouter();
        const toEdit = () => {
            router.push({
                path: '/organization/social/edit',
                query: {
                    id: route.query.id
                }
            })
        }
        return {
            breadLists: breadListsRef,
            statusLabel,
            statusClass,
            socialInfo: socialInfoRef,
            toEdit
        }
    }
};
</script>

<style lang="less" scoped>
    .social-detail-container {
        height: 100%;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        ::v-deep(.el-descriptions__body .el-descriptions__table .el-descriptions__cell) {
            width: 50%;
        }
    }
</style>