<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="edit-company-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">{{
                  nowPageType === "edit-basic" ? "主体基本信息" : "补充信息"
                }}</span>
              </div>
            </template>
            <div class="form-container">
              <template v-if="nowPageType === 'edit-basic'">
                <el-form
                  style="width: 50%"
                  ref="basicFormRef"
                  :model="companyBaseInfo"
                  :rules="companyBaseInfoRule"
                  label-width="140px"
                  class="mg-t-24"
                >
                  <!-- <el-form-item label="名称" prop="name">
                    <el-input
                      v-model="companyBaseInfo.name"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item> -->
                  <el-form-item label="全称" prop="register_name">
                    <el-input
                      v-model="companyBaseInfo.register_name"
                      placeholder="请输入工商注册名称"
                    ></el-input>
                    </el-form-item>
                    <el-form-item label="成立时间" prop="date">
                    <el-date-picker
                      value-format="YYYY-MM-DD"
                      v-model="companyBaseInfo.establish_time"
                      type="date"
                      placeholder="选择日期"
                    />
                  </el-form-item>
                    <el-form-item label="统一社会信用代码" prop="reg_no">
                    <el-input
                      v-model="companyBaseInfo.reg_no"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="国家" prop="province">
                    <!-- <el-select
                      v-model="companyBaseInfo.country"
                      placeholder="请选择"
                      @change="coutryChange"
                    >
                      <el-option
                        v-for="option in countrys"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                      />
                    </el-select> -->
                    <el-select class="select w-100" placeholder="请选择"
                        :popper-append-to-body="false" v-model="companyBaseInfo.country"
                        @change="coutryChange"
                        filterable
                        remote
                        :reserve-keyword="false"
                        :remote-method="countryRemoteMethod"
                    >
                        <el-option
                            v-for="item in countrys"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        {{item.name}}
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="地区" prop="province">
                    <el-cascader
                      v-model="provinceVal"
                      :options="provincesArr"
                      @change="provinceChange"
                      :props="{
                        value: 'id',
                        label: 'name',
                        children: 'child',
                      }"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item label="简介" prop="profile">
                    <el-input
                      v-model="companyBaseInfo.profile"
                      placeholder="一句话简介50字以内"
											show-word-limit
											maxlength="50"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="介绍" prop="introduction">
                    <el-input
                      v-model="companyBaseInfo.introduction"
                      :maxlength="500"
                      :autosize="{ minRows: 2, maxRows: 14 }"
                      show-word-limit
                      type="textarea"
                      placeholder="请输入"
                    ></el-input>
                  </el-form-item>
                  <!-- <el-form-item label="行业领域">
                    <checkTag ref="checkTag"></checkTag>
                  </el-form-item> -->

                  <!-- <el-form-item label="照片" prop="avator">
                    <p>限制大小1M,支持JPG、PNG格式</p>
                    <el-upload
                      class="avatar-uploader"
                      :class="{
                        border: !companyContactInfo.image,
                      }"
                      :show-file-list="false"
                      action="#"
                      :http-request="uploadImage"
                      accept="image/jpg, image/jpeg, image/png"
                      :before-upload="beforeUpload"
                    >
                      <div
                        class="avatar-img-box"
                        v-if="companyContactInfo.image"
                      >
                        <img :src="companyContactInfo.image" class="avatar" />
                      </div>
                      <el-icon v-else class="avatar-uploader-icon"
                        ><plus
                      /></el-icon>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="官网" prop="link">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.link"
                    />
                  </el-form-item>
                  <el-form-item label="联系方式"> </el-form-item>
                  <el-form-item label="电话" prop="telephone">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.telephone"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="邮箱" prop="email">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.email"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="手机" prop="mobile">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.mobile"
                    ></el-input>
                  </el-form-item> -->
                </el-form>
                <el-form
                  style="width: 50%"
                  ref="contentFormRef"
                  :model="companyContactInfo"
                  :rules="companyContactRule"
                  label-width="120px"
                  class="mg-t-24"
                >
                  <el-form-item label="照片" prop="avator">
                    <p>限制大小1M,支持JPG、PNG格式</p>
                    <el-upload
                      class="avatar-uploader"
                      :class="{
                        border: !companyContactInfo.image,
                      }"
                      :show-file-list="false"
                      action="#"
                      :http-request="uploadImage"
                      accept="image/jpg, image/jpeg, image/png"
                      :before-upload="beforeUpload"
                    >
                      <div
                        class="avatar-img-box"
                        v-if="companyContactInfo.image"
                      >
                        <img :src="companyContactInfo.image" class="avatar" />
                      </div>
                      <el-icon v-else class="avatar-uploader-icon"
                        ><plus
                      /></el-icon>
                    </el-upload>
                  </el-form-item>
                  <el-form-item label="官网" prop="link">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.link"
                    />
                  </el-form-item>
                  <el-form-item label="联系方式"> </el-form-item>
                  <el-form-item label="电话" prop="telephone">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.telephone"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="邮箱" prop="email">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.email"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="手机" prop="mobile">
                    <el-input
                      placeholder="请输入"
                      v-model="companyContactInfo.mobile"
                    ></el-input>
                  </el-form-item>
                </el-form>
              </template>

            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-12">
            <el-row justify="end">
              <el-col :span="4">
                <el-button size="small" plain @click="cancelSubmit"
                  >取消</el-button
                >
                <el-button size="small" type="primary" @click="submitForm"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
import { Ajax, MessageTips, Rule } from "../../../utils";
// import checkTag from "../../../components/CheckTag";
export default {
  components: {
    Plus,
    // checkTag,
    Header,
    BreadCrumb,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const breadListsRef = ref([
      {
        name: "主体",
        path: {
          name: "Company",
        },
      },
      {
        name: "主体详情",
        path: {
          name: "CompanyDetail",
          query: {
            id: route.query.id,
          },
        },
      },
      {
        name: route.query.pageName,
      },
    ]);

    // 页面类型
    const nowPageType = computed(() => {
      return route.query.type;
    });
    const { proxy } = getCurrentInstance();

    const companyBaseInfoRef = ref({
      name: "",
      register_name:"",
      reg_no:"",
      country: null,
      province: null,
      city: null,
      profile: "",
      introduction:"",
      establish_time: "",
      trade: "",
      phase: null,
    });
    // 获取信息回填
    const countrysRef = ref([]);
    const provincesArrRef = ref([]);
    const provinceValRef = ref(null);
    let regionArr = [];
    onBeforeMount(async () => {
      // 国家地区信息
      const adminRegions = localStorage.getItem("adminRegions");
      if (adminRegions) {
        const regs = JSON.parse(adminRegions);
        regionArr = regs;
        countrysRef.value = regionArr.map(({ id, name }) => ({
          id,
          name,
        }));
      } else {
        const res = await Ajax.GET({
          url: "/admin/region",
        });
        const resData = res.data;
        if (resData.code === 200) {
          regionArr = resData.data;
          countrysRef.value = regionArr.map(({ id, name }) => ({
            id,
            name,
          }));
        }
      }
      Ajax.GET({
        url: `/admin/company/${route.query.id}`,
      }).then((res) => {
        if (res.data.code === 200) {
          const companyRes = res.data.data;
          // 基本信息
          if (nowPageType.value === "edit-basic") {
            companyBaseInfoRef.value.name = companyRes.name;
            companyBaseInfoRef.value.country = companyRes.country || null;
            companyBaseInfoRef.value.province = companyRes.province || null;
            companyBaseInfoRef.value.city = companyRes.city || null;
            companyBaseInfoRef.value.profile = companyRes.profile;
            companyBaseInfoRef.value.establish_time = companyRes.establish_time;
            companyBaseInfoRef.value.trade = companyRes.trade;
            companyBaseInfoRef.value.introduction = companyRes.introduction;
            companyBaseInfoRef.value.phase = companyRes.phase;
            companyBaseInfoRef.value.register_name = companyRes.register_name;
            companyBaseInfoRef.value.reg_no=companyRes.reg_no;
            // proxy.$refs.checkTag.setValue(companyBaseInfoRef.value.trade_arr);
            const findObj = regionArr.find(
              (item) => item.id === companyRes.country
            );
            if (findObj) {
              provincesArrRef.value = findObj.child;
            }
            if (companyRes.province && companyRes.city) {
              provinceValRef.value = [companyRes.province, companyRes.city];
            } else if (companyRes.province) {
              provinceValRef.value = [companyRes.province];
            }
            console.log(provinceValRef.value);
          // } else {
            // 补充信息
            companyContactInfoRef.value.image = companyRes.image;
            companyContactInfoRef.value.link = companyRes.link;
            companyContactInfoRef.value.telephone = companyRes.telephone;
            companyContactInfoRef.value.email = companyRes.email;
            companyContactInfoRef.value.mobile = companyRes.mobile;
          }
        }
      });
    });

    // 国家选择
    const coutryChange = (val) => {
      const findObj = regionArr.find((item) => item.id === val);
      companyBaseInfoRef.value.country = val;
      companyBaseInfoRef.value.province = null;
      companyBaseInfoRef.value.city = null;
      provincesArrRef.value = findObj?findObj.child:[];
    };
    const countryRemoteMethod = query =>{
        if (query) {
            setTimeout(() => {
            countrysRef.value = regionArr.filter((item) => {
                return item.name.includes(query)
            })
            }, 200)
        } else {
            countrysRef.value = []
        }
    }
    // 地区选择
    const provinceChange = (val) => {
      companyBaseInfoRef.value.province = val[0];
      companyBaseInfoRef.value.city = val[1];
    };

    const typeOptions = [
      {
        value: "Option1",
        label: "Option1",
      },
      {
        value: "Option2",
        label: "Option2",
      },
      {
        value: "Option3",
        label: "Option3",
      },
      {
        value: "Option4",
        label: "Option4",
      },
      {
        value: "Option5",
        label: "Option5",
      },
    ];
    const companyBaseInfoRule = {
      name: [
        {
          required: true,
          message: "请输入名称",
          trigger: "blur",
        },
      ],
      register_name: [
        {
          required: true,
          message: "请输入主体工商名称",
          trigger: "blur",
        },
      ],
    };

    // 修改补充信息
    const avatarUploadSuccess = (res, file) => {
      console.log(res, file);
    };

    const companyContactInfoRef = ref({
      images: "",
      link: "",
      telephone: "",
      email: "",
      mobile: "",
    });

    const companyContactRule = ref({
      telephone: {
        required: false,
        validator: (rule, value, callback) => {
          // if (value && !Rule.isTelephone(value)) {
          //   callback(new Error("请输入正确的电话号码"));
          // } else {
          //   callback();
          // }
          callback()
        },
        trigger: "blur",
      },
      mobile: {
        required: false,
        validator: (rule, value, callback) => {
          // if (value && !Rule.isMobile(value)) {
          //   callback(new Error("请输入正确的手机号"));
          // } else {
          //   callback();
          // }
          callback()
        },
        trigger: "blur",
      },
      email: {
        required: false,
        validator: (rule, value, callback) => {
          // if (value && !Rule.isMail(value)) {
          //   callback(new Error("请输入正确的邮箱"));
          // } else {
          //   callback();
          // }
          callback();
        },
        trigger: "blur",
      },
    });

    // 限制文件大小
    const beforeUpload = (file) => {
      console.log(file);
      const isL1M = file.size / 1000 / 1000 <= 1;
      if (!isL1M) {
        MessageTips.error("文件上传不能大于1M");
        return false;
      }
      return true;
    };

    // 上传照片
    const uploadImage = (data) => {
      const file = data.file;
      let formData = new FormData();
      formData.append("image", file);
      formData.append("restrict", "1000");
      Ajax.POST({
        url: "/admin/upload/image",
        contentType: "multipart/form-data",
        data: formData,
      }).then((res) => {
        if (res.data.code === 200) {
          companyContactInfoRef.value.image = res.data.data.url;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    // 底部提交
    const cancelSubmit = () => {
      if (nowPageType.value === "edit-basic") {
        proxy.$refs["basicFormRef"].resetFields();
      } else {
        proxy.$refs["contentFormRef"].resetFields();
      }
      router.back();
    };
    const submitForm = () => {
      let valid1;
      proxy.$refs["basicFormRef"].validate((valid) => {
        if (valid){
          return valid1=valid
        }
      })
      let valid2;
      proxy.$refs["contentFormRef"].validate((valid) => {
        if (valid) {
          return valid2=valid
        }
      })
      if(valid1 && valid2){
         let primars={};
         primars={
                ...companyContactInfoRef.value,
                ...companyBaseInfoRef.value,
              };
        Ajax.PUT({
          url: `/admin/company/${route.query.id}`,
          data: primars,
        }).then(res=>{
          if (res.data.code === 200) {
            MessageTips.success("编辑成功");
            router.back();
          } else {
            MessageTips.error(res.data.msg);
          }
        })
      }

    };


    return {
      breadLists: breadListsRef,
      companyBaseInfo: companyBaseInfoRef,
      companyBaseInfoRule,
      countrys: countrysRef,
      provincesArr: provincesArrRef,
      provinceVal: provinceValRef,
      countryRemoteMethod,
      typeOptions,
      cancelSubmit,
      submitForm,
      nowPageType,
      avatarUploadSuccess,
      companyContactInfo: companyContactInfoRef,
      companyContactRule,
      coutryChange,
      provinceChange,
      beforeUpload,
      uploadImage,
    };
  },
};
</script>

<style lang="less" scoped>
.edit-company-container {
  height: 100%;
  .form-container {
    overflow: auto;
  }
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 50%;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    &.border {
      border: 1px dashed #d9d9d9;
    }
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-img-box {
      width: 100%;
      height: 100%;
      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
