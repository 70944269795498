<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container" id="literature">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">文献基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="articeForm"
                :rules="articeFormRule"
                label-width="140px"
                class="user-form-box mg-t-24"
              >
                
                <el-form-item label="自定义标题：" prop="title">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.title"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="论文中文标题：" >
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.cn_title"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="论文英文标题：" >
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.en_title"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="简介提要/导读：" prop="brief">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.brief"
                    type="textarea"
                    placeholder="请输入"
                    :rows="5"
                    show-word-limit
                  >
                  </el-input>
                </el-form-item>
                

                <el-form-item label="摘要：" prop="summary" required>
                  <tinymceEditor :tinymceHtml="articeForm.summary" @saveContent="getContent" v-if="!isLoading"/>
                </el-form-item>


                <el-form-item label="行业领域：" >
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.subject"
                    placeholder="如多个行业领域，请用顿号分割"
                  ></el-input>
                </el-form-item>
                <el-form-item label="来源期刊：" prop="periodical">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.periodical"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item label="作者：" prop="author">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.author"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item label="相关专家：">
                  <el-tag
                    class="mg-r-12"
                    v-for="(tag, index) in articeForm.relation_personage"
                    :key="tag.id"
                    closable
                    :disable-transitions="false"
                    @close="deleteTag(index, 'relation_personage')"
                  >
                    {{ tag.name }}
                  </el-tag>
                  <el-button size="small" @click="showDialog('添加专家')"
                    >+ 新标签</el-button
                  >
                </el-form-item>
                <el-form-item label="DOI" prop="doi">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.doi"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="发表时间：" prop="report_time">
                  <el-date-picker
                    style="width: 400px"
                    type="date"
                    v-model="articeForm.report_time"
                    placeholder="选择日期"
                    value-format="YYYY-MM-DD"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="原文链接：" prop="link">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.link"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="关键词：">
                  <!-- <CheckTag width="400px" ref="checktag"></CheckTag> -->
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.keyword"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item> 
                <el-form-item label="引用量：" class="mg-t-12" prop="cites">
                  <el-input
                    style="width: 400px"
                    v-model="articeForm.cites"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-24">
            <el-row justify="end">
              <el-col :span="4">
                <el-button plain @click="cancelSubmit('formRef')"
                  >取消</el-button
                >
                <el-button type="primary" @click="submitForm('formRef')"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>


    <el-dialog
    v-model="tagDialogShow"
    :title="dialogDetail.title"
    :width="880"
    :close-on-click-modal="false"
    :show-close="true"
  >
    <div class="dialog-content" v-if="dialogDetail.title === '添加专家'">
      <el-input
        style="width: 200px"
        v-model="searchForm.name"
        placeholder="搜索专家"
        :suffix-icon="Search"
        @change="getPersons('search')"
      ></el-input>
      <el-table
        class="mg-t-12"
        :data="authorTable"
        border
        max-height="400"
        v-loading="isLoading"
      >
        <el-table-column prop="name" label="姓名">
          <template #default="scope">
            <el-input
              v-if="scope.row.type == 'new'"
              v-model="scope.row.name"
              placeholder="请输入"
            ></el-input>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="research_label_arr" label="研究标签" />
        <el-table-column prop="invest_label_arr" label="投资标签" />
        <el-table-column prop="startup_label_arr" label="创业标签" />
        <el-table-column prop="operation" label="操作">
          <template #default="scope">
            <el-radio
              v-model="authorId"
              :label="scope.row.id"
              @change="authorChange"
              >选择</el-radio
            >
          </template>
        </el-table-column>
        <template #empty>
          没有匹配的结果<el-button
            type="primary"
            class="mg-l-20"
            size="small"
            @click="addAuthor()"
            >新增</el-button
          >
        </template>
      </el-table>
      <div class="table-pagination-row mg-t-24">
        <el-pagination
          background
          :currentPage="paginationData.current"
          :page-sizes="paginationData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paginationData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <el-button @click="tagDialogShow = false">取消</el-button>
      <el-button type="primary" @click="submit()">确定</el-button>
    </template>
  </el-dialog>

  </div>

  
</template>

<script>
import { ref, onMounted, getCurrentInstance, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
import { Plus, Search } from "@element-plus/icons-vue";
import MenuTrees from "../../../components/MenuTrees/MenuTrees.vue";
import CheckTag from "../../../components/CheckTag";
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import tinymceEditor from '@/components/tinymceEditor/index.vue'
export default {
  components: {
    Plus,
    MenuTrees,
    CheckTag,
    Header,
    BreadCrumb,
    tinymceEditor
  },
  setup() {
    const router = useRouter();
    const breadLists = ref([
      {
        name: "文献",
        path: {
          name: "literature",
        },
      },
      {
        name: "新增文献",
      },
    ]);
    const isLoading = ref(false);
    const dialogDetail = ref({
      title: undefined,
    });
    const searchForm = ref({
      page: 1,
      page_size: 10,
      name: undefined,
      status:1,
    });
    const { proxy } = getCurrentInstance();
    const articeFormRef = ref({
      doi: undefined,
      title: undefined,
      cn_title:undefined,
      en_title:undefined,
      brief:undefined,
      subject:undefined,
      periodical: undefined,
      // issn: undefined,
      // impact_factor: undefined,
      summary: undefined,
      cites: undefined,
      report_time: undefined,
      link:undefined,
      author: "",
      relation_personage:[],
      authorId: [],
      keyword: "",
    });
    const authorId = ref();
    const authorTable = ref([]);
    const authorDetail = ref({});

    const getContent = val => {
      articeFormRef.value.summary = val
    }

    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      router.back();
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          Ajax.POST({
            url: "/admin/literature",
            data: {
              doi: articeFormRef.value.doi,
              title: articeFormRef.value.title,
              author:articeFormRef.value.author,
              relation_personage:articeFormRef.value.authorId.length > 0? articeFormRef.value.authorId.join(","): undefined,
              periodical: articeFormRef.value.periodical,
              // issn: articeFormRef.value.issn,
              // impact_factor: articeFormRef.value.impact_factor,
              summary: articeFormRef.value.summary,
              cites: articeFormRef.value.cites,
              report_time: articeFormRef.value.report_time,
              keyword:articeFormRef.value.keyword,
              cn_title:articeFormRef.value.cn_title,
              en_title:articeFormRef.value.en_title,
              brief:articeFormRef.value.brief,
              subject:articeFormRef.value.subject,
              link:articeFormRef.value.link,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("编辑成功");
              router.back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };
    const articeFormRule = {
      doi: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      title: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      author: [
        {
          required: true,
          message: "请添加",
          trigger: "blur",
        },
      ],
      periodical: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      // issn: [
      //   {
      //     required: false,
      //     message: "请输入",
      //     trigger: "blur",
      //   },
      // ],
      // impact_factor: [
      //   {
      //     required: false,
      //     message: "请输入",
      //     trigger: "blur",
      //   },
      // ],
      report_time: [
        {
          required: true,
          message: "请选择",
          trigger: "blur",
        },
      ],
      summary: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      link: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      keyword: [
        {
          required: false,
          message: "请添加",
          trigger: "blur",
        },
      ],
      cites: [
        {
          required: false,
          message: "请输入",
          trigger: "blur",
        },
      ],
    };
    const tagDialogShowRef = ref(false);
    const showDialog = (title) => {
      dialogDetail.value.title = title;
      if (title == "添加专家") {
        authorId.value = undefined;
        searchForm.value.name = undefined;
        authorDetail.value = {};
        getPersons("search");
      }
      tagDialogShowRef.value = true;
    };
    const submit = () => {
      if (dialogDetail.value.title === "添加专家") {
        if (authorId.value) {
          if (articeFormRef.value.authorId.indexOf(authorId.value) != -1) {
            MessageTips.error("不能选择重复数据");
            return;
          }
          articeFormRef.value.relation_personage.push(authorDetail.value);
          articeFormRef.value.authorId.push(authorId.value);
        } else {
          MessageTips.error("请选择");
          return;
        }
      }
      tagDialogShowRef.value = false;
    };
    const deleteTag = (index, type) => {
      if (type == "relation_personage") {
        articeFormRef.value.relation_personage.splice(index, 1);
        articeFormRef.value.authorId.splice(index, 1);
      } else {
        dynamicTagsRef.value.splice(index, 1);
      }
    };
    const authorChange = (id) => {
      if (!id) {
        Ajax.POST({
          url: "/admin/personage/add_audit_personage",
          data: {
            name: authorTable.value[0].name,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            authorTable.value[0].id = res.data.data.insertId;
            authorId.value = res.data.data.insertId;
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
      }
      for (let i in authorTable.value) {
        let item = authorTable.value[i];
        if (item.id === id) {
          authorDetail.value = item;
          return;
        }
      }
    };
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      searchForm.value.page_size = e;
      getPersons();
    };
    const handleCurrentChange = (e) => {
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getPersons();
    };
    const getPersons = (type) => {
      isLoading.value = true;
      if (type) {
        paginationDataRef.value.current = 1;
        searchForm.value.page = 1;
      }
      Ajax.GET({
        url: "/admin/personage",
        params: searchForm.value,
      }).then((res) => {
        isLoading.value = false;
        if (res.data.code == 200) {
          authorTable.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const addAuthor = () => {
      authorTable.value[0] = {
        type: "new",
        name: searchForm.value.name,
        id: 0,
      };
      paginationDataRef.value.total = 1;
    };
    onMounted(() => {});
    return {
      breadLists,
      searchForm,
      articeForm: articeFormRef,
      cancelSubmit,
      submitForm,
      articeFormRule,
      tagDialogShow: tagDialogShowRef,
      dialogDetail,
      paginationData: paginationDataRef,
      authorChange,
      authorId,
      authorTable,
      getPersons,
      showDialog,
      submit,
      deleteTag,
      handleSizeChange,
      handleCurrentChange,
      Search,
      addAuthor,
      isLoading,
      getContent,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 80%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
    overflow: auto;
  }
}
.dialog-search-row {
  position: relative;
  top: -24px;
  height: 52px;
  display: flex;
  align-items: center;
  span {
    position: relative;
    padding-left: 10px;
    &::before {
      content: "*";
      position: absolute;
      left: 0;
      top: 50%;
      display: inline-block;
      color: red;
      font-size: 16px;
      // line-height: 52px;
      transform: translateY(-50%);
    }
  }
  p {
    flex: 1;
    height: 46px;
    border-radius: 4px;
    border: 1px solid #bbb;
  }
}
.dialog-content-key {
  display: flex;
  .left-select-tree {
    width: 256px;
    ::v-deep(.el-menu-item) {
      height: 42px;
      &.is-active {
        position: relative;
        background-color: fadeout(@primary-color, 86%);
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 0;
          width: 4px;
          height: 100%;
          // background-color: #3894FF;
          background-color: @primary-color;
        }
      }
    }
  }
  .right-tags-box {
    .tag-input {
      width: 100px;
      display: inline-block;
    }
  }
}
</style>