<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="exit-ditail-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">消息推送</span>
              </div>
            </template>
            <el-descriptions :column="1">
              <el-descriptions-item label="标题:">{{
                detail.title
              }}</el-descriptions-item>
              <el-descriptions-item label="推送内容:">
                <span>{{ detail.content }}</span>
              </el-descriptions-item>
              <el-descriptions-item label="推送时间:">{{
                detail.create_time
              }}</el-descriptions-item>
            </el-descriptions>
            <div class="texet-right">
              <el-button
                type="primary"
                size="small"
                class="btn-search"
                @click="back"
                >返回</el-button
              >
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { GET } from "../../utils/request";
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
       const breadLists = ref([
      {
        name: "消息",
        path: {
          name: "Messages",
        },
      },
      {
        name: "消息推送",
      },
    ]);
    const detail = ref({});
    const router = useRouter();
    const getDetail = () => {
      let id = useRoute().query.id;
      GET({
        url: `/admin/message/${id}`,
      }).then((res) => {
        let temp = res.data.data;
        detail.value.title = temp.title;
        detail.value.content = temp.content;
        detail.value.create_time = temp.create_time;
      });
    };
    const back = () => {
      router.back();
    };
    onMounted(() => {
      getDetail();
    });

    return {
        breadLists,
      detail,
      back,
    };
  },
};
</script>

<style scoped lang='less'>
.texet-right {
  text-align: right;
  margin-top: 16px;
}
.exit-ditail-container {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>