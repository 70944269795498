<template>
  <div class="tree-select" :style="{ width: width }" @click.stop="treeShow = false">
    <div class="example-block" v-if="!checkedTag.length">
      <el-cascader
				v-model="selectedOptions"
				placeholder="行业领域"
				:options="treeOptions"
				:props="cascaderParams"
				style="width: 100%"
        @change="dataChange"
				filterable></el-cascader>
    </div>
    <!-- <div class="no-data" v-if="!checkedTag.length">请选择</div> -->
    <div class="tags" v-else>
      <span class="tag" v-for="item in checkedTag" :key="item.id">
        {{ item.name }}
        <img
          class="icon"
          src="../../assets/images/close_color.svg"
          alt=""
          @click="removeTag(item.id)"
        />
      </span>
    </div>
     
    <!-- <el-tree
      v-show="treeShow"
      class="tree"
      ref="tree"
      :data="treeOptions"
      node-key="id"
      show-checkbox
      check-strictly
      :default-checked-keys="checkedKeys"
      @check="handleCheckChange"
      :filter-node-method="filterFn"
      :props="{ label: 'name', children: 'child', disabled: disabledFn }"
    >
      <template #default="{ node, data }" v-if="isShowBtn">
        <span class="custom-tree-node">
          <span>{{ node.label }}</span>
          <span>
            <a class="btn" @click.stop="append(node)">添加同级</a>
            <a class="btn"
              ><span v-if="node.level < 4" @click.stop="append(node, 'child')"
                >添加子级</span
              ></a
            >
            <a class="btn del"
              ><span v-if="data.isSelf" @click.stop="remove(node)"
                >删除</span
              ></a
            >
          </span>
        </span>
      </template>
    </el-tree> -->
    <!-- <div
      class="body-mask"
      v-show="treeShow"
      @click.stop="treeShow = false"
    ></div> -->
    <!-- <el-dialog v-model="modalShow" :width="400">
      <input
        class="input"
        type="text"
        placeholder="请输入"
        v-model="form.name"
      />
      <template #footer>
        <el-button @click="modalShow = false">取消</el-button>
        <el-button type="primary" @click="submit()">确定</el-button>
      </template>
    </el-dialog> -->
    <!-- <g-modal title="添加新标签" v-if="modalShow" @hideModal="modalShow = false" @confimFn="submit">
            <input class="input" type="text" placeholder="请输入" v-model="form.name">
        </g-modal>
        <g-dialog type="success" :text="dialogText" ref="dialog" /> -->
  </div>
</template>

<script>
import { ElSelect } from "element-plus";
import { ElOption } from "element-plus";
import { ElTree,ElCascader,ElCascaderPanel } from "element-plus";
import { Ajax, MessageTips } from "../../utils";
export default {
  props: ["width",'isShowBtn'],
  components: {
    ElSelect,
    ElOption,
    ElTree,
    ElCascader,
    ElCascaderPanel
  },
 
  data() {
    return {
      treeShow: false,
      modalShow: false,
      treeOptions: [],
      checkedTag: [],
      checkedKeys: [],
      form: {
        name: undefined,
        tree_id: undefined,
        parent_id: undefined,
      },
      appendType: "",
      appendNode: null,
      dialogText: "",
      selectedOptions:[],
      cascaderParams: {
        multiple: true,
        value:'id',
        label:'name',
        children:'child'
      },
    }
    
  },
  methods: {
    resetValue() {
      this.checkedKeys = [];
      this.$refs.tree.setCheckedKeys(this.checkedKeys);
    },
    setValue(values){
      let tagData=values;
      //将标签中的pid_path转为数组直接放进组件
			let tradeArr=[];
			if(tagData.length>0){
				tagData.forEach(item=>{
          if(item.pid_path){
            let child=item.pid_path.split(","),childNum=[];
            child.forEach(num=>{
              childNum.push(Number(num))
            })
            tradeArr.push(childNum)
          }
					
				})
      }
      this.selectedOptions=tradeArr
      // this.checkedTag=values
      // this.checkedKeys = [];
      // for(let i in values){
      //   let item=values[i]
      //   this.checkedKeys.push(item.id)
      // }
      // this.$refs.tree.setCheckedKeys(this.checkedKeys);
    },
    getValue() {
      let trade = new Set(this.selectedOptions.toString().split(","))
			let tradeArr = [...trade];
      return tradeArr.join(',')
      // return this.$refs.tree.getCheckedKeys();
    },
    submit() {
      if (!this.form.name) {
        this.$message.error({
          message: "请输入标签名",
          showClose: true,
        });
        return false;
      }
      Ajax.GET({
        url: "/admin/tags",
        params: this.form,
      }).then((res) => {
        if (res.code == 200) {
          this.form = {
            name: undefined,
            tree_id: undefined,
            parent_id: undefined,
          };
          this.modalShow = false;
          const newChild = {
            child: [],
            desc: res.data.create.desc,
            name: res.data.create.name,
            parent_id: res.data.create.parent_id,
            tree_id: res.data.create.tree_id,
            id: res.data.insertId,
            isSelf: true,
          };
          if (this.appendType == "child") {
            this.appendNode.data.child.push(newChild);
          } else {
            if (this.appendNode.parent.data.child) {
              this.appendNode.parent.data.child.push(newChild);
            } else {
              this.appendNode.parent.data.push(newChild);
            }
          }
          this.treeOptions = [... this.treeOptions]
          this.dialogText = "添加成功";
          this.$refs.dialog.show();
          this.checkedTag=[]
        } else {
          this.$message.error({
            message: res.msg,
            showClose: true,
          });
        }
      });
    },
    remove(node) {
      Ajax.DELETE(`/api/label/${node.data.id}`).then((res) => {
        if (res.code == 200) {
          let data = node.parent.data;
          if (data.child) {
            for (let i in data.child) {
              if (data.child[i].id == node.data.id) {
                data.child.splice(i, 1);
              }
            }
          } else {
            for (let i in data) {
              if (data[i].id == node.data.id) {
                data.splice(i, 1);
              }
            }
          }
          this.treeOptions = [... this.treeOptions]
          this.dialogText = "删除成功";
          this.$refs.dialog.show();
          this.checkedTag=[]
        } else {
          this.$message.error({
            message: res.msg,
            showClose: true,
          });
        }
      });
    },
    append(node, type) {
      this.appendType = type || "";
      this.form.name = undefined;
      this.appendNode = node;
      if (type == "child") {
        this.form.tree_id = node.data.tree_id;
        this.form.parent_id = node.data.id;
      } else {
        if (node.level == 1) {
          this.form.tree_id = node.data.tree_id;
          this.form.parent_id = node.data.parent_id;
        } else {
          this.form.tree_id = node.parent.data.tree_id;
          this.form.parent_id = node.parent.data.id;
        }
      }
      this.modalShow = true;
    },
    handleCheckChange() {
      debugger
      this.checkedTag = this.$refs.tree.getCheckedNodes();
      this.$emit('getTagList', this.$refs.tree.getCheckedKeys())
    },
    removeTag(id) {
      debugger
      this.checkedKeys = [];
      for (let i in this.checkedTag) {
        if (this.checkedTag[i].id === id) {
          this.checkedTag.splice(i, 1);
        }
      }
      for (let i in this.checkedTag) {
        this.checkedKeys.push(this.checkedTag[i].id);
      }
      this.$refs.tree.setCheckedKeys(this.checkedKeys);
      this.$emit('getTagList', this.$refs.tree.getCheckedKeys())
    },
    disabledFn(data) {
      if (data.child && data.child.length > 0) {
        return true;
      }
    },
    filterFn(data) {
      if (data.child.level > 4) {
        return true;
      }
    },
    dataChange(){
      let trade = new Set(this.selectedOptions.toString().split(","))
			let tradeArr = [...trade];
      this.$emit('getTagList', tradeArr.join(','))
    },
  },
  mounted() {
    let currentParent=document.getElementById('content_box')
    currentParent.addEventListener('click',()=>{
      this.treeShow=false
    })
  },

  created() {
    Ajax.POST({
        url:'/admin/tags'
    }).then(async res => {
      this.treeOptions = res.data.data
    })
  },
};

</script>

<style lang='less' scoped>
.input {
  width: 100%;
  height: 60px;
  padding: 0 20px;
  font-size: 14px;
  color: #333;
  box-shadow: inset 1px -1px 0px 0px rgba(238, 238, 238, 1),
    inset -1px 1px 0px 0px rgba(238, 238, 238, 1);
  &::-webkit-input-placeholder {
    color: #999;
  }
}
.tree-select {
  width: 100%;
  max-width: 100% !important;
  min-height: 36px;
  // border: 1px solid #ddd;
  position: relative;
  // padding: 6px 10px 0;
  cursor: pointer;
  .no-data {
    line-height: 24px;
    font-size: 14px;
    color: #999;
  }
  .tags {
    .tag {
      position: relative;
      display: inline-block;
      vertical-align: top;
      min-width: 80px;
      padding-left: 6px;
      padding-right: 20px;
      height: 24px;
      border: 1px solid @primary-color;
      border-radius: 2px;
      line-height: 22px;
      font-size: 14px;
      color: @primary-color;
      margin-right: 10px;
      margin-bottom: 6px;
      .icon {
        position: absolute;
        right: 6px;
        top: 5px;
        z-index: 8;
        cursor: pointer;
      }
    }
  }
  .example-block{
    width: 100%;
  }
}
.tree {
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 8;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 4px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #9a9a9a;
    border-radius: 10px;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.btn {
  width: 48px;
  margin-left: 18px;
  font-size: 12px;
  color: @primary-color;
  &.del {
    width: 24px;
  }
}
/deep/.el-tree-node__content > label.el-checkbox.is-disabled {
  display: none;
}
.hidden {
  display: none;
}
/deep/.el-cascader__tags {
    max-height: 100px !important;
    overflow: auto !important; // 勾选的tags出现滚动条显示
  }

</style>