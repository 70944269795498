<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-container">
					
          <SearchFilterBar
            isControlStatus
            :controlStatus="labelStatus"
            :statusLabels="statusLabels"
            @searchClick="handleSearch"
            @statusChange="statusChange"
            :selectShow="false"
            :addShow="false"
			:searchInput="false"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="table-container pd-32">
                <el-table
                  :data="tableData"
                  border
                  :max-height="650"
                  v-loading="isLoading"
                >
                  <el-table-column prop="user_id" label="用户ID" width="75" />
                  <el-table-column prop="personage_name" label="反馈用户姓名" width="175" />
                  <el-table-column prop="company_name" label="单位全称"  :show-overflow-tooltip="true" />
                  <el-table-column prop="phone" label="手机号" width="175" :show-overflow-tooltip="true" />
                  <el-table-column prop="create_time" label="提交时间" />
                  <el-table-column prop="audit_time" label="处理时间" />
                  <el-table-column prop="audit_status" label="状态" width="80">
                    <template #default="scope">
											<span
													class="status-text primary"
													v-if="scope.row.audit_status == 1">
												未处理</span>
                      <span
                        class="status-text primary"
                        v-if="scope.row.audit_status == 2">
												已处理</span>
                      
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="140">
                    <template #default="scope">

											<el-link
													type="primary"
													class="mg-r-12"
													v-if="scope.row.audit_status == 1"
													@click="handleStatus(scope.row.id, 2)">
												处理</el-link>

                      <el-link
													type="info" disabled
													v-if="scope.row.audit_status == 2">
												已处理</el-link>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

</template>

<script>
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { ref, onMounted, getCurrentInstance} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "@/utils";
export default {
  components: {
    SearchFilterBar,
    Header,
    BreadCrumb,
  },
  setup() {
		const thisId = 2;
    const route = useRoute();
    const router = useRouter();
    const breadLists = ref([{ name: "单位数据反馈" }]);
    const labelStatusRef = ref(0);
		const {proxy} = getCurrentInstance()
    const statusLabelsRef = ref([
			{
				name: "全部",
				value: 0,
			},
			{
				name: "未处理",
				value: 1,
			},
			{
				name: "已处理",
				value: 2,
			},
		]);
    const isLoading = ref(false);
    const searchForm = ref({
			audit_type: thisId,
      name: undefined,
      audit_status: undefined,
      page: 1,
      page_size: 10,
    });

		

    
    // 查询
    const handleSearch = (res) => {
      searchForm.value.audit_type = res.selectValue||thisId;
      searchForm.value.name = res.searchValue ? res.searchValue : undefined;
      getTableData("search");
    };
    // 表格数据
    const tableDataRef = ref([]);
		const x = () => {

		}
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      searchForm.value.page_size = e;
      getTableData("search");
    };
    const handleCurrentChange = (e) => {
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getTableData();
    };
    
		const handleStatus = (id, status) => {
			let str = status === 2 ? '已处理' : '驳回'
			let type = status === 2 ? 'success' : 'error'
			proxy
					.$confirm('此操作将把数据标记为' + str + ', 是否继续?', "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: type,
					})
					.then(async () => {
						Ajax.POST({
							url: '/admin/claim/feedback/audit',
							params: {
								id: id,
								audit_status: status,
							}
						}).then(res => {
							if(res.data.code === 200) {
								MessageTips.success('成功');
								getTableData();
							}else {
								MessageTips.error(res.data.msg)
							}
						}).catch((err) => {
							MessageTips.error('失败')
						});
					})
		};
    const getTableData = (type) => {
      isLoading.value = true;
      if (type) {
        searchForm.value.page = 1;
        paginationDataRef.value.current = 1;
      }
      // debugger
      Ajax.POST({
        url: "/admin/claim/feedback/companyList",
        params:{
          
          ...searchForm.value
        } 
      }).then((res) => {
        isLoading.value = false;
        if (res.data.code == 200) {
          tableDataRef.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const statusChange = (status) => {
      searchForm.value.audit_status = status == 0 ? undefined : status;
      labelStatusRef.value = status;
      getTableData("search");
    };

		const showString = (string ,len) => {
			return string.length >len ? string.substring(0,len) + '...' : string
		}
    // const submit = () => {
    //   Ajax.DELETE({
    //     url: `/admin/audit/${dialogDetail.value.id}`,
    //     data: {
    //       audit_type: searchForm.value.audit_type,
    //     },
    //   }).then((res) => {
    //     isShowDialog.value = false;
    //     if (res.data.code == 200) {
    //       MessageTips.success("删除成功");
    //       getTableData();
    //     } else {
    //       MessageTips.error(res.data.msg);
    //     }
    //   });
    // };

    onMounted(() => {
      getTableData();
    });
    return {
      breadLists,
      labelStatus: labelStatusRef,
      handleSearch,
      tableData: tableDataRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      statusLabels: statusLabelsRef,
      statusChange,
      isLoading,
			showString,
      searchForm,
			handleStatus,
    };
  },
};
</script>

<style lang="less" scoped>
 .el-menu--horizontal {
	 justify-content: center;
 }
.person-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
}
</style>
