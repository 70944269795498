<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="home-container">
                    <div class="project-summary">
                        <p class="home-title">汇总统计</p>
                        <div class="numbers-box">
                            <div class="number-item">
                                <img class="icon-number" src="../../assets/images/icon_home_person.png" alt="" srcset="">
                                <div class="numbers-main-box">
                                    <p class="tips">人物数量</p>
                                    <p class="number-text">{{ realCount.personage_num || '-' }}</p>
                                    <p class="tips">昨日 {{ realCount.yestday_personage_num || '-' }} <el-icon class="icon-arrow"><bottom /></el-icon></p>
                                </div>
                            </div>
                            <div class="number-item">
                                <img class="icon-number" src="../../assets/images/icon_home_org.png" alt="" srcset="">
                                <div class="numbers-main-box">
                                    <p class="tips">机构数量</p>
                                    <p class="number-text">{{ realCount.organ_num || '-' }}</p>
                                    <p class="tips">昨日 {{ realCount.yestday_organ_num || '-' }} <el-icon class="icon-arrow"><bottom /></el-icon></p>
                                </div>
                            </div>
                            <div class="number-item">
                                <img class="icon-number" src="../../assets/images/icon_home_company.png" alt="" srcset="">
                                <div class="numbers-main-box">
                                    <p class="tips">主体数量</p>
                                    <p class="number-text">{{ realCount.company_num || '-' }}</p>
                                    <p class="tips">昨日 {{ realCount.yestday_company_num || '-' }} <el-icon class="icon-arrow"><bottom /></el-icon></p>
                                </div>
                            </div>
                        </div>
                        <div class="charts-box">
                            <div class="charts-title-box">
                                <span class="title-text">趋势图</span>
                                <div class="screen-box">
                                    <el-dropdown class="select-box">
                                        <el-button plain class="drop-text" size="small">
                                        {{ nowTypes.name }} <el-icon class="el-icon--right"><arrow-down /></el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item 
                                                    v-for="item in types"
                                                    :key="item.id"
                                                    @click="dropDownClick(item.id)"
                                                >
                                                    {{ item.name }}
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                    <div class="radio-group">
                                        <span 
                                            class="radio-item"
                                            v-for="(check, index) in category"
                                            :key="check.id"
                                            :class="{selected: check.checked}"
                                            @click="categoryClick(index)"
                                        >
                                            {{ check.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="echarts" ref="chartsRef"></div>
                        </div>
                    </div>
                    <div class="bottom-items">
                        <div class="bottom-item-box mg-r-27">
                            <p class="home-title">待办事项</p>
                            <div class="deal-items">
                                <div class="deal-item mg-r-20" @click="toPage('feedbacks')">
                                    <img class="icon-deal" src="../../assets/images/icon_home_bottom_1.png" alt="">
                                    <p class="deal-title">待处理反馈：{{ waitTask.audit_feedback_num || '-' }}</p>
                                </div>
                                <div class="deal-item" @click="toPage('audits')">
                                    <img class="icon-deal" src="../../assets/images/icon_home_bottom_2.png" alt="">
                                    <p class="deal-title">待审核入驻：{{ waitTask.audit_settled_num || '-' }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="bottom-item-box">
                            <p class="home-title">快捷入口</p>
                            <div class="deal-items">
                                <div class="deal-v-item" @click="toPage('CreateCompany')">
                                    <img class="icon-deal" src="../../assets/images/icon_home_bottom_3.png" alt="">
                                    <span class="deal-title">+新增主体</span>
                                </div>
                                <div class="deal-v-item mg-r-20 mg-l-20" @click="toPage('CreatePerson')">
                                    <img class="icon-deal" src="../../assets/images/icon_home_bottom_4.png" alt="">
                                    <span class="deal-title">+新增人物</span>
                                </div>
                                <div class="deal-v-item" @click="toPage('OrganizationCreateInvestment')">
                                    <img class="icon-deal" src="../../assets/images/icon_home_bottom_5.png" alt="">
                                    <span class="deal-title">+新增机构</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>

</template>

<script>
import Header from '../../Layout/components/Header.vue'
import BreadCrumb from '../../components/BreadCrumb/index.vue'
import { ref, onMounted, getCurrentInstance, onBeforeMount } from 'vue'
import { Bottom, ArrowDown } from '@element-plus/icons-vue'
import useHomeCharts from './useHomeCharts'
import { Ajax } from '../../utils'
import { useRouter } from 'vue-router'
export default {
    components: {
        Bottom,
        ArrowDown,
        Header,
        BreadCrumb
    },
    setup() {
        // 面包屑数据
        let breadListsRef = ref([
            {
                name: '首页',
            }
        ])
        const router = useRouter();
        const chartsRef = ref(null);
        // 汇总统计
        const realCountRef = ref({
            organ_num: 0,
            company_num: 0,
            personage_num: 0,
            yestday_organ_num: 0,
            yestday_company_num: 0,
            yestday_personage_num: 0
        })
        const getReal = () => {
            return Ajax.GET({
                url: '/admin/index/real'
            })
        }
        // 待办数据
        const waitTaskRef = ref({
            audit_feedback_num: 0,
            audit_settled_num: 0
        })
        const getWaitTask = () => {
            return Ajax.GET({
                url: '/admin/index/task'
            })
        }
        const toPage = name => {
            router.push({
                name,
                query: {
                    fromHome: 1
                }
            })
        }
        // 图表筛选
        const chartOptionRef = ref({});
        const chartsInstanceRef = ref(null)
        const chartsDataRef = ref(null);
        const typesRef = ref([
            {
                id: 1,
                name: '机构'
            },
            {
                id: 2,
                name: '主体'
            },
            {
                id: 3,
                name: '人物'
            }
        ])
        const nowTypesRef = ref({
            id: 1,
            name: '机构'
        })
        const dropDownClick = async id => {
            nowTypesRef.value = typesRef.value.find(item => item.id === id);
            await getCensus()
            // 重新渲染chart
            let xData = [], yData = [];
            chartsDataRef.value.forEach(item => {
                xData.push(item.day);
                yData.push(item.number)
            })
            chartOptionRef.value.xAxis.data = xData;
            chartOptionRef.value.series[0].data = yData;
            chartsInstanceRef.value.setOption(chartOptionRef.value, true)
        }
        const categoryRef = ref([
            {
                id: 0,
                name: '本周',
                checked: true
            },
            {
                id: 1,
                name: '本月',
                checked: false
            }
        ])
        const nowCategoryRef = ref(null)
        const categoryClick = async index => {
            categoryRef.value.forEach((item, i) => {
                if(i === index) return ;
                item.checked = false;
            })
            categoryRef.value[index].checked = !categoryRef.value[index].checked;
            nowCategoryRef.value = categoryRef.value.find(item => item.checked)
            await getCensus()
            // 重新渲染chart
            let xData = [], yData = [];
            chartsDataRef.value.forEach(item => {
                xData.push(item.day);
                yData.push(item.number)
            })
            chartOptionRef.value.xAxis.data = xData;
            chartOptionRef.value.series[0].data = yData;
            chartsInstanceRef.value.setOption(chartOptionRef.value, true)
        }
        const getCensus = async () => {
            const res = await Ajax.GET({
                url: '/admin/index/census',
                params: {
                    type: nowTypesRef.value.id,
                    category: nowCategoryRef.value ? nowCategoryRef.value.id : undefined
                }
            })
            const censusRes = res.data;
            if(censusRes.code === 200) {
                chartsDataRef.value = censusRes.data;
            }
        }
        onMounted(async () => {
            Promise.all([getReal(), getWaitTask()]).then(resArr => {
                const realData = resArr[0].data;
                if(realData.code === 200) {
                    realCountRef.value = realData.data;
                }
                const taskData = resArr[1].data;
                if(taskData.code === 200) {
                    waitTaskRef.value = taskData.data;
                }
            })
            await getCensus()
            const charts = useHomeCharts(chartsRef.value, chartsDataRef)
            chartOptionRef.value = charts.chartOption;
            chartsInstanceRef.value = charts.myChart

        })
        return {
            breadLists: breadListsRef,
            realCount: realCountRef,
            waitTask: waitTaskRef,
            toPage,
            chartsRef,
            types: typesRef,
            nowTypes: nowTypesRef,
            dropDownClick,
            category: categoryRef,
            categoryClick
        }
    }
};
</script>

<style lang="less" scoped>
    .home-container {
        height: 100%;
        .home-title {
            width: 100%;
            height: 60px;
            padding-left: 24px;
            padding-right: 24px;
            line-height: 60px;
            font-size: 18px;
            color: #333;
            font-weight: bold;
            border-bottom: 1px solid #eee;
        }
        .project-summary {
            width: 100%;
            height: 655px;
            margin-bottom: 27px;
            background-color: #fff;
            border-radius: 20px;
            .numbers-box {
                display: flex;
                margin-top: 30px;
                padding-left: 40px;
                padding-right: 40px;
                justify-content: space-between;
                align-items: center;
                .number-item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 330px;
                    height: 166px;
                    border-radius: 12px;
                    border: 1px solid #F7F7F7;
                    .icon-number {
                        display: block;
                        width: 90px;
                        height: 90px;
                    }
                    .numbers-main-box {
                        margin-left: 78px;
                        .tips {
                            display: flex;
                            font-size: 14px;
                            color: #999;
                            align-items: center;
                        }
                        .number-text {
                            display: block;
                            margin-top: 12px;
                            margin-bottom: 12px;
                            font-size: 32px;
                            line-height: 45px;
                            color: #212145;
                            font-weight: bold;
                            text-align: center;
                        }
                        .icon-arrow {
                            margin-left: 6px;
                            transform: rotate(180deg);
                            color: @primary-color;
                        }
                    }
                }
            }
            .charts-box {
                margin-top: 36px;
                padding: 24px;
                padding-top: 0;
                .charts-title-box {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 36px;
                    height: 32px;
                    .title-text {
                        font-size: 16px;
                        color: #000;
                        line-height: 32px;
                    }
                    .screen-box {
                        display: flex;
                        align-items: center;
                        // .select-box {
                        //     .drop-text {
                        //         // color: #8627EA;
                        //     }
                        // }
                        .radio-group {
                            display: flex;
                            margin-left: 20px;
                            height: 32px;
                            .radio-item {
                                padding-left: 16px;
                                padding-right: 16px;
                                padding: 9px 16px;
                                text-align: center;
                                font-size: 12px;
                                color: #666;
                                border: 1px solid #E6E6E6;
                                cursor: pointer;
                                &.selected {
                                    border-color: @primary-color;
                                }
                            }
                        }
                    }
                }
            }
            .echarts {
                width: 100%;
                height: 340px;
            }
        }
        .bottom-items {
            display: flex;
            justify-content: space-between;
            height: 241px;
            .bottom-item-box {
                flex: 1;
                height: 100%;
                border-radius: 20px;
                background-color: #fff;
                .deal-items {
                    padding: 24px 30px;
                    display: flex;
                    height: calc(241px - 60px);
                    justify-content: space-between;
                    align-items: center;
                    .deal-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        height: 100%;
                        border: 1px solid #F7F7F7;
                        cursor: pointer;
                        .icon-deal {
                            display: block;
                            width: 60px;
                            height: 60px;
                        }
                        .deal-title {
                            margin-left: 30px;
                            font-size: 14px;
                            line-height: 20px;
                            color: #333;
                        }
                    }
                    .deal-v-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        flex: 1;
                        height: 100%;
                        border: 1px solid #F7F7F7;
                        cursor: pointer;
                        .icon-deal {
                            display: block;
                            width: 60px;
                            height: 60px;
                            margin: 0 auto;
                        }
                        .deal-title {
                            display: block;
                            width: 100%;
                            margin-top: 10px;
                            font-size: 14px;
                            text-align: center;
                            line-height: 20px;
                            color: #333;
                        }
                    }
                }
            }
        }
    }
</style>