<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists" />
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="person-container">
					<div>
						<el-menu
								:default-active="activeIndex"
								class="el-menu-demo"
								mode="horizontal"
								@select="handleSelect">
							<el-menu-item
									v-for="item in auditType"
									:key="item.value"
									:index="item.url"
							>{{ item.label }}</el-menu-item>
						</el-menu>
					</div>
					<SearchFilterBar
							isControlStatus
							:controlStatus="labelStatus"
							:statusLabels="statusLabels"
							@searchClick="handleSearch"
							@statusChange="statusChange"
							@addClick="addClick"
							batchUrl="admin/task/company/import"
							:batchShow="true"
							:selectShow="false"
							:addShow="true"
							:moduleShow="true"
							:downUrl="'https://image.sciphi.cn/sciphi/import_temp/audit_temp.xlsx'"
					/>
					<div class="table-con mg-t-12">
						<el-scrollbar>
							<div class="table-container pd-32">
								<el-table
										:data="tableData"
										border
										:max-height="650"
										v-loading="isLoading"
								>
									<el-table-column prop="id" label="ID" width="75" />
									<el-table-column prop="company_name" label="主体主体名称" >
										<template #default="scope">
											<span :title="scope.row.company_name">{{showString(scope.row.company_name, 9)}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="reg_no" show-overflow-tooltip label="统一社会信用代码" width="200" />
									<el-table-column prop="source" label="来源" width="85">
										<template #default="scope">
											<span>{{sources[scope.row.source] || ''}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="submit_time" label="提交时间" width="165" />
									<el-table-column prop="crawtime" label="完成时间" width="165" />
									<el-table-column prop="audit_status" label="状态" width="70">
										<template #default="scope">
											<span
													class="status-text danger"
													v-if="scope.row.audit_status == 1">
												待审核</span>
											<span
													class="status-text primary"
													v-if="scope.row.audit_status == 2">
												待爬取</span>
											<span
													class="status-text success"
													v-if="scope.row.audit_status == 3">
												已爬取</span>
											<span
													class="status-text info"
													v-if="scope.row.audit_status == 4">
												已拒绝</span>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="140">
										<template #default="scope">
											<el-link
													type="primary"
													class="mg-r-12"
													@click="gotoPage(scope.row.id)">
												详情</el-link>

											<el-link
													type="primary"
													class="mg-r-12"
													v-if="scope.row.audit_status == 1"
													@click="handleStatus(scope.row.id, 2)">
												通过</el-link>

											<el-link
													type="primary"
													v-if="scope.row.audit_status == 1"
													@click="handleStatus(scope.row.id, 4)">
												拒绝</el-link>
										</template>
									</el-table-column>
								</el-table>
								<div class="table-pagination-row mg-t-24">
									<el-pagination
											background
											:currentPage="paginationData.current"
											:page-sizes="paginationData.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="paginationData.total"
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
									>
									</el-pagination>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-scrollbar>
		<add
				v-if="showDialog"
				:dialogTitle="dialogTitle"
				@closeDialog="dialogClose($event)"
				@getTableData="getTableData()"
		/>
	</div>

</template>

<script>
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { ref, onMounted, getCurrentInstance} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "@/utils";
import add from "./components/add.vue";
export default {
	components: {
		SearchFilterBar,
		Header,
		BreadCrumb,
		add,
	},
	setup() {
		const thisId = 3;
		const route = useRoute();
		const router = useRouter();
		const breadLists = ref([{ name: "审核" }]);
		const labelStatusRef = ref(0);
		const {proxy} = getCurrentInstance()
		const sources = ref({
			1: '前台录入', 2: '后台录入', 3: '临时数据', 4: '榜单小程序', 5: '爬虫',
		})
		const statusLabelsRef = ref([
			{
				name: "全部",
				value: 0,
			},
			{
				name: "待审核",
				value: 1,
			},
			{
				name: "待爬取",
				value: 2,
			},
			{
				name: "已爬取",
				value: 3,
			},
			{
				name: "已拒绝",
				value: 4,
			}
		]);
		const isLoading = ref(false);
		const activeIndex = ref('auditCom')
		const searchForm = ref({
			audit_type: thisId,
			name: undefined,
			status: undefined,
			page: 1,
			page_size: 10,
		});

		const dialogTitle = ref("增加主体");
		const showDialog = ref(false);
		const dialogClose = (e) => {
			showDialog.value = e
		}

		const handleSelect = (key, keyPath) => {
			console.log(key, keyPath);
			router.push({
				name: key,
			});
		}

		const auditType = ref([
			{
				label: "主体",
				value: 3,
				url: 'auditCom'
			},
			{
				label: "人物",
				value: 1,
				url: 'auditPer'
			},
			{
				label: "机构",
				value: thisId,
				url: 'auditOrg'
			},

		]);
		// 查询
		const handleSearch = (res) => {
			searchForm.value.audit_type = 3;
			searchForm.value.name = res.searchValue ? res.searchValue : undefined;
			getTableData("search");
		};
		// 表格数据
		const tableDataRef = ref([]);
		const x = () => {

		}
		// 页码
		let paginationDataRef = ref({
			current: 1,
			pageSize: [10, 20, 50],
			total: 0,
		});
		const handleSizeChange = (e) => {
			searchForm.value.page_size = e;
			getTableData("search");
		};
		const handleCurrentChange = (e) => {
			paginationDataRef.value.current = e;
			searchForm.value.page = e;
			getTableData();
		};
		const gotoPage = (id) => {
			router.push({
				name: "auditComDetail",
				query: {
					id: id
				},
			});
		};

		const addClick = () => {
		  showDialog.value = true
		}

		const batchHandle = () => {
			console.log('导入')
		}

		const handleStatus = (id, status) => {
			let str = status === 2 ? '通过' : '驳回'
			let type = status === 2 ? 'success' : 'error'
			proxy
					.$confirm('此操作将' + str + '该数据, 是否继续?', "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: type,
					})
					.then(async () => {
						Ajax.POST({
							url: '/admin/task/company/audits',
							params: {
								id: id,
								audits_status: status
							}
						}).then(res => {
							if(res.data.code === 200) {
								MessageTips.success('成功');
								getTableData();
							}else {
								MessageTips.error(res.data.msg)
							}
						}).catch((err) => {
							MessageTips.error('失败1')
						});
					})
		};
		const getTableData = (type) => {
			isLoading.value = true;
			if (type) {
				searchForm.value.page = 1;
				paginationDataRef.value.current = 1;
			}

			Ajax.POST({
				url: "/admin/task/company",
				data: searchForm.value,
			}).then((res) => {
				isLoading.value = false;

				if (res.data.code == 200) {
					tableDataRef.value = res.data.data.list;
					paginationDataRef.value.total = res.data.data.page_info.total;
				} else {
					MessageTips.error(res.data.msg);
				}
			});
		};
		const statusChange = (status) => {
			searchForm.value.status = status == 0 ? undefined : status;
			labelStatusRef.value = status;
			getTableData("search");
		};

		const showString = (string ,len) => {
			return string.length >len ? string.substring(0,len) + '...' : string
		}
		// const submit = () => {
		//   Ajax.DELETE({
		//     url: `/admin/audit/${dialogDetail.value.id}`,
		//     data: {
		//       audit_type: searchForm.value.audit_type,
		//     },
		//   }).then((res) => {
		//     isShowDialog.value = false;
		//     if (res.data.code == 200) {
		//       MessageTips.success("删除成功");
		//       getTableData();
		//     } else {
		//       MessageTips.error(res.data.msg);
		//     }
		//   });
		// };

		onMounted(() => {
			getTableData();
		});
		return {
			breadLists,
			getTableData,
			labelStatus: labelStatusRef,
			handleSearch,
			tableData: tableDataRef,
			paginationData: paginationDataRef,
			handleSizeChange,
			handleCurrentChange,
			statusLabels: statusLabelsRef,
			gotoPage,
			statusChange,
			auditType,
			batchHandle,
			activeIndex,
			addClick,
			isLoading,
			sources,
			showString,
			searchForm,
			handleSelect,
			handleStatus,
			dialogClose,
			showDialog,
			dialogTitle,
		};
	},
};
</script>

<style lang="less" scoped>
.el-menu--horizontal {
	justify-content: center;
}
.person-container {
	height: 100%;
.table-pagination-row {
	display: flex;
	justify-content: flex-end;
}
.table-con {
	height: 100%;
	max-height: calc(100% - 66px - 12px);
	background-color: #fff;
}
}
</style>
