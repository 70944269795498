<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="configure">
          <div class="search-filter-bar-container pd-t-b-16 pd-l-r-32">
            <div class="status-box">
              <el-radio-group v-model="status" @change="lableChange">
                <el-radio-button
                  size="small"
                  v-for="item in statusLabels"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.name }}</el-radio-button
                >
              </el-radio-group>
            </div>
            <div class="search-container">
              <el-button
                size="small"
                class="btn-add"
                type="primary"
                @click="addHandle"
                >{{
                  status === 0 || status === 1 ? "新增模块" : "新增轮播"
                }}</el-button
              >
            </div>
          </div>
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="pd-32" v-if="status === 0 || status === 1" style="background: #fff">
                <el-table :data="ListData" border :max-height="650">
                  <el-table-column type="index" label="序号" />
                  <el-table-column prop="album_name" label="模块名称" />
                  <el-table-column prop="icon" label="icon">
                    <template #default="scope">
                      <img :src="scope.row.icon" width="50" class="head_pic" />
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="sort" label="排序"/> -->
                  <el-table-column label="排序">
                    <template #default="scope">
                      <el-form
                        ref="formRef"
                        v-if="listActiveId === scope.row.id"
                      >
                        <el-form-item style="width: 100px;" class="item-active">
                          <el-input
                            v-model="listSort"
                            placeholder="请输入数字"
                            @blur="inputBlur"
                          ></el-input>
                          <el-icon @click="listBlur" v-if="listSort">
                            <Check />
                          </el-icon>
                        </el-form-item>
                      </el-form>
                      <div v-else @dblclick="listDBclick(scope.row)">
                        {{ scope.row.sort }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template #default="scope">
                      <el-link
                        class="mg-r-12 delete"
                        type="primary"
                        @click="handleDelete(scope.row.id)"
                        >删除</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    v-if="tableTotal > ListData.length"
                    :current-page="currentPage"
                    :page-size="paginationData.size"
                    :total="tableTotal"
                    @current-change="handleCurrentChange"
                    @pagination="getTableData"
                  />
                </div>
              </div>
              <div class="pd-32" v-else style="background: #fff">
                <el-table :data="BannerData" border :max-height="650">
                  <el-table-column type="index" label="序号" />
                  <el-table-column prop="title" label="轮播名称" />
                  <el-table-column prop="image" label="广告图片">
                    <template #default="scope">
                      　　　　<img
                        :src="scope.row.image"
                        width="100"
                        class="head_pic"
                      />
                      　　
                    </template>
                  </el-table-column>
                  <el-table-column prop="type" label="类型">
                    <template #default="scope">
                      <div class="cell">
                        {{ scope.row.type === 1 ? "排行" : "报告" }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="album_name" label="专辑" />
                  <!-- <el-table-column prop="sort" label="排序" /> -->
                  <el-table-column label="排序">
                    <template #default="scope">
                      <el-form
                        ref="formRef"
                        v-if="bannerActiveId === scope.row.id"
                      >
                        <el-form-item style="width: 100px">
                          <el-input
                            v-model="bannerSort"
                            placeholder="请输入数字"
                            @blur="bannerBlur"
                          ></el-input>
                        </el-form-item>
                      </el-form>
                      <div v-else @dblclick="bannerDBclick(scope.row)">
                        {{ scope.row.sort }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="apply_url" label="报名地址"></el-table-column>
                  <el-table-column label="操作">
                    <template #default="scope">
                      <el-link
                        v-if="scope.row.status === 1"
                        class="mg-r-12 out"
                        type="primary"
                        @click="changeStatus(scope.row.id, scope.row.status)"
                        >下架</el-link
                      >
                      <el-link
                        v-else
                        class="mg-r-12 added"
                        type="primary"
                        @click="changeStatus(scope.row.id, scope.row.status)"
                        >上架</el-link
                      >
                      <el-link
                        v-if="scope.row.status === 2"
                        class="mg-r-12"
                        type="primary"
                        @click="handleBannerEdit(scope.row.id,)"
                        >编辑</el-link
                      >
                      <el-link
                        class="mg-r-12 delete"
                        type="primary"
                        @click="handleBannerDelete(scope.row.id)"
                        >删除</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    v-if="BannerTotal > BannerData.length"
                    :current-page="BannerpaginationData.current"
                    :page-size="BannerpaginationData.size"
                    :total="BannerTotal"
                    @current-change="handleBannerCurrentChange"
                    @pagination="getBannerTableData"
                  />
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <addModule
          :isShowModule="isShowModule"
          :status="status"
          v-if="isShowModule"
          @closeDialog="closeDialog"
        />
        <addSwiper
          :isShowSwiper="isShowSwiper"
          :status="status"
          v-if="isShowSwiper"
          @closeDialog="closeDialog"
        />
        <editSwiper
          :isShowSwiper="isEditSwiper"
          :status="status"
          :editId="editId"
          v-if="isEditSwiper"
          @closeDialog="closeDialog"
        />
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { ElMessageBox } from "element-plus";
import { ref, reactive, onMounted } from "vue";
import addModule from "./components/addModule.vue";
import addSwiper from "./components/addSwiper.vue";
import editSwiper from "./components/editSwiper.vue";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { Ajax, MessageTips } from "@/utils";
import { Check } from "@element-plus/icons-vue";
export default {
  components: { addModule, addSwiper, Header, BreadCrumb, editSwiper, Check },
  props: {},
  setup(props, ctx) {
    const status = ref(0);
    const state = reactive({
      breadList: [
        {
          name: "首页配置",
        },
      ],
      statusLabels: [
        // {
        //   value: 0,
        //   name: "首页配置",
        //   num: 20,
        // },
        // {
        //   value: 1,
        //   name: "轮播图",
        //   num: 10,
        // },
        {
          value: 0,
          name: "学者配置",
          num: 20,
        },
        {
          value: 1,
          name: "公司配置",
          num: 10,
        },
        {
          value: 2,
          name: "学者轮播",
          num: 20,
        },
        {
          value: 3,
          name: "公司轮播",
          num: 10,
        },
      ],
      queryData: {
        size: 20,
        page: 1,
      },
      bannerQueryData: {
        size: 20,
        page: 1,
      },
    });

    const ListData = ref([]);
    const paginationData = {
      page: 1,
      size: state.queryData.size,
    };
    const BannerData = ref([]);
    const BannerTotal = ref(0);
    const tableTotal = ref(0);
    const BannerpaginationData = {
      page: 1,
      size: state.bannerQueryData.size,
    };

    const isShowModule = ref(false);
    const isShowSwiper = ref(false);
    const isEditSwiper = ref(false)
    const editId=ref("")
    const listActiveId = ref("");
    const listSort = ref("");
    const bannerActiveId = ref("");
    const bannerSort = ref("");
    const getTableData = async () => {
      const ret = await Ajax.POST({
        url: "/admin/mini/index/album",
        data: {
          ...paginationData,
          type: status.value === 0 ? 2 : status.value === 1 ? 1 : 1,
        },
      });
      if (ret.data.code === 200) {
        ListData.value = ret.data.data.items;
        tableTotal.value = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const setStatus = async (id, status) => {
      const ret = await Ajax.POST({
        url: "/admin/mini/banner/status/" + id,
        data: { status: status },
      });
      if (ret.data.code === 200) {
        getBannerTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const changeStatus = async (id, status) => {
      let str = status == 1 ? "下架" : "上架";
      ElMessageBox.confirm("确认" + str + "该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setStatus(id, status == 1 ? 2 : 1);
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };

    const getBannerTableData = async (label) => {
      console.log(status.value);
      const ret = await Ajax.POST({
        url: "/admin/mini/banner",
        data: {
          ...BannerpaginationData,
          type: status.value === 2 ? 3 : status.value === 3 ? 1 : 1,
        },
      });
      if (ret.data.code === 200) {
        BannerData.value = ret.data.data.items;
        BannerTotal.value = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    // 新增事件
    const addHandle = () => {
      isShowModule.value = false;
      isShowSwiper.value = false;
      if (status.value === 0) {
        isShowModule.value = true;
      } else {
        isShowSwiper.value = true;
      }
    };
    //切换状态
    const lableChange = (label) => {
      console.log("label", label);
      status.value = label;
      console.log(status.value);
      if (label === 0 || label === 1) {
        getTableData();
      } else if (label === 2 || label === 3) {
        getBannerTableData();
      }
    };

    // 编辑
    const handleEdit = () => {};

    const delAlbum = async (id) => {
      const ret = await Ajax.POST({
        url: "/admin/mini/index/album/del/" + id,
        data: {},
      });
      if (ret.data.code === 200) {
        getTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const delBannerAlbum = async (id) => {
      const ret = await Ajax.POST({
        url: "/admin/mini/banner/del/" + id,
        data: {},
      });
      if (ret.data.code === 200) {
        getBannerTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };
    //编辑
    const handleBannerEdit =(id)=>{
      console.log(id)
      editId.value=id
      isEditSwiper.value = true;
    }
    const handleBannerDelete = async (id) => {
      ElMessageBox.confirm("确认删除该Banner?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delBannerAlbum(id);
        })
        .catch(() => {
          MessageTips.info("取消删除");
        });
    };

    // 删除
    const handleDelete = async (id) => {
      ElMessageBox.confirm("确认删除该模块?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delAlbum(id);
        })
        .catch(() => {
          MessageTips.info("取消删除");
        });
    };

    const handleCurrentChange = (val) => {
      paginationData.page = val;
      getTableData();
    };

    const handleBannerCurrentChange = (val) => {
      console.log("val", val);
      BannerpaginationData.page = val;
      getBannerTableData();
    };

    const closeDialog = () => {
      isShowModule.value = false;
      isShowSwiper.value = false;
      isEditSwiper.value = false;
      editId.value=""
      getTableData();
      getBannerTableData();
    };

    const listDBclick = (item) => {
      console.log("item", item);
      listActiveId.value = item.id;
      listSort.value = item.sort;
    };

    const listBlur = () => {
      console.log("listBlur");
      Ajax.POST({
        url: `admin/mini/index/album/sort/${listActiveId.value}`,
        data: { sort: listSort.value },
      }).then((res) => {
        if (res.data.code == 200) {
          console.log("首页修改排行");
          listActiveId.value = "";
          getTableData();
        }
      });
    };

    const inputBlur = () => {
      listActiveId.value = "";
    }
    const bannerDBclick = (item) => {
      console.log("item", item);
      bannerActiveId.value = item.id;
      bannerSort.value = item.sort;
    };

    const bannerBlur = () => {
      console.log("bannerBlur");
      Ajax.POST({
        url: `/admin/mini/banner/sort/${bannerActiveId.value}`,
        data: { sort: bannerSort.value },
      }).then((res) => {
        if (res.data.code == 200) {
          console.log("轮播修改排行");
          bannerActiveId.value = "";
          getBannerTableData();
        }
      });
    };
    onMounted(() => {
      getTableData();
      getBannerTableData();
    });
    return {
      status,
      addHandle,
      lableChange,
      ...state,
      handleEdit,
      handleDelete,
      handleCurrentChange,
      isShowModule,
      isShowSwiper,
      isEditSwiper,
      editId,
      ListData,
      paginationData,
      getTableData,
      getBannerTableData,
      BannerData,
      BannerpaginationData,
      handleBannerCurrentChange,
      handleBannerDelete,
      handleBannerEdit,
      changeStatus,
      closeDialog,
      BannerTotal,
      tableTotal,
      listDBclick,
      listActiveId,
      listSort,
      listBlur,
      bannerDBclick,
      bannerBlur,
      bannerActiveId,
      bannerSort,
      inputBlur
    };
  },
};
</script>

<style scoped lang="less">
.configure {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  .search-filter-bar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 66px;
    background-color: #fff;
    .status-box {
      display: flex;
      align-items: center;
      min-width: 20%;
    }
  }
  .delete,
  .delete:hover {
    --el-link-text-color: red;
    color: red;
  }

  .out,
  .out:hover {
    --el-link-text-color: #666;
    color: #666;
  }
  .item-active {
    :deep(.el-form-item__content ){
      display: flex;
      align-items: center;
      .el-input, .el-input__inner {
        width: 120px;
      }
      .el-icon {
        margin-left: 10px;
      }
    }
  }
}
</style>
