<template>
<div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
          <div class="person-detail-container">
        <el-card class="mg-b-16">
            <template #header>
                <div class="card-header">
                    <span class="card-name">{{ nowPageTitle }}（4）</span>
                    <template v-if="nowPageType === 'cooperation-agency'">
                        <el-button type="primary" @click="openDialog"><el-icon><plus /></el-icon> 新增</el-button>
                    </template>
                </div>
            </template>
            <div class="table-container pd-32">
                <!-- 
                    * member  主体成员
                    * financing-in  对内融资
                    * financing-out    对外投资
                    * cooperation-agency   竞合关系
                    * reports  相关报道
                 -->
                <el-table :data="tableData" border :max-height="650">
                    <!-- 相关报道 -->
                    <template v-if="nowPageType === 'reports'">
                        <el-table-column prop="id" label="序号" />
                        <el-table-column prop="title" label="报道题目" />
                        <el-table-column prop="author" label="来源" />
                        <el-table-column prop="journal" label="链接">
                            <template #default="scope">
                                <el-link :href="scope.row.journal" type="primary">{{ scope.row.journal }}</el-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" >
                            <template #default="scope">
                                <template v-if="scope.row.status === 1">
                                    <span class="status-text primary">已发布</span>
                                </template>
                                <template v-else-if="scope.row.status === 2">
                                    <span class="status-text success">已完善</span>
                                </template>
                                <template v-else-if="scope.row.status === 3">
                                    <span class="status-text danger">待完善</span>
                                </template>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                    <el-pagination
                        background
                        :currentPage="paginationData.current"
                        :page-sizes="paginationData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="paginationData.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-card>
        <el-dialog v-model="dialogShow" title="选择主体" :width="800" :close-on-click-modal="true" :show-close="false" destroy-on-close>
            <div class="dialo-content">
                <el-form-item style="width: 200px;">
                    <el-input v-model="dialogSearchInp" placeholder="搜索单位" :suffix-icon="Search"></el-input>
                </el-form-item>
                <el-table :data="dialogTable" border max-height="300">
                    <template v-if="nowPageType === 'edit-teach'">
                        <el-table-column prop="school" label="学校名称" />
                        <el-table-column prop="desc" label="学校简介" />
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
                            </template>
                        </el-table-column>
                    </template>
                    <template v-else>
                        <el-table-column prop="school" label="单位名称" />
                        <el-table-column prop="type" label="单位类型" />
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                    <el-pagination
                        background
                        :currentPage="dialogPaginationData.current"
                        :page-sizes="dialogPaginationData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="dialogPaginationData.total"
                        
                        @size-change="dialogHandleSizeChange"
                        @current-change="dialogHandleCurrentChange"
                    >
                    </el-pagination>
                </div>
            </div>
            <template #footer>
                <el-button plain>返回</el-button>
                <el-button type="primary">完成</el-button>
            </template>
        </el-dialog>
    </div>
      </div>
    </el-scrollbar>
</div>
    
</template>

<script>
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Plus, Search } from '@element-plus/icons-vue'
import Header from "../../../../Layout/components/Header.vue";
import BreadCrumb from "../../../../components/BreadCrumb/index.vue";
export default {
    components: {
        Plus,
        Header,
    BreadCrumb,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
         const breadLists = ref([
      {
        name: "投资事件",
        path: {
          name: "InvestEvent",
        },
      },
      {
        name: "投资事件详情",
        path:{
          name:'InvestEventDetail',
          query:{
            id:route.query.id
          }
        }
      },
      {
        name:'相关报道'
      }
    ]);
        const nowPageType = computed(() => {
            /**
             * member  主体成员
             * financing-in  对内融资
             * financing-out    对外投资
             * cooperation-agency   竞合关系
             * reports  相关报道
             */
            return route.query.type
        })
        const nowPageTitle = computed(() => {
            return route.query.pageName
        });

        const tableDataRef = ref([
            {
                id: 1,
                doi: '10.123123/LO',
                title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt mollitia molestias illum porro sed? Ad exercitationem at suscipit deserunt, consectetur dignissimos cupiditate quisquam in quas, numquam temporibus vel, culpa eos?',
                author: 'C Lin, M Li',
                journal: 'Laser &amp; Optoelectronics Progress》',
                factor: '2.13',
                num: 3,
                year: '2015',
                status: 1
            },
            {
                id: 2,
                doi: '10.123123/LO',
                title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt mollitia molestias illum porro sed? Ad exercitationem at suscipit deserunt, consectetur dignissimos cupiditate quisquam in quas, numquam temporibus vel, culpa eos?',
                author: 'C Lin, M Li',
                journal: 'Laser &amp; Optoelectronics Progress》',
                factor: '2.13',
                num: 3,
                year: '2015',
                status: 1
            },
            {
                id: 3,
                doi: '10.123123/LO',
                title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt mollitia molestias illum porro sed? Ad exercitationem at suscipit deserunt, consectetur dignissimos cupiditate quisquam in quas, numquam temporibus vel, culpa eos?',
                author: 'C Lin, M Li',
                journal: 'Laser &amp; Optoelectronics Progress》',
                factor: '2.13',
                num: 3,
                year: '2015',
                status: 1
            },
            {
                id: 4,
                doi: '10.123123/LO',
                title: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt mollitia molestias illum porro sed? Ad exercitationem at suscipit deserunt, consectetur dignissimos cupiditate quisquam in quas, numquam temporibus vel, culpa eos?',
                author: 'C Lin, M Li',
                journal: 'Laser &amp; Optoelectronics Progress》',
                factor: '2.13',
                num: 3,
                year: '2015',
                status: 1
            }
        ])

        const paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            total: 100
        })
        const handleSizeChange = e => {
            console.log(e)
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
        }
        

         /* 弹窗选择 start */ 
        const dialogShowRef = ref(false);
        const dialogSearchInpRef = ref('')
        const dialogTableRef = ref([
            {
                id: 0,
                school: '清华大学',
                type: 1,
            },
            {
                id: 1,
                school: '南京大学',
                type: 2,
            },
            {
                id: 2,
                school: '清华大学',
                type: 1,
            },
            {
                id: 3,
                school: '南京大学',
                type: 3
            },
            {
                id: 4,
                school: '清华大学',
                type: 1,
            },
            {
                id: 5,
                school: '南京大学',
                type: 2,
            },
            {
                id: 6,
                school: '清华大学',
                type: 1,
            },
        ])
        const dialogRadioRef = ref('')
        const dialogPaginationDataRef = ref({
            current: 1,
            pageSize: 10,
            total: 100
        })
        const dialogHandleSizeChange = e => {
            console.log(e)
        }
        const dialogHandleCurrentChange = e => {
            paginationDataRef.value.current = e;
        }
        const openDialog = () => {
            dialogShowRef.value = true;
        }
        /* 弹窗选择 end */ 

        return {
            breadLists,
            tableData: tableDataRef,
            paginationData: paginationDataRef,
            handleSizeChange,
            handleCurrentChange,
            nowPageType,
            nowPageTitle,

            // 弹窗
            dialogShow: dialogShowRef,
            dialogSearchInp: dialogSearchInpRef,
            dialogTable: dialogTableRef,
            dialogRadio: dialogRadioRef,
            dialogPaginationData: dialogPaginationDataRef,
            dialogHandleSizeChange,
            dialogHandleCurrentChange,
            openDialog,
            Search
        }
    }
}
</script>

<style lang="less" scoped>
    .person-detail-container {
        height: 100%;
        background-color: #fff;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
    }
</style>