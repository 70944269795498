<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="list-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            :addShow="false"
            @statusChange="statusChange"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="pd-32" style="background: #fff">
                <el-table :data="ListData" border >
                  <el-table-column prop="id" label="用户ID" />
                  <el-table-column
                    name="nick_name"
                    prop="nick_name"
                    label="昵称"
                  />
                  <el-table-column prop="phone" label="手机号" />
                  <el-table-column prop="create_time" label="注册时间" />
                  <el-table-column prop="unionid" label="unionid" />
                  <el-table-column
                    prop="gender"
                    :formatter="setgender"
                    label="性别"
                  >
                  </el-table-column>
                  <el-table-column prop="status" label="状态">
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.status === 1"
                        >激活</span
                      >
                      <span class="status-text info" v-else>冻结</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" fixed="right">
                    <template #default="scope">
                      <el-link
                        v-if="scope.row.status === 1"
                        class="mg-r-12"
                        type="primary"
                        @click="changeStatus(scope.row.id, scope.row.status)"
                        >冻结</el-link
                      >
                      <el-link
                        v-else
                        class="mg-r-12"
                        type="primary"
                        @click="changeStatus(scope.row.id, scope.row.status)"
                        >激活</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    v-show="paginationData.total > paginationData.size"
                    :background="background"
                    :current-page.sync="currentPage"
                    :page-size.sync="paginationData.size"
                    :layout="layout"
                    :total="paginationData.total"
                    v-bind="$attrs"
                    @current-change="handleCurrentChange"
                    @pagination="getTableData"
                  />
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";

export default {
  components: {
    Header,
    BreadCrumb,
    SearchFilterBar,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      breadList: [
        {
          name: "用户",
        },
      ],
      statusLabels: [
        {
          value: 0,
          name: "全部",
        },
        {
          value: 1,
          name: "激活",
        },
        {
          value: 2,
          name: "冻结",
        },
      ],
      queryData: {
        size: 10,
        page: 1,
        status: 0,
        name: "",
      },
      mainTableKey: 1,
    });

    const ListData = ref([]);
    const paginationData = {
      current: 1,
      size: state.queryData.size,
      total: 0,
    };

    // 状态选择
    const statusChange = (status) => {
      state.queryData.status = status;
      getTableData();
    };

    // 查询
    const handleSearch = ({ searchValue }) => {
      state.queryData.name = searchValue;
      getTableData();
    };

    const getTableData = async () => {
      const ret = await Ajax.POST({
        url: "/admin/wxuser",
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        ListData.value = ret.data.data.items;
        paginationData.total = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const setStatus = async (id, status) => {
      const ret = await Ajax.POST({
        url: "/admin/wxuser/status/" + id,
        data: { status: status },
      });
      if (ret.data.code === 200) {
        getTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const changeStatus = async (id, status) => {
      let str = status == 1 ? "冻结" : "激活";
      ElMessageBox.confirm("确认" + str + "该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setStatus(id, status == 1 ? 2 : 1);
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };

    const handleCurrentChange = (val) => {
      state.queryData.page = val;
      getTableData();
    };

    const handleSizeChange = () => {};
    onMounted(() => {
      getTableData();
    });

    return {
      ...state,
      statusChange,
      handleSearch,
      changeStatus,
      handleCurrentChange,
      handleSizeChange,
      getTableData,
      ListData,
      paginationData,
    };
  },
  methods: {
    setgender(row, colum) {
      return row.gender === 1 ? "男" : row.gender === 0 ? "未知" : "女";
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  height: 100%;
  .status-text {
    font-size: 14px;
    &.primary {
      color: #409eff;
    }
    &.info {
      color: #909399;
    }
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
