import axios from 'axios'
import Router from '../router'
import { warning } from './message'
const baseURL = window.env.baseUrl;
const instance = axios.create({
    // withCredentials: true,
    baseURL
})

  
console.log('api address is ', baseURL)
const ajax = ({ url, data, params, method, contentType,responseType }) => {
    const token = localStorage.getItem('adminToken')
    return new Promise(resolve => {
        
        instance({
            method: method,
            url: url,
            data,
            params,
            headers: {
                "Content-Type": contentType,
                // "Authorization": `${token}`
                "Authorization": `Bearer ${token}`
            },
            responseType
        }).then(res => {
            if([10001, 10002, 10003].includes(res.data.code)) {
                warning(res.data.msg)
                // Router.replace({
                //     name: 'Login'
                // })
                window.location.href = 'https://login.dtinsights.cn/#/login'
                return ;
            }
            resolve(res)
        })
    })
}
const GET = async({ url, data, params, contentType = 'application/json' }) => {
    let result;
    if(url.includes("signup/export")){
        result=await ajax({
            url,
            data,
            params,
            method: 'get',
            contentType,
            responseType:'arraybuffer'
        })
    }else{
        result = await ajax({
            url,
            data,
            params,
            method: 'get',
            contentType
        })
    }
    // const result = await ajax({
    //     url,
    //     data,
    //     params,
    //     method: 'get',
    //     contentType
    // })
    return Promise.resolve(result)
}
const POST = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'post',
        contentType
    })
    return Promise.resolve(result)
}
const PUT = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'put',
        contentType
    })
    return Promise.resolve(result)
}
const DELETE = async({ url, data, params, contentType = 'application/json' }) => {
    const result = await ajax({
        url,
        data,
        params,
        method: 'delete',
        contentType
    })
    return Promise.resolve(result)
}

export {
    GET,
    POST,
    PUT,
    DELETE
}
