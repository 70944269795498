<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="person-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">{{ nowPageTitle }}（{{ paginationData.total }}）</span>
                                <el-button size="small" type="primary" style="float:right" plain  @click="goBack" >返回</el-button>
                            </div>
                        </template>
                        <div class="table-container pd-32">
                            <el-table v-loading="tableLoading" :data="tableData" border :max-height="650">
                                <template v-if="nowPageType === 'literature'">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <!-- <el-table-column prop="literature_id" label="序号" /> -->
                                    <el-table-column prop="doi" label="DOI">
                                        <template #default="scope" :show-overflow-tooltip="true">
                                        <span :title="scope.row.doi">{{scope.row.doi}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="title" label="自定义标题" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                            <!-- <el-tooltip :content="scope.row.title" placement="bottom" effect="light">
                                            <span >{{scope.row.title}}</span>
                                            </el-tooltip> -->
                                        <span @click="goToLiterature(scope.row.id)" style="cursor: pointer;">{{scope.row.title}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="periodical" label="期刊来源" width="170" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span >{{scope.row.periodical}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="link" label="原文链接" width="80">
                                        <template #default="scope">
                                        <el-link :href="scope.row.link" type="primary" target="_blank">点击查看</el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="author" label="作者" width="170" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span>{{scope.row.author.join(', ')}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="report_time" label="发表时间" width="150">
                                        <template #default="scope">
                                        <span>{{scope.row.report_time.substring(0,10)}}</span>
                                        </template>
                                    </el-table-column>
                                    <!-- <el-table-column prop="impact_factor" label="影响因子" />
                                                        <el-table-column prop="cites" label="被引量" />
                                    <el-table-column prop="publish_time" label="发表年份" />-->
                                    <el-table-column prop="status" label="状态" width="70">
                                        <template #default="scope">
                                        <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                        <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                                        <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                                        <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-else-if="nowPageType === 'books'">
                                    <el-table-column prop="work_id" label="序号" />
                                    <el-table-column prop="name" label="书名" />
                                    <el-table-column prop="author_arr" label="作者">
                                        <template #default="scope">
                                            {{ scope.row.author_arr.join(', ') }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="publisher" label="出版社" />
                                    <el-table-column prop="publish_time" label="发表年份" />
                                    <el-table-column prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-else-if="nowPageType === 'patent'">
                                    <el-table-column prop="patent_id" label="序号" />
                                    <el-table-column prop="name" label="专利名" />
                                    <el-table-column prop="inventor_arr" label="发明人">
                                        <template #default="scope">
                                            {{ scope.row.inventor_arr.join('| ') }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="patentee_arr" label="专利权人">
                                        <template #default="scope">
                                            {{ scope.row.inventor_arr.join('| ') }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="apply_time" label="申请日" />
                                    <el-table-column prop="announce_no" label="公开号" />
                                    <el-table-column prop="announce_time" label="公开日" />
                                    <el-table-column prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>

                                <template v-else-if="nowPageType === 'reports'">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="name" label="报道标题">
                                        <template #default="scope" :show-overflow-tooltip="true">
                                        <span  @click="toPage('ReportDetail', {id: scope.row.id})" style="cursor: pointer;">{{scope.row.name}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="author" label="作者" width="170" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span >{{scope.row.author}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="source" label="来源" width="170" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span >{{scope.row.source}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="link" label="链接" width="80">
                                        <template #default="scope">
                                        <el-link :href="scope.row.link" type="primary" target="_blank">点击查看</el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="report_time" label="报道时间" width="110" />
                                    <el-table-column prop="status" label="状态" width="70">
                                        <template #default="scope">
                                        <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                        <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                                        <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                                        <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                            <div class="table-pagination-row mg-t-24">
                                <el-pagination
                                    background
                                    :currentPage="paginationData.current"
                                    :page-sizes="paginationData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="paginationData.total"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import { computed, ref, onBeforeMount } from 'vue'
import { useRoute,useRouter } from 'vue-router'
import { Ajax } from '../../../utils'
export default {
    components: {
        Header,
        BreadCrumb,
    },
    setup() {
        const breadListsRef = ref([]);
        onBeforeMount(() => {
            breadListsRef.value = [
                {
                    name: '人物',
                    path: {
                        name: 'Person'
                    }
                },
                {
                    name: '人物详情',
                    path: {
                        name: 'PersonDetail',
                        query: {
                            id: route.query.id
                        }
                    },
                },
                {
                    name: `${route.query.pageName}`
                }
            ]
        })
        const route = useRoute();
        const router = useRouter()
        const tableLoadingRef = ref(false)
        const nowPageType = computed(() => {
            /**
             * startUp  创业经历
             * investment-case 投资案例
             * literature  相关文献
             * books    相关著作
             * patent   相关专利
             * reports  相关报道
             */
            return route.query.type
        })
        const nowPageTitle = computed(() => {
            return route.query.pageName
        });

        const tableDataRef = ref([])

        const paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            page_size: 10,
            total: 0
        })

        const getList = () => {
            let url = '';
            switch (nowPageType.value) {
                case 'literature':
                    url = '/admin/personage/literature'
                    break;
                case 'books':
                    url = '/admin/personage/works'
                    break;
                case 'patent':
                    url = '/admin/personage/patent';
                    break;
                case 'reports':
                    url = '/admin/personage/report';
                    break;
            }
            tableLoadingRef.value = true;
            if(nowPageType.value=="reports"){
                Ajax.POST({
                    url,
                    params: {
                        id: route.query.id,
                        page: paginationDataRef.value.current,
                        page_size: paginationDataRef.value.page_size
                    }
                }).then(res => {
                    tableLoadingRef.value = false;
                    if(res.data.code === 200) {
                        tableDataRef.value = res.data.data.list;
                        paginationDataRef.value.current = res.data.data.page_info.page;
                        paginationDataRef.value.total = res.data.data.page_info.total;
                    }
                })
            }else{
                Ajax.GET({
                    url,
                    params: {
                        id: route.query.id,
                        page: paginationDataRef.value.current,
                        page_size: paginationDataRef.value.page_size
                    }
                }).then(res => {
                    tableLoadingRef.value = false;
                    if(res.data.code === 200) {
                        tableDataRef.value = res.data.data.list;
                        paginationDataRef.value.current = res.data.data.page_info.page;
                        paginationDataRef.value.total = res.data.data.page_info.total;
                    }
                })
            }

        }

        onBeforeMount(() => {
            getList();
        })

        const handleSizeChange = e => {
            paginationDataRef.value.page_size = e;
            getList();
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList();
        }
        const goBack=()=>{
            router.back(-1);
        }
        //跳转文献页面
        const goToLiterature=(id)=>{
            router.push({
            name: "literatureDetail",
            query: {
            id: id,
            },
        });
        }
        //跳转报道页面
        const goToReport=(id)=>{
            router.push({
            name: "ReportDetail",
            query: { id: id },
        });
        }

			const toPage = (name, query) => {
				router.push({
					name,
					query
				})
			}
        return {
            breadLists: breadListsRef,
            tableLoading: tableLoadingRef,
            tableData: tableDataRef,
            paginationData: paginationDataRef,
            handleSizeChange,
            handleCurrentChange,
            nowPageType,
            nowPageTitle,
            goBack,
            toPage,
            goToLiterature
        }
    }
}
</script>

<style lang="less" scoped>
    .person-detail-container {
        height: 100%;
        background-color: #fff;
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
    }
</style>
