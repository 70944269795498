<template>
  <div>
    <el-dialog v-model="dialogVisible" title="" width="800px" center @close="close" :show-close="false">
      <div class="header">
        <div class="title_label">
          请选择您从事的研究领域
          <div class="tip">最多可选10个</div>
        </div>
        <img class="delete_icon" @click="close" :src="deleteIcon" alt="" />
      </div>

      <div class="content">
        <div class="menu">
          <div
            :class="['menu_item', { menu_item_active: menuActive === index }, { menu_item_first: menuActive === 0 }]"
            v-for="(item, index) in menuList"
            :key="index"
            @click="changeMenu(index)"
          >
            <div class="menu_item_label" :title="item.name">{{ item.name }}</div>
            <div :class="['number', { 'number-active': item.number === 10 }]" v-if="item.number > 0">{{ item.number }}</div>
          </div>
        </div>
        <div class="choes_box">
          <el-checkbox-group v-model="checkboxGroup" @change="handleChoose">
            <el-checkbox-button class="chose_item" v-for="(item, index) in menuItems" :key="index" :label="item.id">
              {{ item.name }}
            </el-checkbox-button>
          </el-checkbox-group>
        </div>
      </div>
      <div class="chosed_box">
        <div class="chosed_item" v-for="(item, index) in targetLabels" :key="index">
          <span class="chosed_label">{{ item.name }}</span>
          <div class="chosed_delete_box" @click="openDelete(item, index)"><img :src="deleteArea" class="chosed_delete" /></div>
        </div>
      </div>
      <div class="btns">
        <div class="confirm" @click="confirm">确定</div>
      </div>
    </el-dialog>
    
  </div>
</template>
<script setup>
  import { ref, toRefs, onMounted, watch } from 'vue'
  import { ElMessage } from 'element-plus'
  import { Ajax, MessageTips,  } from "../../utils";
  const deleteIcon = 'https://image.deeptechchina.com/image/luoyikexue-web/icon-close.svg'
  const deleteArea = `https://image.deeptechchina.com/image/luoyikexue-web/close.svg?v=${new Date().getTime()}`
  const emit = defineEmits(['handleclose', 'handleconfirm'])
  //传入的值
  const props = defineProps({
    dialogVisible: {
      type: Boolean,
      require: true
    },
    subject: {
      type: String,
      require: true
    },
    subjectlist: {
      type: Array,
      require: true
    }
  })
  const { dialogVisible, subject, subjectlist } = toRefs(props)
  const menuList = ref([])
  const menuActive = ref(0) //选中的菜单下标
  const menuItems = ref([]) //左侧菜单
  const checkboxGroup = ref([]) //选中的领域id集合
  const targetLabels = ref([]) //保存所有已选择的领域 并显示在下方
  onMounted(() => {
    
    
    getArea()
  })

  //获取领域数据
  const getArea = async () => {
    Ajax.GET({
            url: '/admin/getSelect',
            params: {type:'subject'}
        }).then(res => {
            if(res.data.code === 200) {
              menuList.value = res.data.data
              menuItems.value = res.data.data[0].child
            }else {
                MessageTips.error(res.data.msg)
            }
        })
    // let res = await getSelect({ type: 'subject' })
    // if (res.code === 10000) {
    //   menuList.value = res.data
    //   menuItems.value = res.data[0].child
    // }
  }
  //左侧menu
  const changeMenu = (index) => {
    menuActive.value = index
    menuItems.value = menuList.value[index].child
    if (menuList.value[index].idArr) {
      checkboxGroup.value = menuList.value[index].idArr
      lastIds = menuList.value[index].idArr
    } else {
      checkboxGroup.value = []
    }
  }
  // const targetLabel=ref([])
  const targetIds = ref([])
  let lastIds = [] //记录上一次选中的id集合
  let deleteId = null //记录当次删除的id
  const handleChoose = (ids) => {
    if (lastIds.length === 0) {
      lastIds = ids
      deleteId = null
    } else {
      if (!lastIds.includes(ids[ids.length - 1]) && ids.length > lastIds.length) {
        lastIds.push(ids[ids.length - 1])
        deleteId = null
      } else {
        //比较前后两次id集合的不同，记录删除的id
        let deleteArr = lastIds.filter((x) => !ids.some((y) => y === x))
        deleteId = deleteArr[0]
        lastIds = ids
      }
    }
    if (targetIds.value.length > 9) {
      ElMessage({
        message: '最多只能选10个',
        type: 'warning',
        offset: 100
      })
      return false
    }

    //将当前选项id挂到左侧的menu里
    menuList.value[menuActive.value].idArr = ids
    //在左侧菜单显示已选中的个数
    menuList.value[menuActive.value].number = ids.length

    let targeValue = [],
      targetLabel = [] //保存所有已选中的领域的id
    //保存当前选中的选项，用来显示
    menuList.value[menuActive.value].child.forEach((item) => {
      ids.forEach((id) => {
        if (id === item.id) {
          targetLabel.push({ id: item.id, name: item.name, parentNum: menuActive.value })
        }
      })
    })
    if (targetLabels.value.length > 0) {
      targetLabels.value = unique([...targetLabels.value, ...targetLabel], 'id')
    } else {
      targetLabels.value = unique([...targetLabel], 'id')
    }
    //将所有一级目录下选中的id存到一起
    menuList.value.forEach((item) => {
      if (item.idArr) {
        targeValue = [...targeValue, ...item.idArr]
        targetIds.value = Array.from(new Set([...targeValue, ...item.idArr]))
      }
    })
    //根据前面记录的删除的id把总id中的相同项删除
    targetIds.value.forEach((id, index) => {
      if (id === deleteId) {
        targetIds.value.splice(index, 1)
        deleteId = null
      }
    })
    if (targetIds.value.length === 0) {
      targetLabels.value = []
    }

    //检查其他一级目录下是否有当前选中的项 如果有则同步标注
    menuList.value.forEach((item) => {
      let myIds = [],
        idsNum = 0
      item.child.forEach((cell) => {
        if (targetIds.value.includes(cell.id)) {
          myIds.push(cell.id)
          idsNum += 1
        }
      })
      item.idArr = myIds
      item.number = idsNum
    })
  }
  //删除已选中的目标
  const deleteItem = ref({})
  const deleteIndex = ref(null)
  const openDelete = (item, index) => {
    deleteItem.value = item
    deleteIndex.value = index
    handleDeleteArea()
  }
  

  const handleDeleteArea = () => {
    let item = deleteItem.value
    let index = deleteIndex.value
    //在已选中的group中删除
    checkboxGroup.value.splice(index, 1)
    //在下方label中删除
    targetLabels.value.splice(index, 1)
    //在id集合里删除
    targetIds.value = targetIds.value.filter((id) => {
      return id !== item.id
    })
    //将当前选项id挂到左侧的menu里
    menuList.value.forEach((cell) => {
      if (cell.idArr.length > 0) {
        cell.idArr = cell.idArr.filter((id) => {
          return id !== item.id
        })
        cell.number = cell.idArr.length
      }
    })
    // menuList.value[item.parentNum].idArr = menuList.value[item.parentNum].idArr.filter((id) => {
    //   return id !== item.id
    // })
    // //在左侧菜单显示已选中的个数
    // menuList.value[item.parentNum].number = menuList.value[item.parentNum].idArr.length
  }

  //根据对象中的某个属性去重
  const unique = (arr, u_key) => {
    let map = new Map()
    arr.forEach((item) => {
      if (!map.has(item[u_key])) {
        map.set(item[u_key], item)
      }
    })
    return [...map.values()]
  }
  //关闭弹窗
  const close = () => {
    emit('handleclose')
    clearnDate()
  }
  //确认选项
  const confirm = () => {
    //targeValue为选中的id  targetLabel选中的领域标题
    emit('handleconfirm', { id: targetIds.value, label: targetLabels.value })
    clearnDate()
  }
  const clearnDate = () => {
    menuList.value.forEach((item) => {
      delete item.idArr
      delete item.number
    })
    checkboxGroup.value = []
    targetIds.value = []
    targetLabels.value = []
  }

  //检测id的变化 从而删除取消勾选的label
  watch(targetIds, (data) => {
    if (data.length > 0) {
      let newLabels = []
      data.forEach((item) => {
        targetLabels.value.forEach((cell) => {
          if (item === cell.id) {
            newLabels.push(cell)
          }
        })
      })
      targetLabels.value = newLabels
    } else {
      targetLabels.value = []
    }
  })
  watch(dialogVisible, (flag) => {
    //每次打开时更新选中的领域
    if (flag && subjectlist.value.length > 0) {
      if (subject.value.split(',').length > 0) {
        subject.value.split(',').forEach((id) => {
          targetIds.value.push(Number(id))
        })
      }
      //初始化数据
      menuList.value.forEach((item, index) => {
        let idArr = [],
          targetLabel = []
        item.child.forEach((cell) => {
          if (targetIds.value.includes(cell.id)) {
            if (!idArr.includes(cell.id)) {
              idArr.push(cell.id)
              targetLabel.push({ id: cell.id, name: cell.name, parentNum: index })
              if (!checkboxGroup.value.includes(cell.id)) {
                checkboxGroup.value.push(cell.id)
              }
            }
          }
        })
        // checkboxGroup.value = Array.from(new Set([checkboxGroup.value]))
        item.idArr = idArr
        item.number = idArr.length
        targetLabels.value = unique([...targetLabels.value, ...targetLabel], 'id')
      })
      lastIds = checkboxGroup.value
    }
  })

  watch(menuList.value, (newVal, oldVal) => {
    console.log(newVal)
    console.log(oldVal)
  })
</script>
<style lang="less" scoped>
  /deep/ .el-dialog {
    border-radius: 14px;
  }
  /deep/ .el-dialog__header {
    padding-top: 0;
  }
  
  /deep/ .el-dialog__body {
    padding: 20px 0 30px !important;
    border-radius: 14px !important;
    overflow: hidden;
    .chosed_box {
      display: flex !important;
      flex-wrap: wrap !important;
      padding: 0 30px;
      .chosed_item {
        padding: 4px 0px 4px 11px;
        border-radius: 13px;
        border: 1px solid #3b3d40;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .chosed_label {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3b3d40;
          line-height: 18px;
        }
        .chosed_delete_box {
          cursor: pointer;
          padding: 0 8px;
        }
        .chosed_delete {
          width: 16px;
          height: 16px;
        }
      }
    }
    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;

      .confirm {
        width: 160px;
        height: 40px;
        border-radius: 20px;
        border: 2px solid #303234;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303234;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .confirm {
        background: #303234;
        color: #fff;
        border: none;
        margin-left: 20px;
      }
    }
    .header {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      .title_label {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #303234;
        line-height: 24px;
      }
      .delete_icon {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 24px;
        cursor: pointer;
      }
      .tip {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        text-align: center;
        margin-top: 10px;
      }
    }
    .content {
      height: 290px;
      padding: 19px 30px 20px 30px;
      display: flex;
      .menu {
        height: 100%;
        width: 185px;
        // width: 140px;
        background: #e9e9ec;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        overflow: hidden;
        overflow-y: auto;
        padding: 10px 0;
        &::-webkit-scrollbar {
          width: 5px;
        }
        .menu_item_first {
          border-top-left-radius: 8px;
        }
        .menu_item {
          width: 140px;
          height: 32px;
          background: #e9e9ec;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #3b3d40;
          line-height: 30px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          display: flex;

          .menu_item_label {
            width: 110px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .number {
            width: 14px;
            height: 14px;
            background: #3b3d40;
            color: #fff;
            // padding: 0 4px;
            border-radius: 50%;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 14px;
            margin-left: 4px;
            text-align: center;
            flex-shrink: 0;
          }
          .number-active {
            width: 20px;
            height: 20px;
            line-height: 20px;
            transform: scale(0.8);
          }
        }
        .menu_item_active {
          background: #f3f3f5;
        }
      }
      .choes_box {
        height: 100%;
        width: 640px;
        background: #f3f3f5;
        border-radius: 0px 12px 12px 0px;
        overflow: hidden;
        overflow-y: auto;
        padding: 10px 0;
        &::-webkit-scrollbar {
          width: 5px;
        }
        .chose_item {
          width: 136px;
          margin-right: 56px;
          &:nth-child(3n) {
            margin-right: 0;
          }
          .el-checkbox-button__inner {
            border: 0;
            background: #f3f3f5;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 18px;
          }
        }
        .is-checked {
          background: #f3f3f5;
          color: #3b3d40;
          .el-checkbox-button__inner {
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #3b3d40;
            line-height: 18px;
            box-shadow: none;
          }
        }
      }
    }
  }
</style>
