<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            @statusChange="statusChange"
            @addClick="handleClick"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="table-container pd-32">
                <el-table :data="tableData" border :max-height="650">
                  <el-table-column
                    prop="id"
                    label="投资事件ID"
                    width="100"
                  />
                  <el-table-column prop="company_name" label="主体名称" />
                  <el-table-column prop="round" label="轮次" />
                  <el-table-column prop="amount" label="金额">
                    <template #default="scope">
                      <span v-if="scope.row.amount"
                        >{{ scope.row.amount
                        }}{{ scope.row.amount_unit_name }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="finace_time"
                    label="时间"
                    width="100"
                  />
                  <el-table-column prop="investor_arr" label="投资方">
                    <template #default="scope">
                      <span
                        >{{ String(scope.row.investor_arr)
                        }}<span
                          v-if="
                            scope.row.investor_arr.length > 0 &&
                            scope.row.company_arr.length > 0
                          "
                          >,</span
                        >{{ String(scope.row.company_arr) }}</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="appraise"
                    label="最新估值（估算）"
                    width="140"
                  >
                    <template #default="scope">
                      <span v-if="scope.row.is_accurate==1">
                        {{ scope.row.appraise}}{{ scope.row.appraise_unit_name }}
                      </span>
                      <span v-if="scope.row.is_accurate==2">
                        {{ scope.row.no_appraise}}
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="update_time"
                    label="最近编辑时间"
                    width="160"
                  />
                  <el-table-column
                    prop="publish_time"
                    label="发布时间"
                    width="160"
                  />
                  <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.status == 1"
                        >已发布</span
                      >
                      <span
                        class="status-text danger"
                        v-else-if="scope.row.status == 2"
                        >待完善</span
                      >
                      <span
                        class="status-text success"
                        v-else-if="scope.row.status == 3"
                        >已完善</span
                      >
                      <span
                        class="status-text info"
                        v-else-if="scope.row.status == 4"
                        >已撤回</span
                      >
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="operation" label="操作" width="160">
                    <template #default="scope">
                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="gotoPage(scope.row.id)"
                        >详情</el-link
                      >
                      <el-link
                        type="primary"
                        @click="
                          showDialog(scope.row.id, 4, '确定撤回该投资事件')
                        "
                        v-if="scope.row.status == 1"
                        >撤回</el-link
                      >
                      <el-link
                        type="primary"
                        @click="
                          showDialog(scope.row.id, 1, '确定发布该投资事件')
                        "
                        v-else-if="scope.row.status == 3||scope.row.status == 4"
                        >发布</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

  <el-dialog v-model="isShowDialog" :width="500">
    <span>{{ dialogDetail.content }}</span>
    <template #footer>
      <el-button @click="isShowDialog = false">取消</el-button>
      <el-button type="primary" @click="submit()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import SearchFilterBar from "../../../../components/SearchFilterBar/index.vue";
import Header from "../../../../Layout/components/Header.vue";
import BreadCrumb from "../../../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { Ajax, MessageTips } from "../../../../utils";
export default {
  components: {
    SearchFilterBar,
    Header,
    BreadCrumb,
  },
  setup() {
    const isLoading = ref(false);
    const router = useRouter();
    const breadLists = ref([{ name: "投资事件" }]);
    const statusLabelsRef = ref([]);
    const isShowDialog = ref(false);
    const searchForm = ref({
      page: 1,
      page_size: 10,
      name: undefined,
      status: undefined,
    });
    const dialogDetail = ref({
      id: undefined,
      type: undefined,
      content: undefined,
    });
    // 查询
    const handleSearch = (res) => {
      searchForm.value.name = res.searchValue;
      getTableData("search");
    };
    // 新增
    const handleClick = (e) => {
      router.push("/event/invest/create");
    };
    //按状态搜索
    const statusChange = (status) => {
      searchForm.value.status = status == 0 ? undefined : status;
      getTableData("search");
    };
    // 表格数据
    const tableDataRef = ref([]);
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: null,
      total: 0,
    });
    const handleSizeChange = (e) => {
      searchForm.value.page_size = e;
      getTableData("search");
    };
    const handleCurrentChange = (e) => {
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getTableData();
    };
    const gotoPage = (id) => {
      router.push({
        name: "InvestEventDetail",
        query: {
          id: id,
        },
      });
    };
    const getTableData = (type) => {
      if (type) {
        searchForm.value.page = 1;
        paginationDataRef.value.current = 1;
      }
      Ajax.POST({
        url: "/admin/invest",
        data: searchForm.value,
      }).then((res) => {
        if (res.data.code == 200) {
          tableDataRef.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const showDialog = (id, type, content) => {
      dialogDetail.value.type = type;
      dialogDetail.value.id = id;
      dialogDetail.value.content = content;
      isShowDialog.value = true;
    };
    const submit = () => {
      Ajax.POST({
        url: "/admin/invest/status",
        params: {
          id: dialogDetail.value.id,
          status: dialogDetail.value.type,
        },
      }).then((res) => {
        isShowDialog.value = false;
        if (res.data.code == 200) {
          MessageTips.success("操作成功");
          getTableData();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    onMounted(() => {
      getTableData();
      paginationDataRef.value.pageSize = [10, 20, 50];
      statusLabelsRef.value = [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "已发布",
          value: 1,
        },
        {
          name: "已完善",
          value: 3,
        },
        {
          name: "待完善",
          value: 2,
        },
        {
          name: "已撤回",
          value: 4,
        },
      ];
    });
    return {
      breadLists,
      isLoading,
      handleClick,
      handleSearch,
      statusChange,
      tableData: tableDataRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      statusLabels: statusLabelsRef,
      gotoPage,
      isShowDialog,
      dialogDetail,
      showDialog,
      submit,
    };
  },
};
</script>

<style lang="less" scoped>
.person-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
}
</style>