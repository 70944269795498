<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">用户基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="userForm"
                :rules="userFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="姓名" prop="username">
                  <el-input
                    v-model="userForm.username"
                    placeholder="请输入"
                    :maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    v-model="userForm.phone"
                    placeholder="请输入"
                    :maxlength="11"
                    @input="(e) => (userForm.phone = Rule.inputNum(e))"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="userForm.email"
                    placeholder="请输入"
                    @input="(e) => (userForm.email = Rule.inputChe(e))"
                  ></el-input>
                </el-form-item>
                <el-form-item label="用户类型" prop="usertype">
                  <el-select
                    style="width: 100%"
                    v-model="userForm.usertype"
                    placeholder="请选择"
                  >
                    <el-option label="普通用户" value="defaultUser"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="登录密码" prop="password">
                  <el-input
                    v-model="userForm.password"
                    placeholder="请设置登录密码"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom-submit-box pd-16 mg-t-24">
              <el-row justify="end">
                <el-col :span="24" class="text-right">
                  <el-button plain @click="cancelSubmit('formRef')"
                    >取消</el-button
                  >
                  <el-button type="primary" @click="submitForm('formRef')"
                    >保存</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance } from "vue";
import { Ajax, MessageTips, Rule } from "../../utils";
import { useRouter } from "vue-router";
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const breadLists = ref([
      {
        name: "用户",
        path: {
          name: "users",
        },
      },
      {
        name: "新增用户",
      },
    ]);
    const userFormRef = ref({
      username: undefined,
      phone: undefined,
      email: undefined,
      password: undefined
    });
    const userFormRule = {
      username: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error("请输入"));
            } else {
              if (!Rule.isMobile(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      ],
      email: [
        {
          required: false,
          validator: (rule, value, callback) => {
            if (rule.required && !value) {
              callback(new Error("请输入"));
            } else {
              if (value && !Rule.isMail(value)) {
                callback(new Error("请输入正确的邮箱"));
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      ],
      usertype: [
        {
          required: true,
          message: "请选择",
          trigger: "change",
        },
      ],
    };
    const back = () => {
      router.go(-1);
    };

    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      back();
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          Ajax.POST({
            url: "/admin/user",
            data: {
              username: userFormRef.value.username,
              phone: userFormRef.value.phone,
              email: userFormRef.value.email,
              password:  userFormRef.value.password
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("新增成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };
    return {
      breadLists,
      userForm: userFormRef,
      userFormRule,
      cancelSubmit,
      submitForm,
      Rule,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 50%;
    // padding: 0px 180px;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
}
</style>