<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-fund-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">投资机构基本信息</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="fundInfo"
                                :rules="fundInfoRule"
                                label-width="120px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="基金名称" prop="fund_name">
                                    <el-input v-model="fundInfo.fund_name" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="备案时间" prop="filing_time">
                                    <el-date-picker value-format="YYYY-MM-DD" v-model="fundInfo.filing_time" style="width: 100%" type="date" placeholder="选择日期" />
                                </el-form-item>
                                <el-form-item label="注册资本" prop="register_capital">
                                    <el-input v-model="fundInfo.register_capital" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="执行合伙人" prop="copartner">
                                    <el-input v-model="fundInfo.copartner" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="成立时间" prop="establish_time">
                                    <el-date-picker value-format="YYYY-MM-DD" v-model="fundInfo.establish_time" style="width: 100%" type="date" placeholder="选择日期" />
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import { Ajax, MessageTips } from '../../../../utils'
import { useRoute, useRouter } from 'vue-router'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const router = useRouter();
         const breadListsRef = ref([
            {
                name: '投资机构',
                path: {
                    name: 'OrganizationInvestment'
                }
            },
            {
                name: '投资机构详情',
                path: {
                    name: 'OrganizationInvestmentDetail',
                    query: {
                        id: route.query.id
                    }
                }
            },
            {
                name: '基金管理',
                path: {
                    name: 'OrganizationInvestmentRelated',
                    query: {
                        id: route.query.id,
                        pageName: '基金管理',
                        type: 'fund-manage'
                    }
                }
            },
            {
                name: '基金详情',
                path: {
                    name: 'OrganizationInvestmentFundDetail',
                    query: {
                        id: route.query.id,
                        fund_id: route.query.fund_id
                    }
                }
            },
            {
                name: '编辑基金'
            }
        ])
        const fundInfoRef = ref({
            fund_name: '',
            register_capital: '',
            copartner: '',
            filing_time: '',
            establish_time: '',
        })
        const fundInfoRule = {
            fund_name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                } 
            ],
            register_capital: [
                {
                    required: true,
                    message: '请输入注册资本',
                    trigger: 'blur'
                }
            ],
            copartner: [
                {
                    required: true,
                    message: '请输入执行合伙人',
                    trigger: 'blur'
                }
            ]
        }
        onBeforeMount(() => {
            Ajax.GET({
                url: `/admin/organization_funds/${route.query.fund_id}`
            }).then(res => {
                if(res.data.code === 200) {
                    fundInfoRef.value = res.data.data
                }
            })
        })
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        const submitForm = formName => {
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    // fundInfoRef
                    console.log('submit')
                    Ajax.POST({
                        url: '/admin/organization_funds',
                        data: {
                            org_id: route.query.id,
                            ...fundInfoRef.value
                        }
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('修改成功')
                            router.back();
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }
        return {
            breadLists: breadListsRef,
            fundInfo: fundInfoRef,
            fundInfoRule,
            cancelSubmit,
            submitForm
        }
    }
};
</script>

<style lang="less" scoped>
    .create-fund-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
    }
</style>