<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            @addClick="handleAdd"
            @statusChange="statusChange"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="table-container pd-32">
                <el-table :data="tableData" border :max-height="650">
                  <el-table-column prop="user_id" label="用户ID" />
                  <el-table-column prop="username" label="姓名" />
                  <el-table-column prop="phone" label="手机号" />
                  <el-table-column prop="email" label="邮箱" />
                  <el-table-column prop="type" label="用户类型">
                    <template #default>
                      <span>普通用户</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.status == 1"
                        >激活</span
                      >
                      <span class="status-text" v-else>冻结</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="140">
                    <template #default="scope">
                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="gotoPage(scope.row)"
                        >编辑</el-link
                      >

                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="showDialog(scope.row.id, 0, '确认删除该用户?')"
                        >删除</el-link
                      >

                      <el-link
                        type="primary"
                        @click="
                          showDialog(
                            scope.row.id,
                            scope.row.status == 1 ? 2 : 1,
                            scope.row.status == 1
                              ? '确认冻结该用户?'
                              : '确认激活该用户?'
                          )
                        "
                        >{{ scope.row.status == 1 ? "冻结" : "激活" }}</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

  <el-dialog v-model="isShowDialog" :width="400">
    <span>{{ dialogDetail.content }}</span>
    <template #footer>
      <el-button @click="isShowDialog = false">取消</el-button>
      <el-button type="primary" @click="submit()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import SearchFilterBar from "../../components/SearchFilterBar/index.vue";
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../utils";
export default {
  components: {
    SearchFilterBar,
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const breadLists = ref([{ name: "用户" }]);
    const statusLabelsRef = ref([]);
    const isShowDialog = ref(false);
    const dialogDetail = ref({
      recordID: undefined,
      content: "",
      type: undefined,
    });
    const searchForm = ref({
      page: 1,
      page_size: 10,
      username: undefined,
      status: undefined,
    });
    // 查询
    const handleSearch = (res) => {
      searchForm.value.username = res.searchValue;
      paginationDataRef.value.current = 1;
      searchForm.value.page = 1;
      getTableData();
    };
    const statusChange = (status) => {
      paginationDataRef.value.current = 1;
      searchForm.value.page = 1;
      searchForm.value.status = status == 0 ? undefined : status;
      getTableData();
    };
    // 新增
    const handleAdd = (e) => {
      console.log("add-click", e);
      router.push("/users/create");
    };
    // 表格数据
    const tableDataRef = ref([]);
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      console.log(e, "sizeChange");
      searchForm.value.page_size = e;
      getTableData();
    };
    const handleCurrentChange = (e) => {
      console.log(e, "currentChange");
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getTableData();
    };
    const submit = () => {
      if (dialogDetail.value.type == 1 || dialogDetail.value.type == 2) {
        Ajax.POST({
          url: "/admin/user/status",
          data: {
            id: dialogDetail.value.recordID,
            status: dialogDetail.value.type === 2 ? 2 : 1,
          },
        }).then((res) => {
          isShowDialog.value = false;
          if (res.data.code == 200) {
            MessageTips.success("操作成功");
            getTableData();
          } else {
            MessageTips.error(res.data.msg);
          }
        });
      } else if (dialogDetail.value.type == 0) {
        Ajax.DELETE({
          url: `/admin/user/${dialogDetail.value.recordID}`,
          contentType: "application/x-www-form-urlencoded",
        }).then((res) => {
          isShowDialog.value = false;
          if (res.data.code == 200) {
            MessageTips.success("操作成功");
            getTableData();
          } else {
            MessageTips.error(res.data.msg);
          }
        });
      }
    };
    const gotoPage = (record) => {
      router.push({
        path: "/users/edit",
        query: record,
      });
    };
    const showDialog = (id, type, content) => {
      dialogDetail.value.recordID = id;
      dialogDetail.value.type = type;
      dialogDetail.value.content = content;
      isShowDialog.value = true;
    };
    const getTableData = () => {
      Ajax.GET({
        url: "/admin/user",
        params: searchForm.value,
      }).then((res) => {
        if (res.data.code == 200) {
          tableDataRef.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        }
      });
    };

    onMounted(() => {
      statusLabelsRef.value = [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "激活",
          value: 1,
        },
        {
          name: "冻结",
          value: 2,
        },
      ];
      getTableData();
    });
    return {
      breadLists,
      handleAdd,
      handleSearch,
      statusChange,
      tableData: tableDataRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      statusLabels: statusLabelsRef,
      submit,
      gotoPage,
      isShowDialog,
      showDialog,
      dialogDetail,
      getTableData,
    };
  },
};
</script>

<style lang="less" scoped>
.person-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
}
</style>