<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists"/>
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box" >
			<div class="pd-20">
				<div class="create-reports-container">
					<el-card class="mg-b-16" v-loading="loading">
						<template #header>
							<div class="card-header">
								<span class="card-name">报道基本信息</span>
							</div>
						</template>
						<div class="form-container">
							<el-form
									ref="formRef"
									:model="reportsBaseInfo"
									:rules="reportsBaseInfoRule"
									label-width="120px"
									class="user-form-box mg-t-24"
							>
								<el-form-item label="报道标题" prop="name">
									<el-input v-model="reportsBaseInfo.name" style="width: 800px"
														placeholder="请输入"></el-input>
								</el-form-item>
								<el-form-item label="作者" prop="author">
									<el-input v-model="reportsBaseInfo.author" style="width: 800px"
														placeholder="如多个作者，请用顿号分隔"></el-input>
								</el-form-item>
								<el-form-item label="来源" prop="source">
									<el-input v-model="reportsBaseInfo.source"
														style="width: 800px" placeholder="请输入来源"></el-input>
								</el-form-item>
								<el-form-item label="报道时间" prop="report_time">
									<el-date-picker
											v-model="reportsBaseInfo.report_time"
											type="datetime"
											style="width: 800px"
											value-format="YYYY-MM-DD HH:mm"
											format="YYYY-MM-DD HH:mm"
											placeholder="年月日时分"/>
								</el-form-item>
								<el-form-item label="链接" prop="link">
									<el-input v-model="reportsBaseInfo.link" placeholder="请输入"
														style="width: 800px"></el-input>
								</el-form-item>
								<el-form-item label="封面图" prop="image">
									<div>
										<el-upload
												class="avatar-uploader"
												:show-file-list="false"
												:action="`${baseUrl}admin/upload/image`"
												:on-success="avatarUploadSuccess"
												:headers="{ Authorization:'Bearer ' +  adminToken }"
												name="image"
										>
											<img
													v-if="reportsBaseInfo.image"
													:src="reportsBaseInfo.image"
													class="avatar"
											/>
											<el-icon v-else class="avatar-uploader-icon">
												<plus/>
											</el-icon>
										</el-upload>
									</div>
								</el-form-item>
								<el-form-item label="摘要" prop="profile">
									<el-input
											type="textarea"
											v-model="reportsBaseInfo.profile"
											style="width: 800px"
											:autosize={minRows:5}
											maxlength="500"
											show-word-limit
									></el-input>
								</el-form-item>
								<el-form-item label="行业领域" prop="trade">
									<el-input v-model="reportsBaseInfo.trade" style="width: 800px"
														placeholder="如多个行业领域，请用顿号分隔"></el-input>
								</el-form-item>
								<el-form-item label="关键词" prop="keywords">
									<el-input v-model="reportsBaseInfo.keywords" style="width: 800px"
														placeholder="如多个关键词，请用顿号分隔"></el-input>
								</el-form-item>

								<el-form-item label="相关人物" prop="personage_label">
									<el-select
											v-model="reportsBaseInfo.personage_label"
											multiple
											filterable
											remote
											reserve-keyword
											placeholder="请输入人物查找"
											:remote-method="handleAuthor"
											:loading="loadingAuthor"
											style="width: 800px"
									>
										<el-option
												v-for="item in authorData"
												:key="item.id"
												:label="item.name + (item.post ? '(' + item.post + ')' : '')"
												:value="item.id"
										/>
										<template v-slot:empty>
											<div class="me-select-search-empty">
												<div v-loading="loadingAuthor">
													<span class="me-feedback-message">无数据</span>
												</div>
											</div>
										</template>
									</el-select>

								</el-form-item>
								<el-form-item label="相关品牌" prop="brand_label">
									<el-select
											v-model="reportsBaseInfo.brand_label"
											multiple
											filterable
											remote
											reserve-keyword
											placeholder="请输入品牌名称查找"
											:remote-method="handleCompany"
											:loading="loadingCompany"
											style="width: 800px"
									>
										<el-option
												v-for="item in companyData"
												:key="item.id"
												:label="item.name"
												:value="item.id"
										/>
										<template v-slot:empty>
											<div class="me-select-search-empty">
												<div v-loading="loadingCompany">
													<span class="me-feedback-message">无数据</span>
												</div>
											</div>
										</template>
									</el-select>
								</el-form-item>
								<el-form-item label="相关机构" prop="org_label">
									<el-select
											v-model="reportsBaseInfo.org_label"
											multiple
											filterable
											remote
											reserve-keyword
											placeholder="请输入机构查找"
											:remote-method="handleOrg"
											:loading="loadingOrg"
											style="width: 800px"
									>
										<el-option
												v-for="item in orgData"
												:key="item.id"
												:label="item.name"
												:value="item.id"
										/>
										<template v-slot:empty>
											<div class="me-select-search-empty">
												<div v-loading="loadingOrg">
													<span class="me-feedback-message">无数据</span>
												</div>
											</div>
										</template>
									</el-select>
								</el-form-item>

							</el-form>
						</div>
					</el-card>
					<div class="bottom-submit-box pd-16 mg-t-12">
						<el-row justify="end">
							<el-col :span="4">
								<el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
								<el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import {ref, getCurrentInstance, onMounted} from 'vue'
import {Plus} from '@element-plus/icons-vue'
import {useRouter, useRoute} from 'vue-router'
import tinymceEditor from '@/components/tinymceEditor/index.vue'
import {Ajax, MessageTips} from "../../../utils";

export default {
	components: {
		Plus,
		Header,
		BreadCrumb,
		tinymceEditor
	},
	setup() {
		const router = useRouter();
		const route = useRoute();
		const {proxy} = getCurrentInstance();
		const fileProgress = ref(0)

		const breadListsRef = ref([
			{
				name: '报道',
				path: {
					name: 'Reports'
				}
			},
			{
				name: '报道详情',
				path: {
					name: 'ReportDetail',
					query: {
						id: route.query.id
					}
				}
			},
			{
				name: route.query.pageName
			}
		])
		const reportsBaseInfoRef = ref({
			name: '',
			author: '',
			source: '',
			report_time: '',
			link: '',
			image: '',
			profile: '',
			trade: '',
			keywords: '',
			personage_label: [],
			org_label: [],
			brand_label: []
		})
		const reportsBaseInfoRule = {
			name: [
				{
					required: true,
					message: '请输入报道标题',
					trigger: 'blur'
				}
			],
			source: [
				{
					required: true,
					message: '请输入来源',
					trigger: 'blur'
				}
			],
			report_time: [
				{
					required: true,
					message: '请选择报道时间',
					trigger: 'blur'
				}
			],
			link: [
				{
					required: true,
					message: '请输入链接',
					trigger: 'blur'
				}
			]
		}

		const adminToken = ref(localStorage.getItem("adminToken"))
		const baseUrl = ref(window.env.baseUrl)

		// 标签 end

		// 底部提交按钮
		const cancelSubmit = formName => {
			// proxy.$refs[formName].resetFields()
			router.back();
		}
		const submitForm = formName => {
			proxy.$refs[formName].validate(valid => {
				if (valid) {
					Ajax.PUT({
						url: '/admin/report/' + route.query.id,
						data: {
							...reportsBaseInfoRef.value
						}
					}).then(res => {
						if(res.data.code === 200) {
							MessageTips.success('编辑成功');
							router.back();
						}else {
							MessageTips.error(res.data.msg)
						}
					})
				} else {
					return;
				}
			})
		}

		const handlechangeFile = (file, fileList) => {
			fileProgress.value = 0
		}

		const avatarUploadSuccess = (e, type) => {
			reportsBaseInfoRef.value.image = e.data.url;
			if (e.code === 200){
				fileProgress.value = 100
			}
		}

		const getContent = val => {
			reportsBaseInfoRef.value.profile = val
		}
		const handleProgress= (event, file, fileList) => {
			fileProgress.value = Math.floor(event.loaded/event.total*100)
		}
		const handleClose = tag => {
			dynamicTagsRef.value.splice(dynamicTagsRef.value.indexOf(tag), 1)
		}

		const searchText = ref({
			paper: '',
			company: '',
			author: '',
		})
		const loadingAuthor = ref(false)
		const authorData = ref([])
		const handleAuthor = async (query) => {
			searchText.value.author = query
			if (query.length > 1) {
				loadingAuthor.value = true
				Ajax.POST({
					url: '/admin/personage/search',
					data: {
						name : query
					}
				}).then(res => {
					loadingAuthor.value = false
					if(res.data.code === 200) {
						authorData.value = res.data.data
					}
				}).catch(res => {
					loadingAuthor.value = false
					authorData.value = []
				})
			} else {
				authorData.value = []
			}
		}

		const loadingCompany = ref(false)
		const companyData = ref([])
		const handleCompany = async (query) => {
			searchText.value.company = query
			if (query.length > 1) {
				loadingCompany.value = true
				Ajax.POST({
					url: '/admin/brand/search',
					data: {
						name : query
					}
				}).then(res => {
					loadingCompany.value = false
					if(res.data.code === 200) {
						companyData.value = res.data.data
					}
				}).catch(res => {
					loadingCompany.value = false
					companyData.value = []
				})
			} else {
				companyData.value = []
			}
		}

		const loadingOrg = ref(false)
		const orgData = ref([])
		const handleOrg = async (query) => {
			searchText.value.author = query
			if (query.length > 1) {
				loadingOrg.value = true
				Ajax.POST({
					url: '/admin/organization/search',
					data: {
						name : query
					}
				}).then(res => {
					loadingOrg.value = false
					if(res.data.code === 200) {
						orgData.value = res.data.data
					}
				}).catch(res => {
					loadingOrg.value = false
					orgData.value = []
				})
			} else {
				orgData.value = []
			}
		}

		const loading = ref(true)

		const getDetail = () => {
			Ajax.GET({
				url: `/admin/report/${route.query.id}`
			}).then(res => {
				loading.value = false
				if(res.data.code === 200) {
					var d = res.data.data
					reportsBaseInfoRef.value.author = d.author.join("、")
					reportsBaseInfoRef.value.keywords = d.keywords.join("、")
					reportsBaseInfoRef.value.trade = d.trade.join("、")
					reportsBaseInfoRef.value.name = d.name
					reportsBaseInfoRef.value.source = d.source
					reportsBaseInfoRef.value.report_time = d.report_time
					reportsBaseInfoRef.value.link = d.link
					reportsBaseInfoRef.value.image = d.image
					reportsBaseInfoRef.value.profile = d.profile

					d.personage_label_ids.forEach(item=>{
						reportsBaseInfoRef.value.personage_label.push(Number(item))
					})

					d.org_label_ids.forEach(item=>{
						reportsBaseInfoRef.value.org_label.push(Number(item))
					})

					d.brand_label_ids.forEach(item=>{
						reportsBaseInfoRef.value.brand_label.push(Number(item))
					})

					orgData.value = d.org_label
					companyData.value = d.brand_label
					authorData.value = d.personage_label
				}
			}).catch(res => {
				loading.value = false
			})
		}

		onMounted(() => {
			getDetail()
		})
		/* 弹窗选择 end */
		return {
			breadLists: breadListsRef,
			reportsBaseInfo: reportsBaseInfoRef,
			reportsBaseInfoRule,
			avatarUploadSuccess,
			cancelSubmit,
			submitForm,
			searchText,
			authorData,
			loadingAuthor,
			handleAuthor,
			companyData,
			loadingCompany,
			handleCompany,
			orgData,
			loadingOrg,
			handleOrg,
			handleClose,
			fileProgress,
			baseUrl,
			adminToken,
			getContent,
			handlechangeFile,
			handleProgress,
			loading
		}
	}
}
</script>

<style lang="less" scoped>
.me-feedback {
	margin-left: 10px;
}

.me-feedback-message {
	color: #909399;
}

.me-select-search-empty {
	margin: 10px 0px;
	text-align: center;
}

.create-reports-container {
	height: 100%;
	overflow-y: auto;
	width: 100%;
.form-box {
	width: 50%;
}
.avatar-uploader {
	width: 108px;
	height: 108px;
::v-deep(.el-upload) {
	width: 108px;
	height: 108px;
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 108px;
	height: 108px;
	line-height: 108px;
	text-align: center;
}
.avatar {
	width: 108px;
	height: 108px;
}
}
.common-tag {
	margin-right: 8px;
}
.submit-box {
	background-color: #fff;
}
}

</style>
