<template>
  <el-dialog
    v-model="dialogShow"
    title="添加轮播图"
    width="500px"
    :close-on-click-modal="true"
    :show-close="false"
    destroy-on-close
    @close="handleClose"
  >
    <div class="dialo-content">
      <el-form ref="formRef" :model="form" label-width="120px" :rules="rules">
        <el-form-item style="width: 300px" label="轮播名称">
          <el-input v-model="title" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item style="width: 300px" label="上传图片" prop="image">
          <div>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="`${baseUrl}admin/upload/image`"
              :headers="{ Authorization:'Bearer ' +  adminToken }"
              name="image"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              accept="image/jpg,image/jpeg"
              :on-progress="
                (event, file, fileList) => {
                  return handleProgress(event, file, fileList);
                }
              "
              :on-change="
                (file, fileList) => {
                  return handlechangeFile(file, fileList);
                }
              "
            >
              <img v-if="image" :src="image" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
            </el-upload>
            <el-progress :percentage="bannerProgress" v-if="bannerProgress" />
            <p>只支持.jpg 格式,建议尺寸为690*280,大小不超过5M</p>
          </div>
        </el-form-item>
        <!-- <el-form-item label="banner类型" prop="type" v-if="status !== 2">
          <el-radio-group v-model="type">
            <el-radio :label="1">排行</el-radio>
            <el-radio :label="2">报告</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item style="width: 300px" label="排序">
          <el-input v-model="sort" placeholder="请输入数字"></el-input>
        </el-form-item>
        <el-form-item style="width: 300px" label="专辑ID">
          <el-input
            v-model="album_id"
            :disabled="albumFlag"
            placeholder="请输入数字"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="width: 300px; display: flex"
          label="报名链接"
          v-if="status != 1"
        >
          <div style="width: 300px; display: flex">
            <el-select
              v-model="apply_url_type"
              placeholder="请选择"
              :disabled="applyFlag"
              @change="urlTypeChange"
            >
              <el-option
                v-for="item in apply_url_type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-checkbox v-model="addUrl" :disabled="applyFlag" style="margin-left: 10px"
              >选择链接</el-checkbox
            >
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button plain @click="handleBack">返回</el-button>
      <el-button type="primary" @click="handleSure">完成</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Plus, Search } from "@element-plus/icons-vue";
import { Ajax, MessageTips } from "@/utils";
import { number } from "echarts/core";
export default {
  components: {
    Plus,
  },
  props: {
    isShowSwiper: Boolean,
    status: {
      type: Number,
      default: 2,
    },
  },
  setup(props, { emit }) {
    // console.log('props',props);
    const route = useRoute();
    const router = useRouter();
    const adminToken = ref(localStorage.getItem("adminToken"));
    const sort = ref("");
    const album_id = ref("");
    const title = ref("");
    const apply_url_type = ref('');
    const apply_url_type_list = ref([]);

    const applyFlag = ref(false);
    const albumFlag = ref(false);
    const addUrl = ref(false);
    const type = ref();
    const bannerProgress = ref(0);
    /* 弹窗选择 start */
    const dialogShow = ref(props.isShowSwiper);
    const form = reactive({
      name: "",
    });
    const rules = {
      image: [{ required: true, message: "图片不能为空", trigger: "blur" }],
    };
    const image = ref("");
    const handleBack = () => {
      dialogShow.value = false;
    };
    const baseUrl = ref(window.env.baseUrl);
    /* 弹窗选择 end */
    const handleClose = () => {
      emit("closeDialog");
    };

    onMounted(()=>{
      getApplyUrlOptions()
    })

    const getApplyUrlOptions  = () => {
      Ajax.POST({
        url: "/admin/mini/banner/getUrlTypeOptions",
      }).then((res) => {
        console.log(res)
        apply_url_type_list.value = res.data.data
      });
    }


    const handleSure = () => {
      // debugger
      Ajax.POST({
        url: "/admin/mini/banner/create",
        data: {
          image: image.value,
          sort: sort.value,
          album_id: album_id.value,
          title: title.value,
          type: props.status === 2 ? 3 : 1,
          apply_url_type: apply_url_type.value,
        },
      }).then((res) => {
        console.log("首页排行 - 添加", res);
        if (res.data.code === 200) {
          handleClose();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };

    const handleAvatarSuccess = (res, file) => {
      console.log("res, file", res, file);
      if (res.code === 200) {
        image.value = res.data.url;
      }
    };

    const beforeAvatarUpload = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isLt2M) {
        ElMessage.error("上传文件大小不能超过 5MB!");
      }
      return isLt2M;
    };
    const handleProgress = (event, file, fileList) => {
      // debugger
      bannerProgress.value = Math.floor((event.loaded / event.total) * 100);
    };
    const handlechangeFile = (file, fileList) => {
      bannerProgress.value = 0;
    };

    const setapplyurl = (flag) => {
      console.log(flag);
      applyFlag.value = flag;
    };
    const setalbumFlag = (flag) => {
      albumFlag.value = flag;
    };
    const setAddUrl = (flag) => {
      if (!flag) {
        apply_url_type.value= ''
      }
    };
    const urlTypeChange = () => {
      addUrl.value = true
    }
    return {
      // 弹窗
      dialogShow,
      form,
      handleBack,
      image,
      handleClose,
      adminToken,
      sort,
      title,
      album_id,
      handleAvatarSuccess,
      beforeAvatarUpload,
      handleSure,
      baseUrl,
      type,
      setapplyurl,
      applyFlag,
      setalbumFlag,
      albumFlag,
      rules,
      addUrl,
      setAddUrl,
      bannerProgress,
      handleProgress,
      handlechangeFile,
      apply_url_type,
      apply_url_type_list,
      urlTypeChange

    };
  },
  watch: {
    album_id(value) {
      if (value != "") {
        this.setapplyurl(true);
      } else {
        this.setapplyurl(false);
      }
    },
    apply_url_type(value) {
      if (value) {
        this.setalbumFlag(true);
      } else {
        this.setalbumFlag(false);
      }
    },
    addUrl(value) {
      if (value) {
        this.setAddUrl(true);
      } else {
        this.setAddUrl(false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  width: 108px;
  height: 108px;
  ::v-deep(.el-upload) {
    width: 108px;
    height: 108px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
  .avatar {
    width: 108px;
    height: 108px;
  }
}
</style>
