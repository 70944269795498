<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="exit-ditail-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">文献基本信息</span>
                <el-button type="primary" plain @click="gotoPage()"
                  >编辑基本信息</el-button
                >
              </div>
            </template>
            
            <el-form
                ref="formRef"
                label-width="140px"
                class="user-form-box mg-t-24"
              >
              <el-form-item label="自定义标题：" >
                {{detail.title}}
              </el-form-item>
              <el-form-item label="论文中文标题：" >
                {{detail.cn_title}}
              </el-form-item>
              <el-form-item label="论文英文标题：" >
                {{detail.en_title}}
              </el-form-item>
              <el-form-item label="简介提要/导读：" >
                {{detail.brief}}
              </el-form-item>
              <el-form-item label="摘要：" >
                <div v-html="detail.summary"></div>
              </el-form-item>
              <el-form-item label="行业领域：" >
                {{detail.subject}}
              </el-form-item>
              <el-form-item label="来源期刊：" >
                {{detail.periodical}}
              </el-form-item>
              <el-form-item label="作者：" >
                {{detail.author}}
              </el-form-item>
              <el-form-item label="相关专家：" >
                <div v-if="detail.relation_personage && detail.relation_personage.length>0" class="author_con">
                  <div v-for="item in detail.relation_personage" :key="item.id" class="author_item">{{item.name}}</div>
                </div>
              </el-form-item>
              <el-form-item label="DOI：" >
                {{detail.doi}}
              </el-form-item>
              <el-form-item label="发表时间：" >
                {{detail.report_time}}
              </el-form-item>
              <el-form-item label="原文链接：" >
                {{detail.link}}
              </el-form-item>
              <el-form-item label="关键词：" >
                {{detail.keyword}}
              </el-form-item>
              <el-form-item label="被引次数：" >
                {{detail.cites}}
              </el-form-item>
              <el-form-item label="状态：" >
                <span class="status-text primary" v-if="detail.status == 1"
                    >已发布</span
                  >
                  <span class="status-text danger" v-if="detail.status == 2"
                    >待完善</span
                  >
                  <span class="status-text success" v-if="detail.status == 3"
                    >已完善</span
                  >
                  <span
                    class="status-text defainfoult"
                    v-if="detail.status == 4"
                    >已撤销</span
                  >
              </el-form-item>
              <el-form-item label="最近编辑时间：" >
                {{detail.update_time}}
              </el-form-item>
              <el-form-item label="发布时间：" >
                {{detail.publish_time}}
              </el-form-item>
            </el-form>
            
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "文献",
        path: {
          name: "literature",
        },
      },
      {
        name: "文献详情",
      },
    ]);
    const detail = ref({});
    const gotoPage = () => {
      router.push({
        name: "literatureEdit",
        query: {
          id: route.query.id,
        },
      });
    };
    const getDetail = (id) => {
      Ajax.GET({
        url: `/admin/literature/${id}`,
      }).then((res) => {
        if (res.data.code == 200) {
          detail.value = res.data.data;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    onMounted(() => {
      let id = route.query.id;
      getDetail(id);
    });

    return {
      breadLists,
      gotoPage,
      detail,
    };
  },
};
</script>

<style scoped lang='less'>
.exit-ditail-container {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.author_con{
  // padding: 2px 5px;
  display: flex;
  .author_item{
    min-width: 50px;
    text-align: center;
    background-color: #e9f3ff;
    color: #3894ff;
    border: 1px solid #3894ff;
    border-radius: 2px;
    margin-right:10px ;
  }
  
}
</style>