<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="school-container">
                    <SearchFilterBar :statusLabels="statusLabels" @statusChange="statusChange" @addClick="handleAdd" @searchClick="handleSearch" />
                    <div class="table-con mg-t-12">
                        <el-scrollbar>
                            <div class="table-container pd-32">
                                <el-table v-loading="tableLoading" :data="tableData" border :max-height="650">
                                    <el-table-column width="110" prop="id" label="高校院所ID" />
                                    <el-table-column prop="name" label="名称" />
                                    <el-table-column prop="image" label="封面图片">
                                        <template #default="scope">
                                            <el-image
                                                style="width: 100px"
                                                :src="scope.row.image"
                                                fit="cover"
                                                v-if="scope.row.image"
                                            ></el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="country_name" label="国家" />
                                    <el-table-column prop="province_name" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province_name }} {{ scope.row.city_name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="update_time" label="最近编辑时间" />
                                    <el-table-column prop="publish_time" label="发布时间" />
                                    <el-table-column width="90" prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="120" label="操作">
                                        <template #default="scope">
                                            <el-link type="primary" class="mg-r-12" @click="toDetail(scope.row.id)">详情</el-link>
                                            <el-link type="primary" class="mg-r-12" v-if="scope.row.status === 1" @click="coopClick(scope.row.id, 4)">撤回</el-link>
                                            <el-link type="primary" v-if="[3, 4].includes(scope.row.status)" @click="coopClick(scope.row.id, 1)">发布</el-link>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="table-pagination-row mg-t-24">
                                    <el-pagination
                                        background
                                        :currentPage="paginationData.current"
                                        :page-sizes="paginationData.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="paginationData.total"
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>

</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../../components/SearchFilterBar/index.vue';
import { ref, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { Ajax, MessageTips } from '../../../../utils'
export default {
    components: {
        SearchFilterBar,
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '高校院所'
            }
        ])
        const router = useRouter();
        const tableLoadingRef = ref(false)
        const statusRef = ref(0)
        const searchValRef = ref('');
        /* 头部筛选栏 start */ 
        const statusLabelsRef = ref([
            {
                name: "全部",
                value: 0,
            },
            {
                name: "已发布",
                value: 1,
            },
            {
                name: "已完善",
                value: 3,
            },
            {
                name: "待完善",
                value: 2,
            },
            {
                name: "已撤回",
                value: 4,
            } 
        ])
        const statusChange = status => {
            statusRef.value = status;
            getList();
        }
        const handleAdd = () => {
            router.push({
                name: 'OrganizationCreateSchool'
            })
        }
        const handleSearch = searchObj => {
            searchValRef.value = searchObj.searchValue;
            getList();
        }
        /* 头部筛选栏 end */ 
        const tableDataRef = ref([])
        let paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            page_size: 10,
            total: 0
        });
        const handleSizeChange = e => {
            paginationDataRef.value.page_size = e;
            getList();
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList();
        }
        const getList = () => {
            tableLoadingRef.value = true;
            Ajax.GET({
                url: '/admin/university',
                params: {
                    page: paginationDataRef.value.current,
                    page_size: paginationDataRef.value.page_size,
                    name: searchValRef.value,
                    status: statusRef.value || undefined
                }
            }).then(res => {
                tableLoadingRef.value = false;
                if(res.data.code === 200) {
                    tableDataRef.value = res.data.data.list;
                    paginationDataRef.value.total = res.data.data.page_info.total;
                }
            })
        }
        onBeforeMount(() => {
            getList();
        })
        const toDetail = id => {
            router.push({
                path: '/organization/school/detail',
                query: {
                    id
                }
            })
        }
        const coopClick = (id, status) => {
            Ajax.POST({
                url: '/admin/university/status',
                data: {
                    id, status
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('操作成功')
                    getList();
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }
        /* 表格数据操作 */ 
        return {
            breadLists: breadListsRef,
            tableLoading: tableLoadingRef,
            statusLabels: statusLabelsRef,
            statusChange,
            handleAdd,
            handleSearch,
            tableData: tableDataRef,
            handleSizeChange,
            handleCurrentChange,
            paginationData: paginationDataRef,
            toDetail,
            coopClick
        }
    }
}
</script>

<style lang="less" scoped>
    .school-container {
        height: 100%;
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
        .table-con {
            height: 100%;
            max-height: calc(100% - 66px - 12px);
            background-color: #fff;
        }
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
    }
</style>