<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="list-container">
          <SearchFilterBar
            :statusLabels="typeLabels"
            :addShow="true"
            @statusChange="statusChange"
            @pubstatusChange="pubstatusChange"
            @searchClick="handleSearch"
            @addClick="handleClick"
            :isReport="true"
            :publishStatus="publishStatus"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="pd-32" style="background: #fff">
                <el-table :data="ListData" border>
                  <el-table-column
                    prop="id"
                    width="70"
                    align="center"
                    label="报告ID"
                  />
                  <el-table-column
                    name="title"
                    prop="title"
                    label="报告名称"
                    show-overflow-tooltip
                    width="300"
                  />
                  <el-table-column
                    prop="report_type"
                    width="70"
                    align="center"
                    :formatter="setgender"
                    label="类型"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="creator"
                    width="100"
                    align="center"
                    label="创建人"
                  />
                  <el-table-column
                    prop="label_names"
                    width="160"
                    show-overflow-tooltip
                    align="center"
                    label="行业领域"
                  />
                  <el-table-column
                    prop="create_time"
                    width="180"
                    align="center"
                    label="创建时间"
                  />
                  <el-table-column
                    prop="status"
                    width="90"
                    align="center"
                    label="状态"
                  >
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.publish_status === 2"
                        >已发布</span
                      >
                      <span class="status-text info" v-else>未发布</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    width="150"
                    align="center"
                    fixed="right"
                  >
                    <template #default="scope">
                      <el-link
                        class="mg-r-12"
                        type="primary"
                        @click="handleDetail(scope.row.id)"
                        >详情</el-link
                      >
                      <el-link
                        class="mg-r-12"
                        type="primary"
                        v-if="scope.row.publish_status !== 2"
                        @click="handleClick(scope.row.id)"
                        >编辑</el-link
                      >
                      <el-link
                        v-if="scope.row.publish_status === 2"
                        class="mg-r-12"
                        type="primary"
                        @click="
                          changeStatus(scope.row.id, scope.row.publish_status)
                        "
                        >下架</el-link
                      >
                      <el-link
                        v-else
                        class="mg-r-12"
                        type="primary"
                        @click="
                          changeStatus(scope.row.id, scope.row.publish_status)
                        "
                        >发布</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    v-show="paginationData.total > paginationData.size"
                    :background="background"
                    v-model:current-page.sync="paginationData.current"
                    v-model:page-size.sync="paginationData.size"
                    :layout="layout"
                    :total="paginationData.total"
                    v-bind="$attrs"
                    @current-change="handleCurrentChange"
                    @pagination="getTableData"
                  />
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";

export default {
  components: {
    Header,
    BreadCrumb,
    SearchFilterBar,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      breadList: [
        {
          name: "报告列表",
        },
      ],
      typeLabels: [
        {
          value: 0,
          name: "全部",
        },
        {
          value: 1,
          name: "简报",
        },
        {
          value: 2,
          name: "深度",
        },
      ],
      publishStatus: [
        {
          value: 1,
          name: "未发布",
        },
        {
          value: 2,
          name: "已发布",
        },
      ],
      queryData: {
        size: 10,
        page: 1,
        report_type: 0,
        publish_status: 2,
        title: "",
      },
      mainTableKey: 1,
    });

    const ListData = ref([]);
    const paginationData = ref({
      current: 1,
      size: state.queryData.size,
      total: 0,
    });
    // 状态选择
    const statusChange = (status) => {
      state.queryData.report_type = status;
      state.queryData.page = 1;
      paginationData.value.current = 1;
      getTableData();
    };

    const pubstatusChange = (status) => {
      state.queryData.publish_status = status;
      state.queryData.page = 1;
      paginationData.value.current = 1;
      getTableData();
    };

    // 查询
    const handleSearch = ({ searchValue }) => {
      state.queryData.title = searchValue;
      getTableData();
    };

    const getTableData = async () => {
      const ret = await Ajax.POST({
        url: "/admin/mini/report",
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        ListData.value = ret.data.data.items;
        paginationData.value.total = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const setStatus = async (id, status) => {
      const ret = await Ajax.POST({
        url: "/admin/mini/report/publish/" + id,
        data: { publish_status: status },
      });
      if (ret.data.code === 200) {
        getTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const changeStatus = async (id, status) => {
      let str = status == 2 ? "下架" : "发布";
      ElMessageBox.confirm("确认" + str + "该报告?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setStatus(id, status === 2 ? 1 : 2);
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };

    const handleCurrentChange = (val) => {
      state.queryData.page = val;
      paginationData.value.current = val;
      getTableData();
    };

    const handleSizeChange = () => {};

    // 详情
    const handleDetail = (item) => {
      router.push({
        name: "reportsDetail",
        params: { id: item },
      });
    };

    const handleClick = (id) => {
      // console.log('id', id, typeof id === 'object')
      if (typeof id === "object") {
        router.push({
          name: "reportsListEdit",
        });
      } else {
        router.push({
          name: "reportsListEdit",
          params: { id },
        });
      }
    };

    onMounted(() => {
      getTableData();
    });

    return {
      ...state,
      statusChange,
      pubstatusChange,
      handleSearch,
      changeStatus,
      handleCurrentChange,
      handleSizeChange,
      getTableData,
      ListData,
      paginationData,
      handleDetail,
      handleClick,
    };
  },
  methods: {
    setgender(row, colum) {
      return row.report_type === 1 ? "简报" : "深度";
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  height: 100%;
  .status-text {
    font-size: 14px;
    &.primary {
      color: #409eff;
    }
    &.info {
      color: #909399;
    }
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
