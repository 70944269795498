<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-company-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">主体基本信息</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="companyBaseInfo"
                                :rules="companyBaseInfoRule"
                                label-width="140px"
                                class="user-form-box mg-t-24"
                            >
                                <!-- <el-form-item label="全称" prop="name">
                                    <el-input v-model="companyBaseInfo.name" placeholder="请输入"></el-input>
                                </el-form-item> -->
                                <el-descriptions :column="2">
                                    <el-descriptions-item label-align="right">
                                        <el-form-item label="全称:" prop="register_name">
                                            <el-input
                                            v-model="companyBaseInfo.register_name"
                                            placeholder="请输入工商注册名称"
                                            ></el-input>
                                        </el-form-item>
                                    </el-descriptions-item>

                                    <el-descriptions-item label-align="left">
                                        <el-form-item label="统一社会信用代码" prop="reg_no">
                                            <el-input
                                            v-model="companyBaseInfo.reg_no"
                                            placeholder="请输入工商注册代码"
                                            ></el-input>
                                        </el-form-item>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <el-form-item label="成立时间" prop="date">
                                    <el-date-picker value-format="YYYY-MM-DD" v-model="companyBaseInfo.establish_time" type="date" placeholder="选择日期" />
                                </el-form-item>

                                <el-descriptions :column="2">
                                    <el-descriptions-item label-align="right">
                                        <el-form-item label="国家" prop="country">
                                            <el-select class="select w-100" placeholder="请选择"
                                                :popper-append-to-body="false" v-model="companyBaseInfo.country"
                                                @change="coutryChange"
                                                filterable
                                                remote
                                                :reserve-keyword="false"
                                                :remote-method="countryRemoteMethod"
                                            >
                                                <el-option
                                                    v-for="item in countrys"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                >
                                                {{item.name}}
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-descriptions-item>
                                    <el-descriptions-item label-align="left">
                                        <el-form-item label="地区" prop="province">
                                            <el-cascader
                                                v-model="provinceVal"
                                                :options="provincesArr"
                                                @change="provinceChange"
                                                :props="{
                                                    value: 'id',
                                                    label: 'name',
                                                    children: 'child'
                                                }"
                                            ></el-cascader>
                                        </el-form-item>
                                    </el-descriptions-item>
                                </el-descriptions>


                                <el-form-item label="简介" >
                                    <el-input
																				v-model="companyBaseInfo.profile"
																				:autosize="{ minRows: 2,}"
																				:maxlength="50"
																				show-word-limit
																				type="textarea"
																				placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="介绍" prop="introduction">
                                    <el-input v-model="companyBaseInfo.introduction" :autosize="{ minRows: 2, maxRows: 14 }" :maxlength="500" show-word-limit type="textarea" placeholder="请输入"></el-input>
                                </el-form-item>
                                <!-- <el-form-item label="行业领域">
                                    <checkTag ref="checkTag"></checkTag>
                                </el-form-item> -->
                                <el-form-item label="照片" prop="avator">
                                    <p>限制大小1M,支持JPG、PNG格式</p>
                                    <el-upload
                                    class="avatar-uploader"
                                    :class="{
                                        border: !companyBaseInfo.image,
                                    }"
                                    :show-file-list="false"
                                    action="#"
                                    :http-request="uploadImage"
                                    accept="image/jpg, image/jpeg, image/png"
                                    :before-upload="beforeUpload"
                                    >
                                    <div
                                        class="avatar-img-box"
                                        v-if="companyBaseInfo.image"
                                    >
                                        <img :src="companyBaseInfo.image" class="avatar" />
                                    </div>
                                    <el-icon v-else class="avatar-uploader-icon"
                                        ><plus
                                    /></el-icon>
                                    </el-upload>
                                </el-form-item>

                                <el-descriptions :column="2">
                                    <el-descriptions-item label-align="right">
                                        <el-form-item label="官网" prop="link">
                                            <el-input
                                            placeholder="请输入"
                                            v-model="companyBaseInfo.link"
                                            />
                                        </el-form-item>
                                    </el-descriptions-item>
                                    <el-descriptions-item label-align="left">
                                        <el-form-item label="电话" prop="telephone">
                                            <el-input
                                            placeholder="请输入"
                                            v-model="companyBaseInfo.telephone"
                                            ></el-input>
                                        </el-form-item>
                                    </el-descriptions-item>
                                </el-descriptions>
                                <el-descriptions :column="2">
                                    <el-descriptions-item label-align="right">
                                        <el-form-item label="邮箱" prop="email">
                                            <el-input
                                            placeholder="请输入"
                                            v-model="companyBaseInfo.email"
                                            ></el-input>
                                        </el-form-item>
                                    </el-descriptions-item>
                                    <el-descriptions-item label-align="left">
                                        <el-form-item label="手机" prop="mobile">
                                            <el-input
                                            placeholder="请输入"
                                            v-model="companyBaseInfo.mobile"
                                            ></el-input>
                                        </el-form-item>
                                    </el-descriptions-item>
                                </el-descriptions>



                                <!-- <el-form-item label="主体阶段" prop="region">
                                    <el-select class="select w-100" placeholder="请选择"
                                        :popper-append-to-body="false" v-model="companyBaseInfo.phase"
                                        filterable
                                        remote
                                        :reserve-keyword="false"
                                        :remote-method="companyPhaseRemoteMethod"
                                    >
                                        <el-option
                                            v-for="phase in companyPhase"
                                            :key="phase.name"
                                            :label="phase.name"
                                            :value="phase.id"
                                        >
                                        {{phase.name}}
                                        </el-option>
                                    </el-select>
                                </el-form-item> -->


                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router'
import { Ajax, MessageTips } from '../../../utils'
import checkTag from '../../../components/CheckTag'
export default {
    components: {
        checkTag,
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '主体',
                path: {
                    name: 'Company'
                }
            },
            {
                name: '新增主体'
            }
        ])
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const countrysRef = ref([]);
        const provincesArrRef = ref([]);
        const provinceValRef = ref(null);
        const companyPhaseRef = ref([]);
        let regionArr = [],
        companyPhaseArr=[];
        onBeforeMount(async () => {
            Ajax.POST({
                url: '/admin/invest/rounds'
            }).then(res => {
                if(res.data.code === 200) {
                    companyPhaseRef.value = res.data.data;
                    companyPhaseArr=res.data.data;
                }
            })
            const adminRegions = localStorage.getItem('adminRegions');
            if(adminRegions) {
                const regs = JSON.parse(adminRegions);
                regionArr = regs;
                countrysRef.value = regionArr.map(({id, name}) => ({
                    id,
                    name
                }))
            }else {
                const res = await Ajax.GET({
                    url: '/admin/region'
                })
                const resData = res.data;
                if(resData.code === 200) {
                    regionArr = resData.data;
                    countrysRef.value = regionArr.map(({id, name}) => ({
                        id,
                        name
                    }))
                }
            }
        })
        // 国家选择
        const coutryChange = val => {
            const findObj = regionArr.find(item => item.id === val);
            companyBaseInfoRef.value.country = val;
            provincesArrRef.value = findObj?findObj.child:[];
        }
        const countryRemoteMethod = query =>{
            if (query) {
                setTimeout(() => {
                countrysRef.value = regionArr.filter((item) => {
                    return item.name.includes(query)
                })
                }, 200)
            } else {
                countrysRef.value = []
            }
        }
        // 地区选择
        const provinceChange = val => {
            companyBaseInfoRef.value.province = val[0]
            companyBaseInfoRef.value.city = val[1]
        }
        // 限制文件大小
        const beforeUpload = (file) => {
            console.log(file);
            const isL1M = file.size / 1000 / 1000 <= 1;
            if (!isL1M) {
                MessageTips.error("文件上传不能大于1M");
                return false;
            }
            return true;
        };

        // 上传照片
        const uploadImage = (data) => {
            const file = data.file;
            let formData = new FormData();
            formData.append("image", file);
            formData.append("restrict", "1000");
            Ajax.POST({
                url: "/admin/upload/image",
                contentType: "multipart/form-data",
                data: formData,
            }).then((res) => {
                if (res.data.code === 200) {
                companyBaseInfoRef.value.image = res.data.data.url;
                } else {
                MessageTips.error(res.data.msg);
                }
            });
        };
        //主体阶段
        const companyPhaseRemoteMethod= query =>{
            if (query) {
                setTimeout(() => {
                companyPhaseRef.value = companyPhaseArr.filter((item) => {
                    return item.name.includes(query)
                })
                }, 200)
            } else {
                companyPhaseRef.value = []
            }
        }
        const companyBaseInfoRef = ref({
            name: '',
            register_name:"",
            reg_no:"",
            country: null,
            province: null,
            city: null,
            profile: '',
            establish_time: '',
            trade: '',
            phase: null,
            image:"",
            link:"",
            telephone:"",
            email:"",
            mobile:"",
        })

        const companyBaseInfoRule = {
            // name: [
            //     {
            //         required: true,
            //         message: '请输入名称',
            //         trigger: 'blur'
            //     }
            // ],
            register_name: [
                {
                    required: true,
                    message: '请输入主体工商名称',
                    trigger: 'blur'
                }
            ],
            profile: [
                {
                    required: true,
                    message: '请输入简介',
                    trigger: 'blur'
                }
            ]
        }
        // 标签 end
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        const submitForm = formName => {
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    // let tag=ref([])
                    // tag.value=proxy.$refs.checkTag.getValue()
                    // companyBaseInfoRef.value.trade=tag.value.join(',')
                    Ajax.POST({
                        url: '/admin/company',
                        data: companyBaseInfoRef.value
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('提交成功');
                            router.back();
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }
        return {
            breadLists: breadListsRef,
            countrys: countrysRef,
            provincesArr: provincesArrRef,
            provinceVal: provinceValRef,
            coutryChange,
            countryRemoteMethod,
            provinceChange,
            companyPhase: companyPhaseRef,
            companyPhaseRemoteMethod,
            companyBaseInfo: companyBaseInfoRef,
            companyBaseInfoRule,
            cancelSubmit,
            submitForm,
            beforeUpload,
            uploadImage,
        }
    }
};
</script>

<style lang="less" scoped>
    .create-company-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        .el-tag + .el-tag {
            margin-left: 10px;
        }
        .button-new-tag {
            margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
        }
        .input-new-tag {
            width: 90px;
            margin-left: 10px;
            vertical-align: bottom;
        }
        .form-container{
            overflow: auto;
        }
        .avatar-uploader {
            width: 108px;
            height: 108px;
            &.border {
            border: 1px dashed #d9d9d9;
            }
            ::v-deep(.el-upload) {
            width: 108px;
            height: 108px;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            }
            .avatar-img-box {
            width: 100%;
            height: 100%;
            .avatar {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            }
            .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 108px;
            height: 108px;
            line-height: 108px;
            text-align: center;
            }
        }
    }
</style>
