<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists" />
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<el-card class="mg-b-16">
					<template #header>
						<div class="card-header">
							<span class="card-name">竞合关系（{{ tableData && paginationData.total }}）</span>
								<el-button type="primary" @click="openDialog" style="float:right" :icon="Plus" >新增</el-button>
						</div>
					</template>
				<div class="person-container">
					<div class="table-con mg-t-12">
						<el-scrollbar>
							<div class="table-container pd-32">
								<el-table v-loading="tableLoading" :data="tableData" border :max-height="680">
									<el-table-column prop="coopetition_company_id" label="主体ID" width="100px"/>
									<el-table-column prop="name" label="主体名称"/>
									<el-table-column prop="province" label="地区">
										<template #default="scope">
											{{scope.row.country + scope.row.province +  scope.row.city }}
										</template>
									</el-table-column>
									<el-table-column prop="establish_time" label="成立时间" width="120px"/>
									<el-table-column label="操作" width="75">
										<template #default="scope">
											<el-link type="primary" class="mg-r-12" @click="delData(scope.row.id)">删除</el-link>
										</template>
									</el-table-column>
								</el-table>
								<div class="table-pagination-row mg-t-24">
									<el-pagination
											background
											:currentPage="paginationData.current"
											:page-sizes="paginationData.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="paginationData.total"
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
									>
									</el-pagination>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</el-card>
				<el-dialog v-model="dialogShow" title="选择主体" :width="800" :close-on-click-modal="false" :show-close="false" destroy-on-close>
					<div class="dialo-content">
						<div class="mg-b-16" style="width: 200px">
							<el-input v-model="dialogSearchInp" placeholder="搜索单位" :suffix-icon="Search" @change="dialogSearchChange"></el-input>
						</div>
						<el-table v-loading="unitTableLoading" :data="dialogTable" border max-height="300">
								<el-table-column prop="name" label="主体名称">
									<template #default="scope">
										<el-input v-if="scope.row.type=='new'" v-model="scope.row.name" placeholder="请输入"></el-input>
										<span v-else>{{scope.row.register_name}}</span>
									</template>
								</el-table-column>
								<el-table-column label="操作">
									<template #default="scope">
										<el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
									</template>
								</el-table-column>
							<template #empty>
								没有匹配的结果
							</template>
						</el-table>
					</div>
					<template #footer>
						<el-button plain @click="dialogClose">返回</el-button>
						<el-button type="primary" @click="dialogSubmit">完成</el-button>
					</template>
				</el-dialog>

			</div>

		</el-scrollbar>
	</div>
</template>
<script>
import { Plus, Search } from '@element-plus/icons-vue'
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../components/SearchFilterBar/index.vue'
import { ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router';
import {Ajax, MessageTips} from '../../../utils'
export default {
	components: {
		SearchFilterBar,
		Header,
		BreadCrumb,
		Plus
	},
	setup() {
		const route = useRoute();
		const breadListsRef = ref([
			{
				name: '品牌',
				path: {
					name: 'brand'
				}
			},
			{
				name: '品牌详情',
				path: {
					name: 'BrandDetail',
					query: {
						id:route.query.detailId
					}
				}
			},
			
			{
				name: '竞合关系'
			},
		])
		

		// 表格数据
		const tableDataRef = ref([])
		const tableLoadingRef = ref(false)
		// 页码
		let paginationDataRef = ref({
			current: 1,
			pageSize: [10, 20, 50],
			page_size: 10,
			total: 0
		});
		const {proxy} = getCurrentInstance()

		const handleSizeChange = e => {
			paginationDataRef.value.page_size = e;
			getList()
		}
		const handleCurrentChange = e => {
			paginationDataRef.value.current = e;
			getList()
		}

		const dialogShowRef = ref(false);
		const openDialog = () => {
			dialogShowRef.value = true;
		}
		const dialogSearchInpRef = ref('')
		const dialogTableRef = ref([])
		const dialogRadioRef = ref('')
		const addAuditPersonRef = ref(false)
		const newSelectTypeRef = ref(null)
		const unitTableLoadingRef = ref(false);
		const dialogClose = () => {
			dialogShowRef.value = false;
			dialogRadioRef.value = undefined;
			addAuditPersonRef.value = false;
			dialogTableRef.value.splice(0)
			dialogSearchInpRef.value = ''
			newSelectTypeRef.value = null;
		}

		const getUnitList = name => {
			unitTableLoadingRef.value = true;
			addAuditPersonRef.value = false;
			dialogRadioRef.value = undefined;
			Ajax.POST({
				url: '/admin/company/search',
				params: {
					name
				}
			}).then(res => {
				unitTableLoadingRef.value = false;
				if(res.data.code === 200) {
					dialogTableRef.value = res.data.data;
				}
			})
		}
		const dialogSearchChange = value => {
			getUnitList(value)
		}
		const dialogHandleSizeChange = e => {
			console.log(e)
		}
		const dialogHandleCurrentChange = e => {
			paginationDataRef.value.current = e;
		}
		const dialogSubmit = () => {
			if(!dialogRadioRef.value && dialogRadioRef.value !== 0) return;
			// 临时数据添加
			if(addAuditPersonRef.value) {
				if(dialogRadioRef.value !== 0) return ;
				dialogTableRef.value[0].unit_type = newSelectTypeRef.value;
				// if(!(dialogTableRef.value[0].name && dialogTableRef.value[0].unit_type)) {
				//     MessageTips.warning('名称与类型必填')
				//     return ;
				// }
				Ajax.POST({
					url: '/admin/personage/add_unit',
					data: {
						name: dialogTableRef.value[0].name,
						unit_type: 2,
					}
				}).then(res => {
					if(res.data.code==200){
						dialogTableRef.value[0].id = res.data.data.insertId
						if(nowPageType.value === 'cooperation-agency') {
							dialogRadioRef.value = res.data.data.insertId;
							submitAdd(res.data.data.insertId)
						}
						dialogClose();
					}else{
						MessageTips.error(res.data.msg)
						return
					}
				})
				return ;
			}
			submitAdd(+dialogRadioRef.value)
		}

		const submitAdd = (id) => {
			Ajax.POST({
				url: '/admin/company_coopetition',
				data: {
					company_id: +route.query.id,
					coopetition_company_id: id
				}
			}).then(res => {
				if(res.data.code === 200) {
					MessageTips.success('新增成功');
					getList();
					dialogClose();
				}else {
					MessageTips.error(res.data.msg)
				}
			})
		}

		const delData = (id) => {
			proxy
				.$confirm('此操作将删除该数据, 是否继续?', "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: 'error',
				})
				.then(async () => {
					Ajax.DELETE({
						url: '/admin/company_coopetition/' + id,
						params: {
							id: id,
						}
					}).then(res => {
						if(res.data.code === 200) {
							MessageTips.success('成功');
							getList();
						}else {
							MessageTips.error(res.data.msg)
						}
					}).catch((err) => {
						MessageTips.error('失败1')
					});
				})
		}

		const getList = () => {
			tableLoadingRef.value = true;
			Ajax.GET({
				url: '/admin/company_coopetition',
				params: {
					page: paginationDataRef.value.current,
					page_size: paginationDataRef.value.page_size,
					company_id: route.query.id,
				}
			}).then(res => {
				tableLoadingRef.value = false;
				if(res.data.code === 200) {
					tableDataRef.value = res.data.data.list;
					paginationDataRef.value.total = res.data.data.page_info.total;
					paginationDataRef.value.current = res.data.data.page_info.page;
				}
			})
		}
		onMounted(() => {
			getList();
			paginationDataRef.value.pageSize = [10, 20, 50]
		})
		return {
			breadLists: breadListsRef,
			tableLoading: tableLoadingRef,
			tableData: tableDataRef,
			paginationData: paginationDataRef,
			handleSizeChange,
			handleCurrentChange,
			delData,
			Plus,
			Search,
			openDialog,
			dialogShow: dialogShowRef,
			dialogSearchInpRef,
			dialogTable: dialogTableRef,
			dialogRadio: dialogRadioRef,
			dialogClose,
			dialogSearchChange,
			dialogHandleSizeChange,
			dialogHandleCurrentChange,
			dialogSubmit,
			unitTableLoading: unitTableLoadingRef,
			dialogSearchInp: dialogSearchInpRef,
			getUnitList,
		}
	}
};
</script>

<style lang="less" scoped>
.card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.person-container {
	height: 100%;
.table-pagination-row {
	display: flex;
	justify-content: flex-end;
}
.table-con {
	height: 100%;
	max-height: calc(100% - 66px - 12px);
	background-color: #fff;
}
.table-container {
	padding-bottom: 16px;
}
}
</style>
