<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="exit-ditail-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">退出事件基本信息</span>
                <el-button type="primary" plain @click="gotoPage()"
                  >编辑基本信息</el-button
                >
              </div>
            </template>
            <div style="width: 80%">
              <el-descriptions :column="2">
                <el-descriptions-item label="主体名称:">{{
                  detail.company_name
                }}</el-descriptions-item>
                <el-descriptions-item label="状态:">
                  <span class="status-text primary" v-if="detail.status == 1"
                    >已发布</span
                  >
                  <span class="status-text danger" v-if="detail.status == 2"
                    >待完善</span
                  >
                  <span class="status-text success" v-if="detail.status == 3"
                    >已完善</span
                  >
                  <span class="status-text info" v-if="detail.status == 4"
                    >已撤回</span
                  >
                </el-descriptions-item>
                <el-descriptions-item label="退出方式:">
                  <span class="status-text">{{ detail.withdraw_str }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="最近编辑时间:">{{
                  detail.update_time
                }}</el-descriptions-item>
                <el-descriptions-item label="金额:"
                  ><span>{{ detail.is_accurate==1?`${detail.amount}${detail.amount_unit_name} `:detail.no_amount
                  }}</span
                  ></el-descriptions-item
                >
                <el-descriptions-item label="发布时间:">{{
                  detail.publish_time
                }}</el-descriptions-item>
              </el-descriptions>
              <el-descriptions :column="1">
                <el-descriptions-item label="退出时间:">{{
                  detail.quit_time
                }}</el-descriptions-item>
                <el-descriptions-item label="退出方:">
                  <span
                    ><span v-if="detail.investor_arr">{{
                      String(detail.investor_arr)
                    }}</span>
                    <span
                      v-if="
                        detail.investor_arr &&
                        detail.investor_arr.length > 0 &&
                        detail.company_arr &&
                        detail.company_arr.length > 0
                      "
                      >,</span
                    >
                    {{ String(detail.company_arr) }}</span
                  >
                </el-descriptions-item>
                <el-descriptions-item label="回报倍数:">{{
                  detail.ret_multiple
                }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">相关报道（4）</span>
              </div>
            </template>
            <el-table :data="tableData" border :max-height="650">
              <el-table-column prop="id" label="序号" />
              <el-table-column prop="title" label="报道标题" />
              <el-table-column prop="source" label="来源" />
              <el-table-column prop="link" label="链接" />
              <el-table-column prop="status" label="状态">
                <el-link type="primary">已发布</el-link>
              </el-table-column>
            </el-table>
            <p class="text-right link-row mg-t-24">
              <span type="primary" @click="toMore()">更多</span>
            </p>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../../../Layout/components/Header.vue";
import BreadCrumb from "../../../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../../utils";
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "退出事件",
        path: {
          name: "ExitEvent",
        },
      },
      {
        name: "退出事件详情",
      },
    ]);
    const tableData = ref([]);
    const detail = ref({});
    const gotoPage = () => {
      router.push({
        name: "ExitEventEdit",
        query: {
          id: route.query.id,
        },
      });
    };
    const getDetail = (id) => {
      Ajax.POST({
        url: `/admin/quit/details/${id}`,
      }).then((res) => {
        if (res.data.code == 200) {
          detail.value = res.data.data;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const toMore = () => {
      router.push({
        name: "ExitEventList",
        query: {
          id:detail.value.id,
          type: "reports",
          pageName: "相关报道",
        },
      });
    };
    onMounted(() => {
      tableData.value = [
        {
          id: 1,
          title: "一种高精度效率回转体类加工的数控机床",
          source: "######",
          link: "###",
        },
        {
          id: 2,
          title: "一种高精度效率回转体类加工的数控机床",
          source: "######",
          link: "###",
        },
        {
          id: 3,
          title: "一种高精度效率回转体类加工的数控机床",
          source: "######",
          link: "###",
        },
      ];
      let id = route.query.id;
      getDetail(id);
    });

    return {
      breadLists,
      tableData,
      gotoPage,
      detail,
      toMore,
    };
  },
};
</script>

<style scoped lang='less'>
.exit-ditail-container {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .link-row {
    span {
      color: var(--el-color-primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>