<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-detail-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">人物基本信息</span>
                <el-button
                  type="primary"
                  plain
                  @click="goEditPage('PersonEdit', '编辑基本信息', 'edit-basic')"
                >编辑基本信息</el-button>
              </div>
            </template>
            <el-row>
              <el-col>
                <el-descriptions :column="2">
                  <el-descriptions-item label-align="right" label="姓名：">
                    {{ personDetail.name }}
                    <span class="is_deceased" v-if="personDetail.is_deceased==2">已故/不对外推荐</span>
                  </el-descriptions-item>
                  <el-descriptions-item label-align="right" label="状态：">
                    <span
                      :class="`status-text ${statusClass[personDetail.status || 0]}`"
                    >{{ statusLabels[personDetail.status || 0] }}</span>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    label="性别："
                  >{{ personDetail.sex === 1 ? '男' : personDetail.sex === 2 ? '女' : '-' }}</el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    label="最近编辑时间："
                  >{{ personDetail.update_time || '-' }}</el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    label="国家："
                  >{{ personDetail.country_name || '-' }}</el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    label="发布时间："
                  >{{ personDetail.publish_time || '-' }}</el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    label="地区："
                    :span="2"
                  >{{ personDetail.province_name }}-{{ personDetail.city_name }}</el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    label="人物标签："
                    :span="2"
                  >{{ personDetail.type == '1' ? '学者' : personDetail.type == '2' ? '创业者' : '投资人' }}</el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    class="word-break"
                    label="人物简介："
                    :span="2"
                  >
                    <span class="word-break">{{ personDetail.profile || '-' }}</span>
                  </el-descriptions-item>
                  <el-descriptions-item
                    label-align="right"
                    class="word-break"
                    label="任职单位："
                    :span="2"
                  >
                    <span class="word-break">{{ personDetail.unit_name || '-' }}</span>
                  </el-descriptions-item>
                  <template v-if="personDetail.type == '1'">
                    <!-- 研究领域 -->
                    <el-descriptions-item label-align="right" label="研究领域：" :span="2">
                      <template
                        v-if="personDetail.research_label_arr && personDetail.research_label_arr.length"
                      >
                        <span
                          class="mg-r-12"
                          v-for="item in personDetail.research_label_arr"
                          :key="item.id"
                        >{{ item.name }}</span>
                      </template>
                      <template v-else>-</template>
                    </el-descriptions-item>
                  </template>
                  <template v-else-if="personDetail.type == '2'">
                    <!-- 创业领域 -->
                    <el-descriptions-item label-align="right" label="创业领域：" :span="2">
                      <template
                        v-if="personDetail.startup_label_arr && personDetail.startup_label_arr.length"
                      >
                        <span
                          class="mg-r-12"
                          v-for="item in personDetail.startup_label_arr"
                          :key="item.id"
                        >{{ item.name }}</span>
                      </template>
                      <template v-else>-</template>
                    </el-descriptions-item>
                  </template>
                  <template v-else-if="personDetail.type == '3'">
                    <!-- 投资领域 -->
                    <el-descriptions-item label-align="right" label="投资领域：" :span="2">
                      <template
                        v-if="personDetail.invest_label_arr && personDetail.invest_label_arr.length"
                      >
                        <span
                          class="mg-r-12"
                          v-for="item in personDetail.invest_label_arr"
                          :key="item.id"
                        >{{ item.name }}</span>
                      </template>
                      <template v-else>-</template>
                    </el-descriptions-item>
                  </template>
                </el-descriptions>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">联系信息</span>
                <!-- <el-button type="primary" plain @click="goEditPage('PersonEdit', '编辑补充信息', 'edit-contact')">编辑补充信息</el-button> -->
              </div>
            </template>
            <el-row>
              <el-col>
                <el-descriptions :column="4">
                  <el-descriptions-item label="照片：">
                    <el-avatar
                      style="vertical-align: middle;"
                      :size="50"
                      :src="personDetail.image || ''"
                    ></el-avatar>
                  </el-descriptions-item>
                  <el-descriptions-item label="职位：">{{ personDetail.post || '-' }}</el-descriptions-item>
                  <el-descriptions-item label="任职单位：">{{ personDetail.unitName }}</el-descriptions-item>
                  <el-descriptions-item label="联系方式">
                    <span class="inline-block">
                      电话：{{ personDetail.telephone || '-' }}
                      <br />
                      邮箱：{{ personDetail.email || '-' }}
                      <br />
                      手机：{{ personDetail.mobile || '-' }}
                    </span>
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">教育背景</span>
                <!-- <el-button type="primary" plain @click="goEditPage('PersonEdit', '编辑教育背景', 'edit-teach')">编辑教育背景</el-button> -->
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table :show-header="false" :data="personDetail.education">
                  <el-table-column prop="start_year">
                    <template #default="scope">{{ scope.row.start_year }}~{{scope.row.is_so_far==2?'至今': scope.row.end_year }}</template>
                  </el-table-column>
                  <el-table-column prop="university" />
                  <el-table-column prop="specialty" />
                  <el-table-column prop="degree">
                    <template #default="scope">
                      <template v-if="scope.row.degree === 1">学士</template>
                      <template v-else-if="scope.row.degree === 2">硕士</template>
                      <template v-else-if="scope.row.degree === 3">博士</template>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">工作履历</span>
                <!-- <el-button type="primary" plain @click="goEditPage('PersonEdit', '编辑工作履历', 'edit-job')">编辑工作履历</el-button> -->
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table
                  v-if="personDetail.work_history && personDetail.work_history.length"
                  :show-header="false"
                  :data="personDetail.work_history"
                >
                  <el-table-column prop="date">
                    <template
                      #default="scope"
                    >{{ scope.row.start_year||"" }} ~ {{scope.row.is_so_far==2?'至今': scope.row.end_year }}</template>
                  </el-table-column>
                  <el-table-column prop="unit_name">
                    <template #default="scope">{{ scope.row.unit_name }}</template>
                  </el-table-column>
                  <el-table-column prop="post">
                    <template #default="scope">{{ scope.row.post }}</template>
                  </el-table-column>
                </el-table>
                <p class="no-data-tip" v-else>暂无数据</p>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">社会兼职</span>
                <!-- <el-button type="primary" plain @click="goEditPage('PersonEdit', '编辑社会兼职', 'edit-social-job')">编辑社会兼职</el-button> -->
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table
                  v-if="personDetail.social_pt && personDetail.social_pt.length"
                  :show-header="false"
                  :data="personDetail.social_pt"
                >
                  <el-table-column prop="unit_name">
                    <template #default="scope">{{ scope.row.unit_name }}</template>
                  </el-table-column>
                  <el-table-column prop="post">
                    <template #default="scope">{{ scope.row.post }}</template>
                  </el-table-column>
                </el-table>
                <p class="no-data-tip" v-else>暂无数据</p>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">荣誉奖项</span>
                <!-- <el-button type="primary" plain @click="goEditPage('PersonEdit', '编辑荣誉奖项', 'edit-honorary-award')">编辑荣誉奖项</el-button> -->
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table
                  v-if="personDetail.honors && personDetail.honors.length"
                  :show-header="false"
                  :data="personDetail.honors"
                >
                  <el-table-column prop="award_year">
                    <template
                      #default="scope"
                    >{{ scope.row.award_year>0?`${scope.row.award_year}年`:'~' }}</template>
                  </el-table-column>
                  <el-table-column prop="awards">
                    <template #default="scope">{{ scope.row.awards }}</template>
                  </el-table-column>
                </el-table>
                <p class="no-data-tip" v-else>暂无数据</p>
              </el-col>
            </el-row>
          </el-card>

          <!-- 相关专家部分 -->
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">相关专家</span>
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table  border
                  :data="personDetail.expert"
                >
                  <el-table-column prop="id" label="人物ID"/>
									<el-table-column prop="name" label="姓名"/>
									<el-table-column prop="sex" label="性别">
										<template #default="scope">{{
												scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : '-'
											}}
										</template>
									</el-table-column>
									<el-table-column prop="country" label="国家"/>
									<el-table-column prop="province" label="地区">
										<template #default="scope">
											{{ scope.row.province + scope.row.city }}
										</template>
									</el-table-column>
									<el-table-column prop="type_arr" label="人物类型">
										<template #default="scope">
											<el-tag v-for="item in scope.row.type_arr" :key="item">{{item}}</el-tag>
										</template>
									</el-table-column>
									<el-table-column prop="unit_name" label="单位"/>
									<el-table-column prop="post" label="职位"/>
									<el-table-column prop="status" label="状态">
                    <template #default="scope">
                        <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                        <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                        <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                        <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                        <span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                      <template #default="scope">
                        <!-- <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="geToDetail(scope.row.id, 'PersonDetail')"
                        >详情</el-link> -->
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="deleteExpert(scope.row.id)"
                        >删除</el-link>
                      </template>
                    </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-card>
          
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">相关文献（{{ literatureListTotal }}）</span>
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table border :data="literatureList">
                  <el-table-column type="index" width="50" label="序号" />
                  <!-- <el-table-column prop="literature_id" label="序号" /> -->
                  <el-table-column prop="doi" label="DOI">
                    <template #default="scope" :show-overflow-tooltip="true">
                      <span :title="scope.row.doi">{{scope.row.doi}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="title" label="自定义标题" :show-overflow-tooltip="true">
                    <template #default="scope">
                        <!-- <el-tooltip :content="scope.row.title" placement="bottom" effect="light">
                        <span >{{scope.row.title}}</span>
                        </el-tooltip> -->
                      <span @click="goToLiterature(scope.row.id)" style="cursor: pointer;">{{scope.row.title}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="periodical" label="期刊来源" width="170" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <span >{{scope.row.periodical}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="link" label="原文链接" width="80">
                    <template #default="scope">
                      <el-link :href="scope.row.link" type="primary" target="_blank">点击查看</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column prop="author" label="作者" width="170" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <span>{{scope.row.author.join(', ')}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="report_time" label="发表时间" width="150">
                    <template #default="scope">
                      <span>{{scope.row.report_time.substring(0,10)}}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="impact_factor" label="影响因子" />
                                    <el-table-column prop="cites" label="被引量" />
                  <el-table-column prop="publish_time" label="发表年份" />-->
                  <el-table-column prop="status" label="状态" width="70">
                    <template #default="scope">
                      <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                      <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                      <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                      <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col>
                <p class="text-right link-row mg-t-24">
                  <span @click="toMore('literature', '相关文献')">更多</span>
                </p>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">相关著作（{{ worksList.length }}）</span>
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table border :data="worksList">
                  <el-table-column type="index" width="50" label="序号" />
                  <!-- <el-table-column prop="work_id" label="序号" /> -->
                  <el-table-column prop="name" label="书名" />
                  <el-table-column prop="author_arr" label="作者">
                    <template #default="scope">{{ scope.row.author_arr.join(', ') }}</template>
                  </el-table-column>
                  <el-table-column prop="publisher" label="出版社" />
                  <el-table-column prop="publish_time" label="发表年份" />
                  <el-table-column prop="status" label="状态">
                    <template #default="scope">
                      <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                      <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                      <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                      <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col>
                <p class="text-right link-row mg-t-24">
                  <span @click="toMore('books', '相关著作')">更多</span>
                </p>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">相关专利（{{ patentList.length }}）</span>
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table border :data="patentList">
                  <el-table-column prop="patent_id" label="序号" />
                  <el-table-column prop="name" label="专利名" />
                  <el-table-column prop="inventor_arr" label="发明人">
                    <template #default="scope">{{ scope.row.inventor_arr.join('| ') }}</template>
                  </el-table-column>
                  <el-table-column prop="patentee_arr" label="专利权人">
                    <template #default="scope">{{ scope.row.inventor_arr.join('| ') }}</template>
                  </el-table-column>
                  <el-table-column prop="apply_time" label="申请日" />
                  <el-table-column prop="announce_no" label="公开号" />
                  <el-table-column prop="announce_time" label="公开日" />
                  <el-table-column prop="status" label="状态">
                    <template #default="scope">
                      <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                      <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                      <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                      <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col>
                <p class="text-right link-row mg-t-24">
                  <span @click="toMore('patent', '相关专利')">更多</span>
                </p>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">相关报道（{{relevantReportTotal}}）</span>
              </div>
            </template>
            <el-row>
              <el-col>
                <el-table border :data="relevantReports">
                  <el-table-column type="index" width="50" label="序号" />
                  <el-table-column prop="name" label="报道标题" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <span  @click="toPage('ReportDetail', {id: scope.row.id})" style="cursor: pointer;">{{scope.row.name}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="author" label="作者" width="170" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <span >{{scope.row.author}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="source" label="来源" width="170" :show-overflow-tooltip="true">
                    <template #default="scope">
                      <span >{{scope.row.source}}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="link" label="链接" width="80">
                    <template #default="scope">
                      <el-link :href="scope.row.link" type="primary" target="_blank">点击查看</el-link>
                    </template>
                  </el-table-column>
                  <el-table-column prop="report_time" label="报道时间" width="110" />
                  <el-table-column prop="status" label="状态" width="70">
                    <template #default="scope">
                      <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                      <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                      <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                      <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col>
                <p class="text-right link-row mg-t-24">
                  <span @click="toMore('reports', '相关报道')">更多</span>
                </p>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, onBeforeMount, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, configData, messageModal, MessageTips } from "../../../utils";
export default {
  components: {
    Header,
    BreadCrumb
  },
  setup() {
    const breadListsRef = ref([
      {
        name: "人物",
        path: {
          name: "Person"
        }
      },
      {
        name: "人物详情"
      }
    ]);
    const statusLabels = configData.statusLabel;
    const statusClass = configData.statusClass;
    const router = useRouter();
    const route = useRoute();
    const personDetailRef = ref({});
    const literatureListRef = ref([]),
      worksListRef = ref([]),
      patentListRef = ref([]),
      relevantReportsRef = ref([]);
    const relevantReportTotal = ref(""),
      literatureListTotal = ref("");
    // 获取人物详情
    const getDetail = () => {
      Ajax.GET({
        url: `/admin/personage/${route.query.id}`
      }).then(res => {
        if (res.data.code === 200) {
          personDetailRef.value = res.data.data;
          Ajax.GET({
            url: "/admin/work_unit",
            params: {
              unit_type: personDetailRef.value.unit_type,
              unit_id: personDetailRef.value.unit_id
            }
          }).then(res => {
            if (res.data.code === 200) {
              personDetailRef.value.unitName = res.data.data.name;
            }
          });
        }
      });
    };

    // 获取相关文献
    const getLiterature = () => {
      return Ajax.GET({
        url: "/admin/personage/literature",
        params: {
          id: route.query.id,
          page: 1,
          page_size: 3
        }
      });
    };

    // 获取相关著作
    const getWorks = () => {
      return Ajax.GET({
        url: "/admin/personage/works",
        params: {
          id: route.query.id,
          page: 1,
          page_size: 3
        }
      });
    };
    // 获取相关专利
    const getPatent = () => {
      return Ajax.GET({
        url: "/admin/personage/patent",
        params: {
          id: route.query.id,
          page: 1,
          page_size: 3
        }
      });
    };
    //获取相关报道
    const getRelevantReports = () => {
      return Ajax.POST({
        url: "/admin/personage/report",
        params: {
          id: route.query.id,
          page: 1,
          page_size: 3
        }
      });
    };

    // 只拿三条数据
    const getOtherList = () => {
      Promise.all([
        getLiterature(),
        getWorks(),
        getPatent(),
        getRelevantReports()
      ]).then(all => {
        const [literatureRes, worksRes, patentRes, relavantRes] = all;
        if (literatureRes.data.code === 200) {
          literatureListRef.value = literatureRes.data.data.list;
          literatureListTotal.value = literatureRes.data.data.page_info.total;
          console.log(literatureListRef.value, "literatureListRef");
        }
        if (worksRes.data.code === 200) {
          worksListRef.value = worksRes.data.data.list;
        }
        if (patentRes.data.code === 200) {
          patentListRef.value = patentRes.data.data.list;
        }
        if (relavantRes.data.code == 200) {
          relevantReportsRef.value = relavantRes.data.data.list;
          relevantReportTotal.value = relavantRes.data.data.page_info.total;
        }
      });
    };
		const toPage = (name, query) => {
			router.push({
				name,
				query
			})
		}

    onBeforeMount(() => {
      getDetail();
      getOtherList();
      //如果是从热点详情跳转过来的 则修改主体左上角导航条
      if(route.query.hotNews){
        breadListsRef.value=[
          {
            name: "热点",
            path: {
              name: "hotnews"
            }
          },
          {
            name: "热点详情",
            path: {
              name: "hotnewsDetail",
              query: {id:route.query.hotNewsId,basicPage:true}
            },

          },
          {
            name: "人物详情",
          }
        ]
      }

    });

    // 教育背景
    const teachSettingRef = ref([]);

    // 去编辑页面
    const goEditPage = (pathName, pageName, type) => {
      router.push({
        name: pathName,
        query: {
          pageName,
          type,
          id: route.query.id
        }
      });
    };

    // 去相关列表页
    const toMore = (type, pageName) => {
      router.push({
        name: "PersonDetailRelated",
        query: {
          type,
          pageName,
          id: route.query.id
        }
      });
    };
    //跳转文献页面
    const goToLiterature=(id)=>{
        router.push({
        name: "literatureDetail",
        query: {
          id: id,
        },
      });
    }
    //跳转报道页面
    const goToReport=(id)=>{
			console.log(id)
        router.push({
        name: "ReportDetail",
        query: { id: id },
      });
    }

    const showString = (string, len) => {
      return string.length > len ? string.substring(0, len) + "..." : string;
    };

    const deleteExpert = (expert_id) => {
      messageModal.confirm({
          msg: '确认删除？',
          type: 'error',
          confirm() {
              Ajax.POST({
                  url: '/admin/personage/del_expert',
                  data: {
                    personage_id: route.query.id,
                    expert_id
                  }
              }).then(res => {
                  if(res.data.code === 200) {
                      MessageTips.success('删除成功')
                      getDetail()
                  }else {
                      MessageTips.error(res.data.msg)
                  }
              })
          }
      })
    }
    return {
      breadLists: breadListsRef,
      personDetail: personDetailRef,
      literatureList: literatureListRef,
      worksList: worksListRef,
      patentList: patentListRef,
      teachSetting: teachSettingRef,
      goEditPage,
      toMore,
      statusLabels,
      showString,
      statusClass,
      relevantReports: relevantReportsRef,
      relevantReportTotal,
      literatureListTotal,
      goToReport,
      goToLiterature,
			toPage,
      deleteExpert
    };
  }
};
</script>

<style lang="less" scoped>
.inline-block {
  display: inline-block;
  vertical-align: top;
}
.person-detail-container {
  height: 100%;
  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ::v-deep(.el-descriptions__content) {
    color: rgb(16, 16, 16);
  }
  .no-data-tip {
    color: var(--el-text-color-secondary);
    text-align: center;
    font-size: 14px;
  }
  .link-row {
    span {
      color: var(--el-color-primary);
      text-decoration: underline;
      cursor: pointer;
    }
  }
  ::v-deep(.el-avatar > img) {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  .word-break {
    word-break: break-all;
  }
  .is_deceased{
    width: 60px;
    padding: 2px 5px;
    background: #ddd;
    margin-left: 10px;
  }
}
</style>
