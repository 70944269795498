
<template>
    <div class="public_bar_con">
        <div class="ve_nav_bar">
            <div :class="['nav_bar_item',item.isActive?'active_item':'']" v-for="item in navBar" :key="item.id" @click="goProgrom(item.url,item.token,item.auth_type,item)">{{ item.name }}
            </div>
        </div>
        <div class="person_box">
            <div class="personal_label">欢迎! {{ uname }} <img src="https://image.deeptechchina.com/image/public_dowm.svg"
                    class="public_dowm"></div>
            <div class="person_handle">
                <!-- <div class="password" @click="changePass">修改密码</div> -->
                <div class="chang_handle" @click="handleCommand">退出登录</div>
            </div>
        </div>
    </div>
    <!-- <div class="password_box" v-if="showPass">
        <div class="bg_con"></div>
        <div class="pass_con">
            <ul class="pass_group">
                <div class="pass_title">修改密码  <div class="close_img" @click="closePass"><img src="https://image.deeptechchina.com/image/closepassicon.svg"
                    class="closepassicon"></div> </div>
                <li class="pass_item">
                    <p>原始密码</p>
                    <input type="password" class="input_box" v-model="passwordValue.old_password">
                </li>
                <li class="pass_item">
                <p>新密码</p>
                    <input type="password" class="input_box" v-model="passwordValue.new_password">
                </li>
                <li class="pass_item">
                    <p>重复密码</p>
                    <input type="password"  class="input_box" v-model="passwordValue.confirm_password">
                </li>

                <div class="pass_btn_group">
                    <button class="pass_btn_i cancle_btn" @click="closePass">取消</button>
                    <button class="pass_btn_i confirm_btn" @click="onSubmit">确定</button>
                </div>
            </ul>
        </div>
    </div> -->
</template>
<script setup>
import { ref, onMounted, } from "vue";
// import { removeToken, getUserName } from "@/utils/auth";
import { useRouter } from "vue-router";
import {okMessage, errorMessage} from "@/utils/my";
import { Ajax, MessageTips } from "@/utils";
// import {CODES} from "@/utils/codes";
// import { getStaffSyStemList} from "@/api/index";
// import { getSsoId} from '@/utils/auth'

const router = useRouter();
const navBar = ref([]);
const goProgrom = (url,token,type,data) => {
    if(type == "jwt"){
    window.location.href = `${url}?sessionId=${localStorage.getItem('sessionId')}&`;
    localStorage.removeItem('adminToken')
  }else{
    window.location.href = `${url}`;
  }
}
const baseUrl = ref(window.env.baseUrlUni);
const uname = ref('')
const handleCommand =async () => {
    
    let res =await Ajax.POST({
        url: `${baseUrl.value}auth/loginout`,
        data:{session_id:localStorage.getItem('sessionId')}
      })
    if(res.data.code == 10000){
        
        window.location.href = 'https://login.dtinsights.cn/#/login'
        localStorage.clear()
        clearCookies(['dt_cookie', 'cbi_cookie', 'tdp_cookie','sh_cookie','newtr_cookie']);
    }
    
};

const clearCookies=(cookieNames)=> {
  cookieNames.forEach(function(cookieName) {
    document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
  });
}



const getNavBar = async () =>{
    let res =await Ajax.GET({
        url: `${baseUrl.value}permission/getStaffSyStemList`,
        params:{session_id:localStorage.getItem('sessionId')}
      })
    if(res.data.code == 10000){
        navBar.value = res.data.data
    }
}
const getUseInfo = async () =>{
    // let res = await staffInfo({session_id:getSsoId()})
    let res =await Ajax.GET({
        url: `${baseUrl.value}staff/info`,
        params:{session_id:localStorage.getItem('sessionId')}
      })
    if(res.data.code == 10000){
        uname.value = res.data.data.name
    }
}

onMounted(()=>{ 
    // getNavBar(); 
    getUseInfo()
    // localStorage.setItem('navBar',JSON.stringify(res.data.data))
    navBar.value = JSON.parse(localStorage.getItem('navBar'))
})

</script>
<style lang="less" scoped>
.password_box{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    .bg_con{
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
    }
    .pass_con{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9999;
        display: flex;
        justify-content: center;
        margin-top: 20%;
        .pass_group{
            width: 50%;
            background: #fff;
            height: 260px;
            list-style: none;
            padding-left: 0 ;
            .pass_title{
                padding: 20px;
                display: flex;
                justify-content: space-between;
                .close_img{
                    padding: 0 10px;
                    cursor: pointer;
                }
            }
            .closepassicon{
                width: 16px;
            }
            .pass_item{
                padding: 5px 20px;
                display: flex;
                align-items: center;
                p{
                    font-size: 14px;
                    color: #606266;
                    font-family: 'PingFangSC-Regular';
                    width: 100px;
                    margin: 0;
                    text-align: right;
                    margin-right: 10px;
                }
                .input_box{
                    display: inline-block;
                    font-size: inherit;
                    height: 32px;
                    line-height: 32px;
                    outline: 0;
                    padding: 0 11px;
                    width: 100%;
                    border: 1px solid #dcdfe6;
                    border-radius: 4px;
                }
            }
            .pass_btn_group{
                text-align: right;
                padding-right: 20px;
                margin-top: 20px;
                .cancle_btn{
                    margin-right: 20px;
                    background-color:rgb(207, 146, 54);
                    border: 1px solid #e6a23c;
                    &:hover{
                        background-color:rgb(235, 181, 99);
                    }
                }
                .confirm_btn{
                    background-color:#409eff;
                    border: 1px solid #409eff;
                    &:hover{
                        background-color:rgb(102, 177, 255);
                    }
                }
                .pass_btn_i{
                    cursor: pointer;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    line-height: 1;
                    height: 32px;
                    white-space: nowrap;
                    cursor: pointer;
                    user-select: none;
                    vertical-align: middle;
                    padding: 8px 15px;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 4px;
                }
            }
            
        }
    }
}
.public_bar_con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    background: #0f012c;
}

.ve_nav_bar {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 20px 20px;
    padding-top: 20px;
    flex-shrink: 0;
    max-width: 85%;
    overflow-x: auto;
    

    .nav_bar_item {
        padding: 3px 5px;
        cursor: pointer;
        border-radius: 6px;
        margin-right: 15px;
        flex-shrink: 0;
        font-size: 14px;
        color: #fff;
        &:hover {
            background: #8627ea;
            
        }
    }
    .active_item{
        background: #1890ff;
        color: #fff;
    }
}
.ve_nav_bar::-webkit-scrollbar {
    width: 10px;
    }

.person_box {
    position: relative;
    cursor: pointer;

    &:hover {
        .person_handle {
            display: block;
        }
    }

    .personal_label {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: #fff;
    }

    .public_dowm {
        width: 16px;
        margin-left: 3px;
    }

    .person_handle {
        display: none;
        position: absolute;
        font-size: 14px;
        border: 1px solid #ddd;
        border-radius: 6px;
        background-color: #fff;
        padding: 8px 8px;
        width: 160px;
        height: 50px;
        top: 18px;
        z-index: 999;
        .password {
            margin-bottom: 10px;
            cursor: pointer;

            &:hover {
                color: #1890ff;
            }
        }

        .chang_handle {
            cursor: pointer;

            &:hover {
                color: #1890ff;
            }
        }
    }
}</style>
