<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists"/>
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="company-detail-container">
					<!--基本信息-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">品牌基本信息</span>
								<el-button type="primary" plain @click="goEditPage('EditBrand', '编辑基本信息', 'edit-basic')">编辑基本信息
								</el-button>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-descriptions
										:column="2"
										border
								>
									<el-descriptions-item width="60px" label-align="right" label="品牌：">
										{{companyBaseInfo.brand_name}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="状态：" width="60px">
										<span :class="`status-text ${statusClass[companyBaseInfo.publish_status || 0]}`">{{
												statusLabels[companyBaseInfo.publish_status || 0]
											}}</span>
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="主体主体名称：">{{
											companyBaseInfo.register_name
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="统一社会信用代码：">{{
											companyBaseInfo.reg_no
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="地区：">{{
											companyBaseInfo.country_name +
											companyBaseInfo.province_name +
											companyBaseInfo.city_name
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="最近编辑时间：">{{
											companyBaseInfo.update_time
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="成立时间：">{{
											companyBaseInfo.establish_time
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="发布时间：">{{
											companyBaseInfo.publish_time || '-'
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="主体简介：" :span="2">{{
											companyBaseInfo.profile
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="主体介绍：" :span="2">{{
											companyBaseInfo.introduction
										}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="品牌：" :span="2">
										<el-tag v-for="item in companyBaseInfo.brand" :key="item.id">{{item.name}}</el-tag>
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="行业领域：" :span="2">
										<el-tag v-for="item in companyBaseInfo.tag" :key="item.id">{{item.name}}</el-tag>
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="照片：" :span="2">
										<el-image style="width: 100px;" :src="companyBaseInfo.image || ''"  />
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="联系方式：" :span="2">
										<span class="inline-block">
											官网：<el-link v-if="companyBaseInfo.link" :href="companyBaseInfo.link" type="primary">
											{{ companyBaseInfo.link || '-'}}
										</el-link><br>
											电话：{{companyBaseInfo.telephone || '-'}}<br/>
											邮箱：{{ companyBaseInfo.email || '-' }}<br/>
											手机：{{ companyBaseInfo.mobile || '-' }}
										</span>
									</el-descriptions-item>

								</el-descriptions>
							</el-col>
						</el-row>
					</el-card>
					<!--成员-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">品牌成员（{{ companyBaseInfo.member && companyBaseInfo.member.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="companyBaseInfo.member">
									<el-table-column prop="personage_id" label="人物ID"/>
									<el-table-column prop="name" label="姓名"/>
									<el-table-column prop="sex" label="性别">
										<template #default="scope">{{
												scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : '-'
											}}
										</template>
									</el-table-column>
									<el-table-column prop="country" label="国家"/>
									<el-table-column prop="province" label="地区">
										<template #default="scope">
											{{ scope.row.province + scope.row.city }}
										</template>
									</el-table-column>
									<el-table-column prop="type" label="人物类型">
										<template #default="scope">
											<el-tag v-for="item in scope.row.type" :key="item">{{item}}</el-tag>
										</template>
									</el-table-column>
									<el-table-column prop="post" label="职位"/>
									<el-table-column prop="type" label="排序">
										<template #default="scope">
											<el-input
												v-model="scope.row.sort"
												type="number"
												size="small"
												:min="0"
												:value="scope.row.sort"
												@change="submitRow(scope.row)"
											/>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
						</el-row>
					</el-card>
					<!--工商信息-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">工商信息</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-descriptions :column="2" border>
									<el-descriptions-item width="60px" label-align="right" label="法定代表人：">
										{{businessInfo.legal_person}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="经营状态：" width="60px">
										<span>{{businessInfo.open_status}}</span>
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="注册资本：">
										{{businessInfo.reg_capital}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="实缴资本：">
										{{businessInfo.paidin_capital}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="曾用名：">
										{{businessInfo.prev_ent_name}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="所属行业：">
										{{businessInfo.industry}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="统一社会信用代码：">
										{{businessInfo.reg_no}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="纳税人识别号：">
										{{businessInfo.tax_no}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="工商注册号：">
										{{businessInfo.reg_code}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="组织机构代码：">
										{{businessInfo.org_no}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="等级机关：">
										{{businessInfo.authority}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="成立日期：">
										{{businessInfo.start_date}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="企业类型：">
										{{businessInfo.ent_type}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="营业期限：">
										{{businessInfo.open_time}}
									</el-descriptions-item>
									<el-descriptions-item label-align="right" label="参保人数：">
									{{businessInfo.insurance_num}}
								</el-descriptions-item>
									<el-descriptions-item label-align="right" label="注册地址：">
									{{businessInfo.reg_addr}}
								</el-descriptions-item>
									<el-descriptions-item label-align="right" label="经营范围：" :span="2">
									{{businessInfo.scope}}
								</el-descriptions-item>
								</el-descriptions>
							</el-col>
						</el-row>
					</el-card>
					<!--竞合关系-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">竞合关系（{{ coopetitionList && coopetitionList.total }}）</span>
								<el-button type="primary" plain @click="toMore('BrandCoopetition', companyBaseInfo.company_id)">编辑竞合关系</el-button>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="coopetitionList">
									<el-table-column prop="coopetition_company_id" label="主体ID" width="100px"/>
									<el-table-column prop="name" label="主体名称"/>
									<el-table-column prop="province" label="地区">
										<template #default="scope">
											{{scope.row.country + scope.row.province +  scope.row.city }}
										</template>
									</el-table-column>
									<el-table-column prop="establish_time" label="成立时间" width="120px"/>
								</el-table>
							</el-col>
							<el-col v-if="coopetitionList.total > 3">
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('BrandCoopetition', companyBaseInfo.company_id)">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<!--软著信息-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">软著信息（{{ copyrightData && copyrightData.total }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="copyrightData">
									<el-table-column type="index" label="序号" width="80" />
									<el-table-column prop="name" label="软件名称" width="130px"/>
									<el-table-column prop="profile" label="简介"/>
									<el-table-column prop="version" label="版本号" width="80px"/>
									<el-table-column prop="classify" label="软著分类" width="120px"/>
									<el-table-column prop="trade" label="行业分类" width="120px" />
									<el-table-column prop="register_time" label="登记日期" width="120px"/>
									<el-table-column prop="link" label="详情" width="80px">
										<template #default="scope">
											<el-link :href="scope.row.link" target="_blank" type="primary">详情</el-link>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col v-if="copyrightData.total > 3">
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('BrandCopyright', companyBaseInfo.company_id)">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<!--专利信息-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">专利信息（{{ patentData && patentData.total }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="patentData">
									<el-table-column prop="apply_no" label="公布号" width="140px"/>
									<el-table-column prop="name" label="名称"/>
									<el-table-column prop="type" label="类型" width="110px"/>
									<el-table-column prop="announce_time" label="公布/公告日期" width="130px"/>
									<el-table-column prop="link" label="详情" width="80px">
										<template #default="scope">
											<el-link :href="scope.row.link" target="_blank" type="primary">详情</el-link>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col v-if="patentData.total > 3">
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('BrandPatent', companyBaseInfo.company_id)">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
					<!--对内融资-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">对内融资（{{ getInvestList && getInvestList.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="getInvestList">
									<el-table-column type="index" label="序号" width="80" />
									<el-table-column prop="round" label="投资轮次" width="130" />
									<el-table-column prop="finace_time" label="时间" width="120"/>
									<el-table-column prop="amount" label="投资金额" width="150">
										<template #default="scope">
											{{ scope.row.is_accurate == 1 ?
												scope.row.amount + scope.row.amount_unit_name :
												scope.row.no_amount
											}}
										</template>
									</el-table-column>
									<el-table-column prop="all_investor_arr" label="投资方">
										<template #default="scope">
											{{ scope.row.all_investor_arr.toString() }}
										</template>
									</el-table-column>
									<el-table-column prop="appraise" label="估值" width="150">
										<template #default="scope">
											{{ scope.row.is_accurate_app == 1 ?
												scope.row.appraise + scope.row.appraise_unit_name :
												scope.row.no_appraise
											}}
										</template>
									</el-table-column>
								</el-table>
							</el-col>
						</el-row>
					</el-card>
					<!--对外投资-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">对外投资（{{ investList && investList.length }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="investList">
									<el-table-column type="index" label="序号" width="80" />
									<el-table-column prop="finace_time" label="投资时间" width="110" />
									<el-table-column prop="company_name" label="名称" width="250"/>
									<el-table-column prop="round" label="轮次" width="130"/>
									<el-table-column prop="amount" label="金额" width="150">
										<template #default="scope">
											{{ scope.row.is_accurate == 1 ?
												scope.row.amount + scope.row.amount_unit_name :
												scope.row.no_amount
											}}
										</template>
									</el-table-column>
									<el-table-column prop="other_investor_arr" label="其他投资方"/>
								</el-table>
							</el-col>
						</el-row>
					</el-card>
					<!--相关报道-->
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">相关报道（{{ newsData && newsData.total }}）</span>
							</div>
						</template>
						<el-row>
							<el-col>
								<el-table border :data="newsData">
									<el-table-column type="index" label="序号" width="80" />
									<el-table-column prop="name" label="报道标题" :show-overflow-tooltip="true">
										<template #default="scope" >
											<el-link type="primary" :title="scope.row.name" @click="toPage('ReportDetail', {id: scope.row.id})">{{ scope.row.name}}</el-link>
										</template>
									</el-table-column>
									<el-table-column prop="author" label="作者" width="200px" :show-overflow-tooltip="true" />
									<el-table-column prop="source" label="来源" width="110px" :show-overflow-tooltip="true" />
									<el-table-column prop="link" label="链接" width="110px">
										<template #default="scope">
											<el-link :href="scope.row.link" target="_blank" type="primary">点击查看</el-link>
										</template>
									</el-table-column>
									<el-table-column prop="report_time" label="报道时间" width="130px"/>
									<el-table-column prop="status" label="状态" width="80px">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text green" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text info" v-else>已撤回</span>
										</template>
									</el-table-column>
								</el-table>
							</el-col>
							<el-col v-if="newsData.total > 3">
								<p class="text-right link-row mg-t-24">
									<span @click="toMore('BrandReport', '')">更多</span>
								</p>
							</el-col>
						</el-row>
					</el-card>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import {ref, onMounted, onBeforeMount} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import {Ajax, configData} from '../../../utils'
import {unitConvert, showString} from "../../../utils/my";

export default {
	components: {
		Header,
		BreadCrumb
	},
	setup() {
		const breadListsRef = ref([
			{
				name: '品牌',
				path: {
					name: 'brand'
				}
			},
			{
				name: '品牌详情'
			}
		])
		const companyBaseInfoRef = ref({});
		const coopetitionListRef = ref([]);
		const businessInfo = ref({})
		const route = useRoute();
		const router = useRouter();

		// 投资信息（对内对外）
		const getInvestListRef = ref([])
		const investListRef = ref([])
		const copyrightData = ref([])
		const patentData = ref([])
		const newsData = ref([])

		const statusLabels = ref(['', '已发布', '未发布','已撤回']);
		const statusClass = configData.statusClass
		const personType = configData.personType;

		const getMember = (id) => {
			Ajax.POST({
				url: `/admin/brandmember`,
				data: {
					brand_id: id,
					page_size:100,
				}
			}).then(res => {
				if (res.data.code === 200) {
					companyBaseInfoRef.value.member = res.data.data.list.sort(sortBy('sort')) || [];
				}
			})
		}
		const sortBy = (field)=>{
			return(x,y)=>{
				return x[field] - y[field]
			}
		}
		const getBusiness = (id) => {
			Ajax.POST({
				url: `/admin/company/business`,
				data:{company_id: id}
			}).then(res => {
				if (res.data.code === 200) {
					businessInfo.value = res.data.data;
				}
			})
		}
		const invest = (id) => {
			Ajax.POST({
				url: `/admin/company/invest/${id}`,
				data:{page_size:100}
			}).then(res => {
				if (res.data.code === 200) {
					investListRef.value = res.data.data.list;
				}
			})
		}
		const getInvest = (id) => {
			Ajax.POST({
				url: `/admin/company/getinvest/${id}`,
				data:{page_size:100}
			}).then(res => {
				if (res.data.code === 200) {
					getInvestListRef.value = res.data.data.list;
				}
			})
		}
		const coopetition = (id) => {
			Ajax.GET({
				url: '/admin/company_coopetition',
				params: {
					company_id: id,
					page: 1,
					page_size: 3
				}
			}).then(res => {
				if (res.data.code === 200) {
					coopetitionListRef.value = res.data.data.list;
					coopetitionListRef.value.total = res.data.data.page_info.total;
				}
			})
		}
		const copyright = (id) => {
			Ajax.POST({
				url: '/admin/company/works',
				data: {
					company_id: id,
					page: 1,
					page_size: 3
				}
			}).then(res => {
				if (res.data.code === 200) {
					copyrightData.value = res.data.data.list;
					copyrightData.value.total = res.data.data.page_info.total;
				}
			})
		}
		const patent = (id) => {
			Ajax.POST({
				url: '/admin/company/patent',
				data: {
					company_id: id,
					page: 1,
					page_size: 3
				}
			}).then(res => {
				if (res.data.code === 200) {
					patentData.value = res.data.data.list;
					patentData.value.total = res.data.data.page_info.total;
				}
			})
		}
		const news = (id) => {
			Ajax.POST({
				url: '/admin/brand/report',
				data: {
					id: id,
					page: 1,
					page_size: 3
				}
			}).then(res => {
				if (res.data.code === 200) {
					newsData.value = res.data.data.list;
					newsData.value.total = res.data.data.page_info.total;
				}
			})
		}

		const toPage = (name, query) => {
			router.push({
				name,
				query
			})
		}
		//成员排序
		const submitRow=(value)=>{
			Ajax.POST({
				url: `/admin/brandmember/sort`,
				data: {
					id: value.id,
					sort:value.sort,
				}
			}).then(res=>{
				if (res.data.code === 200){
					getMember(route.query.id)
				}
				
			})
		}

		onBeforeMount(() => {
			Ajax.POST({
				url: `/admin/brand/detail/${route.query.id}`
			}).then(res => {
				if (res.data.code === 200) {
					companyBaseInfoRef.value = res.data.data;

					getMember(route.query.id)
					//工商
					getBusiness(res.data.data.company_id)
					//投资
					invest(res.data.data.company_id)
					//获投
					getInvest(res.data.data.company_id)
					//竞合
					coopetition(res.data.data.company_id)
					//软著
					copyright(res.data.data.company_id)
					//专利
					patent(res.data.data.company_id)
					//报道
					news(route.query.id)
				}
			})
			//如果是从热点详情跳转过来的 则修改主体左上角导航条
            if(route.query.hotNews){
                breadListsRef.value=[
                {
                    name: "热点",
                    path: {
                    name: "hotnews"
                    }
                },
                {
                    name: "热点详情",
                    path: {
                    name: "hotnewsDetail",
                    query: {id:route.query.hotNewsId,basicPage:true}
                    },
                    
                },
                {
                    name: "品牌详情",
                }
                ]
            }
		})

		// 去编辑页面
		const goEditPage = (pathName, pageName, type) => {
			// return ;
			router.push({
				name: pathName,
				query: {
					id: route.query.id
				}
			})
		}

		// 去相关列表页
		const toMore = (name, id) => {
			if(name == "BrandCoopetition"){
				router.push({
					name: name,
					query: {
						id: id == '' ? route.query.id : id,
						detailId:route.query.id
					}
				})
			}else{
				router.push({
					name: name,
					query: {
						id: id == '' ? route.query.id : id
					}
				})
			}
			
		}

		return {
			breadLists: breadListsRef,
			statusLabels,
			statusClass,
			personType,
			companyBaseInfo: companyBaseInfoRef,
			getInvestList: getInvestListRef,
			investList: investListRef,
			coopetitionList: coopetitionListRef,
			goEditPage,
			toMore,
			businessInfo,
			copyrightData,
			patentData,
			newsData,
			showString,
			toPage,
			submitRow,
		}
	}
};
</script>

<style lang="less" scoped>
.el-tag {
	margin-right: 10px;
}
.inline-block {
	display: inline-block;
	vertical-align: top;
}

.company-detail-container {
	height: 100%;

.card-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

::v-deep(.el-descriptions__content) {
	color: rgb(16, 16, 16);
}

.no-data-tip {
	color: var(--el-color-primary);
	text-align: center;
	font-size: 14px;
}

.link-row {

span {
	color: var(--el-color-primary);
	text-decoration: underline;
	cursor: pointer;
}

}
::v-deep(.el-avatar>img) {
	width: 50px;
	height: 50px;
	object-fit: cover;
}

}

</style>
