//只能输入数字
export function inputNum(str) {
    if (str) {
        return str.replace(/[^\d]/g, '').trim()
    } else {
        return ''
    }
}
//不能输入汉字
export function inputChe(str) {
    if (str) {
        return str.replace(/[\u4e00-\u9fa5]/g, '').trim()
    } else {
        return ''
    }
}
export function isMobile(str) {
    var regex = /^[1][3,4,5,7,8,9][0-9]{9}$/;
    return regex.test(str);
}
export function isTelephone(str) {
    var regex = /^[\d]{3,4}[\-\s]{0,1}[\d]{7,8}$/;
    return regex.test(str);
}
export function isMail(str) {
    var regex = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+(\.[a-zA-Z]{2,6}){1,2}$/;
    return regex.test(str);
}