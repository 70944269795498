<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="list-container">
          <el-row>
            <div
              style="width: 300px;marginRight: 20px"
              v-for="(o, index) in formList"
              :key="index"
              
            >
              <el-card
                :body-style="{ padding: '0px', background: 'transparent' }"
                shadow="hover"
              >
                <div style="padding: 14px 14px 0">
                  <div class="name">{{ o.name }}</div>
                  <div class="bottom" :class="{ isShowEdit: !o.ediUrl }">
                    <el-button
                      v-if="o.ediUrl"
                      type="text"
                      class="button"
                      @click="handleClick(o.ediUrl)"
                      >{{ o.leftBtnText }}</el-button
                    >
                    <el-button
                      type="text"
                      class="button"
                      @click="handleClick(o.appliFormUrl)"
                      >{{ o.rightBtnText }}</el-button
                    >
                  </div>
                </div>
              </el-card>
            </div>
          </el-row>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";

export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      breadList: [
        {
          name: "表单列表",
        },
      ],
      formList: [
        {
          name: "DeepTech Innovation Week 2022",
          ediUrl: "/activityDetail",
          appliFormUrl: "/innovaApply",
          leftBtnText: "详情",
          rightBtnText: "报名表",
        },
        {
          name: "DT版 Global Insights Program",
          ediUrl: "",
          appliFormUrl: "/dtiApply",
          leftBtnText: "",
          rightBtnText: "报名表",
        },
      ],
    });

    const handleClick = (path) => {
      router.push({
        path: path,
      });
    };

    onMounted(() => {});

    return {
      ...state,
      handleClick,
    };
  },
  methods: {
    setgender(row, colum) {
      return row.gender === 1 ? "男" : row.gender === 0 ? "未知" : "女";
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  height: 100%;
  /deep/ .el-card {
    // background: transparent;
    border: none;
    border-radius: 8px;
  }
  .name {
    padding: 60px 30px;
    background: #ffffff;
    line-height: 24px;
    font-size: 20px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    line-height: 12px;
    border-top: 1px solid #f2f6fc;
  }
  .isShowEdit {
    justify-content: end;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    display: block;
  }
  .status-text {
    font-size: 14px;
    &.primary {
      color: #409eff;
    }
    &.info {
      color: #909399;
    }
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
