<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box" v-if="!isLoading">
      <div class="pd-20">
        <div class="album-detail-container">
          <el-card>
                <div class="basic-container">
                  <el-card class="mg-b-16">
                    <el-form
                      ref="formRef"
                      :model="DetailInfo"
                      label-width="120px"
                      class="form-box mg-t-24"
                    >
                    <el-form-item label="封面图:" prop="cover_img">
                        <img :src="DetailInfo.cover_img" alt=""  style="max-width: 400px;margin-bottom: 20px;"/>
                    </el-form-item>
                    <el-form-item label="顶部banner:" prop="banner">
                        <img :src="DetailInfo.banner" alt=""  style="max-width: 400px;margin-bottom: 20px;"/>
                    </el-form-item>
                    <el-form-item label="海报banner:" prop="poster_banner">
                      <img :src="DetailInfo.poster_banner" alt=""  style="max-width: 400px;margin-bottom: 20px;"/>
                    </el-form-item>
                    <el-form-item label="报告名称:" prop="title">
                        {{ DetailInfo.title }}
                      </el-form-item>
                    <el-form-item label="摘要:" prop="description">
                        {{ DetailInfo.description }}
                      </el-form-item>
                      <el-form-item label="行业领域:">
                        <span v-for="ite in DetailInfo.label_list" class="lable-item">{{ite.name}}</span>
                      </el-form-item>
                      <el-form-item label="内容:">
                        <div v-html="DetailInfo.content"></div>
                      </el-form-item>

                      <!-- <el-form-item label="报告名称" prop="title">
                        {{ DetailInfo.title }}
                      </el-form-item>
                      <el-form-item label="封面图">
                        <img :src="DetailInfo.banner" alt=""  style="max-width: 100%;"/>
                      </el-form-item>
                      <el-form-item label="报告内容" prop="content">
                        <div  v-html="DetailInfo.content"></div>
                      </el-form-item>
                      <el-form-item label="摘要" prop="description">
                        {{ DetailInfo.description }}
                      </el-form-item>
                      <el-form-item label="报告类型">
                        {{ DetailInfo.report_type === 1 ? '简报' : '深度' }}
                      </el-form-item>
                      <el-form-item label="行业领域">
                        <span v-for="ite in DetailInfo.label_list">{{ite.name}}</span>
                      </el-form-item>
                      <el-form-item label="创建时间">
                        {{ DetailInfo.create_time }}
                      </el-form-item>
                      <el-form-item label="上传文件">
                        {{ DetailInfo.file_link }}
                      </el-form-item> -->
                      <el-form-item label="发布时间:">
                        {{ DetailInfo.publish_time }}
                      </el-form-item>
                      <el-form-item label="相关排行:">
                        <span v-for="item in DetailInfo.ablums" :key="item.id" class="aboutitem">{{item.title}}</span>
                      </el-form-item>
                      <el-form-item label="相关品牌:">
                        <span v-for="item in DetailInfo.companys" :key="item.id" class="aboutitem">{{item.name}}</span>
                      </el-form-item>
                    </el-form>
                  </el-card>
                  <div class="submit-box pd-16 mg-t-12" v-if="DetailInfo.publish_status !== 2">
                    <el-row justify="end">
                      <el-col :span="3">
                        <el-button
                          size="small"
                          type="primary"
                          @click="handleEdit"
                          >编辑</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
// import addCompany from "./addCompany.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from '@/components/BreadCrumb/index.vue'
import { ElMessageBox } from "element-plus";

export default {
  components: {
    Plus,
    // addCompany,
    Header,
    BreadCrumb
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadList = ref([
      {
        name: "报告",
        path: {
          name: "reportsList",
        },
      },
      {
        name: "报告详情",
      },
    ]);
    const DetailInfo = ref();
    const isLoading = ref(true)
    const getInfo = async () => {
      const ret = await Ajax.POST({
        url: "/admin/mini/report/details/" + route.params.id,
      });
      if (ret.data.code === 200) {
        DetailInfo.value = ret.data.data;
        isLoading.value = false
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    onMounted(() => {
      getInfo();
    });

    // 编辑
    const handleEdit = () => {
      router.push({
        name: "reportsListEdit",
        params: {
          id: route.params.id,
        },
      });
    };

    return {
      breadList,
      handleEdit,
      DetailInfo,
      isLoading
    };
  },
  methods: {
    showAdress(row, colum) {
      if (row.city_name) {
        return row.city_name;
      }
      if (row.province_name) {
        return row.province_name;
      }

      return "";
    },
    pushDate(row, colum) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row > 0) {
        var time = new Date(row * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y +
          "-" +
          add0(m) +
          "-" +
          add0(d) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  ::v-deep(.el-tabs__content) {
    flex: 1;
    overflow-y: auto;
  }
}
.basic-container {
  word-break: break-all;
  .form-box {
    width: 100%;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
  }
  .submit-box {
    background-color: #fff;
  }
  .aboutitem{
    margin-right: 20px;
  }
}
.content-container {
  height: 100%;
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
 .lable-item{
    margin-right: 10px;
  }
</style>
