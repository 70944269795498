<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">{{ typeObj.title }}</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="detail"
                label-width="140px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="主体主体名称" required>
                  <span class="mg-l-32">{{
                    detail.company_name ? detail.company_name : "-"
                  }}</span>
                </el-form-item>
                <el-form-item label="统一社会信用代码" >
                  <span class="mg-l-32">{{
                    detail.reg_no ? detail.reg_no : "-"
                  }}</span>
                </el-form-item>
              </el-form>
            </div>
          </el-card>

          <div class="bottom-submit-box pd-16">
            <el-row justify="end">
              <el-col :span="12" class="text-right">
                <el-button plain @click="back">取消</el-button>
                <el-button
									plain
									@click="submitForm(2)"
									v-if="audit_status == 1"
                >不通过</el-button
                >
                <el-button
                  type="primary"
                  @click="submitForm(3)"
                  v-if="audit_status == 1"
                  >通过</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
export default {
  components: {
    BreadCrumb,
    Header,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "审核",
        path: {
          name: "auditCom",
        },
      },
      {
        name: "审核详情",
      },
    ]);
    const degreeObj={
      0:"~",
      1:"学士学位",
      2:"硕士学位",
      3:"博士学位"
    };
    const detail = ref({});
    const audit_status = ref();
    const submitForm = (status) => {
      Ajax.POST({
        url: "/admin/task/company/detail/" + route.query.id,
        data: {
          id: route.query.id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          MessageTips.success("操作成功");
          back();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const back = () => {
      router.go(-1);
    };
    const getDetail = () => {
      Ajax.POST({
        url: "/admin/task/company/detail/" + route.query.id,
      }).then((res) => {
        if (res.data.code == 200) {
          detail.value = res.data.data;
          if (detail.value.phase) {
            console.log(detail.value.phase);
            let companyPhase = [
              {
                id: 1,
                name: "天使机构",
              },
              {
                id: 2,
                name: "VC",
              },
              {
                id: 3,
                name: "PE",
              },
              {
                id: 4,
                name: "CVC",
              },
              {
                id: 5,
                name: "FA",
              },
              {
                id: 6,
                name: "FOF",
              },
              {
                id: 7,
                name: "金融机构",
              },
              {
                id: 8,
                name: "国资背景",
              },
              {
                id: 9,
                name: "家族办公室",
              },
              {
                id: 10,
                name: "对冲基金",
              },
              {
                id: 11,
                name: "天使个人",
              },
              {
                id: 12,
                name: "孵化器",
              },
              {
                id: 13,
                name: "其他",
              },
            ];
            for (let i in companyPhase) {
              let item = companyPhase[i];
              if (item.id == detail.value.phase) {
                detail.value.phase = item.name;
								console.log(detail.value);
                break;
              }
            }
          }
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };

    let typeObj = ref({
      type: undefined,
      id: undefined,
      title: undefined,
    });
    let url = ref("");
    onMounted(() => {
      let id = route.query.id;
      typeObj.value.id = id;
			url.value = `/admin/company/${id}`;
			typeObj.value.title = "主体基本信息";
      getDetail(id);
    });
    return {
      breadLists,
      submitForm,
      typeObj,
      detail,
      back,
      audit_status,
      degreeObj
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 100%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
    .compang-pic{
      width: 60px;
      height: 60px;
    }
    .word-break{
      word-break: break-all;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
    overflow: auto;
  }
}
::v-deep(.el-form-item) {
  margin-bottom: 0px;
}
</style>
