<template>
    <div class="new_public_nav">
        <publicBar />
    </div>
    <el-container :class="['layout-container', {'layout-container-h5': isPhone}]">
        <el-aside class="aside-content">
            <Menu />
        </el-aside>
        <el-container class="layout-main-container" id="content_box">
            <router-view></router-view>
        </el-container>
    </el-container>
</template>

<script>
import Menu from './components/Menu.vue'
import Header from './components/Header.vue'
import publicBar from '../components/publicBar/PublicBar.vue'
import BreadCrumb from '../components/BreadCrumb/index.vue'
import { ref, watchEffect, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
export default {
    components: {
        Menu,
        Header,
        BreadCrumb,
        publicBar
    },
    setup() {
        // 处理路由面包屑
        const route = useRoute();
        const pathList = {
            person: '人物',
            company: '主体',
            reports: '报道',
            patent: '专利',
            organization: '机构',
            event: '事件',
            literature: '文献',
            books: '著作',
            tags: '标签',
            users: '用户',
            feedbacks: '反馈',
            audits: '审核',
            operating: '运营',
            messages: '消息',
            ResearchGroup: '期刊课题组列表',
        }
        const isPhone = ref(true)
        const matchPath = (route, path) => {
            return route !== path && route.includes(path)
        }
        const breadList = computed(() => {
            const routePath = route.path;
            if(routePath.match(/\//g).length >= 2) {
                // 多级
                const location = routePath.split('/')[1];
                if(!routePath.includes(location)) return []
                console.log(routePath, route.meta, 'route')
                if(routePath.includes('person/edit') || routePath.includes('patent/edit') || routePath.includes('company/edit') || matchPath(routePath, '/person/detail') || matchPath(routePath, '/organization/investment') || matchPath(routePath, '/organization/social') || matchPath(routePath, '/organization/school')) {
                    // 获取地址栏参数
                    if(routePath.includes('organization/social/edit') || routePath.includes('organization/school/edit') || routePath.includes('organization/investment/edit') || matchPath(routePath, '/organization/investment/detail')) {
                        return [
                            {
                                path: route.meta.homePath || '',
                                name: pathList[location]
                            },
                            {
                                path: route.meta.backPrevPath || '',
                                name: route.meta.title
                            },
                            {
                                path: route.meta.subBackPath || '',
                                name: route.meta.subTitle || route.query.pageName,
                            },
                            {
                                path: '',
                                name: route.meta.nowTitle || route.query.pageName
                            }
                        ]
                    }else {
                        return [
                            {
                                path: route.meta.homePath || '',
                                name: pathList[location],
                                notBack: route.query.fromHome == 1
                            },
                            {
                                path: route.meta.backPrevPath || '',
                                name: route.meta.title,
                                notBack: route.query.fromHome == 1
                            },
                            {
                                path: '',
                                name: route.query.pageName || route.meta.subTitle
                            }
                        ]
                    }
                } else {
                    // 层级结构的两级面包屑第一层是不能跳转的 从首页快捷进来的也不能跳转
                    if(['/organization/investment', '/organization/school', '/organization/social', '/event/invest', '/event/exit'].includes(routePath) || route.query.fromHome == 1) {
                        return [
                            {
                                path: route.meta.backPrevPath || '',
                                notBack: true,
                                name: pathList[location]
                            },
                            {
                                path: '',
                                name: route.meta.title
                            }
                        ]
                    }else {
                        return [
                            {
                                path: route.meta.backPrevPath || '',
                                name: pathList[location]
                            },
                            {
                                path: '',
                                name: route.meta.title
                            }
                        ]
                    }
                }
            }else {
                // 一级
                return [
                    {
                        path: '',
                        name: route.meta.title
                    }
                ]
            }
        })

         // 需要右侧整理滚动的页面
        const contentScrollRef = ref(false)
        watchEffect(() => {
            if(route.meta.contentScroll) {
                contentScrollRef.value = true
            }else {
                contentScrollRef.value = false
            }
        })

        const getPageClientWidth = () => {
            isPhone.value = window.innerWidth >= 1200 ? false : true
            // console.log('isPhone.value', isPhone.value, window.innerWidth)
        }
        onMounted(() => {
            window.addEventListener('resize', getPageClientWidth)
        })
        return {
            route,
            breadList,
            contentScroll: contentScrollRef,
            isPhone
        }
    }
}
</script>

<style scoped lang="less">
    .layout-container {
        position: fixed;
        left: 0;
        top: 60px;
        width: 100%;
        height: 100%;
        .aside-content {
            width: var(--layout-menu-width);
            height: 100%;
            background-color: #0F012C;
        }
        .layout-main-container {
            position: relative;
            width: calc(100% - var(--layout-menu-width));
            height: 100%;
            background-color: #F5F5F5;
            .layout-header {
                height: var(--layout-header-height);
                width: 100%;
                background-color: #fff;
                // box-shadow: rgb(0 0 0 / 20%) 0px 2px 6px 0px;
            }
            .layout-main-content {
                width: 100%;
                box-sizing: border-box;
                height: calc(100% - var(--layout-header-height));
                ::v-deep(.el-scrollbar__view) {
                    height: 100%;
                }
            }
            .slot-container {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: calc(100% - var(--layout-header-height));
            }
        }
    }
    .layout-container-h5{
        overflow-x: auto;
        .layout-main-container{
            min-width: 1000px;
            padding-bottom: 60px;
        }
    }
    
</style>