<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="patent-edit-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">专利基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="patentBaseInfo"
                :rules="patentBaseInfoRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="专利名称" prop="name">
                  <el-input v-model="patentBaseInfo.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="专利权人" prop="patentee">
                  <el-tag
                    v-for="(tag, index) in patentBaseInfo.patenteeArr"
                    :key="tag"
                    closable
                    :disable-transitions="false"
                    @close="deleteTag(index, 'patentee')"
                  >{{ tag.name }}</el-tag>
                  <el-button
                    class="button-new-tag"
                    size="small"
                    @click="openDialog('add-patentee')"
                  >+</el-button>
                </el-form-item>
                <el-form-item label="发明人" prop="inventor">
                  <el-tag
                    v-for="(tag, index) in patentBaseInfo.inventorArr"
                    :key="tag"
                    closable
                    :disable-transitions="false"
                    @close="deleteTag(index, 'inventor')"
                  >{{ tag.name }}</el-tag>
                  <el-button
                    class="button-new-tag"
                    size="small"
                    @click="openDialog('add-inventor')"
                  >+</el-button>
                </el-form-item>
                <el-form-item label="申请号" prop="date">
                  <el-input v-model="patentBaseInfo.apply_no" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="申请日" prop="apply_time">
                  <el-date-picker
                    value-format="YYYY-MM-DD"
                    v-model="patentBaseInfo.apply_time"
                    type="date"
                    placeholder="选择日期"
                  />
                </el-form-item>
                <el-form-item label="公开号" prop="desc">
                  <el-input v-model="patentBaseInfo.announce_no" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="公开日" prop="desc">
                  <el-date-picker
                    value-format="YYYY-MM-DD"
                    v-model="patentBaseInfo.announce_time"
                    type="date"
                    placeholder="选择日期"
                  />
                </el-form-item>
                <el-form-item label="行业领域">
                  <checkTag ref="checkTag"></checkTag>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-12">
            <el-row justify="end">
              <el-col :span="4">
                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <!-- 弹窗 -->
    <el-dialog
      v-model="dialogShow"
      :title="dialogTitle"
      :width="800"
      :close-on-click-modal="false"
      :show-close="false"
      destroy-on-close
    >
      <div class="dialo-content">
        <div class="mg-b-16" style="width: 200px">
          <el-input
            v-model="dialogSearchInp"
            @change="dialogSearchChange"
            placeholder="搜索"
            :suffix-icon="Search"
          ></el-input>
        </div>
        <el-table :data="dialogTable" border max-height="300" v-loading="dialogTableLoading">
          <template v-if="['add-patentee', 'add-inventor'].includes(dialogType)">
            <el-table-column prop="name" label="姓名">
              <template #default="scope">
                <el-input v-if="scope.row.type=='new'" v-model="scope.row.name" placeholder="请输入"></el-input>
                <span v-else>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="research_label_arr" label="研究标签" />
            <el-table-column prop="invest_label_arr" label="投资标签" />
            <el-table-column prop="startup_label_arr" label="创业标签" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
              </template>
            </el-table-column>
          </template>
          <template #empty>
            没有匹配的结果
            <el-button type="primary" class="mg-l-20" size="small" @click="addAuthor">新增</el-button>
          </template>
        </el-table>
        <div class="table-pagination-row mg-t-24">
          <el-pagination
            background
            :currentPage="dialogPaginationData.page"
            :page-sizes="dialogPaginationData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dialogPaginationData.total"
            @size-change="dialogHandleSizeChange"
            @current-change="dialogHandleCurrentChange"
          ></el-pagination>
        </div>
      </div>
      <template #footer>
        <el-button plain @click="dialogClose">返回</el-button>
        <el-button type="primary" @click="dialogSubmit">完成</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
import { Search } from "@element-plus/icons-vue";
import checkTag from "../../../components/CheckTagNew";
export default {
  components: {
    checkTag,
    Header,
    BreadCrumb
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const breadListsRef = ref([
      {
        name: "专利",
        path: {
          name: "Patent"
        }
      },
      {
        name: "专利详情",
        path: {
          name: "PatentDetail",
          query: {
            id: route.query.id
          }
        }
      },
      {
        name: route.query.pageName
      }
    ]);
    const patentBaseInfoRef = ref({
      name: "",
      patentee: "",
      patenteeArr: [],
      inventor: "",
      inventorArr: [],
      apply_no: "",
      apply_time: "",
      announce_no: "",
      announce_time: "",
      trade: ""
    });
    const patentBaseInfoRule = {
      name: [
        {
          required: true,
          message: "请输入专利名称",
          trigger: "blur"
        }
      ],
      apply_time: [
        {
          required: true,
          message: "请选择申请日时间",
          trigger: "blur"
        }
      ]
    };

    onBeforeMount(() => {
      Ajax.GET({
        url: `/admin/patent/${route.query.id}`
      }).then(res => {
        if (res.data.code === 200) {
          patentBaseInfoRef.value = res.data.data;
          patentBaseInfoRef.value.patenteeArr = res.data.data.patentee;
          patentBaseInfoRef.value.inventorArr = res.data.data.inventor;
          let patArr = patentBaseInfoRef.value.patentee.map(item => item.id);
          let invenArr = patentBaseInfoRef.value.inventor.map(item => item.id);
          patentBaseInfoRef.value.patentee = patArr.join(",");
          patentBaseInfoRef.value.inventor = invenArr.join(",");
          console.log(patentBaseInfoRef.value, "patentBaseInfoRef.value");
          proxy.$refs.checkTag.setValue(patentBaseInfoRef.value.trade_arr);
        }
      });
    });

    // 底部提交按钮
    const cancelSubmit = formName => {
      // proxy.$refs[formName].resetFields()
      router.back();
    };
    const submitForm = formName => {
      proxy.$refs[formName].validate(valid => {
        if (valid) {
          let tag = ref([]);
          tag.value = proxy.$refs.checkTag.getValue();
          patentBaseInfoRef.value.trade = tag.value;
          Ajax.PUT({
            url: `/admin/patent/${route.query.id}`,
            data: patentBaseInfoRef.value
          }).then(res => {
            if (res.data.code === 200) {
              MessageTips.success("修改成功");
              router.back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };

    /* 弹窗选择 start */

    const dialogTableLoadingRef = ref(false);
    const dialogShowRef = ref(false);
    const dialogTitleRef = ref("");
    const dialogSearchInpRef = ref("");
    const dialogTableRef = ref([]);
    const dialogRadioRef = ref("");
    const dialogPaginationDataRef = ref({
      page: 1,
      pageSize: [10, 20, 50],
      page_size: 10,
      total: 0
    });
    const dialogHandleSizeChange = e => {
      dialogPaginationDataRef.value.page_size = e;
      getPersonList();
    };
    const dialogHandleCurrentChange = e => {
      dialogPaginationDataRef.value.page = e;
      getPersonList();
    };
    const deleteTag = (index, type) => {
      if (type === "patentee") {
        let patArr = patentBaseInfoRef.value.patentee.split(",");
        patArr.splice(index, 1);
        patentBaseInfoRef.value.patentee = patArr.join(",");
        patentBaseInfoRef.value.patenteeArr.splice(index, 1);
      } else if (type === "inventor") {
        let inventorArr = patentBaseInfoRef.value.inventor.split(",");
        inventorArr.splice(index, 1);
        patentBaseInfoRef.value.inventor = inventorArr.join(",");
        patentBaseInfoRef.value.inventorArr.splice(index, 1);
      }
    };
    // 获取单位列表
    const getPersonList = () => {
      dialogRadioRef.value = undefined;
      dialogTableLoadingRef.value = true;
      addAuditPersonRef.value = false;
      Ajax.GET({
        url: "/admin/personage",
        params: {
          name: dialogSearchInpRef.value,
          page: dialogPaginationDataRef.value.page,
          page_size: dialogPaginationDataRef.value.page_size
        }
      }).then(res => {
        dialogTableLoadingRef.value = false;
        if (res.data.code === 200) {
          dialogTableRef.value = res.data.data.list;
          dialogPaginationDataRef.value.total = res.data.data.page_info.total;
        } else {
          dialogTableRef.value = [];
          dialogPaginationDataRef.value.total = 0;
        }
        console.log(dialogTableRef.value, "dialogTableRef.value");
      });
    };
    const dialogTypeRef = ref("");
    const openDialog = type => {
      dialogTypeRef.value = type;
      dialogShowRef.value = true;
      dialogRadioRef.value = undefined;
      dialogSearchInpRef.value = "";
      if (type === "add-patentee") {
        dialogTitleRef.value = "添加专利权人";
        //  getPersonList()
      } else if (type === "add-inventor") {
        dialogTitleRef.value = "添加发明人";
        //  getPersonList()
      }
      if (["add-patentee", "add-inventor"].includes(type)) {
        getPersonList();
      }
    };

    // 弹窗中的搜索
    const dialogSearchChange = () => {
      switch (dialogTypeRef.value) {
        case "add-patentee":
        case "add-inventor":
          getPersonList();
          break;
        default:
          break;
      }
    };

    // 添加临时人物
    const addAuditPersonRef = ref(false);
    const addAuthor = () => {
      addAuditPersonRef.value = true;
      dialogTableRef.value[0] = {
        type: "new",
        name: dialogSearchInpRef.value,
        id: 0
      };
    };

    // 弹窗返回按钮
    const dialogClose = () => {
      dialogShowRef.value = false;
      dialogRadioRef.value = undefined;
      addAuditPersonRef.value = false;
    };

    const dialogSubmit = () => {
      if (!dialogRadioRef.value && dialogRadioRef.value !== 0) return;
      // 临时人物提交
      if (addAuditPersonRef.value) {
        if (dialogRadioRef.value !== 0) return;
        Ajax.POST({
          url: "/admin/personage/add_audit_personage",
          data: {
            name: dialogTableRef.value[0].name
          }
        }).then(res => {
          if (res.data.code == 200) {
            dialogTableRef.value[0].id = res.data.data.insertId;
            if (dialogTypeRef.value === "add-patentee") {
              let patArr = [];
              if (patentBaseInfoRef.value.patentee) {
                patArr = patentBaseInfoRef.value.patentee.split(",");
              }
              if (!patArr.includes(res.data.data.insertId + "")) {
                patArr.push(res.data.data.insertId);
                patentBaseInfoRef.value.patenteeArr.push(res.data.data.create);
              }
              patentBaseInfoRef.value.patentee = patArr.join(",");
            } else if (dialogTypeRef.value == "add-inventor") {
              let invenArr = [];
              if (patentBaseInfoRef.value.inventor) {
                invenArr = patentBaseInfoRef.value.inventor.split(",");
              }
              if (!invenArr.includes(res.data.data.insertId + "")) {
                invenArr.push(res.data.data.insertId);
                patentBaseInfoRef.value.inventorArr.push(res.data.data.create);
              }
              patentBaseInfoRef.value.inventor = invenArr.join(",");
            }
            dialogClose();
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
        return;
      }
      switch (dialogTypeRef.value) {
        case "add-patentee":
          const selectUnit = dialogTableRef.value.find(
            item => item.id === dialogRadioRef.value
          );
          let patArr = [];
          if (patentBaseInfoRef.value.patentee) {
            patArr = patentBaseInfoRef.value.patentee.split(",");
          }
          if (patArr.includes(selectUnit.id + "")) {
            MessageTips.warning("不能选择重复数据");
            return;
          }
          if (!patArr.includes(selectUnit.id + "")) {
            patArr.push(selectUnit.id);
            patentBaseInfoRef.value.patenteeArr.push(selectUnit);
          }
          patentBaseInfoRef.value.patentee = patArr.join(",");
          dialogClose();
          break;
        case "add-inventor":
          const inselectUnit = dialogTableRef.value.find(
            item => item.id === dialogRadioRef.value
          );
          let invenArr = [];
          if (patentBaseInfoRef.value.inventor) {
            invenArr = patentBaseInfoRef.value.inventor.split(",");
          }
          if (invenArr.includes(inselectUnit.id + "")) {
            MessageTips.warning("不能选择重复数据");
            return;
          }
          if (!invenArr.includes(inselectUnit.id + "")) {
            invenArr.push(inselectUnit.id);
            patentBaseInfoRef.value.inventorArr.push(inselectUnit);
          }
          patentBaseInfoRef.value.inventor = invenArr.join(",");
          dialogClose();
          break;
        default:
          break;
      }
    };
    /* 弹窗选择 end */

    return {
      breadLists: breadListsRef,
      patentBaseInfo: patentBaseInfoRef,
      patentBaseInfoRule,
      cancelSubmit,
      submitForm,

      // 选择人的弹窗
      dialogType: dialogTypeRef,
      dialogTableLoading: dialogTableLoadingRef,
      dialogShow: dialogShowRef,
      dialogTitle: dialogTitleRef,
      dialogSearchInp: dialogSearchInpRef,
      dialogTable: dialogTableRef,
      dialogPaginationData: dialogPaginationDataRef,
      dialogHandleSizeChange,
      dialogHandleCurrentChange,
      dialogRadio: dialogRadioRef,
      openDialog,
      dialogSearchChange,
      dialogClose,
      dialogSubmit,
      Search,
      addAuthor,
      deleteTag
    };
  }
};
</script>

<style lang="less" scoped>
.patent-edit-container {
  .form-container {
    overflow: auto;
  }
  .user-form-box {
    width: 50%;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>