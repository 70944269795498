import Layout from '../Layout/index.vue'
const routes = [{
        name: 'Login',
        path: '/login',
        meta: {
            title: '登录'
        },
        component: () =>
            import ('../pages/Account/Login.vue'),
    },
    {
        path: '/',
        component: Layout,
        redirect: '/index',
        children: [{
                path: 'index',
                name: 'Home',
                meta: {
                    title: '首页',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Home/index.vue'),
            },
            {
                path: 'person',
                name: 'Person',
                meta: {
                    title: '人物'
                },
                component: () =>
                    import ('../pages/Data/Person/index.vue'),
            },
            {
                path: 'person/create',
                name: 'CreatePerson',
                meta: {
                    title: '创建人物',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Person/create.vue'),
            },
            {
                path: 'person/detail',
                name: 'PersonDetail',
                meta: {
                    title: '人物详情',
                    contentScroll: true, // 中间区域滚动
                    backPrevPath: '/person',
                },
                component: () =>
                    import ('../pages/Data/Person/detail.vue')
            },
            {
                path: 'person/edit',
                name: 'PersonEdit',
                meta: {
                    title: '人物详情',
                    homePath: '/person', // 顶级面包屑路径
                    backPrevPath: '/person/detail', // 上一级跳转路径
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Person/edit.vue')
            },
            {
                path: 'person/detail/related',
                name: 'PersonDetailRelated',
                meta: {
                    title: '人物详情',
                    homePath: '/person',
                    backPrevPath: '/person/detail'
                },
                component: () =>
                    import ('../pages/Data/Person/relatedList.vue')
            },
            {
                path: 'company',
                name: 'Company',
                meta: {
                    title: '主体'
                },
                component: () =>
                    import ('../pages/Data/Company/index.vue')
            },
            {
                path: 'company/create',
                name: 'CreateCompany',
                meta: {
                    title: '新增主体',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Company/create.vue')
            },
            {
                path: 'company/detail',
                name: 'CompanyDetail',
                meta: {
                    title: '主体详情',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Data/Company/detail.vue')
            },
            {
                path: 'company/edit',
                name: 'CompanyEdit',
                meta: {
                    title: '主体详情',
                    homePath: '/company', // 顶级面包屑路径
                    backPrevPath: '/company/detail', // 上一级跳转路径
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Company/edit.vue')
            },
            {
                path: 'company/detail/related',
                name: 'CompanyRelated',
                meta: {
                    title: '主体详情',
                    homePath: '/company',
                    backPrevPath: '/company/detail'
                },
                component: () =>
                    import ('../pages/Data/Company/relatedList.vue')
            },
            {
                path: 'brand',
                name: 'brand',
                meta: {
                    title: '品牌'
                },
                component: () =>
                    import ('../pages/Data/Brand/index.vue')
            },
            {
                path: 'brand/create',
                name: 'CreateBrand',
                meta: {
                    title: '新增品牌',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Brand/create.vue'),
            },
            {
                path: 'brand/detail',
                name: 'BrandDetail',
                meta: {
                    title: '品牌详情',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Brand/detail.vue'),
            },
            {
                path: 'brand/detail/report',
                name: 'BrandReport',
                meta: {
                    title: '详情',
                },
                component: () =>
                    import ('../pages/Data/Brand/report.vue')
            },
            {
                path: 'brand/detail/patent',
                name: 'BrandPatent',
                meta: {
                    title: '详情',
                },
                component: () =>
                    import ('../pages/Data/Brand/patent.vue')
            },
            {
                path: 'brand/detail/copyright',
                name: 'BrandCopyright',
                meta: {
                    title: '详情',
                },
                component: () =>
                    import ('../pages/Data/Brand/copyright.vue')
            },
            {
                path: 'brand/detail/coopetition',
                name: 'BrandCoopetition',
                meta: {
                    title: '详情',
                },
                component: () =>
                    import ('../pages/Data/Brand/coopetition.vue')
            },
            {
                path: 'brand/edit',
                name: 'EditBrand',
                meta: {
                    title: '编辑品牌',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Brand/edit.vue'),
            },
            {
                path: 'tags',
                name: 'Tags',
                meta: {
                    title: '标签'
                },
                component: () =>
                    import ('../pages/Tags/index.vue')
            },
            {
                path: 'tags/create',
                name: 'CreateTags',
                meta: {
                    title: '新增标签'
                },
                component: () =>
                    import ('../pages/Tags/create.vue')
            },
            {
                path: 'tags/detail',
                name: 'TagsDetail',
                meta: {
                    title: '标签树详情',
                },
                component: () =>
                    import ('../pages/Tags/detail.vue')
            },
            {
                path: 'tags/edit',
                name: 'TagsEdit',
                meta: {
                    title: '编辑标签树',
                },
                component: () =>
                    import ('../pages/Tags/edit.vue')
            },
            {
                path: 'reports',
                name: 'Reports',
                meta: {
                    title: '报道'
                },
                component: () =>
                    import ('../pages/Data/Reports/index.vue')
            },
            {
                path: 'reports/create',
                name: 'CreateReports',
                meta: {
                    title: '新增报道',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Reports/create.vue'),
            },
            {
                path: 'reports/detail',
                name: 'ReportDetail',
                meta: {
                    title: '报道详情',
                    contentScroll: true, // 中间区域滚动
                    backPrevPath: '/reports',
                },
                component: () =>
                    import ('../pages/Data/Reports/detail.vue')
            },
            {
                path: 'reports/edit',
                name: 'ReportsEdit',
                meta: {
                    title: '报道详情',
                    homePath: '/reports', // 顶级面包屑路径
                    backPrevPath: '/reports/detail', // 上一级跳转路径
                    contentScroll: true,
                    subTitle: '编辑报道信息'
                },
                component: () =>
                    import ('../pages/Data/Reports/edit.vue')
            },
            {
                path: 'patent',
                name: 'Patent',
                meta: {
                    title: '专利'
                },
                component: () =>
                    import ('../pages/Data/Patent/index.vue')
            },
            {
                path: 'patent/create',
                name: 'CreatePatent',
                meta: {
                    title: '新增专利',
                    contentScroll: true, // 中间区域滚动
                },
                component: () =>
                    import ('../pages/Data/Patent/create.vue'),
            },
            {
                path: 'patent/detail',
                name: 'PatentDetail',
                meta: {
                    title: '专利详情',
                    contentScroll: true, // 中间区域滚动
                    backPrevPath: '/patent',
                },
                component: () =>
                    import ('../pages/Data/Patent/detail.vue')
            },
            {
                path: 'patent/edit',
                name: 'PatentEdit',
                meta: {
                    title: '专利详情',
                    homePath: '/patent', // 顶级面包屑路径
                    backPrevPath: '/patent/detail', // 上一级跳转路径
                    contentScroll: true,
                    subTitle: '编辑专利信息'
                },
                component: () =>
                    import ('../pages/Data/Patent/edit.vue')
            },
            {
                path: 'event/invest',
                name: 'InvestEvent',
                meta: {
                    title: '投资事件',
                },
                component: () =>
                    import ('../pages/Data/Event/Invest/index.vue')
            },
            {
                path: 'event/invest/create',
                name: 'InvestEventCreate',
                meta: {
                    title: '新增投资事件',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Event/Invest/create.vue')
            },
            {
                path: 'event/invest/detail',
                name: 'InvestEventDetail',
                meta: {
                    title: '投资事件详情',
                    contentScroll: true,

                },
                component: () =>
                    import ('../pages/Data/Event/Invest/detail.vue')
            },
            {
                path: 'event/invest/edit',
                name: 'InvestEventEdit',
                meta: {
                    title: '编辑投资事件',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Event/Invest/edit.vue')
            },
            {
                path: 'event/invest/list',
                name: 'InvestEventList',
                meta: {
                    title: '相关报道'
                },
                component: () =>
                    import ('../pages/Data/Event/Invest/investList.vue')
            },
            {
                path: 'event/exit',
                name: 'ExitEvent',
                meta: {
                    title: '退出事件',
                },
                component: () =>
                    import ('../pages/Data/Event/Exit/index.vue')
            },
            {
                path: 'organization/investment',
                name: 'OrganizationInvestment',
                meta: {
                    title: '投资机构',
                    backPrevPath: '/organization/investment'
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/index.vue')
            },
            {
                path: 'organization/investment/create',
                name: 'OrganizationCreateInvestment',
                meta: {
                    title: '投资机构',
                    subTitle: '新增投资机构',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment'
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/create.vue')
            },
            {
                path: 'organization/investment/detail',
                name: 'OrganizationInvestmentDetail',
                meta: {
                    title: '投资机构',
                    subTitle: '投资机构详情',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/detail.vue')
            },
            {
                path: 'organization/investment/edit',
                name: 'OrganizationEditInvestment',
                meta: {
                    title: '投资机构',
                    subTitle: '投资机构详情',
                    // nowTitle: '编辑基本信息',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment',
                    subBackPath: '/organization/investment/detail',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/edit.vue')
            },
            {
                path: 'organization/investment/detail/related',
                name: 'OrganizationInvestmentRelated',
                meta: {
                    title: '投资机构',
                    subTitle: '投资机构详情',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment/detail',
                    subBackPath: '/organization/investment/detail'
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/relatedList.vue')
            },
            {
                path: 'organization/investment/detail/fund/create',
                name: 'OrganizationInvestmentCreateFund',
                meta: {
                    title: '投资机构',
                    subTitle: '投资机构详情',
                    nowTitle: '新增基金',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment/detail',
                    subBackPath: '/organization/investment/detail'
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/createFund.vue')
            },
            {
                path: 'organization/investment/detail/fund/detail',
                name: 'OrganizationInvestmentFundDetail',
                meta: {
                    title: '投资机构',
                    subTitle: '投资机构详情',
                    nowTitle: '基金详情',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment/detail',
                    subBackPath: '/organization/investment/detail'
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/fundDetail.vue')
            },
            {
                path: 'organization/investment/detail/fund/edit',
                name: 'OrganizationInvestmentEditFund',
                meta: {
                    title: '投资机构',
                    subTitle: '投资机构详情',
                    nowTitle: '编辑基金',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/investment/detail',
                    subBackPath: '/organization/investment/detail'
                },
                component: () =>
                    import ('../pages/Data/Organization/Investment/fundEdit.vue')
            },
            {
                path: 'organization/school',
                name: 'OrganizationSchool',
                meta: {
                    title: '高校院所',
                    backPrevPath: '/organization/investment',
                },
                component: () =>
                    import ('../pages/Data/Organization/School/index.vue')
            },
            {
                path: 'organization/school/create',
                name: 'OrganizationCreateSchool',
                meta: {
                    title: '高校院所',
                    subTitle: '创建高校院所',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/school'
                },
                component: () =>
                    import ('../pages/Data/Organization/School/create.vue')
            },
            {
                path: 'organization/school/detail',
                name: 'OrganizationSchoolDetail',
                meta: {
                    title: '高校院所',
                    subTitle: '高校院所详情',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/school',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Data/Organization/School/detail.vue')
            },
            {
                path: 'organization/school/edit',
                name: 'OrganizationEditSchool',
                meta: {
                    title: '高校院所',
                    subTitle: '高校院所详情',
                    nowTitle: '编辑基本信息',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/school',
                    subBackPath: '/organization/school/detail'
                },
                component: () =>
                    import ('../pages/Data/Organization/School/edit.vue')
            },
            {
                path: 'organization/social',
                name: 'OrganizationSocial',
                meta: {
                    title: '社会组织',
                    backPrevPath: '/organization/investment',
                },
                component: () =>
                    import ('../pages/Data/Organization/Social/index.vue')
            },
            {
                path: 'organization/social/create',
                name: 'OrganizationCreateSocial',
                meta: {
                    title: '社会组织',
                    subTitle: '创建社会组织',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/social'
                },
                component: () =>
                    import ('../pages/Data/Organization/Social/create.vue')
            },
            {
                path: 'organization/social/detail',
                name: 'OrganizationSocialDetail',
                meta: {
                    title: '社会组织',
                    subTitle: '社会组织详情',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/social'
                },
                component: () =>
                    import ('../pages/Data/Organization/Social/detail.vue')
            },
            {
                path: 'organization/social/edit',
                name: 'OrganizationEditSocial',
                meta: {
                    title: '社会组织',
                    subTitle: '社会组织详情',
                    nowTitle: '编辑基本信息',
                    homePath: '/organization/investment',
                    backPrevPath: '/organization/social',
                    subBackPath: '/organization/social/detail'
                },
                component: () =>
                    import ('../pages/Data/Organization/Social/edit.vue')
            },
            {
                path: 'event/exit/create',
                name: 'ExitEventCreate',
                meta: {
                    title: '新增退出事件',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Event/Exit/create.vue')
            },
            {
                path: 'event/exit/edit',
                name: 'ExitEventEdit',
                meta: {
                    title: '编辑退出事件',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Event/Exit/edit.vue')
            },
            {
                path: 'event/exit/detail',
                name: 'ExitEventDetail',
                meta: {
                    title: '退出事件详情',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Event/Exit/detail.vue')
            },
            {
                path: 'event/exit/list',
                name: 'ExitEventList',
                meta: {
                    title: '相关报道'
                },
                component: () =>
                    import ('../pages/Data/Event/Exit/exitList.vue')
            },
            {
                path: 'literature',
                name: 'literature',
                meta: {
                    title: '文献',
                },
                component: () =>
                    import ('../pages/Data/Literature/index.vue')
            },
            {
                path: 'literature/create',
                name: 'literatureCreate',
                meta: {
                    title: '新增文献',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Data/Literature/create.vue')
            },
            {
                path: 'literature/edit',
                name: 'literatureEdit',
                meta: {
                    title: '编辑文献',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Data/Literature/edit.vue')
            },
            {
                path: 'literature/detail',
                name: 'literatureDetail',
                meta: {
                    title: '文献详情',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Literature/detail.vue')
            },
            {
                path: 'messages',
                name: 'Messages',
                meta: {
                    title: '消息'
                },
                component: () =>
                    import ('../pages/Message/index.vue')
            },
            {
                path: 'messages/create',
                name: 'createMessage',
                meta: {
                    title: '消息推送',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Message/create.vue')
            },
            {
                path: 'messages/detail',
                name: 'messageDetail',
                meta: {
                    title: '消息推送'
                },
                component: () =>
                    import ('../pages/Message/detail.vue')
            },
            {
                path: 'users',
                name: 'users',
                meta: {
                    title: '用户'
                },
                component: () =>
                    import ('../pages/User/index.vue')
            },
            {
                path: 'users/create',
                name: 'createUsers',
                meta: {
                    title: '新增用户'
                },
                component: () =>
                    import ('../pages/User/create.vue')
            },
            {
                path: 'users/edit',
                name: 'editUsers',
                meta: {
                    title: '编辑用户'
                },
                component: () =>
                    import ('../pages/User/edit.vue')
            },
            {
                path: 'operating',
                name: 'operating',
                meta: {
                    title: '运营'
                },
                component: () =>
                    import ('../pages/Operation/index.vue')
            },
            {
                path: 'operating/create',
                name: 'createOperating',
                meta: {
                    title: '新增运营人员'
                },
                component: () =>
                    import ('../pages/Operation/create.vue')
            },
            {
                path: 'operating/edit',
                name: 'editOperating',
                meta: {
                    title: '编辑运营人员'
                },
                component: () =>
                    import ('../pages/Operation/edit.vue')
            },
            {
                path: 'hotnews',
                name: 'hotnews',
                meta: {
                    title: '热点'
                },
                component: () =>
                    import ('../pages/HotNews/index.vue')
            },
            {
                path: 'hotnews/detail',
                name: 'hotnewsDetail',
                meta: {
                    title: '热点详情'
                },
                component: () =>
                    import ('../pages/HotNews/detail.vue')
            },
            {
                path: 'hotnews/create',
                name: 'createHotnews',
                meta: {
                    title: '新增热点'
                },
                component: () =>
                    import ('../pages/HotNews/create.vue')
            },
            {
                path: 'hotnews/edit',
                name: 'editHotnews',
                meta: {
                    title: '编辑热点'
                },
                component: () =>
                    import ('../pages/HotNews/edit.vue')
            },

            {
                path: 'claimUnit',
                name: 'claimUnit',
                meta: {
                    title: '单位数据反馈'
                },
                component: () =>
                    import ('../pages/Claim/unit/index.vue')
            },
            {
                path: 'claimPer',
                name: 'claimPer',
                meta: {
                    title: '申请成为学者'
                },
                component: () =>
                    import ('../pages/Claim/person/index.vue')
            },

            {
                path: 'auditCom',
                name: 'auditCom',
                meta: {
                    title: '主体'
                },
                component: () =>
                    import ('../pages/Audit/com/index.vue')
            },
            {
                path: 'auditCom/detail',
                name: 'auditComDetail',
                meta: {
                    title: '主体详情',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Audit/com/detail.vue')
            },
            {
                path: 'auditPer',
                name: 'auditPer',
                meta: {
                    title: '人物'
                },
                component: () =>
                    import ('../pages/Audit/per/index.vue')
            },
            {
                path: 'auditPer/detail',
                name: 'auditPerDetail',
                meta: {
                    title: '人物详情',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Audit/per/detail.vue')
            },
            {
                path: 'auditOrg',
                name: 'auditOrg',
                meta: {
                    title: '机构'
                },
                component: () =>
                    import ('../pages/Audit/org/index.vue')
            },
            {
                path: 'auditOrg/detail',
                name: 'auditOrgDetail',
                meta: {
                    title: '机构详情',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Audit/org/detail.vue')
            },
            {
                path: 'feedbacks',
                name: 'feedbacks',
                meta: {
                    title: '反馈'
                },
                component: () =>
                    import ('../pages/Feedback/index.vue')
            },
            {
                path: 'feedbacks/detail',
                name: 'feedbacksDetail',
                meta: {
                    title: '反馈详情',
                    contentScroll: true
                },
                component: () =>
                    import ('../pages/Feedback/detail.vue')
            },
            {
                path: 'books',
                name: 'books',
                meta: {
                    title: '著作',
                },
                component: () =>
                    import ('../pages/Data/Book/index.vue')
            },
            {
                path: 'books/create',
                name: 'booksCreate',
                meta: {
                    title: '新增著作',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Book/create.vue')
            },
            {
                path: 'books/edit',
                name: 'booksEdit',
                meta: {
                    title: '编辑著作',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Book/edit.vue')
            },
            {
                path: 'books/detail',
                name: 'booksDetail',
                meta: {
                    title: '著作详情',
                    contentScroll: true,
                },
                component: () =>
                    import ('../pages/Data/Book/detail.vue')
            },
            {
                path: 'researchGroup',
                name: 'ResearchGroup',
                meta: {
                    title: '期刊课题组列表',
                },
                component: () =>
                    import ('../pages/Data/ResearchGroup/index.vue')
            },
            {
                path: 'researchEdit',
                name: 'ResearchEdit',
                meta: {
                    title: '期刊课题组列表',
                },
                component: () =>
                    import ('../pages/Data/ResearchGroup/edit.vue')
            },

            {
                path: 'dtiAlbum',
                name: 'dtiAlbum',
                meta: {
                    title: '专辑列表',
                },
                component: () => import ('../pages/Dti/Album/list.vue')
            },
            {
                path: 'dtiAlbum/create',
                name: 'dtiAlbumCreate',
                meta: {
                    title: '新增专辑'
                },
                component: () => import ('../pages/Dti/Album/create.vue')
            },
            {
                path: 'dtiAlbum/detail/:id',
                name: 'dtiAlbumDetail',
                meta: {
                    title: '专辑详情'
                },
                component: () => import ('../pages/Dti/Album/detail.vue')
            },
            {
                path: 'dtiAlbum/edit/:id',
                name: 'dtiAlbumEdit',
                meta: {
                    title: '编辑专辑',
                },
                component: () => import('../pages/Dti/Album/edit.vue')
            },
            {
                path: 'dtiUser',
                name: 'dtiUser',
                meta: {
                    title: '用户列表',
                },
                component: () => import ('../pages/Dti/User/list.vue')
            },
            {
                path: 'homeConfigure',
                name: 'homeConfigure',
                meta: {
                    title: '首页配置',
                },
                component: () => import ('../pages/Dti/Home/configure.vue')
            },
            {
                path: 'reportsList',
                name: 'reportsList',
                meta: {
                    title: '报告列表',
                },
                component: () => import ('../pages/Dti/Reports/list.vue')
            },
            {
                path: 'reportsList/detail/:id',
                name: 'reportsDetail',
                meta: {
                    title: '报告详情'
                },
                component: () => import ('../pages/Dti/Reports/detail.vue')
            },
            {
                path: 'reportsList/edit/:id?',
                name: 'reportsListEdit',
                meta: {
                    title: '报告编辑',
                },
                component: () => import('../pages/Dti/Reports/edit.vue')
            },
            {
                path: 'apply',
                name: 'Apply',
                meta: {
                    title: '报名列表',
                },
                component: () => import ('../pages/Dti/Apply/index.vue')
            },
            {
                path: 'activityDetail',
                name: 'ActivityDetail',
                meta: {
                    title: '报名列表',
                },
                component: () => import ('../pages/Dti/Apply/activityDetail.vue')
            },
            {
                path: 'dtiApply',
                name: 'dtiApply',
                meta: {
                    title: '报名列表',
                },
                component: () => import ('../pages/Dti/Apply/list.vue')
            },
            {
                path: 'innovaApply',
                name: 'InnovaApply',
                meta: {
                    title: '报名列表',
                },
                component: () => import ('../pages/Dti/Apply/innovationList.vue')
            },
            {
                path: 'innovaApply/detail/:id',
                name: 'InnovaDetail',
                meta: {
                    title: '报名详情',
                },
                component: () => import ('../pages/Dti/Apply/innovaDetail.vue')
            },
            {
                path: 'dtiApply/detail/:id',
                name: 'applyDetail',
                meta: {
                    title: '报告详情'
                },
                component: () => import ('../pages/Dti/Apply/detail.vue')
            },
            {
                path: 'drafts',
                name: 'drafts',
                meta: {
                    title: '草稿箱',
                },
                component: () => import('../pages/Dti/Reports/drafts.vue')
            },
            {
                path: 'paperUser',
                name: 'paperUser',
                meta: {
                    title: '用户列表',
                },
                component: () => import ('../pages/Paper/User/list.vue')
            },
            {
                path: 'Periodical',
                name: 'Periodical',
                meta: {
                    title: '期刊列表',
                },
                component: () => import ('../pages/Paper/Periodical/list.vue')
            },
            {
                path: 'Periodical/create',
                name: 'PeriodicalCreate',
                meta: {
                    title: '新增期刊'
                },
                component: () => import ('../pages/Paper/Periodical/create.vue')
            },
            {
                path: 'Periodical/detail/:id',
                name: 'PeriodicalDetail',
                meta: {
                    title: '期刊详情'
                },
                component: () => import ('../pages/Paper/Periodical/detail.vue')
            },
            {
                path: 'Periodical/edit/:id',
                name: 'PeriodicalEdit',
                meta: {
                    title: '编辑期刊',
                },
                component: () => import('../pages/Paper/Periodical/edit.vue')
            },
            {
                path: 'Literatrue',
                name: 'Literatrue',
                meta: {
                    title: '文献列表',
                },
                component: () => import ('../pages/Paper/Literatrue/list.vue')
            },
            {
                path: 'Literatrue/create',
                name: 'LiteratrueCreate',
                meta: {
                    title: '新增文献'
                },
                component: () => import ('../pages/Paper/Literatrue/create.vue')
            },
            {
                path: 'Literatrue/detail/:id',
                name: 'LiteratrueDetail',
                meta: {
                    title: '文献详情'
                },
                component: () => import ('../pages/Paper/Literatrue/detail.vue')
            },
            {
                path: 'Literatrue/edit/:id',
                name: 'LiteratrueEdit',
                meta: {
                    title: '编辑文献',
                },
                component: () => import('../pages/Paper/Literatrue/edit.vue')
            },
            // {
            //     path: 'jobList',
            //     name: 'JobList',
            //     meta: {
            //         title: '职位列表',
            //     },
            //     component: () => import ('../pages/LoyiGo/Job/index.vue')
            // },
            // {
            //     path: 'jobBackground',
            //     name: 'JobBackground',
            //     meta: {
            //         title: '职位分类封面',
            //     },
            //     component: () => import ('../pages/LoyiGo/JobBackground/index.vue')
            // }
        ]
    }
]
export default routes;
