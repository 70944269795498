<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-album-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-title">创建专辑</span>
              </div>
            </template>
            <div>
              <el-form
                ref="formRef"
                :model="createParams"
                :rules="createRule"
                label-width="120px"
                class="form-box mg-t-24"
              >
                <el-form-item label="名称" prop="title">
                  <el-input
                    v-model="createParams.title"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="数据类别" prop="type">
                  <el-select v-model="createParams.type">
                    <el-option
                      v-for="item in dataTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="简介" prop="summary">
                  <el-input
                    v-model="createParams.summary"
                    maxlength="100"
                    show-word-limit
                    type="textarea"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="顶部banner" prop="banner">
                  <div>
                    <el-upload
                      class="avatar-uploader"
                      :show-file-list="false"
                      :action="`${baseUrl}admin/upload/image`"
                      :on-success="avatarUploadSuccess"
                      :headers="{ Authorization:'Bearer ' + adminToken }"
                      name="image"
                      accept="image/jpg,image/jpeg"
                      :before-upload="
                        (file) => {
                          return handleBeforeUpload(file);
                        }
                      "
                    >
                      <img
                        v-if="createParams.banner"
                        :src="createParams.banner"
                        class="avatar"
                      />
                      <el-icon v-else class="avatar-uploader-icon"
                        ><plus
                      /></el-icon>
                    </el-upload>
                    <p>只支持.jpg 格式，建议尺寸750*440,大小不超过5M</p>
                  </div>
                </el-form-item>
                <el-form-item label="行业领域" prop="domain">
                  <el-tag
                    v-for="tag in createParams.domain"
                    :key="tag"
                    class="common-tag"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    v-if="inputInstance.inputVisible"
                    ref="InputRef"
                    v-model="inputInstance.inputValue"
                    style="width: 80px"
                    size="small"
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                  >
                  </el-input>
                  <el-button
                    v-else
                    class="button-new-tag ml-1"
                    size="small"
                    @click="showInput"
                  >
                    + 新标签
                  </el-button>
                </el-form-item>
                <el-form-item label="发布时间" prop="push_time">
                  <el-date-picker
                    v-model="createParams.push_time"
                    style="width: 400px"
                    type="datetime"
                    placeholder="选择时间"
                    value-format="YYYY-MM-DD HH:mm"
                    format="YYYY-MM-DD HH:mm"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="首页推荐" prop="recommend_type">
                  <el-radio-group v-model="createParams.recommend_type">
                    <el-radio :label="2">是</el-radio>
                    <el-radio :label="1">否</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="推荐理由" prop="reason">
                  <el-input
                    v-model="createParams.reason"
                    maxlength="120"
                    show-word-limit
                    type="textarea"
                    placeholder="请输入推荐理由"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="submit-box pd-16 mg-t-12">
            <el-row justify="end">
              <el-col :span="4">
                <el-button size="small" plain @click="handleCancel"
                  >取消</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="handleSave(formRef)"
                  >下一步</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  toRefs,
  reactive,
  nextTick,
  getCurrentInstance,
  onMounted,
  ref,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import moment from "moment";
export default {
  components: {
    Plus,
    Header,
    BreadCrumb,
  },
  setup() {
    const currentCtx = getCurrentInstance().ctx;
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      breadList: [
        {
          name: "专辑",
          path: {
            name: "dtiAlbum",
          },
        },
        {
          name: "编辑专辑",
        },
      ],
      adminToken: localStorage.getItem("adminToken"),
      inputInstance: {
        inputVisible: false,
        inputValue: "",
      },
      baseUrl: window.env.baseUrl,
      createParams: {
        title: "",
        type: 1,
        summary: "",
        banner: "",
        domain: [],
        push_time: "",
        recommend_type: "",
        reason: "",
      },
      createRule: {
        title: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择数据类别",
            trigger: "change",
          },
        ],
        banner: [
          {
            required: true,
            message: "请上传banner图",
          },
        ],
        recommend_type: [
          {
            required: true,
            message: "请选择是否首页推荐",
            trigger: "change",
          },
        ],
      },
      dataTypeList: [
        {
          label: "品牌",
          value: 1,
        },
        {
          label: "人物",
          value: 2,
        },
      ],
    });

    const formRef = ref();
    onMounted(() => {
      getInfo();
    });
    const handleBeforeUpload = (file) => {
      console.log(file);
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        MessageTips.error("上传文件大小不能超过 5MB!");
      }
      return isLt5M;
    };
    // 上传图片
    const avatarUploadSuccess = (e) => {
      console.log(e, "kkkk");
      state.createParams.banner = e.data.url;
    };

    // 删除标签
    const handleClose = (tag) => {
      state.createParams.domain.splice(
        state.createParams.domain.indexOf(tag),
        1
      );
    };

    // 增加标签
    const showInput = () => {
      state.inputInstance.inputVisible = true;
      // nextTick(() => {
      //   let ipt = currentCtx.$refs.InputRef;
      //   ipt.focus();
      // });
    };

    // 确认添加标签
    const handleInputConfirm = () => {
      debugger
      let {
        inputInstance,
        createParams: { domain },
      } = state;
      if (inputInstance.inputValue) {
        domain.push(inputInstance.inputValue);
      }
      inputInstance.inputVisible = false;
      inputInstance.inputValue = "";
    };

    // 获取详情
    const getInfo = async () => {
      const res = await Ajax.POST({
        url: "admin/album/details/" + route.params.id,
        data: state.queryData,
      });
      if (res.data.code == 200) {
        state.createParams = res.data.data;
        state.createParams.domain = res.data.data.domain
          ? res.data.data.domain.split(",")
          : "";
        console.log(
          moment(res.data.data.push_time * 1000).format("YYYY-MM-DD HH:mm")
        );
        state.createParams.push_time = pushDate(res.data.data.push_time * 1000);
      }
    };

    // 保存
    const handleSave = (formEl) => {
      formEl.validate(async (valid) => {
        if (valid) {
          console.log(state.createParams.push_time);
          const res = await Ajax.POST({
            url: `admin/album/update/${route.params.id}`,
            data: {
              ...state.createParams,
              domain: state.createParams.domain
                ? state.createParams.domain.join(",")
                : "",
              push_time: state.createParams.push_time,
            },
          });
          if (res.data.code == 200) {
            MessageTips.success("编辑成功");
            router.push({
              name: "dtiAlbumDetail",
              params: { id: state.createParams.id, tabIndex: 2 },
            });
          }
        } else {
          return;
        }
      });
    };
    const pushDate = (row) => {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row > 0) {
        var time = new Date(row);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y +
          "-" +
          add0(m) +
          "-" +
          add0(d) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    };

    // 取消
    const handleCancel = () => {
      formRef.value.resetFields();
    };

    return {
      ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      handleSave,
      handleCancel,
      avatarUploadSuccess,
      formRef,
      pushDate,
      handleBeforeUpload,
    };
  },
};
</script>

<style lang="less" scoped>
.create-album-container {
  height: 100%;
  overflow-y: auto;
  width: 100%;
  .form-box {
    width: 50%;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
    .avatar {
      width: 108px;
      height: 108px;
    }
  }

  .common-tag {
    margin-right: 8px;
  }
  .submit-box {
    background-color: #fff;
  }
}
</style>
