<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="person-container">
                    <SearchFilterBar :statusLabels="statusLabels" :batchShow="true" :moduleShow="true" :batchUrl="'admin/company/import'" :downUrl="'https://image.sciphi.cn/sciphi/import_temp/company_temp.xlsx '" @statusChange="statusChange" @addClick="handleClick" @searchClick="handleSearch" />
                    <div class="table-con mg-t-12">
                        <el-scrollbar>
                            <div class="table-container pd-32">
                                <el-table v-loading="tableLoading" :data="tableData" border :max-height="680">
                                    <el-table-column width="90" prop="id" label="主体ID" />
                                    <el-table-column prop="register_name" label="主体名称" />
                                    <el-table-column prop="country_name" label="国家" />
                                    <el-table-column prop="province_name" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province_name }}{{ scope.row.city_name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="120" prop="establish_time" label="成立时间" />
                                    <!-- <el-table-column prop="trade_arr" label="行业领域" /> -->
                                    <el-table-column prop="update_time" label="最近编辑时间" />
                                    <el-table-column prop="publish_time" label="发布时间" />
                                    <el-table-column prop="status" width="80" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 3">未发布</span>
                                            <!-- <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span> -->
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="120">
                                        <template #default="scope">
                                            <el-link type="primary" class="mg-r-12" @click="toDetail(scope.row.id)">详情</el-link>
                                            <el-link type="primary" class="mg-r-12" v-if="scope.row.status === 1" @click="cooperation(scope.row.id, 4)">撤回</el-link>
                                            <el-link type="primary" class="mg-r-12" v-else-if="[3, 4].includes(scope.row.status)" @click="cooperation(scope.row.id, 1)">发布</el-link>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="table-pagination-row mg-t-24">
                                    <el-pagination
                                        background
                                        :currentPage="paginationData.current"
                                        :page-sizes="paginationData.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="paginationData.total"
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../components/SearchFilterBar/index.vue'
import { ref, onMounted } from 'vue'
import {useRouter} from 'vue-router';
import { Ajax, MessageTips } from '../../../utils'
export default {
    components: {
        SearchFilterBar,
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '主体'
            }
        ])
        const router = useRouter();
        const searchVal = ref('')
        const statusLabelsRef = [
            {
                name: '全部',
                value: 0,
            },
            {
                name: '已发布',
                value: 1,
            },
            {
                name: '未发布',
                value: 3,
            },
            // {
            //     name: '待完善',
            //     value: 2,
            // },
            {
                name: '已撤回',
                value: 4,
            }
        ];
        const companyStatus = ref(0)
        // 搜索
        const statusChange = status => {
            companyStatus.value = status;
            paginationDataRef.value.current = 1;
            getList();
        }
        // 查询
        const handleSearch = value => {
            searchVal.value = value.searchValue;
            getList();
        }
        // 新增
        const handleClick = (e) => {
            router.push({
                name: 'CreateCompany'
            })
        };

        const toDetail = id => {
            router.push({
                name: 'CompanyDetail',
                query: {
                    id
                }
            })
        }

        const cooperation = (id, status) => {
            Ajax.POST({
                url: '/admin/company/status',
                data: {
                    id,
                    status
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('操作成功')
                    getList();
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }

        // 表格数据
        const tableDataRef = ref([])
        const tableLoadingRef = ref(false)
        // 页码
        let paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            page_size: 10,
            total: 0
        });
        const handleSizeChange = e => {
            paginationDataRef.value.page_size = e;
            getList()
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList()
        }
        const getList = () => {
            tableLoadingRef.value = true;
            Ajax.GET({
                url: '/admin/company',
                params: {
                    page: paginationDataRef.value.current,
                    page_size: paginationDataRef.value.page_size,
                    name: searchVal.value,
                    status: companyStatus.value
                }
            }).then(res => {
                tableLoadingRef.value = false;
                if(res.data.code === 200) {
                    tableDataRef.value = res.data.data.list;
                    paginationDataRef.value.total = res.data.data.page_info.total;
                    paginationDataRef.value.current = res.data.data.page_info.page;
                }
            })
        }
        onMounted(() => {
            getList();
            paginationDataRef.value.pageSize = [10, 20, 50]
        })
        return {
            breadLists: breadListsRef,
            statusLabels: statusLabelsRef,
            statusChange,
            handleClick,
            handleSearch,
            tableLoading: tableLoadingRef,
            tableData: tableDataRef,
            paginationData: paginationDataRef,
            handleSizeChange,
            handleCurrentChange,
            toDetail,
            cooperation
        }
    }
};
</script>

<style lang="less" scoped>
    .person-container {
        height: 100%;
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
        .table-con {
            height: 100%;
            max-height: calc(100% - 66px - 12px);
            background-color: #fff;
        }
        .table-container {
            padding-bottom: 16px;
        }
    }
</style>