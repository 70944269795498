<template>
    <div class="login-container">
        <div class="login-title-row">
            <img class="login-img" src="../../assets/images/logo.png" alt="">
            <h1 class="login-title">生辉智库管理后台 <span v-if="baseUrl=='http://insights-api-test.sciphi.cn/'" style="position: absolute;width: 100px;">(测试)</span></h1>
        </div>
        <div class="login-content">
            <el-tabs v-model="activeTab" @tab-click="tabChange">
                <!-- <el-tab-pane disabled label="账户密码登录" name="account"> -->
                <el-tab-pane label="账户密码登录" name="account">
                    <el-form class="login-form" ref="accountForm" :model="accountLogin" :rules="accountRule">
                        <el-form-item prop="account">
                            <el-input v-model="accountLogin.account" placeholder="邮箱或手机号" :prefix-icon="User" />
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="accountLogin.password" placeholder="密码" :prefix-icon="User" show-password />
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="accountAuto">自动登录</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="login-btn" type="primary" @click="handleAccountLogin">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="手机号登录" name="mobile">
                    <el-form ref="mobileForm" :model="loginAccount" :rules="mobileRule">
                        <el-form-item prop="phone">
                            <el-input placeholder="手机号" v-model="loginAccount.phone" :prefix-icon="User" />
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-row >
                                <el-col :span="16">
                                    <el-input  placeholder="验证码" v-model="loginAccount.code" :prefix-icon="Message" ></el-input>
                                </el-col>
                                <el-col class="btn-get-code" :span="3" :offset="5">
                                    <el-button class="btn-getcode" style="width: 112px" plain type="primary" :disabled="getCodeStatus" @click="getCode">{{codeText}}</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="phoneAuto">自动登录</el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="login-btn" type="primary" @click="handlePhoneLogin">登录</el-button>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
            </el-tabs>            
        </div>
    </div>
</template>

<script>
import {ref, getCurrentInstance, onBeforeMount,nextTick,reactive} from 'vue';
import { User, Message, SuccessFilled } from '@element-plus/icons-vue'
import { Ajax, MessageTips, Aes } from '../../utils'
import { useRouter } from 'vue-router'
import moment from 'moment'
export default {
    components: {
        SuccessFilled
    },
    setup() {
        onBeforeMount(() => {
            backfill()
        })
        const backfill = (() => {
            if(localStorage['adminAutoLogin']) {
                let obj = JSON.parse(Aes.decrypt(localStorage['adminAutoLogin']))
                const diff = moment(new Date()).diff(moment(obj.time).add(7, 'day'), 'seconds')
                if(diff >= 0) {
                    localStorage['adminAutoLogin'] = ''
                }else {
                    accountLoginRef.value.account = obj.account ? obj.account : ''
                    accountLoginRef.value.password = obj.password ? obj.password : ''
                    loginAccountRef.value.phone = obj.phone ? obj.phone : ''
                    loginAccountRef.value.code=obj.captcha?obj.captcha:''
                    accountAutoRef.value = obj.account ? true : false
                    phoneAutoRef.value = obj.phone ? true : false
                }
            }
        })
        const state = reactive({
            baseUrl: window.env.baseUrl,
        });
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        // 账号密码登录
        const accountAutoRef = ref(false)
        const accountLoginRef = ref({
            account: '',
            password: ''
        })
        const accountRule = {
            account: [
                {
                    required: true,
                    message: '请输入邮箱或手机号',
                    trigger: 'blur'
                }
            ],
            password: [
                {
                    required: true,
                    message: '请输入密码',
                    trigger: 'blur'
                },
            ],
        }
        const tabChange = () => {
            if(activeTabRef.value == 'account') {
                proxy.$nextTick(() => {
                    proxy.$refs['accountForm'].resetFields()
                })
            }else {
                proxy.$nextTick(() => {
                    proxy.$refs['mobileForm'].resetFields()
                })
            }
            backfill()
        }

        const activeTabRef = ref('mobile')
        const handleAccountLogin = async () => {
            proxy.$refs['accountForm'].validate(async valid => {
                if(valid) {
                    const loginRes = await Ajax.POST({
                        url: '/admin/login',
                        data: {
                            type: 1,
                            account: accountLoginRef.value.account,
                            password: accountLoginRef.value.password,
                            day:7
                        }
                    })
                    if(loginRes.data.code === 200 ) {
                        // Ajax.GET({
                        //     url: '/admin/region'
                        // }).then(res => {
                        //     if(res.data.code === 200) {
                        //         localStorage.setItem('adminRegions', JSON.stringify(res.data.data))
                        //     }
                        // })
                        MessageTips.success('登录成功')
                        localStorage.setItem('adminToken', loginRes.data.data.authorization)
                        localStorage.setItem('adminUserInfo', JSON.stringify(loginRes.data.data.user_info))
                        let obj = {
                            account: accountAutoRef.value ? accountLoginRef.value.account : '',
                            password: accountAutoRef.value ? accountLoginRef.value.password : '',
                            phone: phoneAutoRef.value ? loginAccountRef.value.phone : '',
                            captcha:phoneAutoRef.value?loginAccountRef.value.code:'',
                            time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                        }
                        localStorage['adminAutoLogin'] = Aes.encrypt(JSON.stringify(obj))
                        router.push('/')
                        await nextTick().then(()=>{
                            Ajax.GET({
                                url: '/admin/region'
                            }).then(res => {
                                if(res.data.code === 200) {
                                    localStorage.setItem('adminRegions', JSON.stringify(res.data.data))
                                }
                            })
                        })
                    } else {
                        MessageTips.error(loginRes.data.msg)
                    }
                }
            })
        }

        // 手机号码登录
        const phoneAutoRef = ref(false)
        const loginAccountRef = ref({
            phone: '',
            code: ''
        })
        const checkphone = (rule, value, callback) => {
            const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
            if(!reg.test(loginAccountRef.value.phone)){
                callback(new Error("请输入正确手机号"))
            }else {
                callback()
            }
        }
        const mobileRule = {
            phone: [
                {
                    required: true,
                    message: '请输入手机号',
                    trigger: 'blur'
                },
                { validator: checkphone, trigger: 'blur' },
            ],
            code: [
                {
                    required: true,
                    message: '请输入验证码',
                    trigger: 'blur'
                },
            ],
        }
        
        const codeText = ref('获取验证码')
        const getCodeStatus = ref(false)
        let timer = null
        const getCode = () => {
            // if(!loginAccountRef.value.phone) {
            //     MessageTips.warning('请填写完整信息')
            //     return ;
            // }
            proxy.$refs['mobileForm'].validateField('phone', (valid) => {
                if(!valid) {
                    Ajax.POST({
                    url: '/admin/captcha',
                    data: {
                        phone: loginAccountRef.value.phone
                    }
                }).then(res => {
                    if(res.data.code === 200) {
                        MessageTips.success('发送成功')
                        let time = 60
                        timer = setInterval(()=>{
                            time --
                            if(time == 0){
                                clearInterval(timer)
                                getCodeStatus.value = false
                                codeText.value = '获取验证码'
                            }else {
                                getCodeStatus.value = true
                                codeText.value = time + 's'
                            }
                        },1000)
                    }else {
                        MessageTips.error(res.data.msg)
                    }
                })
                }
            })
        }
        const handlePhoneLogin = async () => {
            proxy.$refs['mobileForm'].validate(async valid => {
                if(valid) {
                    const loginRes = await Ajax.POST({
                        url: '/admin/login',
                        data: {
                            type: 2,
                            phone: loginAccountRef.value.phone,
                            captcha: loginAccountRef.value.code,
                            day:7
                        }
                    })
                    console.log(loginRes, 'loginRes')
                    if(loginRes.data.code === 200) {
                        MessageTips.success('登录成功')
                        router.push('/')
                        localStorage.setItem('adminToken', loginRes.data.data.authorization)
                        localStorage.setItem('adminUserInfo', JSON.stringify(loginRes.data.data.user_info))
                        let obj = {
                            account: accountAutoRef.value ? accountLoginRef.value.account : '',
                            password: accountAutoRef.value ? accountLoginRef.value.password : '',
                            phone: phoneAutoRef.value ? loginAccountRef.value.phone : '',
                            captcha:phoneAutoRef.value?loginAccountRef.value.code:'',
                            time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
                        }
                        localStorage['adminAutoLogin'] = Aes.encrypt(JSON.stringify(obj))
                    }else {
                        console.log(loginRes.data)
                        MessageTips.error(loginRes.data.msg)
                    }
                }
            })
        }
        return {
            accountAuto: accountAutoRef,
            phoneAuto: phoneAutoRef,
            accountRule,
            User,
            Message,
            tabChange,
            loginAccount: loginAccountRef,
            accountLogin: accountLoginRef,
            activeTab: activeTabRef,
            getCode,
            mobileRule,
            getCodeStatus,
            codeText,
            handleAccountLogin,
            handlePhoneLogin,
            baseUrl:state.baseUrl
        }
    }
}
</script>

<style lang="less" scoped>
    .login-container {
        position: fixed;
        left: 50%;
        top: 50%;
        width: 384px;
        transform: translate(-50%, -50%);
        .login-title-row {
            display: flex;
            margin-bottom: 32px;
            justify-content: center;
            align-items: center;
            .login-img {
                display: block;
                width: 80px;
                height: 80px;
            }
            .login-title {
                margin-left: 10px;
                color: #333;
                font-size: 32px;
                font-weight: bold;
            }
        }  
        .login-content {
            ::v-deep(.el-form) {
                margin-top: 10px;
            }
            ::v-deep(.el-tabs__nav-scroll) {
                display: flex;
                justify-content: center;
            }
            ::v-deep(.el-tabs__nav-wrap::after) {
                display: none;
            }
        }
        ::v-deep(.el-tabs__item) {
            font-size: 18px;
        } 
        ::v-deep(.el-input__suffix-inner) {
            align-items: center;
        }
        .btn-get-code {
            display: flex;
            justify-content: flex-end;
        }
        .login-btn {
            width: 100%;
        }
        .btn-getcode {
            transition: all .2s;
        }
    }
</style>