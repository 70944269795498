<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="tags-detail-container">
                    <div class="tags-detail-content pd-32">
                        <el-scrollbar class="scrollbar">
                            <TagTree 
                                ref="tagTree" 
                                :isShowBtn="true" 
                                width="100%" 
                                :dataList="treeList" 
                                v-if="treeList.length" 
                                @add="addTagTree" 
                                @remove="removeTag" 
                                @edit="editTag"
                            />
                        </el-scrollbar>
                    </div>
                    <el-dialog title="修改数据分类" v-model="modalShow" :width="400">
                        <el-input v-model="tagName" placeholder="请输入" type="text"></el-input>
                        <template #footer>
                            <el-button @click="cancelTagEdit">取消</el-button>
                            <el-button type="primary" @click="editTagSubmit">确定</el-button>
                        </template>
                    </el-dialog>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../Layout/components/Header.vue';
import BreadCrumb from '../../components/BreadCrumb/index.vue';
import {ref, reactive, getCurrentInstance, onMounted} from 'vue';
import {useRoute} from 'vue-router'
import { Ajax, MessageTips, messageModal } from '../../utils'
import MenuTrees from '../../components/MenuTrees/MenuTrees.vue'
import TagTree from '../../components/TagTree/index.vue'
export default {
    components: {
        MenuTrees,
        TagTree,
        Header,
        BreadCrumb
    },
    setup() {
        const route = useRoute();
        const breadListsRef = ref([
            {
                name: '数据分类',
                path: {
                    name: 'Tags'
                }
            },
            {
                name: '编辑数据分类树'  
            }
        ])
        const { proxy } = getCurrentInstance();
        // 数据分类选择
        const treeActiveRef = ref();
        /**
         * 数据分类树数据结构
         * [
            {
                index: '1',
                level: 1,
                title: '生物工程',
                icon: 'Folder',
                showTag: false,
                children: [
                    {
                        index: '1-1',
                        level: 2,
                        title: '合成生物学',
                        showTag: false,
                    },
                    {
                        index: '1-2',
                        level: 2,
                        title: '微生物工程',
                        showTag: false,
                        children: [
                            {
                                index: '1-2-1',
                                level: 3,
                                title: '第三级',
                                showTag: false,
                            },
                            {
                                index: '1-2-2',
                                level: 3,
                                title: '第三级',
                                showTag: false,
                            }
                        ],
                    },
                    {
                        index: '1-3',
                        level: 2,
                        title: '发酵工程',
                        showTag: false,
                    },
                ]
            },
            {
                index: '2',
                title: '生物制药',
                icon: 'Folder',
                showTag: false,
            },
            {
                index: '3',
                title: '分子诊断',
                icon: 'Folder',
                showTag: false,
            },
            {
                index: '4',
                title: '再生医学',
                icon: 'Folder',
                showTag: false,
            }
        ]
         */
        const treeListRef = ref([]);
        

        const treeDatas = [
            {
                label: 'Level one 1',
                children: [
                {
                    label: 'Level two 1-1',
                    children: [
                    {
                        label: 'Level three 1-1-1',
                    },
                    ],
                },
                ],
            },
            {
                label: 'Level one 2',
                children: [
                {
                    label: 'Level two 2-1',
                    children: [
                    {
                        label: 'Level three 2-1-1',
                    },
                    ],
                },
                {
                    label: 'Level two 2-2',
                    children: [
                    {
                        label: 'Level three 2-2-1',
                    },
                    ],
                },
                ],
            },
        ]

        const showAddTag = index => {
            recursionGet(treeListRef.value, index)
        }

        const hideAddTag = index => {
            deepClear(treeListRef.value, index)
        }   

        /**
         * 递归查找符合条件的项,然后改变数组
         * @param setValues 数组结构
         * @param key 查找的key
         */
        const recursionGet = (setValues, key) => {
            let value = null;
            for (let index = 0; index < setValues.length; index++) {
                if(setValues[index].index === key) {
                    setValues[index].showTag = true;
                    value = true;
                    break;
                }else {
                    setValues[index].showTag = false;
                }
                if(setValues[index].children instanceof Array && setValues[index].children.length > 0) {
                   const text = recursionGet(setValues[index].children, key)
                   if(text) {
                       return text
                   }
                }
            }
            return value;
        }

        /**
         * 递归重置属性
         */
        const deepClear = (setValues) => {
            for (let index = 0; index < setValues.length; index++) {
                setValues[index].showTag = false;
                if(setValues[index].children instanceof Array && setValues[index].children.length > 0) {
                   recursionGet(setValues[index].children)
                }
            }
        }

        const tagWriteShowRef = ref(false)
        const tagSameChildRef = ref(false)
        const nowLevelInfo = reactive({});
        // 左侧数据分类树操作-添加数据分类
        const addTag = item => {
            console.log(item)
            nowLevelInfo.value = item;
            tagWriteShowRef.value = true;
            tagSameChildRef.value = false;
        }
        // 左侧数据分类树操作-添加同级
        const addSameLevel = item => {
            nowLevelInfo.value = item;
            const parentObj = treeListRef.value.find(it => it.id === item.parent_id)
            childrenFormModelRef.value.parentTag = `${parentObj ? parentObj.name : '-'}`;
            tagWriteShowRef.value = false;
            tagSameChildRef.value = true;
            
        }
        // 左侧数据分类树操作-添加子级
        const addChildLevel = item => {
            nowLevelInfo.value = item;
            const parentObj = treeListRef.value.find(it => it.id === item.parent_id)
            childrenFormModelRef.value.parentTag = `${parentObj ? parentObj.name : '-'}`;
            tagWriteShowRef.value = false;
            tagSameChildRef.value = true;
        }

        /**
         * 递归重组
         */
        const deepCreate = (originList, level) => {
            let lists = [];
            let obj = {};
            originList.forEach((item, index) => {
                const temp = {...item};
                temp.level = level || 1
                if(temp.child) {
                    temp.children = deepCreate(temp.child, temp.level + 1)
                    const { id, name } = item;
                    obj = {
                        ...temp,
                        index: String(id),
                        title: name,
                        showTag: false
                    }
                    delete obj.child;
                    if(!temp.child.length) {
                        delete obj.children
                    }
                }
                lists.push(obj)
            })
            return lists;
        }


        // 数据分类表单信息
        const tagFormModelRef = ref({
            name: '',
            parentTag: '',
            tagTreeName: '',
            desc: ''
        })
        const tagFormRules = {
            name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }
            ],
            desc: [
                {
                    required: true,
                    message: '请输入描述',
                    trigger: 'blur'
                },
                {
                    max: 100,
                    message: '不能超过100个字符',
                    trigger: 'blur'
                }
            ]
        }

        const childrenFormModelRef = ref({
            name: '',
            parentTag: '',
            tagTreeName: ''
        })
        const childrenFormRules = {
            name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }
            ]
        }
        
        // 底部提交按钮
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
        }
        const submitForm = formName => {
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    console.log('submit')
                    Ajax.POST({
                        // url: '/admin/label',
                        url: '/admin/tags/update',
                        data: {
                            name: childrenFormModelRef.value.name,
                            parent_id: nowLevelInfo.value.parent_id,
                            tree_id: nowLevelInfo.value.id,
                            // tree_id: nowLevelInfo.value.tree_id,
                        }
                    }).then(res => {
                        console.log(res)
                        if(res.data.code === 200) {
                            MessageTips.success('添加成功')
                            getTreeList(route.query.id)
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }

        const getTreeList = (id) => {
            return Ajax.POST({
                // url: `/admin/label_tree/${id}`
                url: `/admin/tags`
            }).then(res => {
                // treeListRef.value = deepCreate(res.data.data)
                let data = res.data.data;
                let targetValue=[];
                data.map(item=>{
                    if(item.id==id){
                        // targetValue = item
                        targetValue.push(item)
                    }
                })
                // console.log(targetValue)
                treeListRef.value = targetValue;
                treeListRef.value.forEach(item => {
                    item.icon = 'Folder'
                })
            })
        }

        onMounted(() => {
            const id = route.query.id;
            getTreeList(id).then(res => {
                console.log(treeListRef.value, 'res==')
                if(treeListRef.value && treeListRef.value.length === 0) {
                    
                    Ajax.POST({
                        url: '/admin/label',
                        data: {
                            name: route.query.title,
                            parent_id: 0,
                            tree_id: route.query.id,
                        }
                    }).then(res => {
                        if(res.data.code === 200) {
                            getTreeList(route.query.id)
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }
            })
        })

        const addTagTree = (type, form) => {
            Ajax.POST({
                // url: '/admin/label',
                url: '/admin/tags/create',
                data: {
                    name: form.name,
                    pid: form.parent_id,
                    desc:form.desc,
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('添加成功')
                    proxy.$refs.tagTree.modalClose();
                    getTreeList(route.query.id)
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }

        const removeTag = (node, data) => {
            const id = data.id;
            messageModal.confirm({
                msg: '确认删除？',
                type: 'error',
                confirm() {
                    Ajax.POST({
                        // url: `/admin/label/${id}`
                        url: `/admin/tags/del/${id}`
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('删除成功')
                            getTreeList(route.query.id)
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }
            })
        } 

        const modalShowRef = ref(false);
        const tagNameRef = ref('');
        const nowEditTagRef = ref({})
        const editTagSubmit = () => {
            if(!tagNameRef.value) {
                MessageTips.warning('请输入数据分类名')
                return ;
            }
            Ajax.PUT({
                url: `/admin/label/${nowEditTagRef.value.id}`,
                data: {
                    name: tagNameRef.value,
                    tree_id: nowEditTagRef.value.tree_id,
                    parent_id: nowEditTagRef.value.parent_id
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('修改成功')
                    modalShowRef.value = false;
                    getTreeList(route.query.id)
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }

        const cancelTagEdit = () => {
            modalShowRef.value = false;
            nowEditTagRef.value = {};
        }

        const editTag = (node, data) => {
            modalShowRef.value = true;
            nowEditTagRef.value = data;
            tagNameRef.value = data.name;
        }

        return {
            breadLists: breadListsRef,
            treeActive: treeActiveRef,
            treeList: treeListRef,
            treeDatas,
            showAddTag,
            hideAddTag,
            tagFormModel: tagFormModelRef,
            tagFormRules,
            childrenFormModel: childrenFormModelRef,
            childrenFormRules,
            cancelSubmit,
            submitForm,
            addTag,
            addSameLevel,
            addChildLevel,
            tagWriteShow: tagWriteShowRef,
            tagSameChild: tagSameChildRef,
            addTagTree,
            removeTag,
            editTag,
            modalShow: modalShowRef,
            tagName: tagNameRef,
            editTagSubmit,
            cancelTagEdit
        }
    }
}
</script>

<style lang="less" scoped>
    .tags-detail-container {
        height: 100%;
        .search-row {
            display: flex;
            align-items: center;
            width: 100%;
            height: 66px;
            padding-left: 32px; 
            padding-right: 32px; 
            background-color: #fff;
            .input-box {
                width: 200px;
            }
        }
        .tags-detail-content {
            // height: calc(100% - 66px - 12px);
            height: 100%;
            background-color: #fff;
        }
        .tags-scroll-box {
            display: flex;
            height: 100%;
            .left-tree {
                width: 500px;
                ::v-deep(.el-menu) {
                    height: 100%;
                }
                ::v-deep(.el-menu-item) {
                    height: 42px;
                    &.is-active {
                        position: relative;
                        // color: #3894FF;
                        // background-color: fadeout(#3894FF, 86%);
                        background-color: fadeout(@primary-color, 86%);
                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            width: 4px;
                            height: 100%;
                            // background-color: #3894FF;
                            background-color: @primary-color;
                        }
                    }
                }
                ::v-deep(.is-current > .el-tree-node__content) {
                    color: red;
                }
            }
            .right-tag-info {
                flex: 1;
                margin-left: 32px;
                padding: 32px;
                border: 1px solid #e6e6e6;
                .tag-title {
                    margin-bottom: 32px;
                    font-weight: bold;
                    font-size: 16px;
                    color: #101010;
                }
                .form-box {
                    width: 600px;
                }
            }
        }
        ::v-deep(.el-scrollbar__view) {
            height: 100%;
        }
    }
    
</style> 