<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="investment-container">
                    <SearchFilterBar :statusLabels="statusLabels" :batchShow="true"  :batchUrl="'admin/company/import'"
                    :moduleShow="true" :downUrl="'https://image.sciphi.cn/sciphi/import_temp/organization_temp.xlsx '"
                    @statusChange="statusChange" @addClick="handleAdd" @searchClick="handleSearch"
                    />
                    <div class="table-con mg-t-12">
                        <el-scrollbar>
                            <div class="table-container pd-32">
                                <el-table v-loading="tableLoading" :data="tableData" border :max-height="650">
                                    <el-table-column width="100" prop="id" label="投资机构ID" />
                                    <el-table-column prop="name" label="名称" />
                                    <el-table-column prop="country_name" label="国家" />
                                    <el-table-column prop="province_name" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province_name }} {{ scope.row.city_name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="phases" label="类型" />
                                        <!-- <template #default="scope">
                                            {{ companyPhaseRef.find(item => item.id === scope.row.phase) }}
                                        </template>
                                    </el-table-column> -->
                                    <el-table-column prop="update_time" label="最近编辑时间" />
                                    <el-table-column prop="publish_time" label="发布时间" />
                                    <el-table-column prop="status" label="状态" width="80">
                                        <template #default="scope">
                                            <template v-if="scope.row.status === 1">
                                                <span class="status-text primary">已发布</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 2">
                                                <span class="status-text success">待完善</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 3">
                                                <span class="status-text danger">已完善</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 4">
                                                <span class="status-text info">已撤回</span>
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" width="120">
                                        <template #default="scope">
                                            <!-- {{ scope.row }} -->
                                            <el-link type="primary" class="mg-r-12" @click="toDetail(scope.row.id)">详情</el-link>
                                            <el-link type="primary" class="mg-r-12" @click="opClick(scope.row.id, 4)" v-if="scope.row.status === 1">撤回</el-link>
                                            <el-link type="primary" v-else-if="[3, 4].includes(scope.row.status)" @click="opClick(scope.row.id, 1)">发布</el-link>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="table-pagination-row mg-t-24">
                                    <el-pagination
                                        background
                                        :currentPage="paginationData.current"
                                        :page-sizes="paginationData.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="paginationData.total"
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                    >
                                    </el-pagination>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import SearchFilterBar from '../../../../components/SearchFilterBar/index.vue';
import { ref, onBeforeMount} from 'vue'
import { useRouter } from 'vue-router'
import { Ajax, MessageTips } from '../../../../utils'
export default {
    components: {
        SearchFilterBar,
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '投资机构'
            }
        ])
        const router = useRouter();
        /* 头部筛选栏 start */ 
        const statusLabelsRef = ref([
            {
                name: "全部",
                value: 0,
            },
            {
                name: "已发布",
                value: 1,
            },
            {
                name: "已完善",
                value: 3,
            },
            {
                name: "待完善",
                value: 2,
            },
            {
                name: "已撤回",
                value: 4,
            }            
        ])
        const statusRef = ref(0)
        const searchValueRef = ref('')
        const handleAdd = () => {
            router.push({
                name: 'OrganizationCreateInvestment'
            })
        }
        const handleSearch = obj => {
            searchValueRef.value = obj.searchValue;
            getList();
        }
        const statusChange = status => {
            statusRef.value = status;
            getList();
        }
        
        /* 头部筛选栏 end */ 
        const tableDataRef = ref([])
        const tableLoadingRef = ref(false)
        let paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            page_size: 10,
            total: 0
        });
        const companyPhaseRef = ref([])
        const handleSizeChange = e => {
            paginationDataRef.value.page_size = e;
            getList();
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList();
        }
        const getList = () => {
            tableLoadingRef.value = true;
            Ajax.GET({
                url: '/admin/organization',
                params: {
                    page: paginationDataRef.value.current,
                    page_size: paginationDataRef.value.page_size,
                    name: searchValueRef.value,
                    status: statusRef.value || undefined
                }
            }).then(res => {
                tableLoadingRef.value = false;
                if(res.data.code === 200) {
                    const resData = res.data.data.list;
                    resData.forEach(item => {
                        item.phases = [];
                        let phaseArr = item.phase.split(',')
                        companyPhaseRef.value.forEach(it => {
                            if(phaseArr.includes(String(it.id))) {
                                item.phases.push(it.name)
                            }
                        })                        
                        item.phases = item.phases.join(', ')
                    })
                    tableDataRef.value = resData;
                    paginationDataRef.value.total = res.data.data.page_info.total;
                }
            })
        }
        onBeforeMount(() => {
            companyPhaseRef.value = [
                {
                    id: 1,
                    name: '天使机构'
                },
                {
                    id: 2,
                    name: 'VC'
                },
                {
                    id: 3,
                    name: 'PE'
                },
                {
                    id: 4,
                    name: 'CVC'
                },
                {
                    id: 5,
                    name: 'FA'
                },
                {
                    id: 6,
                    name: 'FOF'
                },
                {
                    id: 7,
                    name: '金融机构'
                },
                {
                    id: 8,
                    name: '国资背景'
                },
                {
                    id: 9,
                    name: '家族办公室'
                },
                {
                    id: 10,
                    name: '对冲基金'
                },
                {
                    id: 11,
                    name: '天使个人'
                },
                {
                    id: 12,
                    name: '孵化器'
                },
                {
                    id: 13,
                    name: '其他'
                }
            ]
            getList();
        })

        const toDetail = id => {
            router.push({
                path: '/organization/investment/detail',
                query: {
                    id
                }
            })
        }

        const opClick = (id, status) => {
            Ajax.POST({
                url: '/admin/organization/status',
                data: {
                    id,
                    status
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('操作成功')
                    getList();
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }
        /* 表格数据操作 */ 
        return {
            breadLists: breadListsRef,
            statusLabels: statusLabelsRef,
            companyPhase: companyPhaseRef,
            handleAdd,
            handleSearch,
            statusChange,
            tableLoading: tableLoadingRef,
            tableData: tableDataRef,
            handleSizeChange,
            handleCurrentChange,
            paginationData: paginationDataRef,
            toDetail,
            opClick,
        }
    }
}
</script>

<style lang="less" scoped>
    .investment-container {
        height: 100%;
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
        .table-con {
            height: 100%;
            max-height: calc(100% - 66px - 12px);
            background-color: #fff;
        }
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
    }
</style>