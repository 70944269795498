<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleTabClick">
              <el-tab-pane label="基本信息" name="基本信息">
                <div class="show_information" v-show="!editFalg">
                  <el-descriptions :column="1">
                    <el-descriptions-item label-align="right" label="名称">
                      <span :class="`status-text `">{{ baseMessage.title}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label-align="right" label="类型">
                      <span
                        :class="`status-text `"
                      >{{ baseMessage.type==1?"品牌":baseMessage.type==2?"人物":"机构"}}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label-align="right" label="简介">
                      <span :class="`status-text `">{{ baseMessage.summary }}</span>
                    </el-descriptions-item>
                    <el-descriptions-item label-align="right" label="行业领域：" :span="2">
                      <template v-if="baseMessage.tag && baseMessage.tag.length">
                        <span
                          class="mg-r-12"
                          v-for="item in baseMessage.tag"
                          :key="item.id"
                        >{{ item.name }}</span>
                      </template>
                    </el-descriptions-item>
                    <el-descriptions-item label-align="right" label="发布时间">
                      <span :class="`status-text `">{{ baseMessage.publish_time }}</span>
                    </el-descriptions-item>
                  </el-descriptions>
                </div>
                <div class="show_information" v-show="editFalg">
                  <el-form
                    ref="formRef"
                    :model="baseNewsForm"
                    :rules="baseNewsFormRule"
                    label-width="120px"
                    class="user-form-box mg-t-24"
                  >
                    <el-form-item label="名称" prop="title">
                      <el-input v-model="baseNewsForm.title" placeholder="请输入" :maxlength="50"></el-input>
                    </el-form-item>
                    <el-form-item label="类型" prop="type">
                      <el-select style="width: 100%" v-model="baseNewsForm.type" placeholder="请选择">
                        <el-option label="品牌" value="1"></el-option>
                        <el-option label="人物" value="2"></el-option>
                        <el-option label="机构" value="3"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="简介" prop="summary">
                      <el-input
                        v-model="baseNewsForm.summary"
                        type="textarea"
                        placeholder="请输入"
                        :autosize="{ minRows: 2, maxRows: 14 }"
                        maxlength="100"
                      ></el-input>
                    </el-form-item>

                    <el-form-item label="行业领域" prop="tags">
                      <checkTag ref="checkTag"></checkTag>
                    </el-form-item>
                    <el-form-item label="发布时间" prop="publish_time">
                      <el-date-picker
                        value-format="YYYY-MM-DD"
                        v-model="baseNewsForm.publish_time"
                        type="date"
                        placeholder="选择日期"
                      />
                    </el-form-item>
                  </el-form>
                </div>
              </el-tab-pane>
              <el-tab-pane label="热点内容" name="热点内容">
                <div class="add_more">
                  <el-button type="primary" @click="openDialog">
                    <el-icon>
                      <plus />
                    </el-icon>新增
                  </el-button>
                </div>
                <el-table :data="tableData" border :max-height="650">
                  <template v-if="baseMessage.type==1">
                    <el-table-column prop="object_id" label="品牌ID" />
                    <el-table-column prop="brand_name" label="品牌名称" />
                    <el-table-column prop="country_name" label="国家" />
                    <el-table-column prop="province" label="地区">
                      <template
                        #default="scope"
                      >{{ scope.row.province_name }} {{ scope.row.city_name }}</template>
                    </el-table-column>
                    <el-table-column prop="establish_time" label="成立时间" />
                    <el-table-column prop="trade_arr" label="行业领域" />
                    <el-table-column label="操作" width="140">
                      <template #default="scope">
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="geToDetail(scope.row.id, 'BrandDetail')"
                        >详情</el-link>
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="showDialog(scope.row.id, 0, '确认删除该品牌?')"
                        >删除</el-link>
                      </template>
                    </el-table-column>
                  </template>

                  <template v-if="baseMessage.type==2">
                    <el-table-column prop="object_id" label="人物ID" />
                    <el-table-column prop="name" label="人物名称" />
                    <el-table-column prop="post" label="职位" />
                    <el-table-column prop="type" label="类型">
                      <template #default="scope">
                        <span>{{scope.row.type==1?"学者":scope.row.type==2?"创业者":"投资人"}}</span>
                      </template>
                    </el-table-column>

                    <el-table-column prop="type" label="专业领域">
                      <template #default="scope">
                        <span v-if="scope.row.type==1">{{scope.row.research_label}}</span>
                        <span v-if="scope.row.type==2">{{scope.row.invest_label}}</span>
                        <span v-if="scope.row.type==3">{{scope.row.startup_label}}</span>
                      </template>
                    </el-table-column>

                    <el-table-column prop="education" label="教育背景">
                      <template #default="scope">
                        <span
                          v-for="item in scope.row.education"
                          :key="item.id"
                        >{{`${item.university},`}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="work_history" label="任职机构">
                      <template #default="scope">
                        <div class="work_history_box">
                          <span
                          v-for="item in scope.row.work_history"
                          :key="item.id"
                        >{{`${item.unit_name},`}}</span>
                        </div>
                      </template>
                    </el-table-column>

                    <el-table-column label="操作" width="140">
                      <template #default="scope">
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="geToDetail(scope.row.id, 'PersonDetail')"
                        >详情</el-link>
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="showDialog(scope.row.id, 0, '确认删除该人物?')"
                        >删除</el-link>
                      </template>
                    </el-table-column>
                  </template>

                  <template v-if="baseMessage.type==3">
                    <el-table-column prop="object_id" label="机构ID" />
                    <el-table-column prop="name" label="机构名称" />
                    <el-table-column prop="country_name" label="国家" />
                    <el-table-column prop="province" label="地区">
                      <template
                        #default="scope"
                      >{{ scope.row.province_name }} {{ scope.row.city_name }}</template>
                    </el-table-column>
                    <el-table-column prop="phase_arr" label="投资机构类型" />
                    <el-table-column label="操作" width="140">
                      <template #default="scope">
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="geToDetail(scope.row.id, 'OrganizationInvestmentDetail')"
                        >详情</el-link>
                        <el-link
                          type="primary"
                          class="mg-r-12"
                          @click="showDialog(scope.row.id, 0, '确认删除该机构?')"
                        >删除</el-link>
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  ></el-pagination>
                </div>
              </el-tab-pane>
            </el-tabs>

            <div class="bottom-submit-box pd-16 mg-t-24">
              <el-row justify="end">
                <el-col :span="24" class="text-right" v-if="editFalg">
                  <el-button plain @click="editFalg=false">取消</el-button>
                  <el-button type="primary" @click="submitForm('formRef')">保存</el-button>
                </el-col>
                <el-col :span="24" class="text-right" v-if="!editFalg">
                  <el-button plain @click="back">返回</el-button>
                  <el-button type="primary" @click="editHandle">编辑</el-button>
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
    <el-dialog
      v-model="dialogShow"
      :title="addBoxMessage.boxTitle"
      :width="800"
      :close-on-click-modal="false"
      :show-close="false"
      destroy-on-close
    >
      <div class="dialo-content">
        <div class="mg-b-16" style="width: 200px">
          <el-input
            v-model="dialogSearchInp"
            :placeholder="addBoxMessage.searchLabel"
            :suffix-icon="Search"
            @change="dialogSearchChange"
          ></el-input>
        </div>
        <el-table v-loading="unitTableLoading" :data="dialogTable" border max-height="300">
          <!-- <template v-if="nowPageType === 'edit-teach'"> -->
            <template v-if="baseMessage.type==1">
            <el-table-column prop="name" label="品牌名称" />
            
            <el-table-column label="操作">
              <template #default="scope">
                <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
              </template>
            </el-table-column>
          </template>

          <template v-if="baseMessage.type==2">post
            <el-table-column prop="name" label="人物名称" />
            <el-table-column prop="post" label="职位" />
            
            <el-table-column label="操作">
              <template #default="scope">
                <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
              </template>
            </el-table-column>
          </template>

          <template v-if="baseMessage.type==3">
                <el-table-column prop="name" label="机构名称" />
                <el-table-column label="操作">
                <template #default="scope">
                    <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
                </template>
                </el-table-column>
            </template>
          <!-- <template v-else> -->
          <!-- <template >
            <el-table-column prop="name" :label="addBoxMessage.contentTitle">
              <template #default="scope">
                <el-input v-if="scope.row.type=='new'" v-model="scope.row.name" placeholder="请输入"></el-input>
                <span v-else>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template #default="scope">
                <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
              </template>
            </el-table-column>
          </template> -->
          <template #empty>没有匹配的结果</template>
        </el-table>
        <!-- <div class="table-pagination-row mg-t-24">
                                <el-pagination
                                    background
                                    :currentPage="dialogPaginationData.current"
                                    :page-sizes="dialogPaginationData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="dialogPaginationData.total"

                                    @size-change="dialogHandleSizeChange"
                                    @current-change="dialogHandleCurrentChange"
                                >
                                </el-pagination> -->
        <!-- </div> -->
      </div>
      <template #footer>
        <el-button plain @click="dialogClose">返回</el-button>
        <el-button type="primary" @click="submitAdd">完成</el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="isShowDialog" :width="400">
      <span>{{ dialogDetail.content }}</span>
      <template #footer>
        <el-button @click="isShowDialog = false">取消</el-button>
        <el-button type="primary" @click="submitDelete()">确定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance, onMounted } from "vue";
import { Ajax, MessageTips, Rule } from "../../utils";
import { Plus, Search } from "@element-plus/icons-vue";
import { useRouter, useRoute } from "vue-router";
import checkTag from "../../components/CheckTagNew";
import { TabsPaneContext } from "element-plus";
export default {
  components: {
    Header,
    BreadCrumb,
    checkTag,
    Plus,
    TabsPaneContext
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "热点",
        path: {
          name: "hotnews"
        }
      },
      {
        name: "热点详情"
      }
    ]);
    const activeName=ref("基本信息")
    const editFalg = ref(false);
    const baseMessage = ref({});
    const isShowDialog = ref(false);
    const dialogDetail = ref({
      recordID: undefined,
      content: "",
      type: undefined
    });
    const showDialog = (id, type, content) => {
      dialogDetail.value.recordID = id;
      dialogDetail.value.type = type;
      dialogDetail.value.content = content;
      isShowDialog.value = true;
    };
    const geToDetail = (id, pathName) => {
      router.push({
        name: pathName,
        query: {
          id,
          hotNews:true,
          hotNewsId:route.query.id
        }
      });
    };
    const submitDelete = () => {
      Ajax.POST({
        url: `/admin/hotcontent/delContent/${dialogDetail.value.recordID}`
      }).then(res => {
        if (res.data.code == 200) {
          MessageTips.success("删除成功");
          isShowDialog.value = false;
          getHotTableData();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const addBoxMessage=ref({
        boxTitle:"",
        contentTitle:"",
        searchLabel:"",
    })
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0
    });
    const searchForm = ref({
      page: 1,
      page_size: 10
    });
    //获取热点详情
    onMounted(() => {
      getInformation();
      if(route.query.basicPage){
        activeName.value="热点内容"
        getHotTableData();
      }
      
    });
    const baseNewsFormRef = ref({
      title: undefined,
      type: undefined,
      summary: undefined,
      tags: undefined,
      publish_time: undefined
    });

    const tableData = ref([]);
    const handleTabClick = (TabsPaneContext, e) => {
      if (TabsPaneContext.props.name == "热点内容") {
        getHotTableData();
      }
    };
    const handleSizeChange = e => {
      console.log(e, "sizeChange");
      searchForm.value.page_size = e;
      getHotTableData();
    };
    const handleCurrentChange = e => {
      console.log(e, "currentChange");
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getHotTableData();
    };
    const openDialog = () => {
      dialogShowRef.value = true;
      let addType= baseMessage.value.type;
      if(addType==1){
          addBoxMessage.value.boxTitle="选择品牌"
          addBoxMessage.value.searchLabel="搜索品牌"
          addBoxMessage.value.contentTitle="品牌名称"
      }else if(addType==2){
          addBoxMessage.value.boxTitle="选择人物"
          addBoxMessage.value.searchLabel="搜索人物"
          addBoxMessage.value.contentTitle="人物名称"
      }else if(addType==3){
          addBoxMessage.value.boxTitle="选择机构"
          addBoxMessage.value.searchLabel="搜索机构"
          addBoxMessage.value.contentTitle="机构名称"
      }
    };

    const baseNewsFormRule = {
      title: [
        {
          required: true,
          message: "请输入",
          trigger: "blur"
        }
      ],
      type: [
        {
          required: true,
          message: "请选择",
          trigger: "change"
        }
      ]
    };
    const getInformation = () => {
      Ajax.POST({
        url: `/admin/hot/detail/${route.query.id}`
      }).then(res => {
        if (res.data.code === 200) {
          baseMessage.value = res.data.data;
          baseNewsFormRef.value.title = res.data.data.title;
          baseNewsFormRef.value.type = res.data.data.type.toString();
          baseNewsFormRef.value.summary = res.data.data.summary;
          baseNewsFormRef.value.publish_time = res.data.data.publish_time;
          baseNewsFormRef.value.tags = res.data.data.tag;
          proxy.$refs.checkTag.setValue(baseNewsFormRef.value.tags);
        }
      });
    };
    const getHotTableData = () => {
      Ajax.POST({
        url: `/admin/hotcontent`,
        data: {
          hot_id: route.query.id,
          ...searchForm.value
        }
      }).then(res => {
        if (res.data.code === 200) {
          tableData.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        }
      });
    };
    const back = () => {
      router.go(-1);
    };
    const editHandle = () => {
      editFalg.value = true;
    };

    const cancelSubmit = formName => {
      // proxy.$refs[formName].resetFields();
      back();
    };
    const submitForm = formName => {
      proxy.$refs[formName].validate(valid => {
        if (valid) {
          let tag = ref([]);
          tag.value = proxy.$refs.checkTag.getValue();
          baseNewsFormRef.value.tags = tag.value;
          Ajax.POST({
            url: `/admin/hot/update/${route.query.id}`,
            data: {
              title: baseNewsFormRef.value.title,
              type: baseNewsFormRef.value.type,
              summary: baseNewsFormRef.value.summary,
              tags: baseNewsFormRef.value.tags,
              publish_time: baseNewsFormRef.value.publish_time
            }
          }).then(res => {
            if (res.data.code == 200) {
              MessageTips.success("修改成功");
              editFalg.value = false;
              getInformation();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };

    const unitTableLoadingRef = ref(false);
    // const personType = configData.personType;
    // const nowPageType = computed(() => {
    //     /**
    //      * member  主体成员
    //      * financing-in  对内融资
    //      * financing-out    对外投资
    //      * cooperation-agency   竞合关系
    //      * company-worksList 软著列表
    //      * company-patent 专利信息
    //      * reports  相关报道
    //      */
    //     return route.query.type
    // })
    /* 弹窗选择 start */
    const dialogShowRef = ref(false);
    const dialogSearchInpRef = ref("");
    const dialogTableRef = ref([]);
    const dialogRadioRef = ref("");
    const dialogPaginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
      page_size: 10
    });
    // 获取机构列表
    const getUnitList = name => {
      unitTableLoadingRef.value = true;
      addAuditPersonRef.value = false;
      dialogRadioRef.value = undefined;
      let addType =baseMessage.value.type;
      let searchUrl ='';
    //   addType==1?searchUrl='/admin/brand':addType==2?searchUrl='/admin/personage':searchUrl='/admin/organization'
      addType==1?searchUrl='/admin/brand/search':addType==2?searchUrl='/admin/personage/search':searchUrl='/admin/organization/search'
    //   if(addType==1){
          Ajax.POST({
            url: searchUrl,
            params: {
            name,
            }
        }).then(res => {
            unitTableLoadingRef.value = false;
            if (res.data.code === 200) {
            dialogTableRef.value = res.data.data;
            }
        });
    //   }else{
    //       Ajax.GET({
    //         url: searchUrl,
    //         params: {
    //         name,
    //         }
    //     }).then(res => {
    //         unitTableLoadingRef.value = false;
    //         if (res.data.code === 200) {
    //         dialogTableRef.value = res.data.data.list;
    //         }
    //     });
    //   }
      
    };
    const dialogSearchChange = value => {
      getUnitList(value);
    };
    const dialogHandleSizeChange = e => {
      console.log(e);
    };
    const dialogHandleCurrentChange = e => {
      dialogPaginationDataRef.value.current = e;
    };
    // const openDialog = () => {
    //   dialogShowRef.value = true;
    // };
    // 添加临时数据
    const addAuditPersonRef = ref(false);
    const newSelectTypeRef = ref(null);
    const addAuthor = type => {
      addAuditPersonRef.value = true;
      let unit_type = undefined;
      if (type === "cooperation-agency") {
        unit_type = 2;
      }
      dialogTableRef.value[0] = {
        type: "new",
        name: dialogSearchInpRef.value,
        unit_type: newSelectTypeRef.value,
        id: 0
      };
      console.log(dialogTableRef.value);
    };
    const dialogClose = () => {
      dialogShowRef.value = false;
      dialogRadioRef.value = undefined;
      addAuditPersonRef.value = false;
      dialogTableRef.value.splice(0);
      dialogSearchInpRef.value = "";
      newSelectTypeRef.value = null;
    };
    const dialogSubmit = () => {
      if (!dialogRadioRef.value && dialogRadioRef.value !== 0) return;
      // 临时数据添加
      if (addAuditPersonRef.value) {
        if (dialogRadioRef.value !== 0) return;
        dialogTableRef.value[0].unit_type = newSelectTypeRef.value;
        // if(!(dialogTableRef.value[0].name && dialogTableRef.value[0].unit_type)) {
        //     MessageTips.warning('名称与类型必填')
        //     return ;
        // }
        Ajax.POST({
          url: "/admin/hotcontent/create",
          data: {
            hot_id: dialogTableRef.value[0].name,
            object_id: 2
          }
        }).then(res => {
          if (res.data.code == 200) {
            dialogTableRef.value[0].id = res.data.data.insertId;
            if (nowPageType.value === "cooperation-agency") {
              dialogRadioRef.value = res.data.data.insertId;
              submitAdd(res.data.data.insertId);
            }
            dialogClose();
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
        return;
      }
      submitAdd(+dialogRadioRef.value);
    };

    const submitAdd = () => {
      //待修改
      Ajax.POST({
        url: "/admin/hotcontent/create",
        data: {
          hot_id: baseMessage.value.id,
          object_id: dialogRadioRef.value
        }
      }).then(res => {
        if (res.data.code === 200) {
          MessageTips.success("新增成功");
          dialogShowRef.value = false;
          getHotTableData()
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    /* 弹窗选择 end */

    return {
      breadLists,
      baseNewsForm: baseNewsFormRef,
      baseNewsFormRule,
      cancelSubmit,
      submitForm,
      Rule,
      activeName,
      handleTabClick,
      editFalg,
      editHandle,
      baseMessage,
      tableData,
      paginationData: paginationDataRef,
      isShowDialog,
      showDialog,
      submitDelete,
      dialogDetail,
      geToDetail,
      back,
      // 弹窗
      dialogShow: dialogShowRef,
      unitTableLoading: unitTableLoadingRef,
      dialogSearchInp: dialogSearchInpRef,
      dialogTable: dialogTableRef,
      dialogRadio: dialogRadioRef,
      dialogPaginationData: dialogPaginationDataRef,
      dialogHandleSizeChange,
      dialogHandleCurrentChange,
      openDialog,
      dialogSearchChange,
      dialogClose,
      dialogSubmit,
      Search,
      addAuthor,
      addAuditPerson: addAuditPersonRef,
      newSelectType: newSelectTypeRef,
      addBoxMessage,
      submitAdd
    };
  }
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 50%;
    // padding: 0px 180px;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .add_more {
    float: right;
    margin-bottom: 10px;
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .work_history_box{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>