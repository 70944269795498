const unitConvert = (num)  => {
    var moneyUnits = ["元", "万元", "亿元", "万亿"]
    var dividend = 10000;
    var curentNum = num;
    //转换数字
    var curentUnit = moneyUnits[0];
    //转换单位
    for (var i = 0; i <4; i++) {
        curentUnit = moneyUnits[i]
        if(strNumSize(curentNum)<5){
            break;
        }
        curentNum = curentNum / dividend
    }
    var m = {num: 0, unit: ""}
    m.num = curentNum.toFixed(2)
    m.unit = curentUnit;
    return m;
}

const strNumSize = (tempNum)  => {
    var stringNum = tempNum
    var index = stringNum
    var newNum = stringNum;
    if(index!=-1){
        newNum = stringNum.substring(0,index)
    }
    return newNum.length
}

const showString = (string ,len) => {
    return string.length >len ? string.substring(0,len) + '...' : string
}
export {
    unitConvert,
    showString,
}
