<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists" />
				</template>
			</Header>
		</el-header>
		<el-scrollbar class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="create-company-container">
					<el-card class="mg-b-16">
						<template #header>
							<div class="card-header">
								<span class="card-name">品牌基本信息</span>
							</div>
						</template>
						<div class="form-container">
							<el-form
									ref="formRef"
									:model="companyBaseInfo"
									:rules="companyBaseInfoRule"
									label-width="140px"
									class="user-form-box mg-t-24"
							>
								<el-form-item label="品牌名" prop="brand_name">
									<el-input v-model="companyBaseInfo.brand_name" placeholder="请输入品牌名称" />
								</el-form-item>
								<el-form-item label="主体全称" prop="company_id" style="width: 660px">
									<el-select
											v-model="companyBaseInfo.company_id"
											filterable
											remote
											reserve-keyword
											placeholder="请输入工商注册名称"
											:remote-method="handleCom"
											:loading="loadingCom"
											@change="getSelectInfo"
											style="width: 407px"
									>
										<el-option
												v-for="item in comData"
												:key="item.id"
												:label="item.register_name"
												:value="item.id"
										/>
										<template v-slot:empty>
											<div class="me-select-search-empty">
												<div v-loading="loadingCom">
													<span class="me-feedback-message">无数据</span>
												</div>
											</div>
										</template>
									</el-select>
									<el-link
											@click="addCompany"
											class="new-brand"
											type="primary"
											v-if="selectNotFind"
									> 新增主体主体</el-link>
								</el-form-item>
								<el-form-item
										v-if="companyBaseInfo.company_id"
										label="统一社会信用代码">
									{{selectData.reg_no || ''}}
								</el-form-item>
								<el-form-item
										v-if="companyBaseInfo.company_id"
										label="地区">
									{{selectData.country_name +
								' - ' + selectData.province_name +
								' - ' + selectData.city_name}}
								</el-form-item>

								<el-form-item label="简介" prop="profile">
									<el-input
											v-model="companyBaseInfo.profile"
											placeholder="一句话简介50字以内"
											show-word-limit
											maxlength="50"
									></el-input>
								</el-form-item>
								<el-form-item label="成立时间" prop="date">
									<el-date-picker
											value-format="YYYY-MM-DD"
											v-model="companyBaseInfo.establish_time"
											type="date"
											placeholder="选择日期"
											style="width: 407px"
									/>
								</el-form-item>
								<el-form-item label="介绍" prop="introduction">
									<el-input
											show-word-limit
											maxlength="500"
											v-model="companyBaseInfo.introduction"
											:autosize="{ minRows: 4}"
											type="textarea"></el-input>
								</el-form-item>
								<el-form-item label="品牌" v-if="companyBaseInfo.company_id">
									<el-tag v-for="item in selectData.brand" :key="item.id">{{item.name}}</el-tag>
								</el-form-item>
								<el-form-item label="行业领域">
									<!-- <el-cascader
											v-model="companyBaseInfo.trade"
											placeholder="行业领域"
											:options="trades"
											:props="cascaderParams"
											style="width: 407px"
											filterable></el-cascader> -->
									<checkTag ref="checkTag"></checkTag>
								</el-form-item>
								<el-form-item label="品牌logo" prop="image">
									<el-upload
											class="avatar-uploader"
											:show-file-list="false"
											:action="`${baseUrl}admin/upload/image`"
											:on-success="avatarUploadSuccess"
											:headers="{ Authorization: 'Bearer ' + adminToken }"
											name="image">
										<img
												v-if="companyBaseInfo.image"
												:src="companyBaseInfo.image"
												class="avatar"/>
										<el-icon v-else class="avatar-uploader-icon"><plus/></el-icon>
									</el-upload>
								</el-form-item>
								<el-form-item label="官网" prop="link">
									<el-input v-model="companyBaseInfo.link" placeholder="请输入官网" />
								</el-form-item>
								<el-form-item label="电话" prop="telephone">
									<el-input v-model="companyBaseInfo.telephone" placeholder="请输入电话"/>
								</el-form-item>
								<el-form-item label="邮箱" prop="email">
									<el-input v-model="companyBaseInfo.email" placeholder="请输入邮箱" />
								</el-form-item>
								<el-form-item label="手机" prop="mobile">
									<el-input v-model="companyBaseInfo.mobile" placeholder="请输入手机号" />
								</el-form-item>
							</el-form>
						</div>
					</el-card>
					<div class="bottom-submit-box pd-16 mg-t-12">
						<el-row justify="end">
							<el-col :span="4">
								<el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
								<el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
							</el-col>
						</el-row>
					</div>
				</div>
			</div>
		</el-scrollbar>

		<add
				v-if="showDialog"
				:dialogTitle="dialogTitle"
				@closeDialog="dialogClose($event)"
				@getTableData="showAddClose"
		/>
	</div>
</template>

<script>
import { Plus } from '@element-plus/icons-vue'
import Header from '@/Layout/components/Header.vue';
import BreadCrumb from '@/components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, onBeforeMount, onMounted} from 'vue';
import { useRouter, useRoute} from 'vue-router'
import { Ajax, MessageTips } from '@/utils'
import checkTag from '@/components/CheckTagNew'
import add from "@/pages/Audit/com/components/add.vue";
export default {
	components: {
		checkTag,
		Header,
		BreadCrumb,
		add,
		Plus
	},
	setup() {
		const { proxy } = getCurrentInstance();
		const router = useRouter();
		const route = useRoute();

		const breadListsRef = ref([
			{
				name: '品牌',
				path: {
					name: 'brand'
				}
			},
			{
				name: "品牌详情",
				path: {
					name: "BrandDetail",
					query: {
						id: route.query.id,
					},
				},
			},
			{
				name: '编辑品牌'
			}
		])
		const adminToken = ref(localStorage.getItem("adminToken"))
		const baseUrl = ref(window.env.baseUrl)
		const cascaderParams = ref({
			multiple: true,
			value:'id',
			label:'name',
			children:'child'
		})
		const companyBaseInfoRef = ref({
			brand_name: '',
			company_id: '',
			establish_time: '',
			profile: '',
			introduction: '',
			trade: [],
			image: '',
			link: '',
			mobile: '',
			telephone: '',
			email: '',
		})
		
		const selectNotFind = ref(false)

		const addCompany = () => {
			showDialog.value = true
			console.log(showDialog.value)
		}

		const dialogTitle = ref("增加主体");
		const showDialog = ref(false);
		const selectData = ref({
			brand: [],
			reg_no: '',
			country_name: '',
			province_name: '',
			city_name: ''
		})
		const trades = ref([])
		const dialogClose = (e) => {
			showDialog.value = e
		}
		const showAddClose = () => {
			selectNotFind.value = false
		}

		const companyBaseInfoRule = {
			brand_name: [
				{
					required: true,
					message: '请输入品牌名',
					trigger: 'blur'
				}
			],
			company_id: [
				{
					required: true,
					message: '请输入主体全称',
					trigger: 'blur'
				}
			],
		}
		// 标签 end
		const cancelSubmit = formName => {
			// proxy.$refs[formName].resetFields()
			router.back();
		}
		const submitForm = formName => {
			proxy.$refs[formName].validate(valid => {
				if(valid) {
					let tag=ref([])
                    tag.value=proxy.$refs.checkTag.getValue()
                    companyBaseInfoRef.value.trade=tag.value
					// let trade = new Set(companyBaseInfoRef.value.trade.toString().split(","))
					// let tradeArr = [...trade];
					// companyBaseInfoRef.value.trade = tradeArr.join(',')
					Ajax.POST({
						url: '/admin/brand/update/' + route.query.id,
						data: companyBaseInfoRef.value
					}).then(res => {
						if(res.data.code === 200) {
							MessageTips.success('提交成功');
							router.back();
						}else {
							MessageTips.error(res.data.msg)
						}
					})
				}else {
					console.log('error')
					return ;
				}
			})
		}
		const searchText = ref({
			company: '',
		})

		const loadingCom = ref(false)
		const comData = ref([])
		const handleCom = async (query) => {
			searchText.value.company = query
			if (query.length > 1) {
				loadingCom.value = true
				Ajax.POST({
					url: '/admin/company/search',
					data: {
						name : query
					}
				}).then(res => {
					loadingCom.value = false
					selectNotFind.value = false;
					if(res.data.code === 200) {
						comData.value = res.data.data
					}

					if (comData.value.length < 1) {
						selectNotFind.value = true;
					}
				}).catch(res => {
					loadingCom.value = false
					compData.value = []
				})
			} else {
				comData.value = []
			}
		}

		const getSelectInfo = (val) => {
			Ajax.GET({
				url: '/admin/company/' + val,
				data: {}
			}).then(res => {
				if(res.data.code === 200) {
					debugger
					selectData.value = res.data.data;
					companyBaseInfoRef.value.establish_time = res.data.data.establish_time || ''
					companyBaseInfoRef.value.image = res.data.data.image || ''
					companyBaseInfoRef.value.link = res.data.data.link || ''
					companyBaseInfoRef.value.mobile = res.data.data.mobile || ''
					companyBaseInfoRef.value.telephone = res.data.data.telephone || ''
					companyBaseInfoRef.value.email = res.data.data.email || ''
					companyBaseInfoRef.value.introduction = res.data.data.profile || ''

				}
			}).catch(res => {
				selectData.value = {};
			})
		}

		const avatarUploadSuccess = (e, type) => {
			companyBaseInfoRef.value.image = e.data.url;
		}

		const getTrades = () => {
			Ajax.POST({
				url: '/admin/tags',
				data: {}
			}).then(res => {
				if(res.data.code === 200) {
					trades.value = res.data.data;
				}
			}).catch(res => {
				trades.value = {};
			})
		}

		const getInfo = () => {
			Ajax.POST({
				url: '/admin/brand/detail/' + route.query.id,
				data: {}
			}).then(res => {
				if(res.data.code === 200) {
					comData.value.push({id:res.data.data.company_id, register_name:res.data.data.register_name})
					//将标签中的pid_path转为数组直接放进组件
					// let tradeArr=[];
					// if(res.data.data.tag.length>0){
					// 	res.data.data.tag.forEach(item=>{
					// 		let child=item.pid_path.split(","),childNum=[];
					// 		child.forEach(num=>{
					// 			childNum.push(Number(num))
					// 		})
					// 		tradeArr.push(childNum)
					// 	})
					// }
					companyBaseInfoRef.value = res.data.data;
					selectData.value.brand = res.data.data.brand
					selectData.value.reg_no = res.data.data.reg_no
					selectData.value.country_name = res.data.data.country_name
					selectData.value.province_name = res.data.data.province_name
					selectData.value.city_name = res.data.data.city_name
					proxy.$refs.checkTag.setValue(res.data.data.tag)
				}
			}).catch(res => {
				companyBaseInfoRef.value = {};
			})
		}

		onMounted(() => {
			getTrades()
			getInfo()
		})
		return {
			breadLists: breadListsRef,
			companyBaseInfo: companyBaseInfoRef,
			companyBaseInfoRule,
			cancelSubmit,
			submitForm,
			baseUrl,
			adminToken,
			loadingCom,
			comData,
			handleCom,
			getSelectInfo,
			selectNotFind,
			addCompany,
			dialogTitle,
			showDialog,
			dialogClose,
			showAddClose,
			avatarUploadSuccess,
			trades,
			selectData,
			cascaderParams,
		}
	}
};
</script>

<style lang="less" scoped>
.new-brand {
	margin-left: 15px;
}

.me-feedback {
	margin-left: 10px;
}

.me-feedback-message {
	color: #909399;
}

.me-select-search-empty {
	margin: 10px 0px;
	text-align: center;
}

.create-company-container {
	height: 100%;
	overflow-y: auto;
	width: 100%;
.write-box {
	background-color: #fff;
}
.user-form-box {
	width: 50%;
}
.bottom-submit-box {
	background-color: #fff;
}
.el-tag + .el-tag {
	margin-left: 10px;
}
.button-new-tag {
	margin-left: 10px;
	height: 32px;
	line-height: 30px;
	padding-top: 0;
	padding-bottom: 0;
}
.input-new-tag {
	width: 90px;
	margin-left: 10px;
	vertical-align: bottom;
}
.form-container{
	overflow: auto;
}

.avatar-uploader {
	width: 108px;
	height: 108px;
::v-deep(.el-upload) {
	width: 108px;
	height: 108px;
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 108px;
	height: 108px;
	line-height: 108px;
	text-align: center;
}
.avatar {
	width: 108px;
	height: 108px;
}
}
}

</style>
