<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-social-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">高校院所基本信息</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="schoolBaseInfo"
                                :rules="schoolBaseInfoRule"
                                label-width="120px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="名称" prop="name">
                                    <el-input v-model="schoolBaseInfo.name" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="国家" prop="country">
                                    <!-- <el-select 
                                        v-model="schoolBaseInfo.country" 
                                        placeholder="请选择"
                                        @change="coutryChange"
                                    >
                                        <el-option 
                                            v-for="option in countrys"
                                            :key="option.id"
                                            :label="option.name" 
                                            :value="option.id" 
                                        />
                                    </el-select> -->
                                    <el-select class="select w-100" placeholder="请选择"
                                        :popper-append-to-body="false" v-model="schoolBaseInfo.country"
                                        @change="coutryChange"
                                        filterable
                                        remote
                                        :reserve-keyword="false"
                                        :remote-method="countryRemoteMethod"
                                    >
                                        <el-option
                                            v-for="item in countrys"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        >
                                        {{item.name}}
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="地区" prop="province">
                                    <el-cascader
                                        v-model="provinceVal"
                                        :options="provincesArr"
                                        @change="provinceChange"
                                        :props="{
                                            value: 'id',
                                            label: 'name',
                                            children: 'child'
                                        }"
                                    ></el-cascader>
                                </el-form-item>
                                <el-form-item label="简介" prop="desc">
                                    <el-input v-model="schoolBaseInfo.desc" :autosize="{ minRows: 2, maxRows: 14 }" maxlength="500" type="textarea" placeholder="请输入" show-word-limit></el-input>
                                </el-form-item>
                                <el-form-item label="封面图片" prop="image">
									<el-upload
											class="avatar-uploader"
											:show-file-list="false"
                                            :action="`${baseUrl}admin/upload/image`"
                                            :on-success="(value) => avatarUploadSuccess(value)"
                                            :headers="{ Authorization: 'Bearer ' + adminToken }"
                                            name="image"
                                            accept="image/jpg,image/jpeg,image/png"
                                            :before-upload="
                                                (file) => {
                                                return handleBeforeUpload(file);
                                                }
                                            "
                                        >
										<img
												v-if="schoolBaseInfo.image"
												:src="schoolBaseInfo.image"
												class="avatar"/>
										<el-icon v-else class="avatar-uploader-icon"><plus/></el-icon>
									</el-upload>
                                    <div>限制大小5M,支持JPG、PNG格式</div>
								</el-form-item>
                                <el-form-item label="学校特色" prop="feature">
                                    <el-checkbox-group v-model="schoolBaseInfo.feature">
                                        <el-checkbox :label="`${item.id}`" name="feature" :key="item.id" v-for="item in featureList">{{item.name}}</el-checkbox>
                                    </el-checkbox-group>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, onBeforeMount } from 'vue'
import { Ajax, MessageTips } from '../../../../utils'
import { useRouter } from 'vue-router'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const router = useRouter();
        const breadListsRef = ref([
            {
                name: '高校院所',
                path: {
                    name: 'OrganizationSchool'
                }
            },
            {
                name: '创建高校院所'
            }
        ])
        const { proxy } = getCurrentInstance();
        const countrysRef = ref([]);
        const provincesArrRef = ref([]);
        const provinceValRef = ref(null);
        let regionArr = [];
        const featureList = ref([])
        const baseUrl = ref(window.env.baseUrl)
        const adminToken = ref(localStorage.getItem("adminToken"))
        onBeforeMount(async () => {
            const adminRegions = localStorage.getItem('adminRegions');
            if(adminRegions) {
                const regs = JSON.parse(adminRegions);
                regionArr = regs;
                countrysRef.value = regionArr.map(({id, name}) => ({
                    id,
                    name
                }))
            }else {
                const res = await Ajax.GET({
                    url: '/admin/region'
                })
                const resData = res.data;
                if(resData.code === 200) {
                    regionArr = resData.data;
                    countrysRef.value = regionArr.map(({id, name}) => ({
                        id,
                        name
                    }))
                }
            }
            Ajax.POST({
                url: `/admin/university/feature`
            }).then(res => {
                if(res.data.code === 200) {
                    featureList.value = res.data.data
                    console.log('特色res', res)
                }
            })
        })
        // 国家选择
        const coutryChange = val => {
            const findObj = regionArr.find(item => item.id === val);
            schoolBaseInfoRef.value.country = val;
            provincesArrRef.value = findObj?findObj.child:[];
        }
        const countryRemoteMethod = query =>{
            if (query) {
                setTimeout(() => {
                countrysRef.value = regionArr.filter((item) => {
                    return item.name.includes(query)
                })
                }, 200)
            } else {
                countrysRef.value = []
            }
        }
        // 地区选择
        const provinceChange = val => {
            schoolBaseInfoRef.value.province = val[0]
            schoolBaseInfoRef.value.city = val[1]
        }
        const schoolBaseInfoRef = ref({
            name: '',
            country: null,
            province: null,
            city: null,
            desc: '',
            feature: []
        })
        const schoolBaseInfoRule = {
            name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }
            ],
            image: [
                {
                    required: true,
                    message: '请上传封面图片',
                    trigger: 'blur'
                }
            ]
        }
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        const submitForm = formName => {
            let Arr = schoolBaseInfoRef.value.feature.filter(item => item)
            let params = Object.assign({}, schoolBaseInfoRef.value, {feature: Arr.join()})
            console.log('params', params)
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    console.log(schoolBaseInfoRef.value, 'schoolBaseInfoRef')
                    console.log('submit')
                    Ajax.POST({
                        url: '/admin/university',
                        data: params
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('新建成功')
                            router.back();
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }
        // 上传图片
        const avatarUploadSuccess = (e) => {
            console.log('e==========', e)
            schoolBaseInfoRef.value.image = e.data.url;
        };

        const handleBeforeUpload = (file) => {
            console.log(file.type, ['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type));
            let msg = ''
            const isLt5M = file.size / 1024 / 1024 < 5;
            if (['image/png', 'image/jpeg', 'image/jpg'].indexOf(file.type) == -1) {
                msg = '图片格式有误，请重新上传'
            }
            if (!isLt5M) {
                msg = '上传文件大小不能超过 5MB!'
            }

            if (msg) {
                MessageTips.error(msg)
                return false
            }
        };
        return {
            breadLists: breadListsRef,
            countrys: countrysRef,
            provincesArr: provincesArrRef,
            provinceVal: provinceValRef,
            coutryChange,
            countryRemoteMethod,
            provinceChange,
            schoolBaseInfo: schoolBaseInfoRef,
            schoolBaseInfoRule,
            cancelSubmit,
            submitForm,
            baseUrl,
            adminToken,
            avatarUploadSuccess,
            handleBeforeUpload,
            featureList
        }
    }
};
</script>

<style lang="less" scoped>
    .create-social-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
    }
    .avatar-uploader {
        width: 200px;
        height: 200px;
        ::v-deep(.el-upload) {
            width: 200px;
            height: 200px;
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 200px;
            height: 200px;
            line-height: 200px;
            text-align: center;
        }
        .avatar {
            width: 200px;
        }
    }
</style>