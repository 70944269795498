<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">运营基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="operationForm"
                :rules="operationFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="operationForm.name"
                    placeholder="请输入"
                    :maxlength="50"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                  <el-input
                    v-model="operationForm.phone"
                    placeholder="请输入"
                    :maxlength="11"
                    @input="(e) => (operationForm.phone = Rule.inputNum(e))"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="operationForm.email"
                    placeholder="请输入"
                    @input="(e) => (operationForm.email = Rule.inputChe(e))"
                  ></el-input>
                </el-form-item>
                <el-form-item label="所属部门" prop="dept_id">
                  <el-select
                    style="width: 100%"
                    v-model="operationForm.dept_id"
                    placeholder="请选择"
                  >
                    <el-option label="编辑部" :value="1"></el-option>
                    <el-option label="运营部" :value="2"></el-option>
                    <el-option label="产研中心" :value="3"></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom-submit-box pd-16 mg-t-24">
              <el-row justify="end">
                <el-col :span="24" class="text-right">
                  <el-button plain @click="cancelSubmit('formRef')"
                    >取消</el-button
                  >
                  <el-button type="primary" @click="submitForm('formRef')"
                    >保存</el-button
                  >
                </el-col>
              </el-row>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Ajax, MessageTips, Rule } from "../../utils";
export default {
  components: {
     Header,
    BreadCrumb,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const breadLists = ref([
      { name: "运营",
      path:{
        name:'operating'
      } 
      },{
        name:'新增运营人员'
      }
      ]);
    const operationFormRef = ref({
      name: undefined,
      phone: undefined,
      email: undefined,
      dept_id: undefined,
    });
    const operationFormRule = {
      name: [
        {
          required: true,
          message: "请输入",
          trigger: "blur",
        },
      ],
      phone: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (!value) {
              callback(new Error("请输入"));
            } else {
              if (!Rule.isMobile(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (rule.required && !value) {
              callback(new Error("请输入"));
            } else {
              if (value && !Rule.isMail(value)) {
                callback(new Error("请输入正确的邮箱"));
              } else {
                callback();
              }
            }
          },
          trigger: "blur",
        },
      ],
      dept_id: [
        {
          required: true,
          message: "请选择所属部门",
          trigger: "change",
        },
      ],
    };
    const depart = ref([
      {
        id: 1,
        name: "编辑部",
      },
      {
        id: 2,
        name: "运营部",
      },
      {
        id: 3,
        name: "产研中心",
      },
    ]);
    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      back();
    };
    const back = () => {
      router.go(-1);
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        console.log(valid, operationFormRef.value);
        if (valid) {
          Ajax.POST({
            url: "/admin/admin",
            data: operationFormRef.value,
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("新增成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };
    return {
      breadLists,
      operationForm: operationFormRef,
      operationFormRule,
      cancelSubmit,
      submitForm,
      depart,
      Rule,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 50%;
    // padding: 0px 180px;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
}
</style>