<template>
  <el-dialog
      :title="title"
      append-to-body
      destroy-on-close
      :model-value="showDialog"
      @close="closeDialog()"
      :close-on-click-modal=false
  >
    <el-form
        :model="form"
        ref="formRef"
        :rules="rules"
        label-width="140px"
        :inline="false"
    >
      <el-form-item label="主体主体全名" prop="company_name">
        <el-input v-model="company_name" placeholder="" clearable></el-input>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="reg_no">
        <el-input
            autocomplete='off'
            v-model="reg_no"
            placeholder=""
            clearable
        ></el-input>
      </el-form-item>
    </el-form>

    <template v-slot:footer>
      <span>
        <el-button :type="primary" @click="onSubmit">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {reactive, toRefs, ref, defineProps, defineEmits} from "vue";
import { Ajax, MessageTips } from "@/utils";

const rules = {
	company_name: [
    {
      required: true,
      message: "主体主体全名",
      trigger: "blur",
    },
  ],
	reg_no: [
    {
      required: true,
      message: "统一社会信用代码",
      trigger: "blur",
    },
  ]
};
const props = defineProps({
  showDialog: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "添加",
  },
  rowData: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["closeDialog", 'getTableData']);
const {title, rowData} = toRefs(props);
const closeDialog = () => {
  emit("closeDialog", false);
};
const formRef = ref(null);
const form = reactive({
	reg_no: "",
	company_name: ""
});
const {company_name, reg_no} = toRefs(form);
const roleList = ref([]);

/**
 * @description:提交
 * @param {*}
 * @return {*}
 */
const onSubmit = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
			Ajax.POST({
				url: '/admin/task/company/create',
				params: {
					...form
				}
			}).then(res => {
				if(res.data.code === 200) {
					MessageTips.success('成功');
					emit("getTableData");
				}else {
					MessageTips.error(res.data.msg)
				}
			}).catch((err) => {
				MessageTips.error('失败1')
			});
      closeDialog();
    } else {
      return false;
    }
  });
};
</script>

<style lang="scss" scoped></style>
