<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="person-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">{{ nowPageTitle }}（{{ tableData && tableData.length }}）</span>
                                <template v-if="nowPageType === 'cooperation-agency'">
                                    <el-button type="primary" @click="openDialog"><el-icon><plus /></el-icon> 新增</el-button>
                                </template>
                            </div>
                        </template>
                        <div class="table-container pd-32">
                            <!--
                                * member  主体成员
                                * financing-in  对内融资
                                * financing-out    对外投资
                                * cooperation-agency   竞合关系
                                * reports  相关报道
                            -->
                            <el-table v-loading="tableLoading" :data="tableData" border :max-height="650">
                                <!-- 机构成员 -->
                                <template v-if="nowPageType === 'member'">
                                    <el-table-column prop="id" label="人物ID" />
                                    <el-table-column prop="name" label="姓名" />
                                    <el-table-column prop="sex" label="性别">
                                        <template #default="scope">{{ scope.row.sex == 1 ? '男' : scope.row.sex == 2 ? '女' : '-' }}</template>
                                    </el-table-column>
                                    <el-table-column prop="country" label="国家" />
                                    <el-table-column prop="province" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province }} {{ scope.row.city }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="type" label="人物类型">
                                        <template #default="scope">
                                            {{ personType[scope.row.type] }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="post" label="职位" />
                                    <el-table-column prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 对内融资 -->
                                <template v-else-if="nowPageType === 'financing-in'">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="round" label="轮次" />
                                    <el-table-column prop="finace_time" label="时间" />
                                    <el-table-column prop="amount" label="投资金额">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate==1 ? scope.row.amount : scope.row.no_amount }}{{scope.row.amount_unit_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="all_investor_arr" label="投资方" />
                                    <el-table-column label="估值" width="180">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate_app==1 ? scope.row.appraise : scope.row.no_appraise }}{{scope.row.appraise_unit_name}}
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 对外投资 -->
                                <template v-else-if="nowPageType === 'financing-out'">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="finace_time" label="投资时间" />
                                    <el-table-column prop="company_name" label="名称" />
                                    <el-table-column prop="round" label="投资轮次" />
                                    <el-table-column prop="amount" label="投资金额">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate==1 ? scope.row.amount : scope.row.no_amount }}{{scope.row.amount_unit_name}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="other_investor_arr" label="其他投资方" />
                                </template>
                                <!-- 投资事件 -->
                                <template v-else-if="nowPageType === 'event-investment'">
                                    <el-table-column prop="id" label="序号" />
                                    <el-table-column prop="id" label="主体ID" />
                                    <el-table-column prop="title" label="主体名称" />
                                    <el-table-column prop="author" label="投资轮次" />
                                    <el-table-column prop="journal" label="投资金额" />
                                    <el-table-column prop="date" label="时间" />
                                    <el-table-column prop="status" label="状态" >
                                        <template #default="scope">
                                            <template v-if="scope.row.status === 1">
                                                <span class="status-text primary">已发布</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 2">
                                                <span class="status-text success">已完善</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 3">
                                                <span class="status-text danger">待完善</span>
                                            </template>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 竞合关系 -->
                                <template v-else-if="nowPageType === 'cooperation-agency'">
                                    <el-table-column prop="coopetition_company_id" label="主体ID" />
                                    <el-table-column prop="name" label="主体名称" />
                                    <!-- <el-table-column prop="country" label="国家" /> -->
                                    <el-table-column prop="province" label="地区" >
                                        <template #default="scope">
                                            {{ scope.row.country }} {{ scope.row.province }} {{ scope.row.city }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="establish_time" label="成立时间" />
                                    <!-- <el-table-column  label="操作" >
                                        <template #default="scope">
                                            <span style="cursor:pointer" @click="deleteCompete(scope.row.id)" >删除</span>
                                        </template>
                                    </el-table-column> -->
                                </template>

                                <!-- 软著列表 -->
                                <template v-else-if="nowPageType === 'company-worksList'">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="name" label="软件名称" />
                                    <el-table-column prop="profile" label="简介" />
                                    <el-table-column prop="version" label="版本号" />
                                    <el-table-column prop="classify" label="软著分类" />
                                    <el-table-column prop="trade" label="行业分类" />
                                    <el-table-column prop="register_time" label="等级日期" />
                                    <el-table-column prop="name" label="主体名称" />
                                    <el-table-column prop="province" label="详情">
                                        <template #default="scope">
                                            <el-link :href="scope.row.link" type="primary">详情</el-link>
                                        </template>
                                    </el-table-column>
                                </template>

                                <!-- 专利信息 -->
                                <template v-else-if="nowPageType === 'company-patent'">
                                    <el-table-column prop="apply_no" label="申请号" />
                                    <el-table-column prop="announce_time" label="公开(公告日)" />
                                    <el-table-column prop="name" label="名称" />
                                    <el-table-column prop="type" label="类型" />
                                    <el-table-column prop="announce_time" label="公布/公告日期" />
                                    <el-table-column prop="province" label="详情">
                                        <template #default="scope">
                                            <el-link :href="scope.row.link" type="primary">详情</el-link>
                                        </template>
                                    </el-table-column>
                                </template>

                                <!-- 相关报道 -->
                                <template v-else-if="nowPageType === 'reports'">
                                    <el-table-column prop="id" label="序号" />
                                    <el-table-column prop="title" label="报道题目" />
                                    <el-table-column prop="author" label="来源" />
                                    <el-table-column prop="journal" label="链接">
                                        <template #default="scope">
                                            <el-link :href="scope.row.journal" type="primary">{{ scope.row.journal }}</el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="status" label="状态" >
                                        <template #default="scope">
                                            <template v-if="scope.row.status === 1">
                                                <span class="status-text primary">已发布</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 2">
                                                <span class="status-text success">已完善</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 3">
                                                <span class="status-text danger">待完善</span>
                                            </template>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                            <div class="table-pagination-row mg-t-24">
                                <el-pagination
                                    background
                                    :currentPage="paginationData.current"
                                    :page-sizes="paginationData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="paginationData.total"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </el-card>
                    <el-dialog v-model="dialogShow" title="选择主体" :width="800" :close-on-click-modal="false" :show-close="false" destroy-on-close>
                        <div class="dialo-content">
                            <div class="mg-b-16" style="width: 200px">
                                <el-input v-model="dialogSearchInp" placeholder="搜索单位" :suffix-icon="Search" @change="dialogSearchChange"></el-input>
                            </div>
                            <el-table v-loading="unitTableLoading" :data="dialogTable" border max-height="300">
                                <template v-if="nowPageType === 'edit-teach'">
                                    <el-table-column prop="school" label="学校名称">
                                        <template #default="scope">
                                            <el-input v-if="scope.row.type=='new'" v-model="scope.row.name" placeholder="请输入"></el-input>
                                            <span v-else>{{scope.row.school}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="desc" label="学校简介" />
                                    <el-table-column label="操作">
                                        <template #default="scope">
                                            <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
                                        </template>
                                    </el-table-column>
                                </template>
                                <template v-else>
                                    <el-table-column prop="name" label="主体名称">
                                        <template #default="scope">
                                            <el-input v-if="scope.row.type=='new'" v-model="scope.row.name" placeholder="请输入"></el-input>
                                            <span v-else>{{scope.row.name}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作">
                                        <template #default="scope">
                                            <el-radio v-model="dialogRadio" :label="scope.row.id">选择</el-radio>
                                        </template>
                                    </el-table-column>
                                </template>
                                <template #empty>
                                没有匹配的结果
                                </template>
                            </el-table>
                            <!-- <div class="table-pagination-row mg-t-24">
                                <el-pagination
                                    background
                                    :currentPage="dialogPaginationData.current"
                                    :page-sizes="dialogPaginationData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="dialogPaginationData.total"

                                    @size-change="dialogHandleSizeChange"
                                    @current-change="dialogHandleCurrentChange"
                                >
                                </el-pagination>
                            </div> -->
                        </div>
                        <template #footer>
                            <el-button plain @click="dialogClose">返回</el-button>
                            <el-button type="primary" @click="dialogSubmit">完成</el-button>
                        </template>
                    </el-dialog>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import { computed, ref, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Plus, Search } from '@element-plus/icons-vue'
import { Ajax, configData, MessageTips } from '../../../utils'
export default {
    components: {
        Plus,
        Header,
        BreadCrumb
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const breadListsRef = ref([
            {
                    name: '主体',
                    path: {
                        name: 'Company'
                    }
                },
                {
                    name: '主体详情',
                    path: {
                        name: 'CompanyDetail',
                        query: {
                            id: route.query.id
                        }
                    }
                },
                {
                    name: route.query.pageName
                }
        ])
        const apiUrlRef = ref('');
        const tableLoadingRef = ref(false);
        const unitTableLoadingRef = ref(false);
        const personType = configData.personType;
        const nowPageType = computed(() => {
            /**
             * member  主体成员
             * financing-in  对内融资
             * financing-out    对外投资
             * cooperation-agency   竞合关系
             * company-worksList 软著列表
             * company-patent 专利信息
             * reports  相关报道
             */
            return route.query.type
        })
        const nowPageTitle = computed(() => {
            return route.query.pageName
        });

        const tableDataRef = ref([])

        const paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            total: 0,
            page_size: 10,
        })
        const handleSizeChange = e => {
            paginationDataRef.value.page_size = e;
            getList();
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList()
        }

        const getList = () => {
            tableLoadingRef.value = true;
            if(['financing-in', 'financing-out'].includes(nowPageType.value)) {
                Ajax.POST({
                    url: apiUrlRef.value,
                    params: {
                        page: paginationDataRef.value.current,
                        page_size: paginationDataRef.value.page_size
                    }
                }).then(res => {
                    tableLoadingRef.value = false;
                    if(res.data.code === 200) {
                        tableDataRef.value = res.data.data.list;
                        paginationDataRef.value.total = res.data.data.page_info.total
                    }
                })
            }else if(['company-worksList', 'company-patent'].includes(nowPageType.value)){
                Ajax.POST({
                    url: apiUrlRef.value,
                    params: {
                        company_id: +route.query.id,
                        page: paginationDataRef.value.current,
                        page_size: paginationDataRef.value.page_size
                    }
                }).then(res => {
                    tableLoadingRef.value = false;
                    if(res.data.code === 200) {
                        tableDataRef.value = res.data.data.list;
                        paginationDataRef.value.total = res.data.data.page_info.total
                    }
                })
            }else {
                Ajax.GET({
                    url: apiUrlRef.value,
                    params: {
                        company_id: +route.query.id,
                        page: paginationDataRef.value.current,
                        page_size: paginationDataRef.value.page_size
                    }
                }).then(res => {
                    tableLoadingRef.value = false;
                    if(res.data.code === 200) {
                        tableDataRef.value = res.data.data.list;
                        paginationDataRef.value.total = res.data.data.page_info.total
                    }
                })
            }
        }

        onBeforeMount(() => {
            // 列表数据获取
            switch (nowPageType.value) {
                case 'member':
                    apiUrlRef.value = '/admin/company_member'
                    break;
                case 'cooperation-agency':
                    apiUrlRef.value = '/admin/company_coopetition'
                    // 需要先拿取机构主体的列表数据
                    getUnitList();
                    break;
                // 对内融资
                case 'financing-in':
                    apiUrlRef.value = `/admin/company/getinvest/${route.query.id}`
                    break;
                // 对外投资
                case 'financing-out':
                    apiUrlRef.value = `/admin/company/invest/${route.query.id}`
                    break;
                // 软著列表
                case 'company-worksList':
                    apiUrlRef.value = `/admin/company/works`
                    break;
                // 专利信息
                case 'company-patent':
                    apiUrlRef.value = `/admin/company/patent`
                    break;
                default:
                    break;
            }
            getList();
        })

         /* 弹窗选择 start */
        const dialogShowRef = ref(false);
        const dialogSearchInpRef = ref('')
        const dialogTableRef = ref([])
        const dialogRadioRef = ref('')
        const dialogPaginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            total: 0,
            page_size: 10,
        })
        // 获取机构列表
        const getUnitList = name => {
            unitTableLoadingRef.value = true;
            addAuditPersonRef.value = false;
            dialogRadioRef.value = undefined;
            Ajax.GET({
                url: '/admin/personage/search_unit',
                params: {
                    name,
                    type: 2
                }
            }).then(res => {
                unitTableLoadingRef.value = false;
                if(res.data.code === 200) {
                    dialogTableRef.value = res.data.data;
                }
            })
        }
        const dialogSearchChange = value => {
            getUnitList(value)
        }
        const dialogHandleSizeChange = e => {
            console.log(e)
        }
        const dialogHandleCurrentChange = e => {
            paginationDataRef.value.current = e;
        }
        const openDialog = () => {
            dialogShowRef.value = true;
        }
        // 添加临时数据
        const addAuditPersonRef = ref(false)
        const newSelectTypeRef = ref(null)
        const addAuthor = type => {
            addAuditPersonRef.value = true;
            let unit_type = undefined;
            if(type === 'cooperation-agency') {
                unit_type = 2
            }
            dialogTableRef.value[0]={
                type: 'new',
                name: dialogSearchInpRef.value,
                unit_type: newSelectTypeRef.value,
                id: 0
            }
            console.log(dialogTableRef.value)
        }
        const dialogClose = () => {
            dialogShowRef.value = false;
            dialogRadioRef.value = undefined;
            addAuditPersonRef.value = false;
            dialogTableRef.value.splice(0)
            dialogSearchInpRef.value = ''
            newSelectTypeRef.value = null;
        }
        const dialogSubmit = () => {
            if(!dialogRadioRef.value && dialogRadioRef.value !== 0) return;
            // 临时数据添加
            if(addAuditPersonRef.value) {
                if(dialogRadioRef.value !== 0) return ;
                dialogTableRef.value[0].unit_type = newSelectTypeRef.value;
                // if(!(dialogTableRef.value[0].name && dialogTableRef.value[0].unit_type)) {
                //     MessageTips.warning('名称与类型必填')
                //     return ;
                // }
                Ajax.POST({
                    url: '/admin/personage/add_unit',
                    data: {
                        name: dialogTableRef.value[0].name,
                        unit_type: 2,
                    }
                }).then(res => {
                    if(res.data.code==200){
                        dialogTableRef.value[0].id = res.data.data.insertId
                        if(nowPageType.value === 'cooperation-agency') {
                            dialogRadioRef.value = res.data.data.insertId;
                            submitAdd(res.data.data.insertId)
                        }
                        dialogClose();
                    }else{
                        MessageTips.error(res.data.msg)
                        return
                    }
                })
                return ;
            }
            submitAdd(+dialogRadioRef.value)
        }

        const submitAdd = (id) => {
            Ajax.POST({
                url: '/admin/company_coopetition',
                data: {
                    company_id: +route.query.id,
                    coopetition_company_id: id
                }
            }).then(res => {
                if(res.data.code === 200) {
                    MessageTips.success('新增成功');
                    getList();
                    dialogClose();
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }
        /* 弹窗选择 end */

        // 删除竞合关系
        const deleteCompete=(id)=>{
            console.log(id)
        }

        return {
            breadLists: breadListsRef,
            personType,
            tableLoading: tableLoadingRef,
            tableData: tableDataRef,
            paginationData: paginationDataRef,
            handleSizeChange,
            handleCurrentChange,
            nowPageType,
            nowPageTitle,
            deleteCompete,
            
            // 弹窗
            dialogShow: dialogShowRef,
            unitTableLoading: unitTableLoadingRef,
            dialogSearchInp: dialogSearchInpRef,
            dialogTable: dialogTableRef,
            dialogRadio: dialogRadioRef,
            dialogPaginationData: dialogPaginationDataRef,
            dialogHandleSizeChange,
            dialogHandleCurrentChange,
            openDialog,
            dialogSearchChange,
            dialogClose,
            dialogSubmit,
            Search,
            addAuthor,
            addAuditPerson: addAuditPersonRef,
            newSelectType: newSelectTypeRef
        }
    }
}
</script>

<style lang="less" scoped>
    .person-detail-container {
        height: 100%;
        background-color: #fff;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
    }
</style>
