<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="create-tags-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">数据分类树基本信息</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <el-form
                                ref="formRef"
                                :model="tagsBaseInfo"
                                :rules="tagsBaseInfoRule"
                                label-width="140px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="数据分类树名称" prop="name">
                                    <el-input v-model="tagsBaseInfo.name" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="描述" prop="desc">
                                    <el-input v-model="tagsBaseInfo.desc" maxlength="100" show-word-limit type="textarea" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit('formRef')">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm('formRef')">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../Layout/components/Header.vue';
import BreadCrumb from '../../components/BreadCrumb/index.vue';
import {ref, getCurrentInstance} from 'vue'
import { Ajax, MessageTips } from '../../utils'
import { useRouter } from 'vue-router'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const { proxy } = getCurrentInstance();
        const router = useRouter();
        const breadListsRef = ref([
            {
                name: '数据分类',
                path: {
                    name: 'Tags'
                }
            },
            {
                name: '新增数据分类'
            }
        ])
        const tagsBaseInfoRef = ref({
            name: '',
            desc: ''
        })
        const tagsBaseInfoRule = {
            name: [
                {
                    required: true,
                    message: '请输入数据分类树名称',
                    trigger: 'blur'
                }
            ]
        }

        // 底部提交按钮
        const cancelSubmit = formName => {
            // proxy.$refs[formName].resetFields()
            router.back();
        }
        const submitForm = formName => {
            proxy.$refs[formName].validate(valid => {
                if(valid) {
                    Ajax.POST({
                        url: '/admin/tags/create',
                        // url: '/admin/label_tree',
                        data: {
                            ...tagsBaseInfoRef.value
                        }
                    }).then(res => {
                        if(res.data.code === 200) {
                            MessageTips.success('新增成功')
                            router.back()
                        }else {
                            MessageTips.error(res.data.msg)
                        }
                    })
                }else {
                    console.log('error')
                    return ;
                }
            })
        }
        return {
            breadLists: breadListsRef,
            tagsBaseInfo: tagsBaseInfoRef,
            tagsBaseInfoRule,
            cancelSubmit,
            submitForm
        }
    }
}
</script>

<style lang="less" scoped>
    .create-tags-container {
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        .el-tag + .el-tag {
            margin-left: 10px;
        }
        .button-new-tag {
            margin-left: 10px;
            height: 32px;
            line-height: 30px;
            padding-top: 0;
            padding-bottom: 0;
        }
        .input-new-tag {
            width: 90px;
            margin-left: 10px;
            vertical-align: bottom;
        }
    }
</style>