<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">退出事件基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="exitForm"
                :rules="exitFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="主体名称" prop="company_id">
                  <el-input
                    style="width: 400px"
                    v-model="exitForm.company_name"
                    placeholder="请选择"
                    @click="showDialog('company', '选择主体')"
                  ></el-input>
                </el-form-item>
                <el-form-item label="退出方式" prop="withdraw_type">
                  <el-select
                    style="width: 400px"
                    placeholder="请选择"
                    v-model="exitForm.withdraw_type"
                  >
                    <el-option
                      :label="item.name"
                      :value="item.id"
                      v-for="item in exitData"
                      :key="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="退出金额" prop="no_amount">
                  <el-input
                    style="width: 400px"
                    v-model="exitForm.no_amount"
                    placeholder="请输入"
                    :disabled="exitForm.is_accurate"
                  />
                  <el-input
                    v-model="exitForm.amount"
                    class="mg-l-32 mg-r-12"
                    style="width: 100px"
                    @input="(e) => (exitForm.amount = Rule.inputNum(e))"
                    :disabled="!exitForm.is_accurate"
                  />
                  <el-select
                    class="mg-r-12"
                    style="width: 100px"
                    v-model="exitForm.amount_unit"
                    :disabled="!exitForm.is_accurate"
                  >
                    <el-option
                      v-for="item in moneyUnits"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                  <el-checkbox
                    v-model="exitForm.is_accurate"
                    label="准确"
                    @change="accurateChange"
                  ></el-checkbox>
                </el-form-item>
                <el-form-item label="退出时间" prop="quit_time">
                  <el-date-picker
                    style="width: 400px"
                    v-model="exitForm.quit_time"
                    type="date"
                    placeholder="选择日期"
                    :value-format="'YYYY-MM-DD'"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="退出方" prop="investorobj">
                  <el-tag
                    class="mg-r-12"
                    v-for="(item, index) in exitForm.investorobj"
                    :key="item.id"
                    closable
                    :disable-transitions="false"
                    @close="deleteInvestor(index)"
                  >
                    {{ item.name }}
                  </el-tag>
                  <el-button
                    size="small"
                    @click="showDialog('exit', '选择退出方')"
                    >+ 新标签</el-button
                  >
                </el-form-item>
                <el-form-item
                  label="回报倍数"
                  class="mg-t-12"
                  prop="ret_multiple"
                >
                  <el-input
                    style="width: 400px"
                    placeholder="请输入"
                    v-model="exitForm.ret_multiple"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-24">
            <el-row justify="end">
              <el-col :span="24" class="text-right">
                <el-button plain @click="cancelSubmit('formRef')"
                  >取消</el-button
                >
                <el-button type="primary" @click="submitForm('formRef')"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
  <el-dialog
    v-model="tagDialogShow"
    :title="dialogDetail.title"
    :width="880"
    :close-on-click-modal="false"
    :show-close="true"
  >
    <div class="dialog-content">
      <el-input
        style="width: 200px"
        v-model="searchForm.name"
        placeholder="请输入"
        :suffix-icon="Search"
        @change="submit('search')"
      ></el-input>
      <el-table
        class="mg-t-12"
        :data="dialogTable"
        border
        max-height="400"
        v-loading="isLoading"
      >
        <el-table-column prop="name" label="名称">
          <template #default="scope">
            <el-input
              v-if="scope.row.status == 'new'"
              v-model="scope.row.name"
              placeholder="请输入"
            ></el-input>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="type"
          label="类型"
          v-if="dialogDetail.type == 'exit'"
        >
          <template #default="scope">
            <div v-if="scope.row.status == 'new'">
              <el-select
                class="mg-r-12"
                style="width: 100px"
                v-model="scope.row.unit_type"
              >
                <el-option label="机构" :value="1"></el-option>
                <el-option label="主体" :value="2"></el-option>
              </el-select>
            </div>
            <div v-else>
              <span v-if="scope.row.type == 'com'">主体</span>
              <span v-else-if="scope.row.type == 'org'">机构</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作">
          <template #default="scope">
            <el-radio
              v-if="dialogDetail.type == 'company'"
              v-model="companyId"
              :label="scope.row.id"
              @change="companyChange"
              >选择</el-radio
            >
            <el-checkbox
              v-else
              v-model="investors"
              :label="scope.row.id"
              @change="(status) => investorChange(scope.row.id, status)"
              >选择</el-checkbox
            >
          </template>
        </el-table-column>
        <template #empty>
          没有匹配的结果<el-button
            type="primary"
            class="mg-l-20"
            size="small"
            @click="addCompany()"
            >新增</el-button
          >
        </template>
      </el-table>
      <div class="table-pagination-row mg-t-24">
        <el-pagination
          v-if="dialogDetail.type == 'company'"
          background
          :currentPage="paginationData.current"
          :page-sizes="paginationData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paginationData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <el-button @click="tagDialogShow = false">取消</el-button>
      <el-button type="primary" @click="submit()">确定</el-button>
    </template>
  </el-dialog>
  <!-- </Layout> -->
</template>

<script>
import Header from "../../../../Layout/components/Header.vue";
import BreadCrumb from "../../../../components/BreadCrumb/index.vue";
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips, Rule } from "../../../../utils";
export default {
  components: {
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "退出事件",
        path: {
          name: "ExitEvent",
        },
      },
      {
        name: "退出事件详情",
        path: {
          name: "ExitEventDetail",
          query: {
            id: route.query.id,
          },
        },
      },
      {
        name: "编辑退出事件",
      },
    ]);
    const isLoading = ref(false);
    const searchForm = ref({
      page: 1,
      page_size: 10,
      name: undefined,
    });
    const dialogTable = ref([]);
    const companyId = ref();
    const companyObj = ref({});
    const companyChange = (id) => {
      if (!id) {
        Ajax.POST({
          url: "/admin/personage/add_unit",
          data: {
            name: dialogTable.value[0].name,
            unit_type: 2,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            dialogTable.value[0].id = res.data.data.insertId;
            companyId.value = res.data.data.insertId;
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
      }
      for (let i in dialogTable.value) {
        let item = dialogTable.value[i];
        if (item.id == companyId.value) {
          console.log(item);
          companyObj.value = item;
        }
      }
    };
    const investors = ref([]);
    const { proxy } = getCurrentInstance();
    const dialogDetail = ref({
      title: undefined,
      type: undefined,
    });
    const exitFormRef = ref({
      company_id: undefined,
      company_name: undefined,
      withdraw_type: undefined,
      amount: undefined,
      quit_time: undefined,
      investor: [],
      investorobj: [],
      company_ids: [],
      ret_multiple: undefined,
      amount_unit: undefined,
      is_accurate: undefined,
      no_amount: undefined,
    });
    const investorobj = ref([]);
    const getDetail = (id) => {
      Ajax.POST({
        url: `/admin/quit/details/${id}`,
      }).then((res) => {
        if (res.data.code == 200) {
          exitFormRef.value.company_id = res.data.data.company_id;
          exitFormRef.value.company_name = res.data.data.company_name;
          exitFormRef.value.withdraw_type = res.data.data.withdraw_type;
          exitFormRef.value.amount = res.data.data.amount;
          exitFormRef.value.quit_time = res.data.data.quit_time;
          exitFormRef.value.ret_multiple = res.data.data.ret_multiple;
          exitFormRef.value.amount_unit = res.data.data.amount_unit;
          exitFormRef.value.is_accurate =
            res.data.data.is_accurate == 1 ? true : false;
          exitFormRef.value.no_amount = res.data.data.no_amount;
          for (let i in res.data.data.investor_arr_info) {
            let item = res.data.data.investor_arr_info[i];
            item.type = "org";
            exitFormRef.value.investorobj.push(item);
          }
          for (let i in res.data.data.company_arr_info) {
            let item = res.data.data.company_arr_info[i];
            item.type = "com";
            exitFormRef.value.investorobj.push(item);
          }
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const paginationData = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      searchForm.value.page_size = e;
      if (dialogDetail.value.type == "company") {
        getCompany("search");
      }
    };
    const handleCurrentChange = (e) => {
      paginationData.value.current = e;
      searchForm.value.page = e;
      if (dialogDetail.value.type == "company") {
        getCompany();
      }
    };
    //获取主体
    const getCompany = (type) => {
      isLoading.value = true;
      if (type) {
        searchForm.value.page = 1;
        paginationData.value.current = 1;
      }
      Ajax.GET({
        url: "/admin/company",
        params: searchForm.value,
      }).then((res) => {
        isLoading.value = false;
        dialogTable.value = res.data.data.list;
        paginationData.value.total = res.data.data.page_info.total;
      });
    };
    const addCompany = () => {
      dialogTable.value[0] = {
        status: "new",
        name: searchForm.value.name,
        id: 0,
      };
      paginationData.value.total = 1;
    };
    const exitData = ref([]);
    //获取退出方式
    const getExitType = () => {
      Ajax.POST({
        url: "/admin/quit/exitMode",
      }).then((res) => {
        exitData.value = res.data.data;
      });
    };

    //获取退出方
    const getExitor = (type) => {
      isLoading.value = true;
      if (type) {
        searchForm.value.page = 1;
        paginationData.value.current = 1;
        dialogTable.value = [];
      }
      Ajax.POST({
        url: "/admin/quit/invest",
        data: searchForm.value,
      }).then((res) => {
        isLoading.value = false;
        if (res.data.code == 200) {
          let com = ref(res.data.data.com);
          let org = ref(res.data.data.org);
          for (let i in com.value) {
            let item = com.value[i];
            item.type = "com";
            dialogTable.value.push(item);
          }
          for (let i in org.value) {
            let item = org.value[i];
            item.type = "org";
            dialogTable.value.push(item);
          }
          console.log(dialogTable.value);
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const showDialog = (type, title) => {
      dialogDetail.value.type = type;
      dialogDetail.value.title = title;
      searchForm.value.page = 1;
      searchForm.value.name = undefined;
      paginationData.value.total = 0;
      paginationData.value.current = 1;
      dialogTable.value = [];
      if (type == "company") {
        getCompany();
      } else {
        investors.value = [];
        investorobj.value = [];
        for (let i in exitFormRef.value.investorobj) {
          let item = exitFormRef.value.investorobj[i];
          investors.value.push(item.id);
          investorobj.value.push(item);
        }
      }
      tagDialogShowRef.value = true;
    };

    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      router.go(-1);
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit");
          exitFormRef.value.investor = [];
          exitFormRef.value.company_ids = [];
          for (let i in exitFormRef.value.investorobj) {
            let item = exitFormRef.value.investorobj[i];
            if (item.type == "com") {
              exitFormRef.value.company_ids.push(item.id);
            } else {
              exitFormRef.value.investor.push(item.id);
            }
          }
          Ajax.POST({
            url: `/admin/quit/update/${route.query.id}`,
            data: {
              company_id: exitFormRef.value.company_id,
              withdraw_type: exitFormRef.value.withdraw_type,
              amount: exitFormRef.value.amount,
              quit_time: exitFormRef.value.quit_time,
              investor: exitFormRef.value.investor,
              company_ids: exitFormRef.value.company_ids,
              ret_multiple: exitFormRef.value.ret_multiple,
              amount_unit: exitFormRef.value.amount_unit,
              is_accurate: exitFormRef.value.is_accurate ? 1 : 2,
              no_amount: exitFormRef.value.no_amount,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("新增成功");
              router.go(-1);
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };
    const exitFormRule = {
      company_id: [
        {
          required: true,
          message: "请选择",
          trigger: "blur",
        },
      ],
      withdraw_type: [
        {
          required: true,
          message: "请选择",
          trigger: "change",
        },
      ],
      no_amount: [
        {
          required: true,
           validator: (rule, value, callback) => {
            if (!exitFormRef.value.no_amount&&!exitFormRef.value.amount) {
              callback(new Error("请输入"));
            } else {
                callback();
            }
          },
          trigger: "blur",
        },
      ],
      quit_time: [
        {
          required: true,
          message: "请选择",
          trigger: "blur",
        },
      ],
      investorobj: [
        {
          required: true,
          message: "请添加",
          trigger: "blur",
        },
      ],
    };
    const tagDialogShowRef = ref(false);
    const addNewTag = () => {
      tagDialogShowRef.value = true;
    };
    const submit = (type) => {
      if (type) {
        if (dialogDetail.value.type == "company") {
          getCompany("search");
        } else {
          getExitor("search");
        }
      } else {
        tagDialogShowRef.value = false;
        if (dialogDetail.value.type == "company") {
          exitFormRef.value.company_id = companyId;
          exitFormRef.value.company_name = companyObj.value.name;
        } else {
          exitFormRef.value.investorobj = [];
          for (let i in investorobj.value) {
            let item = investorobj.value[i];
            exitFormRef.value.investorobj.push(item);
          }
        }
      }
    };
    const investorChange = (id, status) => {
      if (!id && status) {
        let index = investors.value.indexOf(id);
        if (!dialogTable.value[0].name) {
          investors.value.splice(index, 1);
          MessageTips.error("请输入名称");
          return;
        }
        if (!dialogTable.value[0].unit_type) {
          investors.value.splice(index, 1);
          MessageTips.error("请选择类型");
          return;
        }
        Ajax.POST({
          url: "/admin/personage/add_unit",
          data: {
            name: dialogTable.value[0].name,
            unit_type: dialogTable.value[0].unit_type,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            dialogTable.value[0].id = res.data.data.insertId;
            dialogTable.value[0].status = undefined;
            dialogTable.value[0].type =
              dialogTable.value[0].unit_type == 1 ? "org" : "com";
            investors.value.push(res.data.data.insertId);
          } else {
            MessageTips.error(res.data.msg);
            investors.value.splice(index, 1);
            return;
          }
        });
      }
      if (status) {
        for (let i in dialogTable.value) {
          let item = dialogTable.value[i];
          if (item.id == id) {
            investorobj.value.push(item);
            break;
          }
        }
      } else {
        for (let i in investorobj.value) {
          let item = investorobj.value[i];
          if (item.id == id) {
            investorobj.value.splice(i, 1);
            break;
          }
        }
      }
    };
    const deleteInvestor = (index) => {
      investors.value.splice(index, 1);
      exitFormRef.value.investorobj.splice(index, 1);
    };
    const moneyUnits = ref([]);
    const getUnit = () => {
      Ajax.POST({
        url: "/admin/unit",
      }).then((res) => {
        moneyUnits.value = res.data.data;
      });
    };
    const accurateChange=()=>{
      if(exitFormRef.value.is_accurate){
        exitFormRef.value.no_amount=undefined
      }else{
        exitFormRef.value.amount=undefined
        exitFormRef.value.amount_unit=undefined
      }
    }
    onMounted(() => {
      getUnit();
      let id = route.query.id;
      getDetail(id);
      getExitType();
    });
    return {
      breadLists,
      exitForm: exitFormRef,
      cancelSubmit,
      submitForm,
      exitFormRule,
      addNewTag,
      tagDialogShow: tagDialogShowRef,
      searchForm,
      dialogTable,
      paginationData,
      handleSizeChange,
      handleCurrentChange,
      companyChange,
      showDialog,
      dialogDetail,
      submit,
      exitData,
      companyId,
      investorChange,
      investors,
      deleteInvestor,
      Rule,
      moneyUnits,
      addCompany,
      isLoading,
      accurateChange
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 80%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
  }
}
</style>