<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="edit-person-container">
          <!-- 编辑基本信息 -->
          <!-- <el-card class="mg-b-16" v-if="nowPageType === 'edit-basic'" style="overflow: auto;"> -->
          <el-card class="mg-b-16" style="overflow: auto">
            <template #header>
              <span class="card-name">人物基本信息</span>
              <el-button
                size="small"
                type="primary"
                style="float: right"
                plain
                @click="cancelSubmit"
                >返回</el-button
              >
            </template>
            <div class="user-basic-form-container">
              <el-form
                ref="basicFormRef"
                :model="userForm"
                :rules="userFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="姓名" prop="name">
                  <div style="display: flex">
                    <el-input v-model="userForm.name"></el-input>
                    <el-checkbox v-model="isDeceased" style="margin-left: 10px"
                      >已故/不对外推荐</el-checkbox
                    >
                  </div>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                  <el-radio-group v-model="userForm.sex">
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="国家" prop="province">
                  <el-select
                    class="select w-100"
                    placeholder="请选择"
                    :popper-append-to-body="false"
                    v-model="userForm.country"
                    @change="coutryChange"
                    filterable
                    remote
                    :reserve-keyword="false"
                    :remote-method="countryRemoteMethod"
                  >
                    <el-option
                      v-for="item in countrys"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                      >{{ item.name }}</el-option
                    >
                  </el-select>
                </el-form-item>
                <el-form-item label="地区" prop="province">
                  <el-cascader
                    v-model="provinceVal"
                    :options="provincesArr"
                    @change="provinceChange"
                    :props="{
                      value: 'id',
                      label: 'name',
                      children: 'child',
                    }"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="人物标签" prop="personTag">
                  <el-radio-group v-model="userForm.type">
                    <el-radio label="1">学者</el-radio>
                    <el-radio label="3">投资人</el-radio>
                    <el-radio label="2">创业者</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="人物简介" prop="profile">
                  <el-input
                    v-model="userForm.profile"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 14 }"
                    maxlength="500"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <!-- <checkTag ref="checkTag"></checkTag> -->

                <el-form-item
                  :label="
                    userForm.type === '1'
                      ? '研究领域'
                      : userForm.type === '3'
                      ? '投资领域'
                      : '创业领域'
                  "
                  prop="research_label"
                >
                  <checkTag ref="checkTag"></checkTag>
                </el-form-item>
                <!-- <el-form-item label="投资领域" prop="invest_label" v-show="userForm.type === '3'">
                                    <checkTag ref="checkTag"></checkTag>
                                </el-form-item>
                                <el-form-item label="创业领域" prop="startup_label" v-show="userForm.type === '2'">
                                    <checkTag ref="checkTag"></checkTag>
                </el-form-item>-->
              </el-form>
              <el-form
                ref="contactFormRef"
                :model="contactForm"
                :rules="contactFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="照片" prop="image">
                  <el-upload
                    class="avatar-uploader"
                    :class="{
                      border: !contactForm.image,
                    }"
                    :show-file-list="false"
                    :http-request="uploadImage"
                    action="#"
                    accept="image/jpg, image/jpeg, image/png"
                    :before-upload="beforeUpload"
                  >
                    <div v-if="contactForm.image" class="avatar-img-box">
                      <img :src="contactForm.image" class="avatar" />
                    </div>
                    <el-icon v-else class="avatar-uploader-icon">
                      <plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item label="任职单位" prop="unit_type">
                  <!-- <el-select v-model="userForm.unit_type" placeholder="请选择">
                                        <el-option label="机构" value="org"></el-option>
                                        <el-option label="主体" value="company"></el-option>
                                        <el-option label="高校" value="school"></el-option>
                                        <el-option label="社会组织" value="ser"></el-option>
                  </el-select>-->
                  <!-- <el-input placeholder="请选择" disabled @click="openDialog" /> -->
                  <template v-if="contactForm.unit_id">
                    <span
                      class="value-border mg-r-24"
                      @click="openDialog('edit-contact')"
                    >
                      <!-- <el-scrollbar> -->
                      {{ contactForm.unitName || contactForm.unit_name }}
                      <!-- </el-scrollbar> -->
                    </span>
                    <!-- <el-button plain type="primary" @click="openDialog">修改</el-button> -->
                  </template>
                  <span
                    v-else
                    class="input-span mg-r-24"
                    @click="openDialog('edit-contact')"
                    >请选择</span
                  >
                </el-form-item>
                <el-form-item label="职位" prop="post">
                  <el-input
                    v-model="contactForm.post"
                    placeholder="请输入"
                    maxlength="30"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系方式："></el-form-item>
                <el-form-item label="电话" prop="telephone">
                  <el-input
                    v-model="contactForm.telephone"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                  <el-input
                    v-model="contactForm.email"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="mobile">
                  <el-input
                    v-model="contactForm.mobile"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <el-row justify="end">
              <el-col :span="4">
                <el-button size="small" plain @click="cancelSubmit"
                  >取消</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  @click="submitForm('edit-basic')"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </el-card>
          <!-- 编辑补充信息 -->
          <!-- <el-card class="mg-b-16" v-else-if="nowPageType === 'edit-contact'"> -->
          <!-- <el-card class="mg-b-16" >
                        <template #header>
                            <span class="card-name">补充信息</span>
                        </template>
                        <div class="user-contact-form-container">
                            <el-form
                                ref="contactFormRef"
                                :model="contactForm"
                                :rules="contactFormRule"
                                label-width="120px"
                                class="user-form-box mg-t-24"
                            >
                                <el-form-item label="照片" prop="image">
                                    <el-upload
                                        class="avatar-uploader"
                                        :class="{
                                            border: !contactForm.image
                                        }"
                                        :show-file-list="false"
                                        :http-request="uploadImage"
                                        action="#"
                                        accept="image/jpg, image/jpeg, image/png"
                                        :before-upload="beforeUpload"
                                    >
                                        <div
                                            v-if="contactForm.image"
                                            class="avatar-img-box"
                                        >
                                            <img :src="contactForm.image" class="avatar" />
                                        </div>
                                        <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item label="任职单位" prop="unit_type">
                                    <template v-if="contactForm.unit_id">
                                        <span class="value-border mg-r-24" @click="openDialog">
                                                {{ contactForm.unitName || contactForm.unit_name }}
                                        </span>
                                    </template>
                                    <span v-else class="input-span mg-r-24" @click="openDialog">请选择</span>
                                </el-form-item>
                                <el-form-item label="职位" prop="post">
                                    <el-input v-model="contactForm.post" placeholder="请输入" maxlength="30"></el-input>
                                </el-form-item>
                                <el-form-item label="联系方式：">
                                </el-form-item>
                                <el-form-item label="电话" prop="telephone">
                                    <el-input v-model="contactForm.telephone" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="邮箱" prop="email">
                                    <el-input v-model="contactForm.email" placeholder="请输入"></el-input>
                                </el-form-item>
                                <el-form-item label="手机" prop="mobile">
                                    <el-input v-model="contactForm.mobile" placeholder="请输入"></el-input>
                                </el-form-item>
                            </el-form>
                        </div>
          </el-card>-->
          <!-- 编辑教育背景 -->
          <!-- <el-card class="mg-b-16" v-else-if="nowPageType === 'edit-teach'"> -->
          <el-card class="mg-b-16">
            <template #header>
              <span class="card-name">人物教育背景</span>
            </template>
            <div class="user-teach-form-container pd-32">
              <el-row class="title-row">
                <el-col :span="3">
                  <span class="title-text">开始年份：</span>
                </el-col>
                <el-col :span="5">
                  <span class="title-text">结束年份：</span>
                </el-col>
                <el-col :span="4">
                  <span class="title-text-start">学校：</span>
                </el-col>
                <el-col :span="4">
                  <span class="title-text">专业：</span>
                </el-col>
                <el-col :span="5">
                  <span class="title-text">学位：</span>
                </el-col>
                <el-col :span="3"></el-col>
              </el-row>
              <el-row
                class="form-row"
                v-for="(item, index) in teachRows"
                :key="item.id"
              >
                <el-col :span="3" class="inps-box">
                  <el-date-picker
                    value-format="YYYY"
                    :model-value="item.start_year"
                    v-model="item.start_year"
                    style="width: 100%"
                    type="year"
                    placeholder="请选择"
                    @change="getChange(item)"
                  ></el-date-picker>
                </el-col>
                <el-col :span="5" class="inps-box">
                  <div class="together_line">
                    <el-date-picker
                      class="end_years"
                      value-format="YYYY"
                      :model-value="item.end_year"
                      v-model="item.end_year"
                      style="width: 70%"
                      type="year"
                      placeholder="请选择"
                      @change="getChange(item)"
                    ></el-date-picker>

                    <el-checkbox v-model="item.isSoFar" @change="getChange(item)">至今</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="4" class="inps-box">
                  <div class v-if="item.university || item.university_id">
                    <span
                      class="mg-r-20 value-border"
                      @click="openDialog('edit-teach', index)"
                      >{{ item.university }}</span
                    >
                    <!-- <el-button size="small" @click="openDialog(index)">修改</el-button> -->
                  </div>
                  <span
                    v-else
                    class="input-span"
                    @click="openDialog('edit-teach', index)"
                    >请选择</span
                  >
                </el-col>
                <el-col :span="4" class="inps-box">
                  <el-input
                    placeholder="请输入"
                    v-model="item.specialty"
                    @input="getChange(item)"
                  />
                </el-col>
                <el-col :span="5" class="inps-box">
                  <el-select v-model="item.degree" @change="getChange(item)">
                    <el-option label="学士" :value="1">学士</el-option>
                    <el-option label="硕士" :value="2">硕士</el-option>
                    <el-option label="博士" :value="3">博士</el-option>
                  </el-select>
                </el-col>
                <el-col :span="3" class="subtract-col">
                  <span
                    class="subtract-box"
                    v-if="teachRows.length > 1 && !item.isNew"
                    @click="removeRow('edit-teach', index, item)"
                  >
                    <el-icon>
                      <semi-select />
                    </el-icon>
                  </span>
                  <span
                    class="subtract-box success"
                    v-if="item.isChange"
                    @click="submitRow('edit-teach', index, item)"
                  >
                    <el-icon>
                      <check />
                    </el-icon>
                  </span>
                </el-col>
              </el-row>
              <p class="add-row">
                <el-link type="primary" @click="addRow('edit-teach')"
                  >增加一行</el-link
                >
              </p>
            </div>
            <el-row justify="end">
              <el-col :span="4">
                <!-- <el-button size="small" plain @click="cancelSubmit">取消</el-button> -->
                <!-- <el-button size="small" type="primary" @click="submitForm('edit-teach')">保存</el-button> -->
              </el-col>
            </el-row>
          </el-card>
          <!-- 编辑工作履历 -->
          <!-- <el-card class="mg-b-16" v-else-if="nowPageType === 'edit-job'"> -->
          <el-card class="mg-b-16">
            <template #header>
              <span class="card-name">人物工作履历</span>
            </template>
            <div class="user-teach-form-container pd-32">
              <el-row class="title-row">
                <el-col :span="3">
                  <span class="title-text">开始年份：</span>
                </el-col>
                <el-col :span="5">
                  <span class="title-text">结束年份：</span>
                </el-col>
                <el-col :span="4">
                  <span class="title-text-start">单位：</span>
                </el-col>
                <el-col :span="4">
                  <span class="title-text">职位：</span>
                </el-col>
                <el-col :span="4">
                  <span class="title-text">排序(越小越前)：</span>
                </el-col>
                <el-col :span="3"></el-col>
              </el-row>
              <el-row
                class="form-row"
                v-for="(item, index) in jobRows"
                :key="item.id"
              >
                <el-col :span="3" class="inps-box">
                  <el-date-picker
                    value-format="YYYY"
                    :model-value="item.start_year"
                    v-model="item.start_year"
                    style="width: 100%"
                    type="year"
                    placeholder="请选择"
                    @change="getChange(item)"
                  ></el-date-picker>
                </el-col>
                <el-col :span="5" class="inps-box">
                  <div class="together_line">
                    <el-date-picker
                      value-format="YYYY"
                      :model-value="item.end_year"
                      v-model="item.end_year"
                      style="width: 70%"
                      type="year"
                      placeholder="请选择"
                      @change="getChange(item)"
                    ></el-date-picker>
                    <el-checkbox v-model="item.isSoFar" @change="getChange(item)">至今</el-checkbox>
                  </div>
                </el-col>
                <el-col :span="4" class="inps-box">
                  <div class v-if="item.unitName || item.unit_id">
                    <span
                      class="mg-r-20 value-border"
                      @click="openDialog('edit-job', index)"
                      >{{ item.unitName || item.unit_name }}</span
                    >
                    <!-- <el-button size="small" @click="openDialog(index)">修改</el-button> -->
                  </div>
                  <span
                    v-else
                    class="input-span"
                    @click="openDialog('edit-job', index)"
                    >请选择</span
                  >
                </el-col>
                <el-col :span="4" class="inps-box">
                  <el-input
                    placeholder="请输入"
                    v-model="item.post"
                    @input="getChange(item)"
                  />
                </el-col>
                <!-- 排序 -->
                <el-col :span="3" class="inps-box">
                  <el-input
                    v-model="item.sort"
                    type="number"
                    :min="0"
                    :value="item.sort"
                    @input="getChange(item)"
                  />
                  <!-- <el-button
                    :disabled="index == 0"
                    @click="topItem(index, 'edit-job',item)"
                    >上移</el-button>
                  <el-button
                    :disabled="index == jobRows.length - 1"
                    @click="bottomItem(index, 'edit-job',item)"
                    >下移</el-button
                  > -->
                </el-col>
                <el-col :span="3" class="subtract-col">
                  <!-- 删除 -->
                  <span
                    class="subtract-box"
                    v-if="jobRows.length > 1 && !item.isNew"
                    @click="removeRow('edit-job', index, item)"
                  >
                    <el-icon>
                      <semi-select />
                    </el-icon>
                  </span>
                  <!-- 保存 -->
                  <span
                    class="subtract-box success"
                    v-if="item.isChange"
                    @click="submitRow('edit-job', index, item)"
                  >
                    <el-icon>
                      <check />
                    </el-icon>
                  </span>
                </el-col>
              </el-row>
              <p class="add-row">
                <el-link type="primary" @click="addRow('edit-job')"
                  >增加一行</el-link
                >
              </p>
            </div>
            <el-row justify="end">
              <el-col :span="4">
                <!-- <el-button size="small" plain @click="cancelSubmit">取消</el-button> -->
                <!-- <el-button size="small" type="primary" @click="submitForm('edit-job')">保存</el-button> -->
              </el-col>
            </el-row>
          </el-card>
          <!-- 编辑社会兼职 -->
          <!-- <el-card class="mg-b-16" v-else-if="nowPageType === 'edit-social-job'"> -->
          <el-card class="mg-b-16">
            <template #header>
              <span class="card-name">人物社会兼职</span>
            </template>
            <div class="user-teach-form-container pd-32">
              <el-row class="title-row">
                <el-col :span="4">
                  <span class="title-text-start">机构：</span>
                </el-col>
                <el-col :span="4">
                  <span class="title-text">职位：</span>
                </el-col>
                <el-col :span="3"></el-col>
              </el-row>
              <el-row
                class="form-row"
                v-for="(item, index) in socialRows"
                :key="item.id"
              >
                <el-col :span="4" class="inps-box">
                  <div class v-if="item.unitName || item.unique_id">
                    <span
                      class="mg-r-20 value-border"
                      @click="openDialog('edit-social-job', index)"
                      >{{ item.unitName || item.unit_name }}</span
                    >
                    <!-- <el-button size="small" @click="openDialog(index)">修改</el-button> -->
                  </div>
                  <span
                    v-else
                    class="input-span"
                    @click="openDialog('edit-social-job', index)"
                    >请选择</span
                  >
                </el-col>
                <el-col :span="4" class="inps-box">
                  <el-input
                    placeholder="请输入"
                    v-model="item.post"
                    @input="getChange(item)"
                  />
                </el-col>
                <el-col :span="3" class="subtract-col">
                  <span
                    class="subtract-box"
                    v-if="socialRows.length > 1 && !item.isNew"
                    @click="removeRow('edit-social-job', index, item)"
                  >
                    <el-icon>
                      <semi-select />
                    </el-icon>
                  </span>
                  <span
                    class="subtract-box success"
                    v-if="item.isChange"
                    @click="submitRow('edit-social-job', index, item)"
                  >
                    <el-icon>
                      <check />
                    </el-icon>
                  </span>
                </el-col>
              </el-row>
              <p class="add-row">
                <el-link type="primary" @click="addRow('edit-social-job')"
                  >增加一行</el-link
                >
              </p>
            </div>
            <el-row justify="end">
              <el-col :span="4">
                <!-- <el-button size="small" plain @click="cancelSubmit">取消</el-button> -->
                <!-- <el-button size="small" type="primary" @click="submitForm('edit-social-job')">保存</el-button> -->
              </el-col>
            </el-row>
          </el-card>
          <!-- 编辑荣誉奖项 -->
          <!-- <el-card class="mg-b-16" v-else-if="nowPageType === 'edit-honorary-award'"> -->
          <el-card class="mg-b-16">
            <template #header>
              <span class="card-name">人物荣誉奖项</span>
            </template>
            <div class="user-teach-form-container pd-32">
              <el-row class="title-row">
                <el-col :span="3">
                  <span class="title-text">获奖年份：</span>
                </el-col>
                <el-col :span="6">
                  <span class="title-text-start">荣誉奖项：</span>
                </el-col>
                <el-col :span="3"></el-col>
              </el-row>
              <el-row
                class="form-row"
                v-for="(item, index) in awardRows"
                :key="item.id"
              >
                <el-col :span="3" class="inps-box">
                  <el-date-picker
                    value-format="YYYY"
                    :model-value="item.award_year"
                    v-model="item.award_year"
                    style="width: 100%"
                    type="year"
                    placeholder="请选择"
                    @change="getChange(item)"
                  ></el-date-picker>
                </el-col>
                <el-col :span="6" class="inps-box">
                  <el-input
                    placeholder="请输入"
                    v-model="item.awards"
                    @input="getChange(item)"
                  />
                </el-col>
                <el-col :span="3" class="subtract-col">
                  <span
                    class="subtract-box mg-r-12"
                    v-if="awardRows.length > 1 && !item.isNew"
                    @click="removeRow('edit-honorary-award', index, item)"
                  >
                    <el-icon>
                      <semi-select />
                    </el-icon>
                  </span>
                  <span
                    class="subtract-box success"
                    v-if="item.isChange"
                    @click="submitRow('edit-honorary-award', index, item)"
                  >
                    <el-icon>
                      <check />
                    </el-icon>
                  </span>
                </el-col>
              </el-row>
              <p class="add-row">
                <el-link type="primary" @click="addRow('edit-honorary-award')"
                  >增加一行</el-link
                >
              </p>
            </div>
            <el-row justify="end">
              <el-col :span="4">
                <!-- <el-button size="small" plain @click="cancelSubmit">取消</el-button> -->
                <!-- <el-button size="small" type="primary" @click="submitForm('edit-honorary-award')">保存</el-button> -->
              </el-col>
            </el-row>
          </el-card>

          <el-card class="mg-b-16">
            <template #header>
              <span class="card-name">人物相关专家（生辉小程序使用）</span>
            </template>
            <div class="user-teach-form-container pd-32">
              <!-- <el-row class="title-row">
                <el-col :span="3">
                  <span class="title-text">获奖年份：</span>
                </el-col>
                <el-col :span="6">
                  <span class="title-text-start">荣誉奖项：</span>
                </el-col>
                <el-col :span="3"></el-col>
              </el-row> -->
              <!-- <el-row class="form-row" v-for="(item, index) in awardRows" :key="item.id">
                <el-col :span="3" class="inps-box">
                  <el-date-picker
                    value-format="YYYY"
                    :model-value="item.award_year"
                    v-model="item.award_year"
                    style="width: 100%;"
                    type="year"
                    placeholder="请选择"
                  ></el-date-picker>
                </el-col>
                <el-col :span="6" class="inps-box">
                  <el-input placeholder="请输入" v-model="item.awards" />
                </el-col>
                <el-col :span="3" class="subtract-col">
                  <span
                    class="subtract-box mg-r-12"
                    v-if="awardRows.length > 1"
                    @click="removeRow('edit-honorary-award', index, item)"
                  >
                    <el-icon>
                      <semi-select />
                    </el-icon>
                  </span>
                  <span class="subtract-box success" @click="submitRow('edit-honorary-award',index, item)">
                    <el-icon>
                      <check />
                    </el-icon>
                  </span>
                </el-col>
              </el-row> -->
              <!-- <p class="add-row">
                <el-link type="primary" @click="addRow('edit-honorary-award')">增加一行</el-link>
              </p> -->
              <div>
                <el-tag
                  class="mg-r-12"
                  v-for="(tag, index) in userForm.relation_personage"
                  :key="tag.id"
                  closable
                  :disable-transitions="false"
                  @close="deleteTag(index, 'relation_personage')"
                >
                  {{ tag.name }}
                </el-tag>
                <el-button size="small" @click="showDialog('添加专家')"
                  >+ 新标签</el-button
                >
              </div>
            </div>
            <!-- <el-row justify="end">
              <el-col :span="4">
                <el-button size="small" type="primary" @click="addExpert">保存</el-button>
              </el-col>
            </el-row> -->
          </el-card>

          <!-- <div class="bottom-submit-box pd-16 mg-t-12" v-if="!['edit-teach', 'edit-job', 'edit-social-job', 'edit-honorary-award'].includes(nowPageType)">
                        <el-row justify="end">
                            <el-col :span="4" >
                                <el-button size="small" plain @click="cancelSubmit">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm">保存</el-button>
                            </el-col>
                        </el-row>
          </div>-->
          <el-dialog
            v-model="dialogShow"
            :title="dialogTitle"
            :width="800"
            :close-on-click-modal="false"
            :show-close="false"
            destroy-on-close
          >
            <div class="dialo-content">
              <div class="mg-b-16" style="width: 320px">
                <el-input
                  v-model="dialogSearchInp"
                  @change="dialogSearchChange"
                  placeholder="搜索"
                  :suffix-icon="Search"
                ></el-input>
              </div>
              <el-table
                :data="dialogTable"
                border
                max-height="300"
                v-loading="dialogTableLoading"
              >
                <template v-if="dialogTitle == '选择学校'">
                  <el-table-column prop="name" label="学校名称">
                    <template #default="scope">
                      <el-input
                        v-if="scope.row.type == 'new'"
                        v-model="scope.row.name"
                        placeholder="请输入"
                      ></el-input>
                      <span v-else>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <!-- <el-table-column prop="desc" label="学校简介" /> -->
                  <el-table-column label="操作">
                    <template #default="scope">
                      <el-radio v-model="dialogRadio" :label="scope.row.id"
                        >选择</el-radio
                      >
                    </template>
                  </el-table-column>
                </template>
                <template v-else>
                  <el-table-column prop="name" label="单位名称">
                    <template #default="scope">
                      <el-input
                        v-if="scope.row.type == 'new'"
                        v-model="scope.row.name"
                        placeholder="请输入"
                      ></el-input>
                      <span v-else>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="unit_type"
                    label="单位类型"
                    width="220"
                  >
                    <template #default="scope">
                      <template v-if="scope.row.type == 'new'">
                        <el-select v-model="newSelectType">
                          <el-option label="机构" :value="1" />
                          <el-option label="品牌" :value="2" />
                          <el-option label="高校" :value="3" />
                          <el-option label="社会组织" :value="4" />
                        </el-select>
                      </template>
                      <template v-else>
                        <span v-if="scope.row.unit_type === 1">机构</span>
                        <span v-else-if="scope.row.unit_type === 2">品牌</span>
                        <span v-else-if="scope.row.unit_type === 3">高校</span>
                        <span v-else-if="scope.row.unit_type === 4"
                          >社会组织</span
                        >
                      </template>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="100">
                    <template #default="scope">
                      <el-radio v-model="dialogRadio" :label="scope.row.id"
                        >选择</el-radio
                      >
                    </template>
                  </el-table-column>
                </template>
                <template #empty>
                  没有匹配的结果
                  <!-- <el-button
                    type="primary"
                    class="mg-l-20"
                    size="small"
                    @click="addAuthor(nowPageType)"
                  >新增</el-button> -->
                </template>
              </el-table>
            </div>
            <template #footer>
              <el-button plain @click="dialogClose">返回</el-button>
              <el-button type="primary" @click="dialogSubmit">完成</el-button>
            </template>
          </el-dialog>

          <el-dialog
            v-model="tagDialogShow"
            :title="dialogDetail.title"
            :width="880"
            :close-on-click-modal="false"
            :show-close="true"
          >
            <div
              class="dialog-content"
              v-if="dialogDetail.title === '添加专家'"
            >
              <el-input
                style="width: 200px"
                v-model="searchForm.name"
                placeholder="搜索专家"
                :suffix-icon="Search"
                @change="getPersons('search')"
              ></el-input>
              <el-table
                class="mg-t-12"
                :data="authorTable"
                border
                max-height="400"
                v-loading="isLoading"
              >
                <el-table-column prop="name" label="姓名">
                  <template #default="scope">
                    <el-input
                      v-if="scope.row.type == 'new'"
                      v-model="scope.row.name"
                      placeholder="请输入"
                    ></el-input>
                    <span v-else>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="research_label_arr" label="研究标签" />
                <el-table-column prop="invest_label_arr" label="投资标签" />
                <el-table-column prop="startup_label_arr" label="创业标签" />
                <el-table-column prop="operation" label="操作">
                  <template #default="scope">
                    <el-radio
                      v-model="authorId"
                      :label="scope.row.id"
                      @change="authorChange"
                      >选择</el-radio
                    >
                  </template>
                </el-table-column>
                <template #empty>
                  没有匹配的结果<el-button
                    type="primary"
                    class="mg-l-20"
                    size="small"
                    @click="addAuthorFn()"
                    >新增</el-button
                  >
                </template>
              </el-table>
              <div class="table-pagination-row mg-t-24">
                <el-pagination
                  background
                  :currentPage="paginationData.current"
                  :page-sizes="paginationData.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="paginationData.total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                >
                </el-pagination>
              </div>
            </div>
            <template #footer>
              <el-button @click="tagDialogShow = false">取消</el-button>
              <el-button type="primary" @click="submit()">确定</el-button>
            </template>
          </el-dialog>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, getCurrentInstance, computed, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import * as dayjs from "dayjs";
import {
  Plus,
  SemiSelect,
  Search,
  Check,
  Top,
  Bottom,
} from "@element-plus/icons-vue";
import { Ajax, MessageTips, Rule, messageModal } from "../../../utils";
import checkTag from "../../../components/CheckTagNew";
export default {
  components: {
    Header,
    BreadCrumb,
    Plus,
    SemiSelect,
    checkTag,
    Check,
    Top,
    Bottom,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const breadListsRef = ref([
      {
        name: "人物",
        path: {
          name: "Person",
        },
      },
      {
        name: "人物详情",
        path: {
          name: "PersonDetail",
          query: {
            id: route.query.id,
          },
        },
      },
      {
        name: `${route.query.pageName}`,
      },
    ]);
    const newSelectTypeRef = ref(null);
    // 获取信息回填
    const personDetailRef = ref({});
    const countrysRef = ref([]);
    const provincesArrRef = ref([]);
    const provinceValRef = ref(null);
    const isDeceased = ref(false);
    let regionArr = [];
    const userFormRef = ref({
      name: "",
      sex: 1,
      country: null,
      province: null,
      city: null,
      type: "1",
      profile: "",
      research_label: undefined,
      invest_label: undefined,
      startup_label: undefined,
      relation_personage: [],
      authorId: [],
    });

    const dialogDetail = ref({
      title: undefined,
    });
    const authorId = ref();
    const authorTable = ref([]);
    const authorDetail = ref({});
    const searchForm = ref({
      page: 1,
      page_size: 10,
      name: undefined,
    });
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const isLoading = ref(false);
    onBeforeMount(async () => {
      // 国家地区信息
      const adminRegions = localStorage.getItem("adminRegions");
      if (adminRegions) {
        const regs = JSON.parse(adminRegions);
        regionArr = regs;
        countrysRef.value = regionArr.map(({ id, name }) => ({
          id,
          name,
        }));
      } else {
        const res = await Ajax.GET({
          url: "/admin/region",
        });
        const resData = res.data;
        if (resData.code === 200) {
          regionArr = resData.data;
          localStorage.setItem("adminRegions", JSON.stringify(resData.data));
          countrysRef.value = regionArr.map(({ id, name }) => ({
            id,
            name,
          }));
        }
      }
      getDetail();
    });

    const showDialog = (title) => {
      dialogDetail.value.title = title;
      if (title == "添加专家") {
        authorId.value = undefined;
        authorDetail.value = {};
        searchForm.value.name = undefined;
        getPersons("search");
      }
      tagDialogShowRef.value = true;
    };

    const submit = () => {
      // console.log('authorDetail.value', authorDetail.value)
      if (dialogDetail.value.title === "添加专家") {
        if (authorId.value) {
          if (userFormRef.value.authorId.indexOf(authorId.value) != -1) {
            MessageTips.error("不能选择重复数据");
            return;
          }
          userFormRef.value.relation_personage.push(authorDetail.value);
          userFormRef.value.authorId.push(authorId.value);
          addExpert(authorId.value);
        } else {
          MessageTips.error("请选择");
          return;
        }
      }
      tagDialogShowRef.value = false;
    };
    const deleteTag = (index, type) => {
      console.log(
        "userFormRef.value.authorId",
        userFormRef.value.authorId[index],
        index
      );
      if (type == "relation_personage") {
        deleteExpert(userFormRef.value.authorId[index], index);
        // userFormRef.value.relation_personage.splice(index, 1);
        // userFormRef.value.authorId.splice(index, 1);
      } else {
        dynamicTagsRef.value.splice(index, 1);
      }
    };

    const deleteExpert = (expert_id) => {
      messageModal.confirm({
        msg: "确认删除？",
        type: "error",
        confirm() {
          Ajax.POST({
            url: "/admin/personage/del_expert",
            data: {
              personage_id: route.query.id,
              expert_id,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              MessageTips.success("删除成功");
              getDetail();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        },
      });
    };
    const authorChange = (id) => {
      if (!id) {
        Ajax.POST({
          url: "/admin/personage/add_audit_personage",
          data: {
            name: authorTable.value[0].name,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            authorTable.value[0].id = res.data.data.insertId;
            authorId.value = res.data.data.insertId;
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
      }
      for (let i in authorTable.value) {
        let item = authorTable.value[i];
        if (item.id === id) {
          authorDetail.value = item;
          return;
        }
      }
    };
    const addAuthorFn = () => {
      authorTable.value[0] = {
        type: "new",
        name: searchForm.value.name,
        id: 0,
      };
      paginationDataRef.value.total = 1;
    };
    const handleSizeChange = (e) => {
      searchForm.value.page_size = e;
      getPersons();
    };
    const handleCurrentChange = (e) => {
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getPersons();
    };
    const getPersons = (type) => {
      isLoading.value = true;
      if (type) {
        paginationDataRef.value.current = 1;
        searchForm.value.page = 1;
      }
      Ajax.GET({
        url: "/admin/personage",
        params: searchForm.value,
      }).then((res) => {
        isLoading.value = false;
        if (res.data.code == 200) {
          authorTable.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };

    const addExpert = (expert_id) => {
      Ajax.POST({
        url: "/admin/personage/add_expert",
        data: {
          personage_id: route.query.id,
          expert_id,
          // : userFormRef.value.authorId.length > 0 ? userFormRef.value.authorId.join(","): undefined
        },
      }).then((res) => {
        if (res.data.code == 200) {
          MessageTips.success("保存成功");
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const getDetail = () => {
      Ajax.GET({
        url: `/admin/personage/${route.query.id}`,
      }).then((res) => {
        if (res.data.code === 200) {
          const personRes = res.data.data;
          personDetailRef.value = personRes;
          // 基本信息

          userFormRef.value.name = personRes.name;
          userFormRef.value.sex = personRes.sex;
          userFormRef.value.type = personRes.type;
          userFormRef.value.profile = personRes.profile;
          userFormRef.value.country = personRes.country || null;
          userFormRef.value.province = personRes.province || null;
          userFormRef.value.city = personRes.city || null;
          userFormRef.value.research_label_arr = personRes.research_label_arr;
          userFormRef.value.startup_label_arr = personRes.startup_label_arr;
          userFormRef.value.invest_label_arr = personRes.invest_label_arr;
          userFormRef.value.relation_personage = personRes.expert;
          userFormRef.value.authorId = [];
          for (let i in userFormRef.value.relation_personage) {
            let item = userFormRef.value.relation_personage[i];
            userFormRef.value.authorId.push(item.id);
          }
          isDeceased.value = personRes.is_deceased == 2 ? true : false;

          if (nowPageType === "edit-basic") {
            switch (userFormRef.value.type) {
              case "1":
                // 研究领域
                proxy.$refs.checkTag.setValue(
                  userFormRef.value.research_label_arr
                );
                break;
              case "2":
                // 创业领域
                proxy.$refs.checkTag.setValue(
                  userFormRef.value.startup_label_arr
                );
                break;
              case "3":
                // 投资领域
                proxy.$refs.checkTag.setValue(
                  userFormRef.value.invest_label_arr
                );
                break;
            }
          }
          const findObj = regionArr.find(
            (item) => item.id === personRes.country
          );
          if (findObj) {
            provincesArrRef.value = findObj.child;
            if (personRes.province && personRes.city) {
              provinceValRef.value = [personRes.province, personRes.city];
            } else if (personRes.province) {
              provinceValRef.value = [personRes.province];
            }
          }
          // 扩展信息
          contactFormRef.value.image = personRes.image;
          contactFormRef.value.post = personRes.post;
          contactFormRef.value.telephone = personRes.telephone;
          contactFormRef.value.email = personRes.email;
          contactFormRef.value.mobile = personRes.mobile;
          contactFormRef.value.unit_id = personRes.unit_id || null;
          contactFormRef.value.unit_name = personRes.unit_name || null;
          contactFormRef.value.unit_type = personRes.unit_type || null;
          personRes.education.forEach((item) => {
            item.start_year = item.start_year + "";
            item.end_year = item.end_year + "";
            item.degree = item.degree == "0" ? null : item.degree;
          });
          // 教育信息
          personRes.education.forEach(item=>{
            item.isSoFar=item.is_so_far==2?true:false
          })
          teachRowsRef.value =
            personRes.education && personRes.education.length
              ? personRes.education
              : [
                  {
                    id: 1,
                    create_type: "new",
                    start_year: "",
                    end_year: "",
                    university_id: null,
                    university: "",
                    specialty: "",
                    degree: null,
                  },
                ];

          // 工作履历  work_history
          personRes.work_history.forEach((item) => {
            item.start_year = item.start_year + "";
            item.end_year = item.end_year + "";
            item.isSoFar=item.is_so_far==2?true:false
          });
          jobRowsRef.value =
            personRes.work_history && personRes.work_history.length
              ? personRes.work_history
              : [
                  {
                    id: 1,
                    create_type: "new",
                    start_year: "",
                    end_year: "",
                    unit_type: null,
                    unit_id: null,
                    unitName: "",
                    post: "",
                    sort: "0",
                  },
                ];

          socialRowsRef.value =
            personRes.social_pt && personRes.social_pt.length
              ? personRes.social_pt
              : [
                  {
                    id: 1,
                    create_type: "new",
                    type: 1,
                    unique_id: null,
                    post: "",
                  },
                ];

          personRes.honors.forEach((item) => {
            item.award_year = item.award_year + "";
          });
          awardRowsRef.value =
            personRes.honors && personRes.honors.length
              ? personRes.honors
              : [
                  {
                    id: 1,
                    create_type: "new",
                    award_year: "",
                    awards: "",
                  },
                ];
        }
      });
    };

    // edit-basic           编辑基本信息
    // edit-contact         编辑补充信息
    // edit-teach           编辑教育背景
    // edit-job             编辑工作履历
    // edit-social-job      编辑社会兼职
    // edit-honorary-award  编辑荣誉奖项
    const nowPageType = route.query.type;
    // let nowPageType = '';
    console.log(nowPageType, "nowPageType");
    const { proxy } = getCurrentInstance();
    // formRef 类型
    const formRefType = computed(() => {
      let type = "";
      switch (nowPageType) {
        case "edit-basic":
          type = "basicFormRef";
          break;
        case "edit-contact":
          type = "contactFormRef";
          break;
        case "edit-teach":
          type = "teachFormRef";
          break;
        case "edit-job":
          type = "jobFormRef";
          break;
        case "edit-social-job":
          type = "socialFormRef";
          break;
        case "edit-honorary-award":
          type = "awardFormRef";
          break;
      }
      return type;
    });
    /** 人物基本信息 start */

    const userFormRule = {
      name: [
        {
          required: true,
          message: "请输入姓名",
          trigger: "blur",
        },
      ],
      profile: [
        {
          required: true,
          message: "请输入人物简介",
          trigger: "blur",
        },
      ],
    };

    // 国家选择
    const coutryChange = (val) => {
      const findObj = regionArr.find((item) => item.id === val);
      userFormRef.value.country = val;
      userFormRef.value.province = null;
      userFormRef.value.city = null;
      provincesArrRef.value = findObj ? findObj.child : [];
    };
    const countryRemoteMethod = (query) => {
      if (query) {
        setTimeout(() => {
          countrysRef.value = regionArr.filter((item) => {
            return item.name.includes(query);
          });
        }, 200);
      } else {
        countrysRef.value = [];
      }
    };
    // 地区选择
    const provinceChange = (val) => {
      userFormRef.value.province = val[0];
      userFormRef.value.city = val[1];
    };

    // 添加标签
    const tagDialogShowRef = ref(false);
    const addNewTag = () => {
      tagDialogShowRef.value = true;
    };

    /** 人物基本信息 end */

    /* 编辑补充联系信息 start */
    const contactFormRef = ref({
      image: "",
      unit_type: "",
      unit_id: "",
      telephone: "",
      post: "",
      email: "",
      mobile: "",
    });
    const contactFormRule = {
      telephone: {
        required: false,
        validator: (rule, value, callback) => {
          // if (value && !Rule.isTelephone(value)) {
          //     callback(new Error("请输入正确的电话号码"));
          // }else{
          //     callback()
          // }
          callback();
        },
        trigger: "blur",
      },
      mobile: {
        required: false,
        validator: (rule, value, callback) => {
          // if (value && !Rule.isMobile(value)) {
          //     callback(new Error("请输入正确的手机号"));
          // }else{
          //     callback()
          // }
          callback();
        },
        trigger: "blur",
      },
      email: {
        required: false,
        validator: (rule, value, callback) => {
          // if (value && !Rule.isMail(value)) {
          //     callback(new Error("请输入正确的邮箱"));
          // }else{
          //     callback()
          // }
          callback();
        },
        trigger: "blur",
      },
    };

    // 限制文件大小
    const beforeUpload = (file) => {
      console.log(file);
      const isL1M = file.size / 1000 / 1000 <= 1;
      if (!isL1M) {
        MessageTips.error("文件上传不能大于1M");
        return false;
      }
      return true;
    };

    // 上传照片
    const uploadImage = (data) => {
      const file = data.file;
      let formData = new FormData();
      formData.append("image", file);
      Ajax.POST({
        url: "/admin/upload/image",
        contentType: "multipart/form-data",
        data: formData,
      }).then((res) => {
        if (res.data.code === 200) {
          contactFormRef.value.image = res.data.data.url;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    /* 编辑补充联系信息 end */

    /* 编辑教育背景 start */
    const teachRowsRef = ref([
      {
        id: 1,
        create_type: "new",
        start_year: "",
        end_year: "",
        university_id: null,
        university: "",
        specialty: "",
        degree: null,
      },
    ]);
    /* 编辑教育背景 end */

    /* 编辑工作履历 start */
    const jobRowsRef = ref([
      {
        id: 1,
        create_type: "new",
        start_year: "",
        end_year: "",
        unit_type: null,
        unit_id: null,
        unitName: "",
        post: "",
        sort: "0",
      },
    ]);
    /* 编辑工作履历 end */

    /* 编辑社会兼职 start */
    const socialRowsRef = ref([
      {
        id: 1,
        create_type: "new",
        type: 1,
        unique_id: null,
        post: "",
      },
    ]);
    /* 编辑社会兼职 end */

    /* 编辑荣誉奖项 start */
    const awardRowsRef = ref([
      {
        id: 1,
        create_type: "new",
        award_year: "",
        awards: "",
      },
    ]);
    /* 编辑荣誉奖项 end */
    // id最大值、赋值新增项
    const getMaxId = (arr) => {
      let idArr = arr.map((item) => {
        return (item = item.id);
      });
      return Math.max(...idArr);
    };
    // 表格增加一行删除一行
    const addRow = (nowPageType) => {
      switch (nowPageType) {
        case "edit-teach":
          teachRowsRef.value.push({
            // id: teachRowsRef.value[teachRowsRef.value.length - 1].id + 1,
            id: getMaxId(teachRowsRef.value) + 1,
            create_type: "new",
            start_year: "",
            end_year: "",
            university_id: null,
            university: "",
            specialty: "",
            degree: null,
            isNew: true, //新增没有删除按钮
            isChange: true, //新增即改变/显示提交按钮
          });
          break;
        case "edit-job":
          jobRowsRef.value.push({
            // id: jobRowsRef.value[jobRowsRef.value.length - 1].id + 1,
            id: getMaxId(jobRowsRef.value) + 1,
            create_type: "new",
            start_year: "",
            end_year: "",
            unit_type: null,
            unit_id: null,
            unitName: "",
            post: "",
            sort: "0",
            isNew: true, //新增没有删除按钮
            isChange: true, //新增即改变/显示提交按钮
          });
          break;
        case "edit-social-job":
          socialRowsRef.value.push({
            id: socialRowsRef.value[socialRowsRef.value.length - 1].id + 1,
            create_type: "new",
            type: 1,
            unique_id: null,
            post: "",
            isNew: true, //新增没有删除按钮
            isChange: true, //新增即改变/显示提交按钮
          });
          break;
        case "edit-honorary-award":
          awardRowsRef.value.push({
            id: awardRowsRef.value[awardRowsRef.value.length - 1].id + 1,
            create_type: "new",
            award_year: "",
            awards: "",
            isNew: true, //新增没有删除按钮
            isChange: true, //新增即改变/显示提交按钮
          });
          break;
      }
    };
    const removeRow = (nowPageType, index, item) => {
      switch (nowPageType) {
        case "edit-teach":
          // teachRowsRef.value.splice(index, 1)
          messageModal.confirm({
            msg: "是否删除？",
            type: "error",
            confirm() {
              Ajax.POST({
                url: "/admin/personage/update_education",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  education: [],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("删除成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            },
          });

          break;
        case "edit-job":
          // jobRowsRef.value.splice(index, 1)
          messageModal.confirm({
            msg: "是否删除？",
            type: "error",
            confirm() {
              Ajax.POST({
                url: "/admin/personage/update_work_history",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  work_history: [],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("删除成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            },
          });
          break;
        case "edit-social-job":
          // socialRowsRef.value.splice(index, 1)
          messageModal.confirm({
            msg: "是否删除？",
            type: "error",
            confirm() {
              Ajax.POST({
                url: "/admin/personage/update_social_pt",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  social_pt: [],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("删除成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            },
          });
          break;
        case "edit-honorary-award":
          // awardRowsRef.value.splice(index, 1)
          messageModal.confirm({
            msg: "是否删除？",
            type: "error",
            confirm() {
              Ajax.POST({
                url: "/admin/personage/update_honors",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  honors: [],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("删除成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            },
          });
          break;
      }
    };

    /* 弹窗选择 start */
    const dialogTableLoadingRef = ref(false);
    const dialogShowRef = ref(false);
    const dialogTitleRef = ref("");
    const labelTypeRef = ref("");
    const dialogSearchInpRef = ref("");
    const dialogTableRef = ref([]);
    const dialogRadioRef = ref("");
    // 获取单位列表
    const getUnitList = (type = undefined) => {
      dialogRadioRef.value = undefined;
      addAuditPersonRef.value = false;
      let params;
      if(type==='work'){
        params = {
        name: dialogSearchInpRef.value,
        status:1
      }
      }else{
        params = {
        name: dialogSearchInpRef.value,
        type,
      }
      }
      
      return Ajax.GET({
        url: "/admin/personage/search_unit",
        params,
        // params: {
        //   name: dialogSearchInpRef.value,
        //   type,
        // },
      });
    };
    //获取品牌列表-任职单位、工作履历专用
    const getBrandList = (type = undefined) => {
      dialogRadioRef.value = undefined;
      addAuditPersonRef.value = false;
      return Ajax.POST({
        url: "/admin/brand/search",
        params: {
          name: dialogSearchInpRef.value,
          type,
        },
      });
    };
    // 表格可以插入行的索引
    const dialogTableIndexRef = ref(0);
    const openDialog = (nowPageType, index) => {
      dialogShowRef.value = true;
      dialogTableIndexRef.value = index;
      dialogRadioRef.value = undefined;
      switch (nowPageType) {
        case "edit-contact":
          dialogTitleRef.value = "选择单位";
          labelTypeRef.value = "edit-contact";
          dialogSearchInpRef.value = contactFormRef.value.unitName || "";
          // 请求接口
          dialogTableLoadingRef.value = true;
          getUnitList("work").then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              dialogTableRef.value = [];
            }
          });
          break;
        case "edit-teach":
          dialogTitleRef.value = "选择学校";
          labelTypeRef.value = "edit-teach";
          dialogTableLoadingRef.value = true;
          getUnitList(3).then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              dialogTableRef.value = [];
            }
          });
          break;
        case "edit-job":
          dialogTitleRef.value = "选择单位";
          labelTypeRef.value = "edit-job";
          // 请求接口
          dialogTableLoadingRef.value = true;
          getUnitList().then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              dialogTableRef.value = [];
            }
          });
          break;
        case "edit-social-job":
          dialogTitleRef.value = "选择单位";
          labelTypeRef.value = "edit-social-job";
          // 请求接口
          dialogTableLoadingRef.value = true;
          getUnitList().then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              dialogTableRef.value = [];
            }
          });
          break;
      }
    };

    // 弹窗中的搜索
    const dialogSearchChange = () => {
      let labelType = labelTypeRef.value;
      console.log(labelType);
      // switch (nowPageType) {
      switch (labelType) {
        // 编辑补充信息
        case "edit-contact":
          dialogTableLoadingRef.value = true;
          getUnitList("work").then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              MessageTips.warning(res.data.msg);
            }
          });
          break;
        // 教育
        case "edit-teach":
          dialogTableLoadingRef.value = true;
          getUnitList(3).then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              MessageTips.warning(res.data.msg);
            }
          });
          break;
        // 工作经历
        case "edit-job":
          // 请求接口
          dialogTableLoadingRef.value = true;
          getUnitList().then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              dialogTableRef.value = [];
            }
          });
          break;
        case "edit-social-job":
          dialogTableLoadingRef.value = true;
          
          getUnitList().then((res) => {
            dialogTableLoadingRef.value = false;
            if (res.data.code === 200) {
              dialogTableRef.value = res.data.data;
            } else {
              dialogTableRef.value = [];
            }
          });
          break;
        default:
          break;
      }
    };

    // 添加临时数据
    const addAuditPersonRef = ref(false);
    const addAuthor = (type) => {
      // edit-contact         补充信息
      // edit-teach           编辑教育背景
      // edit-job             编辑工作履历
      // edit-social-job      编辑社会兼职
      // edit-honorary-award  编辑荣誉奖项
      addAuditPersonRef.value = true;
      // let unit_type = undefined;
      // switch (type) {
      //     case 'edit-contact':
      //         unit_type = undefined;
      //         break;
      //     case 'edit-teach':
      //         unit_type = 3;
      //         break;
      //     case 'edit-job':
      //         unit_type = undefined;
      //         break;
      //     case 'edit-social-job':
      //         unit_type = 1;
      //         break;
      //     case 'edit-honorary-award':
      //         unit_type = undefined;
      //         break;
      //     default:
      //         break;
      // }
      dialogTableRef.value[0] = {
        type: "new",
        name: dialogSearchInpRef.value,
        unit_type: newSelectTypeRef.value,
        id: 0,
      };
      console.log(dialogTableRef.value);
    };

    // 弹窗返回按钮
    const dialogClose = () => {
      dialogSearchInpRef.value = "";
      dialogRadioRef.value = undefined;
      dialogShowRef.value = false;
      addAuditPersonRef.value = false;
      newSelectTypeRef.value = null;
    };
    //弹窗 提交
    const dialogSubmit = () => {
      let labelType = labelTypeRef.value;
      if (!dialogRadioRef.value && dialogRadioRef.value !== 0) return;
      // 临时数据添加
      if (addAuditPersonRef.value) {
        if (dialogRadioRef.value !== 0) return;
        dialogTableRef.value[0].unit_type = newSelectTypeRef.value;
        if (labelType !== "edit-teach") {
          if (
            !(dialogTableRef.value[0].name && dialogTableRef.value[0].unit_type)
          ) {
            MessageTips.warning("名称与类型必填");
            return;
          }
        }
        Ajax.POST({
          url: "/admin/personage/add_unit",
          data: {
            name: dialogTableRef.value[0].name,
            unit_type:
              labelType !== "edit-teach"
                ? dialogTableRef.value[0].unit_type
                : 3,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            dialogTableRef.value[0].id = res.data.data.insertId;
            dialogRadioRef.value = res.data.data.insertId;
            console.log(labelType);
            console.log(dialogRadioRef.value);
            switch (labelType) {
              // 编辑补充信息
              case "edit-contact":
                contactFormRef.value.unit_type =
                  dialogTableRef.value[0].unit_type;
                contactFormRef.value.unit_id = res.data.data.insertId;
                contactFormRef.value.unitName = dialogTableRef.value[0].name;
                break;
              case "edit-teach":
                teachRowsRef.value[dialogTableIndexRef.value].university_id =
                  res.data.data.insertId;
                teachRowsRef.value[dialogTableIndexRef.value].university =
                  dialogTableRef.value[0].name;
                break;
              case "edit-job":
                jobRowsRef.value[dialogTableIndexRef.value].unit_id =
                  res.data.data.insertId;
                jobRowsRef.value[dialogTableIndexRef.value].unit_type =
                  dialogTableRef.value[0].unit_type;
                jobRowsRef.value[dialogTableIndexRef.value].unitName =
                  dialogTableRef.value[0].name;
                jobRowsRef.value[dialogTableIndexRef.value].sort =
                  dialogTableRef.value[0].sort;
                break;
              case "edit-social-job":
                socialRowsRef.value[dialogTableIndexRef.value].unique_id =
                  res.data.data.insertId;
                socialRowsRef.value[dialogTableIndexRef.value].type =
                  dialogTableRef.value[0].unit_type;
                socialRowsRef.value[dialogTableIndexRef.value].unitName =
                  dialogTableRef.value[0].name;
                break;
            }
            dialogClose();
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
        return;
      }
      switch (labelType) {
        // 编辑补充信息
        case "edit-contact":
          const selectUnit = dialogTableRef.value.find(
            (item) => item.id === dialogRadioRef.value
          );
          if (selectUnit.id === contactFormRef.value.unit_id) {
            MessageTips.warning("不能选择重复数据");
            return;
          }
          contactFormRef.value.unit_type = selectUnit.unit_type;
          contactFormRef.value.unit_id = selectUnit.id;
          contactFormRef.value.unitName = selectUnit.name;
          dialogShowRef.value = false;
          console.log(contactFormRef.value, "contactFormRef");
          break;
        // 教育
        case "edit-teach":
          const selectUn = dialogTableRef.value.find(
            (item) => item.id === dialogRadioRef.value
          );
          console.log(selectUn, dialogTableIndexRef.value, "selectUn");
          if (
            selectUn.id ===
            teachRowsRef.value[dialogTableIndexRef.value].university_id
          ) {
            MessageTips.warning("不能选择重复数据");
            return;
          }
          // dialogTableIndexRef  对应的表格的索引
          teachRowsRef.value[dialogTableIndexRef.value].university_id =
            selectUn.id;
          teachRowsRef.value[dialogTableIndexRef.value].university =
            selectUn.name;
          teachRowsRef.value[dialogTableIndexRef.value].isChange = true; //显示保存按钮
          dialogShowRef.value = false;
          console.log(teachRowsRef.value, "valuess");
          break;
        case "edit-job":
          const jobUnit = dialogTableRef.value.find(
            (item) => item.id === dialogRadioRef.value
          );
          console.log(jobUnit, dialogTableIndexRef.value, "jobUnit");
          if (
            jobUnit.id === jobRowsRef.value[dialogTableIndexRef.value].unit_id
          ) {
            MessageTips.warning("不能选择重复数据");
            return;
          }
          // dialogTableIndexRef  对应的表格的索引
          jobRowsRef.value[dialogTableIndexRef.value].unit_id = jobUnit.id;
          jobRowsRef.value[dialogTableIndexRef.value].unit_type =
            jobUnit.unit_type;
          jobRowsRef.value[dialogTableIndexRef.value].unitName = jobUnit.name;
          jobRowsRef.value[dialogTableIndexRef.value].isChange = true; //显示保存按钮
          dialogShowRef.value = false;
          console.log(jobRowsRef.value, "valuess");
          break;
        case "edit-social-job":
          const socialUnit = dialogTableRef.value.find(
            (item) => item.id === dialogRadioRef.value
          );
          console.log(socialUnit, dialogTableIndexRef.value, "socialUnit");
          if (
            socialUnit.id ===
            socialRowsRef.value[dialogTableIndexRef.value].unique_id
          ) {
            MessageTips.warning("不能选择重复数据");
            return;
          }
          // dialogTableIndexRef  对应的表格的索引
          socialRowsRef.value[dialogTableIndexRef.value].unique_id =
            socialUnit.id;
          socialRowsRef.value[dialogTableIndexRef.value].type =
            socialUnit.unit_type;
          socialRowsRef.value[dialogTableIndexRef.value].unitName =
            socialUnit.name;
          socialRowsRef.value[dialogTableIndexRef.value].isChange = true; //显示保存按钮
          dialogShowRef.value = false;
          console.log(socialRowsRef.value, "valuess");
          break;
        default:
          break;
      }
    };
    /* 弹窗选择 end */

    // 底部提交按钮事件
    const cancelSubmit = () => {
      router.back();
      // proxy.$refs[formRefType].resetFields()
    };

    // 基本信息-提交
    const submitForm = (nowPageType) => {
      // TODO 如果自己新增了机构，需要判断
      switch (nowPageType) {
        // 编辑基本信息
        case "edit-basic":
          console.log(userFormRef.value);
          console.log(proxy.$refs.checkTag.getValue());
          if (userFormRef.value.type === "1") {
            let tag = ref([]);
            tag.value = proxy.$refs.checkTag.getValue();
            userFormRef.value.research_label = tag.value;
            userFormRef.value.startup_label = undefined;
            userFormRef.value.invest_label = undefined;
          } else if (userFormRef.value.type === "2") {
            let tag = ref([]);
            tag.value = proxy.$refs.checkTag.getValue();
            userFormRef.value.startup_label = tag.value;
            userFormRef.value.invest_label = undefined;
            userFormRef.value.research_label = undefined;
          } else if (userFormRef.value.type === "3") {
            let tag = ref([]);
            tag.value = proxy.$refs.checkTag.getValue();
            console.log(tag.value);
            userFormRef.value.invest_label = tag.value;
            userFormRef.value.startup_label = undefined;
            userFormRef.value.research_label = undefined;
          }
          userFormRef.value.is_deceased = isDeceased.value ? 2 : 1;
          proxy.$refs["basicFormRef"].validate((valid) => {
            if (valid) {
              console.log(userFormRef.value, "userForm");
              Ajax.PUT({
                url: `/admin/personage/${route.query.id}`,
                data: {
                  ...userFormRef.value,
                  ...contactFormRef.value,
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("修改成功");
                  // router.back();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              console.log("error");
              return;
            }
          });

          break;
        // case 'edit-contact':
        //      proxy.$refs['contactFormRef'].validate(valid => {
        //          if(valid) {
        //             Ajax.POST({
        //                 url: `/admin/personage/add_extend`,
        //                 data: {
        //                     personage_id: route.query.id,
        //                     ...contactFormRef.value
        //                 }
        //             }).then(res => {
        //                 if(res.data.code === 200) {
        //                     MessageTips.success('修改成功')
        //                     // router.back();
        //                 }else {
        //                     MessageTips.error(res.data.msg)
        //                 }
        //             })
        //          }else {
        //             console.log('error')
        //             return ;
        //          }
        //      })
        //     break;
        case "edit-teach":
          console.log(teachRowsRef.value, "teach");
          const isEmpty = teachRowsRef.value.every(
            (item) =>
              // item.start_year &&
              // item.end_year &&
              item.university_id && item.university
            // item.specialty &&
            // item.degree
          );
          console.log(isEmpty);
          if (!isEmpty) {
            MessageTips.error("请填写必填字段");
            return;
          }
          Ajax.POST({
            url: "/admin/personage/add_education",
            data: {
              personage_id: route.query.id,
              education: teachRowsRef.value,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              MessageTips.success("保存成功");
              // router.back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
          break;
        case "edit-job":
          console.log(jobRowsRef.value, "jobRowsRef");
          const jobIsEmpty = jobRowsRef.value.every(
            (item) =>
              item.start_year &&
              item.end_year &&
              item.unit_type &&
              item.unit_id &&
              item.post &&
              item.sort
          );
          console.log(jobIsEmpty);
          if (!jobIsEmpty) {
            MessageTips.error("请填写必填字段");
            return;
          }
          Ajax.POST({
            url: "/admin/personage/add_work_history",
            data: {
              personage_id: route.query.id,
              work_history: jobRowsRef.value,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              MessageTips.success("保存成功");
              // router.back();
              jobRowsRef.value = [];
            } else {
              MessageTips.error(res.data.msg);
            }
          });
          break;
        case "edit-social-job":
          console.log(socialRowsRef.value, "socialRows");
          const socialIsEmpty = socialRowsRef.value.every(
            (item) => item.type && item.unique_id && item.post
          );
          console.log(socialIsEmpty);
          if (!socialIsEmpty) {
            MessageTips.error("请填写必填字段");
            return;
          }
          Ajax.POST({
            url: "/admin/personage/add_social_pt",
            data: {
              personage_id: route.query.id,
              social_pt: socialRowsRef.value,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              MessageTips.success("保存成功");
              // router.back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
          break;
        case "edit-honorary-award":
          console.log(awardRowsRef.value, "awardRows");
          const awardIsEmpty = awardRowsRef.value.every(
            (item) => item.award_year && item.awards
          );
          console.log(awardIsEmpty);
          if (!awardIsEmpty) {
            MessageTips.error("请填写必填字段");
            return;
          }
          Ajax.POST({
            url: "/admin/personage/add_honors",
            data: {
              personage_id: route.query.id,
              honors: awardRowsRef.value,
            },
          }).then((res) => {
            if (res.data.code === 200) {
              MessageTips.success("保存成功");
              // router.back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
          break;
        default:
          break;
      }
    };

    // 其他-单行-提交
    const submitRow = (nowPageType, index, item) => {
      console.log(item, "item");
      console.log("改变了、提交");
      // return ;
      if (item.create_type === "new") {
        // 新增的
        switch (nowPageType) {
          case "edit-teach":
            // if(item.start_year && item.end_year && item.university_id && item.specialty && item.degree) {
            // if(dayjs(item.end_year).isBefore(item.start_year)) {
            //     MessageTips.error('结束时间必须大于开始时间')
            //     return ;
            // }
            if (item.university_id) {
              delete item.create_type;
              item.is_so_far=item.isSoFar?2:1
              Ajax.POST({
                url: "/admin/personage/add_education",
                data: {
                  personage_id: route.query.id,
                  education: [item],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
          case "edit-job":
            // if(item.start_year && item.end_year && item.unit_type && item.unit_id && item.post) {
            //     if(dayjs(item.end_year).isBefore(item.start_year)) {
            //         MessageTips.error('结束时间必须大于开始时间')
            //         return ;
            //     }
            if (item.unit_type && item.unit_id) {
              // if(!item.end_year&&item.start_year){
              //     MessageTips.error('请选择结束时间')
              // }
              // if(item.end_year&&!item.start_year){
              //     MessageTips.error('请选择开始时间')
              // }
              // if(item.end_year&&item.start_year&&dayjs(item.end_year).isBefore(item.start_year)) {
              //     MessageTips.error('结束时间必须大于开始时间')
              //     return ;
              // }
              delete item.create_type;
              item.is_so_far=item.isSoFar?2:1
              Ajax.POST({
                url: "/admin/personage/add_work_history",
                data: {
                  personage_id: route.query.id,
                  work_history: [item],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
          case "edit-social-job":
            // if(item.type && item.post && item.unique_id) {
            if (item.type && item.unique_id) {
              delete item.create_type;
              Ajax.POST({
                url: "/admin/personage/add_social_pt",
                data: {
                  personage_id: route.query.id,
                  social_pt: [item],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
          case "edit-honorary-award":
            // if(item.award_year && item.awards) {
            if (item.awards) {
              delete item.create_type;
              Ajax.POST({
                url: "/admin/personage/add_honors",
                data: {
                  personage_id: route.query.id,
                  honors: [item],
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
        }
      } else {
        // 修改的
        switch (nowPageType) {
          case "edit-teach":
            // if(item.start_year && item.end_year && item.university_id && item.specialty && item.degree) {
            //     if(dayjs(item.end_year).isBefore(item.start_year)) {
            //         MessageTips.error('结束时间必须大于开始时间')
            //         return ;
            //     }
            if (item.university_id) {
              item.is_so_far=item.isSoFar?2:1
              Ajax.POST({
                url: "/admin/personage/update_education",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  education: item,
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
          case "edit-job":
            // if(item.start_year && item.end_year && item.unit_type && item.unit_id && item.post) {
            //     if(dayjs(item.end_year).isBefore(item.start_year)) {
            //         MessageTips.error('结束时间必须大于开始时间')
            //         return ;
            //     }
            if (item.unit_type && item.unit_id) {
              // if(!item.end_year&&item.start_year){
              //     MessageTips.error('请选择结束时间')
              // }
              // if(item.end_year&&!item.start_year){
              //     MessageTips.error('请选择开始时间')
              // }
              // if(item.end_year&&item.start_year&&dayjs(item.end_year).isBefore(item.start_year)) {
              //     MessageTips.error('结束时间必须大于开始时间')
              //     return ;
              // }
              console.log("保存", item);
              item.is_so_far=item.isSoFar?2:1
              Ajax.POST({
                url: "/admin/personage/update_work_history",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  work_history: item,
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
          case "edit-social-job":
            // if(item.type && item.post && item.unique_id) {
            if (item.type && item.unique_id) {
              Ajax.POST({
                url: "/admin/personage/update_social_pt",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  social_pt: item,
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
          case "edit-honorary-award":
            // if(item.award_year && item.awards) {
            if (item.awards) {
              Ajax.POST({
                url: "/admin/personage/update_honors",
                data: {
                  id: item.id,
                  personage_id: route.query.id,
                  honors: item,
                },
              }).then((res) => {
                if (res.data.code === 200) {
                  MessageTips.success("提交成功");
                  getDetail();
                } else {
                  MessageTips.error(res.data.msg);
                }
              });
            } else {
              MessageTips.error("请填写必填字段");
            }
            break;
        }
      }
    };

    //监听 单行信息改变/显示保存按钮
    const getChange = (item) => {
      if(item.isSoFar && item.is_so_far==1){
        item.end_year=""
        item.is_so_far=2
      }
      if(!item.isSoFar ){
        item.is_so_far=1
      }
      if(item.end_year!="" && item.end_year!="null" && item.is_so_far==2){
        item.isSoFar=false
        item.is_so_far=1
      }
      console.log("发生改变，");
      item.isChange = true;
    };

    //上下移动 （排序）未完成 x
    // const topItem = (index, nowPageType,item) => {
    //   switch (nowPageType) {
    //     case "edit-job":
    //       [jobRowsRef.value[index], jobRowsRef.value[index - 1]] = [
    //         jobRowsRef.value[index - 1],
    //         jobRowsRef.value[index],
    //       ];
    //       [jobRowsRef.value[index].sort, jobRowsRef.value[index - 1].sort] = [
    //         index,
    //         index-1,
    //       ];
    //       jobRowsRef.value.sort(); //前端排序
    //       //提交 (交换位置，要提交两条？？)
    //       submitRow(nowPageType,index,jobRowsRef.value[index])
    //       submitRow(nowPageType,index,jobRowsRef.value[index - 1])
    //       break;
    //     default:
    //       break;
    //   }
    // };
    // const bottomItem = (index, nowPageType,item) => {
    //   switch (nowPageType) {
    //     case "edit-job":
    //       [jobRowsRef.value[index], jobRowsRef.value[index + 1]] = [
    //         jobRowsRef.value[index + 1],
    //         jobRowsRef.value[index],
    //       ];
    //       [jobRowsRef.value[index].sort, jobRowsRef.value[index + 1].sort] = [
    //         index,
    //         index+1,
    //       ];
    //       jobRowsRef.value.sort(); //前端排序
    //        //提交 (交换位置，要提交两条？？)
    //        submitRow(nowPageType,index,jobRowsRef.value[index])
    //        submitRow(nowPageType,index,jobRowsRef.value[index+1])
    //       break;
    //     default:
    //       break;
    //   }
    // };

    return {
      breadLists: breadListsRef,
      nowPageType,
      countrys: countrysRef,
      provincesArr: provincesArrRef,
      provinceVal: provinceValRef,
      personDetail: personDetailRef,
      userForm: userFormRef,
      userFormRule,
      coutryChange,
      provinceChange,
      dialogShow: dialogShowRef,
      dialogTitle: dialogTitleRef,
      addNewTag,
      cancelSubmit,
      submitForm,
      contactFormRule,
      contactForm: contactFormRef,
      beforeUpload,
      uploadImage,
      teachRows: teachRowsRef,
      jobRows: jobRowsRef,
      socialRows: socialRowsRef,
      awardRows: awardRowsRef,
      addRow,
      removeRow,
      openDialog,
      dialogSearchInp: dialogSearchInpRef,
      Search,
      dialogTable: dialogTableRef,
      dialogRadio: dialogRadioRef,
      dialogSearchChange,
      dialogClose,
      dialogSubmit,
      dialogTableLoading: dialogTableLoadingRef,
      addAuthor,
      submitRow,
      newSelectType: newSelectTypeRef,
      countryRemoteMethod,
      submit,
      deleteTag,
      showDialog,
      dialogDetail,
      authorId,
      authorTable,
      authorDetail,
      searchForm,
      paginationData: paginationDataRef,
      getPersons,
      handleCurrentChange,
      handleSizeChange,
      isLoading,
      tagDialogShow: tagDialogShowRef,
      authorChange,
      addAuthorFn,
      addExpert,
      deleteExpert,
      isDeceased,
      getChange,
      // topItem,
      // bottomItem,
    };
  },
};
</script>

<style lang="less" scoped>
.edit-person-container {
  height: 100%;
  .user-basic-form-container,
  .user-contact-form-container {
    width: 50%;
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    &.border {
      border: 1px dashed #d9d9d9;
    }
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-img-box {
      width: 100%;
      height: 100%;
      .avatar {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
  }
  .input-span {
    display: block;
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: #c7c7cc;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid rgb(220, 223, 230);
    border-radius: 4px;
    cursor: pointer;
  }
  .user-teach-form-container {
    .title-row {
      margin-bottom: 12px;
      .title-text {
        position: relative;
        padding-left: 10px;
        // &::before {
        //   content: "*";
        //   position: absolute;
        //   left: 0;
        //   top: 50%;
        //   height: 100%;
        //   line-height: 25px;
        //   font-size: 14px;
        //   color: #ff2525;
        //   transform: translateY(-50%);
        // }
      }
      .title-text-start {
        position: relative;
        padding-left: 10px;
        &::before {
          content: "*";
          position: absolute;
          left: 0;
          top: 50%;
          height: 100%;
          line-height: 25px;
          font-size: 14px;
          color: #ff2525;
          transform: translateY(-50%);
        }
      }
      .title-text-no-require {
        position: relative;
        padding-left: 10px;
      }
    }
    .form-row {
      margin-bottom: 20px;
      .inps-box {
        padding-right: 12px;
      }
      .subtract-col {
        display: flex;
        align-items: center;
        .subtract-box {
          display: block;
          width: 24px;
          height: 24px;
          margin-left: 12px;
          color: black;
          text-align: center;
          line-height: 24px;
          font-size: 16px;
          border: 1px solid black;
          border-radius: 50%;
          cursor: pointer;
          &.success {
            color: #0bb976;
            border-color: #0bb976;
          }
        }
      }
    }
    .add-row {
      margin-top: 20px;
    }
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .value-border {
    width: 100%;
    display: inline-block;
    padding: 0 12px;
    height: 40px;
    line-height: 38px;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
    border: var(--el-input-border, var(--el-border-base));
    color: #666;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
  }
}
.together_line{
  display: flex;
  /deep/.el-date-editor--year{
    flex-shrink: 0 !important;
  }
}

</style>
