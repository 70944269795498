<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar
      class="layout-main-content"
      id="content_box"
      v-if="!isLoading"
    >
      <div class="pd-20">
        <div class="album-detail-container">
          <el-card>
            <div class="basic-container">
              <el-form
                ref="formRef"
                :model="DetailInfo"
                label-width="120px"
                class="form-box mg-t-24"
                label-suffix="："
              >
                <el-form-item label="活动名称" prop="real_name">
                  {{ DetailInfo.title }}
                </el-form-item>
                <el-form-item label="活动时间" prop="mobile">
                  {{ DetailInfo.start_time + "-" + DetailInfo.end_time }}
                </el-form-item>
                <el-form-item label="活动简介">
                  <div v-html="DetailInfo.intro" style="width: 1000px"></div>
                </el-form-item>
                <el-form-item label="会议议程">
                  <el-tabs
                    v-model="activeName"
                    @tab-click="handleClick"
                    style="width: 1000px"
                  >
                    <el-tab-pane
                      :label="item.title"
                      v-for="item in DetailInfo.agenda_list"
                      :key="item.id"
                      :name="String(item.id)"
                    >
                      <div
                        style="
                          margin-bottom: 20px;
                          display: flex;
                          justify-content: end;
                        "
                      >
                        <el-button type="primary" @click="addPerson"
                          >添加</el-button
                        >
                      </div>
                      <el-table
                        :data="DetailInfo.guest_list"
                        border
                        style="width: 1000px"
                      >
                        <el-table-column
                          prop="name"
                          label="嘉宾姓名 "
                          width="80"
                        >
                        </el-table-column>
                        <el-table-column
                          prop="workplace"
                          label="单位"
                          width="180"
                        >
                        </el-table-column>
                        <el-table-column prop="time_name" label="活动时间">
                        </el-table-column>
                        <el-table-column
                          prop="job_title"
                          label="职位"
                          width="140"
                          show-overflow-tooltip
                        >
                        </el-table-column>
                        <el-table-column
                          prop="sort"
                          label="排序"
                          width="120"
                          align="center"
                        >
                          <template #default="scope">
                            <el-form
                              ref="formRef"
                              v-if="bannerActiveId == scope.row.id"
                            >
                              <el-form-item class="item-active">
                                <el-input
                                  style="width: 90px"
                                  size="small"
                                  v-model="bannerSort"
                                  @blur="inputBlur"
                                ></el-input>
                                <el-icon @click="listBlur" v-if="bannerSort">
                                  <Check />
                                </el-icon>
                              </el-form-item>
                            </el-form>
                            <div v-else @dblclick="bannerDBclick(scope.row)">
                              {{ scope.row.sort }}
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="address"
                          label="操作"
                          align="center"
                          width="120"
                        >
                          <template #default="scope">
                            <el-link
                              class="mg-r-12"
                              type="primary"
                              @click="addPerson(scope.row)"
                              >编辑</el-link
                            >
                            <el-link
                              class="mg-r-12"
                              type="primary"
                              @click="handleDelete(4, scope.row.id)"
                              >删除</el-link
                            >
                          </template>
                        </el-table-column>
                      </el-table>
                    </el-tab-pane>
                  </el-tabs>
                </el-form-item>
                <!-- 主办单位 -->
                <el-form-item label="主办单位">
                  <div style="width: 600px">
                    <div
                      style="
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: end;
                      "
                    >
                      <el-button type="primary" @click="handleModify(1)"
                        >添加</el-button
                      >
                    </div>
                    <el-table
                      :data="DetailInfo.organizer_list"
                      border
                      style="width: 600px"
                    >
                      <el-table-column prop="logo" label="logo" align="center">
                        <template #default="scope">
                          <el-image
                            style="width: 100px"
                            :src="scope.row.logo"
                            fit="cover"
                          ></el-image>
                          <!-- <el-avatar :src="scope.row.logo"></el-avatar> -->
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        label="企业名称"
                        width="300"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="address"
                        label="操作"
                        align="center"
                        width="120"
                      >
                        <template #default="scope">
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleModify(1, scope.row)"
                            >编辑</el-link
                          >
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleDelete(1, scope.row.id)"
                            >删除</el-link
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
                <!-- 战略合作伙伴 -->
                <el-form-item label="战略合作伙伴">
                  <div style="width: 600px">
                    <div
                      style="
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: end;
                      "
                    >
                      <el-button type="primary" @click="handleModify(2)"
                        >添加</el-button
                      >
                    </div>
                    <el-table
                      :data="DetailInfo.partner_list"
                      border
                      style="width: 600px"
                    >
                      <el-table-column prop="logo" label="logo" align="center">
                        <template #default="scope">
                          <el-image
                            style="width: 100px"
                            :src="scope.row.logo"
                            fit="cover"
                          ></el-image>
                          <!-- <el-avatar :src="scope.row.logo"></el-avatar> -->
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        label="企业名称"
                        width="300"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="address"
                        label="操作"
                        align="center"
                        width="120"
                      >
                        <template #default="scope">
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleModify(2, scope.row)"
                            >编辑</el-link
                          >
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleDelete(2, scope.row.id)"
                            >删除</el-link
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
                <!-- 合作媒体 -->
                <el-form-item label="合作媒体 ">
                  <div style="width: 600px">
                    <div
                      style="
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: end;
                      "
                    >
                      <el-button type="primary" @click="handleModify(3)"
                        >添加</el-button
                      >
                    </div>
                    <el-table
                      :data="DetailInfo.medium_list"
                      border
                      style="width: 600px"
                    >
                      <el-table-column prop="logo" label="logo " align="center">
                        <template #default="scope">
                          <el-image
                            style="width: 100px"
                            :src="scope.row.logo"
                            fit="cover"
                          ></el-image>
                          <!-- <el-avatar :src="scope.row.logo"></el-avatar> -->
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="name"
                        label="企业名称"
                        width="300"
                        align="center"
                      >
                      </el-table-column>
                      <el-table-column
                        prop="address"
                        label="操作"
                        align="center"
                        width="120"
                      >
                        <template #default="scope">
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleModify(3, scope.row)"
                            >编辑</el-link
                          >
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleDelete(3, scope.row.id)"
                            >删除</el-link
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>

    <!-- 嘉宾添加修改弹窗 -->

    <el-dialog v-model="personModalVisible" width="700px">
      <el-form
        ref="personModalRef"
        :model="personModalInfo"
        label-width="120px"
        class="form-box mg-t-24"
        label-suffix="："
      >
        <el-form-item label="选择论坛" prop="real_name">
          <el-select
            v-model="personModalInfo.club_id"
            placeholder="请选择论坛"
            style="width: 500px"
            @change="clubChange"
          >
            <el-option
              v-for="item in club_list"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择时间段" prop="real_name">
          <el-select
            v-model="personModalInfo.time_id"
            placeholder="请选择时间段"
            style="width: 500px"
          >
            <el-option
              v-for="item in time_list"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <div>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="`${baseUrl}admin/upload/image`"
              :on-success="(value) => avatarUploadSuccess(value, 1)"
              :headers="{ Authorization: 'Bearer ' + adminToken }"
              name="image"
              accept="image/jpg,image/jpeg,image/png"
              :before-upload="
                (file) => {
                  return handleBeforeUpload(file);
                }
              "
            >
              <img
                v-if="personModalInfo.avatar"
                :src="personModalInfo.avatar"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
            </el-upload>
            <p>只支持.jpg .png格式,建议尺寸180*220</p>
          </div>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input
            v-model="personModalInfo.name"
            placeholder="请输入姓名"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="workplace">
          <el-input
            v-model="personModalInfo.workplace"
            placeholder="请输入单位名称"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="职位" prop="job_title">
          <el-input
            v-model="personModalInfo.job_title"
            placeholder="请输入职位"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input
            v-model="personModalInfo.sort"
            placeholder="请输入职位"
            style="width: 500px"
          ></el-input>
        </el-form-item>
        <el-form-item label="介绍" prop="intro">
          <el-input
            v-model="personModalInfo.intro"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6 }"
            placeholder="请输入介绍"
            style="width: 500px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="personModalVisible = false">取消</el-button>
        <el-button type="primary" @click="submitPerson">保存</el-button>
      </template>
    </el-dialog>

    <!-- 合作伙伴弹窗 -->

    <el-dialog v-model="companyModalVisible" width="500px">
      <el-form
        ref="companyModalRef"
        :model="companyModalInfo"
        label-width="120px"
        class="form-box mg-t-24"
        label-suffix="："
      >
        <el-form-item label="logo" prop="avatar">
          <div>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="`${baseUrl}admin/upload/image`"
              :on-success="(value) => avatarUploadSuccess(value, 2)"
              :headers="{ Authorization: 'Bearer ' + adminToken }"
              name="image"
              accept="image/jpg,image/jpeg,image/png"
              :before-upload="
                (file) => {
                  return handleBeforeUpload(file);
                }
              "
            >
              <img
                v-if="companyModalInfo.logo"
                :src="companyModalInfo.logo"
                class="avatar"
                mode="aspectFit"
              />
              <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
            </el-upload>
            <p>只支持.jpg .png格式,建议尺寸128*44</p>
          </div>
        </el-form-item>

        <el-form-item label="单位名称" prop="workplace">
          <el-input
            v-model="companyModalInfo.name"
            placeholder="请输入单位名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="companyModalVisible = false">取消</el-button>
        <el-button type="primary" @click="submitCompany">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { ElMessageBox } from "element-plus";
import { Check } from "@element-plus/icons-vue";

export default {
  components: {
    Plus,
    Header,
    BreadCrumb,
    Check
  },
  setup() {
    const bannerActiveId = ref("");
    const bannerSort = ref("");
    const adminToken = ref(localStorage.getItem("adminToken"));
    const baseUrl = ref(window.env.baseUrl);
    const personModalVisible = ref(false);
    const companyModalVisible = ref(false);
    const activeName = ref("1");
    const router = useRouter();
    const breadList = ref([
      {
        name: "报名",
        path: {
          name: "dtiApply",
        },
      },
      {
        name: "报名详情",
      },
    ]);
    const DetailInfo = ref({
      title: "", // 活动名称
      intro: "", // 简介
      start_time: "", // 开始时间
      end_time: "", //结束时间
      state: "", //状态
      agenda_list: [
        {
          id: "",
          title: "",
          club_list: [
            {
              id: "",
              title: "",
              time_list: [{ id: "", title: "" }],
            },
          ],
        },
      ], // 会议议程列表
      guest_list: [], //嘉宾列表
      organizer_list: [], //主办单位
      partner_list: [], //合作伙伴
      medium_list: [], //合作媒体
    });
    const personModalInfo = ref({
      time_id: "",
      avatar: "", //
      name: "",
      intro: "",
      club_id: "",
      sort: 0,
    });
    const companyModalInfo = ref({
      logo: "",
      id: "",
      type: 1,
      name: "",
    });
    const club_list = ref([]);
    const time_list = ref([]);
    const isLoading = ref(false);
    const getInfo = async () => {
      const ret = await Ajax.GET({
        url: "/admin/mini/weekActivity/getInfo",
      });

      if (ret.data.code === 200) {
        DetailInfo.value = ret.data.data;
        console.log(ret.data.data.agenda_list);
        const dateInfo = ret.data.data.agenda_list.filter(
          (item) => item.id === Number(activeName.value)
        );
        console.log(dateInfo);
        if (activeName.value == 1) {
          club_list.value = dateInfo[0].club_list;
          time_list.value = club_list.value[0].time_list;
        } else {
          getGuestListByDate(activeName.value);
        }

        isLoading.value = false;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };
    // 选择论坛
    const clubChange = () => {
      const clubId = personModalInfo.value.club_id;
      personModalInfo.value.time_id = "";
      console.log(clubId);
      console.log(club_list);
      const timeList = club_list.value.filter((item) => item.id === clubId);
      time_list.value = timeList[0].time_list;
    };

    onMounted(() => {
      getInfo();
    });

    /**
     * 嘉宾新增或者编辑弹窗
     */
    //返回
    const goBack = () => {
      router.push({ name: "dtiApply" });
    };

    // 通过日期获取对应的嘉宾列表
    const getGuestListByDate = async (dateId) => {
      const ret = await Ajax.GET({
        url: `/admin/mini/weekActivity/getGuestList`,
        params: {
          date_id: dateId,
        },
      });
      if (ret.data.code === 200) {
        DetailInfo.value.guest_list = ret.data.data;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    // tab 切换

    const handleClick = (tab, event) => {
      getGuestListByDate(Number(activeName.value));
    };

    const addPerson = async (row) => {
      personModalVisible.value = true;
      console.log(row);
      if (row && row.id) {
        const res = await Ajax.GET({
          url: `/admin/mini/weekActivity/getGuestInfo`,
          params: {
            guest_id: row.id,
          },
        });
        if (res.data.code === 200) {
          personModalInfo.value = res.data.data;
        } else {
          MessageTips.error(res.data.msg);
        }
      } else {
        personModalInfo.value = {
          time_id: "",
          avatar: "", //
          name: "",
          intro: "",
          club_id: "",
        };
      }
      personModalInfo.value.date_id = Number(activeName.value);
      const dateInfo = DetailInfo.value.agenda_list.filter(
        (item) => item.id === Number(activeName.value)
      );
      console.log(dateInfo);
      club_list.value = dateInfo[0].club_list;
    };

    // 上传图片
    const avatarUploadSuccess = (e, type) => {
      console.log(e, "kkkk");
      if (type === 1) {
        personModalInfo.value.avatar = e.data.url;
      } else {
        companyModalInfo.value.logo = e.data.url;
      }
    };

    const handleBeforeUpload = (file) => {
      console.log(file);
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        MessageTips.error("上传文件大小不能超过 5MB!");
      }
      return isLt5M;
    };

    const submitPerson = async () => {
      let ret = await Ajax.POST({
        url: "/admin/mini/weekActivity/guestCreateOrUpdate",
        data: personModalInfo.value,
      });
      if (ret.data.code === 200) {
        personModalVisible.value = false;
        getGuestListByDate(activeName.value);
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const submitCompany = async () => {
      let ret = await Ajax.POST({
        url: "/admin/mini/weekActivity/relationCreateOrUpdate",
        data: companyModalInfo.value,
      });
      if (ret.data.code === 200) {
        companyModalVisible.value = false;
        getInfo();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    /**
     * 添加或编辑主办单位、合作伙伴、合作媒体
     * type: 1主办单位 2合作伙伴 3合作媒体
     */

    const handleModify = (type, row) => {
      companyModalVisible.value = true;
      if (row && row.id) {
        companyModalInfo.value = { ...row, type: type };
      } else {
        companyModalInfo.value = {
          logo: "",
          id: "",
          type: type,
          name: "",
        };
      }
    };

    /**
     * 删除主办单位、合作伙伴、合作媒体
     * type: 1主办单位 2合作伙伴 3合作媒体
     */

    const handleDelete = (type, id) => {
      ElMessageBox.confirm("确认删除" + "该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ret = await Ajax.POST({
            url: "/admin/mini/weekActivity/delRelation",
            data: {
              type: type,
              relation_id: id,
            },
          });
          if (ret.data.code === 200) {
            getInfo();
          }
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };

    const bannerDBclick = async (item) => {
      console.log("item", item);
      bannerActiveId.value = item.id;
      bannerSort.value = item.sort;
    };

    const inputBlur = () => {
      // bannerActiveId.value = "";
    };

    const listBlur = () => {
      console.log("bannerBlur");
      Ajax.POST({
        url: `/admin/mini/weekActivity/updateGuestSort`,
        data: { sort: bannerSort.value, guest_id: bannerActiveId.value },
      }).then((res) => {
        if (res.data.code == 200) {
          bannerActiveId.value = "";
          getGuestListByDate(Number(activeName.value));
        }
      });
    };

    return {
      breadList,
      // handleEdit,
      baseUrl,
      goBack,
      DetailInfo,
      personModalInfo,
      isLoading,
      handleClick,
      activeName,
      personModalVisible,
      companyModalVisible,
      addPerson,
      club_list,
      time_list,
      avatarUploadSuccess,
      handleBeforeUpload,
      companyModalInfo,
      handleModify,
      handleDelete,
      clubChange,
      submitPerson,
      adminToken,
      submitCompany,
      bannerDBclick,
      listBlur,
      inputBlur,
      bannerSort,
      bannerActiveId,
    };
  },
  methods: {
    showAdress(row, colum) {
      if (row.city_name) {
        return row.city_name;
      }
      if (row.province_name) {
        return row.province_name;
      }

      return "";
    },
    pushDate(row, colum) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row > 0) {
        var time = new Date(row * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y +
          "-" +
          add0(m) +
          "-" +
          add0(d) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  ::v-deep(.el-tabs__content) {
    flex: 1;
    overflow-y: auto;
  }
}
.basic-container {
  word-break: break-all;
  .form-box {
    width: 100%;
  }

  .submit-box {
    background-color: #fff;
  }
  .aboutitem {
    margin-right: 20px;
  }
}
.content-container {
  height: 100%;
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
.lable-item {
  margin-right: 10px;
}
.business_card {
  width: 400px;
  height: 200px;
}
.avatar-uploader {
  width: 108px;
  height: 108px;
  ::v-deep(.el-upload) {
    width: 108px;
    height: 108px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }
}
.avatar {
  width: 108px;
  height: 108px;
}

.item-active {
    :deep(.el-form-item__content ){
      display: flex;
      align-items: center;
      .el-input, .el-input__inner {
        width: 70px;
      }
      .el-icon {
        margin-left: 10px;
      }
    }
  }
</style>
