<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">著作基本信息</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="bookForm"
                :rules="bookFormRule"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="著作名称" prop="name">
                  <el-input
                    style="width: 400px"
                    v-model="bookForm.name"
                    placeholder="请输入"
                  ></el-input>
                </el-form-item>
                <el-form-item label="作者" prop="author">
                  <el-tag
                    class="mg-r-12"
                    v-for="(tag, index) in bookForm.author"
                    :key="index"
                    closable
                    :disable-transitions="false"
                    @close="deleteTag(index)"
                  >
                    {{ tag.name }}
                  </el-tag>
                  <el-button size="small" @click="showDialog"
                    >+ 新标签</el-button
                  >
                </el-form-item>
                <el-form-item label="出版社" prop="publisher">
                  <el-input
                    style="width: 400px"
                    v-model="bookForm.publisher"
                    placeholder="请输入"
                  />
                </el-form-item>
                <el-form-item label="出版年份" prop="publish_time">
                  <el-date-picker
                    style="width: 400px"
                    v-model="bookForm.publish_time"
                    type="year"
                    placeholder="请选择"
                    :value-format="'YYYY'"
                  ></el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16 mg-t-24">
            <el-row justify="end">
              <el-col :span="4">
                <el-button plain @click="cancelSubmit('formRef')"
                  >取消</el-button
                >
                <el-button type="primary" @click="submitForm('formRef')"
                  >保存</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

  <el-dialog
    v-model="tagDialogShow"
    title="选择作者"
    :width="880"
    :close-on-click-modal="false"
    :show-close="true"
  >
    <div class="dialog-content">
      <el-input
        style="width: 200px"
        v-model="searchForm.name"
        placeholder="搜索作者"
        :suffix-icon="Search"
        @change="getPersons('search')"
      ></el-input>
      <el-table
        class="mg-t-12"
        :data="dialogTable"
        border
        max-height="400"
        v-loading="isLoading"
      >
        <el-table-column prop="name" label="姓名">
          <template #default="scope">
            <el-input
              v-if="scope.row.type == 'new'"
              v-model="scope.row.name"
              placeholder="请输入"
            ></el-input>
            <span v-else>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="research_label_arr" label="研究标签" />
        <el-table-column prop="invest_label_arr" label="投资标签" />
        <el-table-column prop="startup_label_arr" label="创业标签" />
        <el-table-column prop="operation" label="操作">
          <template #default="scope">
            <el-radio v-model="tagId" :label="scope.row.id" @change="tagChange"
              >选择</el-radio
            >
          </template>
        </el-table-column>
        <template #empty>
          没有匹配的结果<el-button
            type="primary"
            class="mg-l-20"
            size="small"
            @click="addAuthor()"
            >新增</el-button
          >
        </template>
      </el-table>
      <div class="table-pagination-row mg-t-24">
        <el-pagination
          background
          :currentPage="paginationData.current"
          :page-sizes="paginationData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paginationData.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <el-button @click="tagDialogShow = false">取消</el-button>
      <el-button type="primary" @click="addNewTag()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips, Rule } from "../../../utils";
import { Plus, Search } from "@element-plus/icons-vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import Header from "../../../Layout/components/Header.vue";
export default {
  components: {
    Plus,
    BreadCrumb,
    Header,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "著作",
        path: {
          name: "books",
        },
      },
      {
        name: "著作详情",
        path: {
          name: "booksDetail",
          query: {
            id: route.query.id,
          },
        },
      },
      {
        name: "编辑著作",
      },
    ]);
    const isLoading = ref(false);
    const { proxy } = getCurrentInstance();
    const bookFormRef = ref({
      name: undefined,
      author: [],
      authorID: [],
      publish_time: undefined,
      publisher: undefined,
    });
    const tagId = ref();
    const bookFormRule = {
      name: [
        {
          required: true,
          message: "请输入著作名称",
          trigger: "blur",
        },
      ],
      author: [
        {
          required: false,
          message: "请选择退出方式",
          trigger: "blur",
        },
      ],
      publisher: [
        {
          required: false,
          message: "请输入出版社",
          trigger: "blur",
        },
      ],
      publish_time: [
        {
          required: false,
          message: "请输入发布时间",
          trigger: "blur",
        },
      ],
    };

    const dialogTable = ref([]);
    const searchForm = ref({
      page: 1,
      page_size: 10,
      name: undefined,
    });
    const tagDetail = ref({
      id: 0,
      name: undefined,
    });
    const cancelSubmit = (formName) => {
      // proxy.$refs[formName].resetFields();
      back();
    };
    const back = () => {
      router.go(-1);
    };
    const submitForm = (formName) => {
      proxy.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit");
          Ajax.PUT({
            url: `/admin/works/${bookFormRef.value.id}`,
            data: {
              name: bookFormRef.value.name,
              author:
                bookFormRef.value.authorID.length > 0
                  ? bookFormRef.value.authorID.join(",")
                  : undefined,
              publisher: bookFormRef.value.publisher,
              publish_time: bookFormRef.value.publish_time,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              MessageTips.success("编辑成功");
              back();
            } else {
              MessageTips.error(res.data.msg);
            }
          });
        } else {
          console.log("error");
          return;
        }
      });
    };

    const tagDialogShowRef = ref(false);

    const showDialog = () => {
      dialogTable.value = [];
      getPersons("search");
      tagDetail.value = {};
      tagDialogShowRef.value = true;
    };
    const addNewTag = () => {
      if (tagId.value) {
        if (bookFormRef.value.authorID.indexOf(tagId.value) != -1) {
          MessageTips.error("不能选择重复数据");
          return;
        }
        bookFormRef.value.author.push(tagDetail.value);
        bookFormRef.value.authorID.push(tagId.value);
      }else{
        MessageTips.error("请选择");
          return;
      }

      tagDialogShowRef.value = false;
    };
    const deleteTag = (index) => {
      bookFormRef.value.author.splice(index, 1);
      bookFormRef.value.authorId.splice(index, 1);
    };
    const tagChange = (tag) => {
      if (!tag) {
        Ajax.POST({
          url: "/admin/personage/add_audit_personage",
          data: {
            name: dialogTable.value[0].name,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            dialogTable.value[0].id = res.data.data.insertId;
            tagId.value = res.data.data.insertId;
          } else {
            MessageTips.error(res.data.msg);
            return;
          }
        });
      }
      for (let i in dialogTable.value) {
        let item = dialogTable.value[i];
        if (item.id === tag) {
          tagDetail.value = item;
        } else {
        }
      }
    };
    const getPersons = (type) => {
      isLoading.value = true;
      if (type) {
        paginationDataRef.value.current = 1;
        searchForm.value.page = 1;
      }
      Ajax.GET({
        url: "/admin/personage",
        params: searchForm.value,
      }).then((res) => {
        isLoading.value = false;
        dialogTable.value = res.data.data.list;
        paginationDataRef.value.total = res.data.data.page_info.total;
      });
    };
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      searchForm.value.page_size = e;
      getPersons();
    };
    const handleCurrentChange = (e) => {
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getPersons();
    };
    const getDetail = (id) => {
      Ajax.GET({
        url: `/admin/works/${id}`,
      }).then((res) => {
        if (res.data.code == 200) {
          bookFormRef.value = res.data.data;
          bookFormRef.value.authorID = [];
          for (let i in bookFormRef.value.author) {
            let item = bookFormRef.value.author[i];
            bookFormRef.value.authorID.push(item.id);
          }
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const addAuthor = () => {
      dialogTable.value[0] = {
        type: "new",
        name: searchForm.value.name,
        id: 0,
      };
      paginationDataRef.value.total = 1;
    };
    onMounted(() => {
      let id = route.query.id;
      getDetail(id);
    });
    return {
      breadLists,
      tagId,
      tagDetail,
      dialogTable,
      searchForm,
      bookForm: bookFormRef,
      cancelSubmit,
      submitForm,
      bookFormRule,
      showDialog,
      addNewTag,
      deleteTag,
      tagChange,
      getPersons,
      tagDialogShow: tagDialogShowRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      Rule,
      Search,
      addAuthor,
      isLoading,
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 80%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
}
</style>