<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="list-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            @statusChange="statusChange"
            @addClick="handleAdd"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="pd-32">
                <el-table
                  :data="ListData"
                  border
                  v-loading="loading"
                  element-loading-text="加载中"
                >
                  <el-table-column
                    prop="id"
                    width="70"
                    align="center"
                    label="专辑ID"
                  />
                  <el-table-column
                    prop="title"
                    width="180"
                    show-overflow-tooltip
                    label="专辑名称"
                  />
                  <el-table-column
                    prop="object_num"
                    width="70"
                    align="center"
                    label="数量"
                  />
                  <el-table-column
                    prop="type"
                    label="类型"
                    :formatter="formateType"
                    width="70"
                    align="center"
                  />
                  <el-table-column
                    prop="name"
                    label="创建人"
                    width="90"
                    align="center"
                  />
                  <el-table-column
                    prop="domain"
                    label="行业领域"
                    width="140"
                    align="center"
                  />
                  <el-table-column
                    prop="create_time"
                    label="创建时间"
                    :formatter="createDate"
                    width="170"
                    align="center"
                  />
                  <el-table-column
                    prop="update_time"
                    label="最后更新时间"
                    :formatter="updateDate"
                    width="170"
                    align="center"
                  />
                  <el-table-column
                    prop="status"
                    label="状态"
                    width="90"
                    align="center"
                  >
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.status === 1"
                        >已发布</span
                      >
                      <span class="status-text info" v-else>未发布</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="操作"
                    fixed="right"
                    width="120"
                    align="center"
                  >
                    <template #default="scope">
                      <el-link
                        class="mg-r-12"
                        type="primary"
                        @click="handleDetail(scope.row.id)"
                        >详情</el-link
                      >
                      <el-link
                        v-if="scope.row.status === 1"
                        class="mg-r-12"
                        type="primary"
                        @click="changeStatus(scope.row.id, scope.row.status)"
                        >下架</el-link
                      >
                      <el-link
                        v-else-if="scope.row.status === 2"
                        class="mg-r-12"
                        type="primary"
                        @click="changeStatus(scope.row.id, scope.row.status)"
                        >上架</el-link
                      >
                      <el-link
                        v-if="scope.row.status !== 1"
                        class="mg-r-12"
                        type="primary"
                        @click="handlePublish(scope.row.id)"
                        >发布</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="queryData.page"
                    :page-size="queryData.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  />
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { Ajax, MessageTips } from "@/utils";
import { ElMessageBox } from "element-plus";
export default {
  components: {
    Header,
    BreadCrumb,
    SearchFilterBar,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      breadList: [
        {
          name: "专辑",
        },
      ],
      statusLabels: [
        {
          value: 0,
          name: "全部",
        },
        {
          value: 1,

          name: "已发布",
        },
        {
          value: 2,
          name: "未发布",
        },
      ],
      queryData: {
        size: 10,
        page: 1,
        status: 0,
        title: "",
      },
      // total:  10,
    });

    const loading = ref(false);

    const ListData = ref([]);
    const total = ref(0);

    const getTableData = async () => {
      loading.value = true;
      const ret = await Ajax.POST({
        url: "/admin/album",
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        loading.value = false;
        ListData.value = ret.data.data.items;
        total.value = ret.data.data.total;
      } else {
        loading.value = false;
        MessageTips.error(ret.data.msg);
      }
    };

    // 状态选择
    const statusChange = (status) => {
      state.queryData.status = status;
      getTableData();
    };

    // 新增
    const handleAdd = () => {
      router.push({
        name: "dtiAlbumCreate",
      });
    };

    // 查询
    const handleSearch = ({ searchValue }) => {
      state.queryData.title = searchValue;
      getTableData();
    };

    // 详情
    const handleDetail = (item) => {
      router.push({
        name: "dtiAlbumDetail",
        params: { id: item },
      });
    };

    // 页数改变
    const handleCurrentChange = (val) => {
      state.queryData.page = val;
      getTableData();
    };

    const handleSizeChange = (val) => {
      state.queryData.size = val;
      getTableData();
    };

    const setStatus = async (id, status) => {
      const ret = await Ajax.POST({
        url: "/admin/album/status/" + id,
        data: { status: status },
      });
      if (ret.data.code === 200) {
        getTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const changeStatus = async (id, status) => {
      let str = status == 1 ? "下架" : "上架";
      ElMessageBox.confirm("确认" + str + "该专辑?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setStatus(id, status == 1 ? 2 : 1);
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };
    const handlePublish = async (id) => {
      const ret = await Ajax.POST({
        url: "/admin/album/publish",
        data: { id: id },
      });
      console.log(ret.data.msg)
      if (ret.data.code === 200) {
         MessageTips.success("发布成功");
         getTableData();
      }else{
        MessageTips.error(ret.data.msg);
      }
    };

    onMounted(() => {
      getTableData();
    });
    return {
      ...state,
      statusChange,
      handleAdd,
      handleSearch,
      handleDetail,
      handleCurrentChange,
      handleSizeChange,
      ListData,
      total,
      getTableData,
      changeStatus,
      loading,
      handlePublish
    };
  },
  methods: {
    updateDate(row, colum) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row.update_time > 0) {
        var time = new Date(row.update_time * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y +
          "-" +
          add0(m) +
          "-" +
          add0(d) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    },
    createDate(row, colum) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row.create_time > 0) {
        var time = new Date(row.create_time * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y +
          "-" +
          add0(m) +
          "-" +
          add0(d) +
          " " +
          add0(h) +
          ":" +
          add0(mm) +
          ":" +
          add0(s)
        );
      }
    },
    formateType(row, colum) {
      return row.type == 1 ? "品牌" : "人物";
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
  .status-text {
    font-size: 14px;
    &.primary {
      color: #409eff;
    }
    &.success {
      color: #67c23a;
    }
    &.danger {
      color: #f56c6c;
    }
    &.info {
      color: #909399;
    }
  }
}
// .list-container {
//   height: 100%;
//   overflow-y: auto;
//   width: 100%;
//   .status-text {
//     font-size: 14px;
//     &.primary {
//       color: #409eff;
//     }
//     &.info {
//       color: #909399;
//     }
//   }
//   .table-pagination-row {
//     display: flex;
//     justify-content: flex-end;
//   }
// }
</style>
