<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-container">
          <SearchFilterBar
            isControlStatus
            :controlStatus="labelStatus"
            :statusLabels="statusLabels"
            @statusChange="statusChange"
            @searchClick="handleSearch"
            :addShow="false"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="table-container pd-32">
                <el-table :data="tableData" border :max-height="650">
                  <el-table-column prop="id" label="反馈ID" width="80" />
                  <el-table-column prop="desc" label="内容" />
                  <el-table-column
                    prop="username"
                    label="用户姓名"
                    width="100"
                  />
                  <el-table-column prop="phone" label="联系方式" width="140" />
                  <el-table-column
                    prop="create_time"
                    label="反馈时间"
                    width="160"
                  />
                  <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.status === 1"
                        type="text"
                        >待处理</span
                      >
                      <span
                        class="status-text primary"
                        v-else-if="scope.row.status === 2"
                        type="text"
                        >已处理</span
                      >
                      <span
                        class="status-text info"
                        v-else-if="scope.row.status === 3"
                        type="text"
                        >已驳回</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="110">
                    <template #default="scope">
                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="gotoPage(scope.row.id)"
                        >查看</el-link
                      >
                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="showDialog(scope.row.id)"
                        >删除</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

  <el-dialog v-model="isShowDialog" :width="400">
    <span>确定删除该反馈?</span>
    <template #footer>
      <el-button @click="isShowDialog = false">取消</el-button>
      <el-button type="primary" @click="del()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import SearchFilterBar from "../../components/SearchFilterBar/index.vue";
import Header from "../../Layout/components/Header.vue";
import BreadCrumb from "../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax } from "../../utils";
export default {
  components: {
    SearchFilterBar,
    Header,
    BreadCrumb,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const breadLists = ref([{ name: "反馈" }]);
    const labelStatusRef = ref(0);
    const statusLabelsRef = ref([
      {
        name: "全部",
        value: 0,
      },
      {
        name: "待处理",
        value: 1,
      },
      {
        name: "已处理",
        value: 2,
      },
      {
        name: "已驳回",
        value: 3,
      },
    ]);
    const nowStatusRef = ref(null);
    const nowSearchValueRef = ref("");
    // 状态选择
    const statusChange = (status) => {
      nowStatusRef.value = status;
      labelStatusRef.value = status;
      console.log(labelStatusRef.value, status, "stauts");
      getList();
    };
    // 查询
    const handleSearch = (searchObj) => {
      nowSearchValueRef.value = searchObj.searchValue;
      getList();
    };
    // 表格数据
    const tableDataRef = ref([]);
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      page_size: 10,
      total: 0,
    });
    const handleSizeChange = (e) => {
      paginationDataRef.value.page_size = e;
      getList();
    };
    const handleCurrentChange = (e) => {
      paginationDataRef.value.current = e;
      getList();
    };
    const gotoPage = (id) => {
      router.push({
        path: "/feedbacks/detail",
        query: {
          id: id,
        },
      });
    };

    const getList = async () => {
      const res = await Ajax.GET({
        url: "/admin/feedback",
        params: {
          page: paginationDataRef.value.current,
          page_size: paginationDataRef.value.page_size,
          desc: nowSearchValueRef.value || undefined,
          status: nowStatusRef.value ? nowStatusRef.value : undefined,
        },
      });
      const resData = res.data;
      if (resData.code === 200) {
        tableDataRef.value = resData.data.list;
        paginationDataRef.value.current = resData.data.page_info.page;
        paginationDataRef.value.total = resData.data.page_info.total;
      }
    };
    onMounted(() => {
      if (route.query.fromHome == 1) {
        labelStatusRef.value = 1;
        statusChange(1);
      } else {
        getList();
      }
    });
    //删除
    const isShowDialog = ref(false);
    const dialogDetail = ref({
      id: undefined,
    });
    const showDialog = (id) => {
      dialogDetail.value.id = id;
      isShowDialog.value = true;
    };
    const del = () => {
      Ajax.DELETE({
        url: `/admin/feedback/${dialogDetail.value.id}`,
        contentType: "application/x-www-form-urlencoded",
      }).then((res) => {
        isShowDialog.value = false;
        if (res.data.code == 200) {
          MessageTips.success("操作成功");
          getList();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    return {
      breadLists,
      labelStatus: labelStatusRef,
      statusChange,
      handleSearch,
      tableData: tableDataRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      statusLabels: statusLabelsRef,
      gotoPage,
      isShowDialog,
      dialogDetail,
      showDialog,
      del,
    };
  },
};
</script>

<style lang="less" scoped>
.person-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
}
</style>