<template>
	<div class="layout-container-box">
		<el-header class="layout-header">
			<Header>
				<template #header-brand>
					<BreadCrumb :breadList="breadLists"/>
				</template>
			</Header>
		</el-header>
		<el-scrollbar v-loading="tableLoading" class="layout-main-content" id="content_box">
			<div class="pd-20">
				<div class="reports-container">
					<SearchFilterBar :statusLabels="statusLabels"
													 @statusChange="statusChange"
													 @addClick="handleAdd" @searchClick="handleSearch"/>
					<div class="table-con mg-t-12">
						<el-scrollbar>
							<div class="table-container pd-32">
								<el-table :data="tableData" border :max-height="650">
									<el-table-column prop="name" label="报道标题" :show-overflow-tooltip="true">
										<template #default="scope">
											<span >{{scope.row.name}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="author" label="作者" :show-overflow-tooltip="true">
										<template #default="scope">
											<span >{{scope.row.author}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="source" label="来源" :show-overflow-tooltip="true">
										<template #default="scope">
											<span >{{scope.row.source}}</span>
										</template>
									</el-table-column>
									<el-table-column prop="link" label="链接" width="80">
										<template #default="scope">
											<span :title="scope.row.link" v-if="scope.row.link">
												<el-link type="primary" :href="scope.row.link" target="_blank">点击查看</el-link>
											</span>
										</template>
									</el-table-column>
									<el-table-column prop="report_time" label="报道时间" width="160"/>
									<el-table-column prop="update_time" label="最近编辑时间" width="160"/>
									<el-table-column prop="create_time" label="发布时间" width="160"/>
									<el-table-column prop="status" label="状态" width="70">
										<template #default="scope">
											<span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
											<span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
											<span class="status-text green" v-else-if="scope.row.status === 3">已完善</span>
											<span class="status-text info" v-else>已撤回</span>
										</template>
									</el-table-column>
									<el-table-column label="操作" width="100">
										<template #default="scope">
											<el-link type="primary" @click="toPage('ReportDetail', {id: scope.row.id})">详情</el-link>

											<el-link
													type="primary"
													@click="handleStatus(scope.row.id, 4)"
													v-if="scope.row.status === 1" >撤回</el-link>

											<el-link
													type="primary"
													@click="handleStatus(scope.row.id, 1)"
													v-if="scope.row.status === 3" >发布</el-link>
											<el-link
													type="primary"
													@click="handleStatus(scope.row.id, 1)"
													v-if="scope.row.status === 4" >发布</el-link>
										</template>
									</el-table-column>
								</el-table>
								<div class="table-pagination-row mg-t-24">
									<el-pagination
											background
											:currentPage="paginationData.current"
											:page-sizes="paginationData.pageSize"
											layout="total, sizes, prev, pager, next, jumper"
											:total="paginationData.total"
											@size-change="handleSizeChange"
											@current-change="handleCurrentChange"
									>
									</el-pagination>
								</div>
							</div>
						</el-scrollbar>
					</div>
				</div>
			</div>
		</el-scrollbar>
	</div>
</template>

<script>
import Header from '@/Layout/components/Header.vue';
import BreadCrumb from '@/components/BreadCrumb/index.vue';
import SearchFilterBar from '@/components/SearchFilterBar/index.vue'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRouter} from 'vue-router'
import {Ajax, MessageTips} from "@/utils";

export default {
	components: {
		SearchFilterBar,
		Header,
		BreadCrumb
	},
	setup() {
		const router = useRouter();
		const breadListsRef = ref([
			{
				name: '报道'
			}
		])
		const statusLabelsRef = ref(
				[
					{
						name: '全部',
						value: 0,
					},
					{
						name: '已发布',
						value: 1,
					},
					{
						name: '已完善',
						value: 3,
					},
					{
						name: '待完善',
						value: 2,
					},
					{
						name: '已撤回',
						value: 4,
					}
				]
		);
		const {proxy} = getCurrentInstance()
		// 新增
		const handleAdd = (e) => {
			console.log('add-click', e)
			// router.push('/person/create')
			router.push({
				name: 'CreateReports'
			})
		};
		// 表格数据
		const tableDataRef = ref([])
		// 页码
		let paginationDataRef = ref({
			current: 1,
			pageSize: [10, 20, 50],
			page_size: 10,
			page: 1,
			total: 0
		});

		const tableLoadingRef = ref(true)
		const searchValuesRef = ref({
			searchValue: ''
		});

		const dataStatus = ref(0)
		const statusChange = status => {
			dataStatus.value = status
			getDataList()
		}
		// 查询
		const handleSearch = value => {
			searchValuesRef.value.searchValue = value.searchValue;
			getDataList()
		}

		const handleSizeChange = e => {
			console.log(e, 'sizeChange');
		}
		const handleCurrentChange = (e) => {
			paginationDataRef.value.current = e;
			// searchForm.value.page = e;
			getDataList();
		};

		const showString = (string ,len) => {
			return string.length >len ? string.substring(0,len) + '...' : string
		}

		const toPage = (name, query) => {
			router.push({
				name,
				query
			})
		}

		const handleStatus = (id, status) => {
			let str = status === 1 ? '发布' : '撤回'
			let type = status === 1 ? 'success' : 'error'
			proxy
					.$confirm('此操作将' + str + '该数据, 是否继续?', "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: type,
					})
					.then(async () => {
						Ajax.POST({
							url: '/admin/report/status',
							params: {
								id: id,
								status: status
							}
						}).then(res => {
							if(res.data.code === 200) {
								MessageTips.success('成功');
								getDataList();
							}else {
								MessageTips.error(res.data.msg)
							}
						}).catch(() => {
							MessageTips.error('失败')
						});
					})
		};

		const getDataList = () => {
			tableLoadingRef.value = true;
			Ajax.GET({
				url: '/admin/report',
				params: {
					page: paginationDataRef.value.current,
					page_size: paginationDataRef.value.page_size,
					name: searchValuesRef.value.searchValue || '',
					status: dataStatus.value ? dataStatus.value : 0
				}
			}).then(res => {
				if (res.data.code === 200) {
					tableDataRef.value = res.data.data.list
					paginationDataRef.value.current = res.data.data.page_info.page;
					paginationDataRef.value.total = res.data.data.page_info.total;
					tableLoadingRef.value = false;
				}
			})
		}

		onMounted(() => {
			getDataList()
		})
		return {
			breadLists: breadListsRef,
			handleAdd,
			handleSearch,
			tableData: tableDataRef,
			paginationData: paginationDataRef,
			handleSizeChange,
			handleCurrentChange,
			statusChange,
			statusLabels: statusLabelsRef,
			tableLoading: tableLoadingRef,
			handleStatus,
			toPage,
			showString,
		}
	}
};
</script>

<style lang="less" scoped>
.cell > .el-link {
	padding-right: 10px;
}

.reports-container {
	height: 100%;

.table-pagination-row {
	display: flex;
	justify-content: flex-end;
}

.table-con {
	height: 100%;
	max-height: calc(100% - 66px - 12px);
	background-color: #fff;
}

.green {
	color: #67c23a;
}

.status-text {
	font-size: 14px;

&
.primary {
	color: #409eff;
}

&
.success {
	color: #67c23a;
}

&
.danger {
	color: #f56c6c;
}

&
.info {
	color: #909399;
}

}
}
</style>
