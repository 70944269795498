<template>
  <el-dialog
    v-model="dialogShow"
    title="选择专辑"
    width="500px"
    :close-on-click-modal="true"
    :show-close="false"
    destroy-on-close
    @close="handleClose"
  >
    <div class="dialo-content">
      <el-form ref="form">
        <el-form-item style="width: 200px" label="排序">
          <el-input v-model="sort" placeholder="请输入排序"></el-input>
        </el-form-item>
        <el-form-item style="width: 300px" label="上传图片">
          <div>
            <el-upload
              class="avatar-uploader"
              :show-file-list="false"
              :action="`${baseUrl}admin/upload/image`"
              :headers="{'Authorization': 'Bearer ' +  adminToken}"
              name="image"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              accept="image/jpg,image/jpeg"
            >
              <img
                v-if="icon"
                :src="icon"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
            </el-upload>
            <p>只支持.jpg 格式,建议尺寸为690*280,大小不超过5M</p>
          </div>
        </el-form-item>
        <el-form-item style="width: 200px">
          <!-- <el-input
            v-model="dialogSearchInp"
            placeholder="搜索专辑"
            :suffix-icon="Search"
          ></el-input> -->
          <el-input
            v-model="dialogSearchInp"
            placeholder="搜索专辑"
            class="input-with-select"
          >
            <template #append>
              <el-button :icon="Search" @click="getSearchList"></el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <el-table :data="dialogTable" border max-height="300">
        <el-table-column prop="title" label="专辑名称" />
        <el-table-column prop="object_num" label="主体数量" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-radio v-model="dialogRadio" :label="scope.row.id" @change="()=> handleRadioChange(scope.row.id)"
              >选择</el-radio
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="table-pagination-row mg-t-24">
        <el-pagination
          background
          :currentPage="dialogPaginationData.current"
          :page-sizes="dialogPaginationData.pageSizes"
          :page-size="dialogPaginationData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="dialogPaginationData.total"
          @size-change="dialogHandleSizeChange"
          @current-change="dialogHandleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <template #footer>
      <el-button plain @click="handleBack">返回</el-button>
      <el-button type="primary" @click="handleSure">完成</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, onMounted} from "vue";
import { useRoute, useRouter } from "vue-router";
import { Plus, Search } from "@element-plus/icons-vue";
import { Ajax, MessageTips } from "@/utils";
import { ElMessage } from 'element-plus'
export default {
  components: {
    Plus,
  },
  props: {
    isShowModule: Boolean,
    status: Number
  },
  setup(props, {emit}) {
    const route = useRoute();
    const router = useRouter();
    /* 弹窗选择 start */
    const dialogShow = ref(props.isShowModule);
    const dialogSearchInp = ref("");
    const dialogTable = ref([]);
    const dialogRadio = ref("");
    const dialogPaginationDataRef = ref({
      current: 1,
      pageSizes: [10, 20, 50],
      pageSize: 20,
      total: 0,
    });
    const icon = ref('')
    const sort = ref('')
    const album_id = ref('')
    const adminToken = ref(localStorage.getItem('adminToken'))
    const baseUrl = ref(window.env.baseUrl)
    const dialogHandleSizeChange = (e) => {
      console.log(e);
      dialogPaginationDataRef.value.pageSize = e
      dialogPaginationDataRef.value.current = 1
      getSearchList()
    };
    const dialogHandleCurrentChange = (e) => {
      dialogPaginationDataRef.value.current = e;
      getSearchList()
    };
    const handleBack = () => {
      dialogShow.value = false;
      emit('closeDialog')
    };
    
    const handleClose = () => {
      emit('closeDialog')
    }

    const getSearchList = () => {
      Ajax.POST({
        url: "/admin/mini/album/search",
        data: {
          name: dialogSearchInp.value,
          page: dialogPaginationDataRef.value.current,
          pageSize: dialogPaginationDataRef.value.pageSize,
          type: props.status == 0 ? 2 : 1
        },
      }).then((res) => {
          // console.log(res)
          if (res.data.code === 200){
            dialogPaginationDataRef.value.total = res.data.data.total,
            dialogTable.value = res.data.data.items
          }
      });
    }

    const handleSure = () => {
      console.log('完成')
      Ajax.POST({
        url: "/admin/mini/index/album/create",
        data: {
          icon: icon.value,
          sort: sort.value,
          album_id: album_id.value,
          type: props.status === 0 ? 2 :  1
        },
      }).then((res) => {
          console.log('首页专辑 - 添加',  res)
          if (res.data.code === 200){
            handleClose()
          }
      });
    }

    const handleRadioChange = id => {
      album_id.value = id
    }

    const handleAvatarSuccess = (res, file) => {
      console.log('res, file', res, file)
      // icon.value = URL.createObjectURL(file.raw)
      if (res.code === 200){
        icon.value = res.data.url
      }
    }

    const beforeAvatarUpload = (file) => {
      const isLt2M = file.size / 1024 / 1024 < 5

      if (!isLt2M) {
        ElMessage.error('上传文件大小不能超过 5MB!')
      }
      return  isLt2M
    }
    const setAddUrl=(flag)=>{
      if(flag){
        apply_url.value="/pages/apply-form/index?source=1"
      }else{
        apply_url.value=""
      }
    }
    return {
      // 弹窗
      dialogShow,
      dialogSearchInp,
      dialogTable,
      dialogRadio,
      dialogPaginationData: dialogPaginationDataRef,
      dialogHandleSizeChange,
      dialogHandleCurrentChange,
      handleBack,
      Search,
      handleClose,
      getSearchList,
      handleSure,
      icon,
      sort,
      album_id,
      handleRadioChange,
      adminToken,
      handleAvatarSuccess,
      beforeAvatarUpload,
      baseUrl,
      addUrl,
      setAddUrl,
    };
  },
  watch:{
    album_id(value){
      if(value!=''){
        this.setapplyurl(true)
      }else{
        this.setapplyurl(false)
      }
    },
    apply_url(value){
      if(value!=''){
        this.setalbumFlag(true)
      }else{
        this.setalbumFlag(false)
      }
    },
    addUrl(value){
      if(value){
        this.setAddUrl(true)
      }else{
        this.setAddUrl(false)
      }
    }
  }
};
</script>

<style lang="less" scoped>
.avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
    .avatar{
      width: 108px;
    height: 108px;
    }
  }
  </style>
