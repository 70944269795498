import * as echarts from 'echarts/core';
import { TooltipComponent, GridComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([TooltipComponent, GridComponent, LineChart, CanvasRenderer, UniversalTransition]);

export default function useHomeCharts(chartDom, refDatas) {
    const myChart = echarts.init(chartDom);
    let option, xData = [], yData = [];
    refDatas.value.forEach(item => {
        xData.push(item.day);
        yData.push(item.number)
    })
    option = {
        grid: {
            left: 42,
            right: 24
        },
        xAxis: {
            type: 'category',
            data: xData,
            axisTick: {
                show: false
            },
            nameTextStyle: {
                color: 'rgba(0, 0, 0, 0.45)'
            }
        },
        yAxis: {
            type: 'value',
            splitNumber: 4,
            nameTextStyle: {
                color: 'rgba(0, 0, 0, 0.45)'
            }
        },
        series: [
            {
                data: yData,
                type: 'line',
                smooth: true,
                symbol: 'circle',
                symbolSize: 8,
                itemStyle: {
                    color: '#AA68F0',
                },
                lineStyle: {
                    width: 2,
                    color: '#AA68F0'
                }
            }
        ],
        tooltip: {
            show: true
        }
    };

    option && myChart.setOption(option);
    return {
        myChart,
        chartOption: option
    }
}
