<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="person-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">{{ nowPageTitle }}（{{ paginationData.total }}）</span>
                                <template v-if="nowPageType === 'fund-manage'">
                                    <el-button type="primary" @click="createFund"><el-icon><plus /></el-icon> 新增</el-button>
                                </template>
                                
                            </div>
                        </template>
                        <div class="table-container pd-32">
                            <el-table :data="tableData" border :max-height="650">
                                <!-- 机构成员 -->
                                <template v-if="nowPageType === 'member'">
                                    <el-table-column width="90" prop="id" label="人物ID" />
                                    <el-table-column prop="name" label="姓名" />
                                    <el-table-column width="50" prop="sex" label="性别">
                                        <template #default="scope">
                                            {{ scope.row.sex === 1 ? '男' : scope.row.sex === 2 ? '女' : '-' }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="country" label="国家" />
                                    <el-table-column prop="province" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province }} {{ scope.row.city }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="100" prop="type_arr" label="人物类型" />
                                    <el-table-column width="80" prop="post" label="职位" />
                                    <el-table-column width="120" prop="status" label="状态">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 5">待审核</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 投资事件 -->
                                <template v-else-if="nowPageType === 'event-investment'">
                                    <el-table-column prop="id" label="序号" width="90" />
                                    <el-table-column prop="company_id" label="主体ID" width="90" />
                                    <el-table-column prop="company_name" label="主体名称" />
                                    <el-table-column prop="round" label="投资轮次" />
                                    <el-table-column prop="amount" label="投资金额">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate ? scope.row.amount : scope.row.no_amount }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="finace_time" label="时间" />
                                    <el-table-column prop="status" label="状态" width="90">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 退出事件 -->
                                <template v-else-if="nowPageType === 'event-exit'">
                                    <el-table-column prop="id" label="序号" />
                                    <el-table-column prop="company_id" label="退出事件ID" width="90" />
                                    <el-table-column prop="company_name" label="主体名称" />
                                    <el-table-column prop="withdraw_type" label="退出方式">
                                        <template #default="scope">
                                            <span v-if="scope.row.withdraw_type === 1">ipo</span>
                                            <span v-else-if="scope.row.withdraw_type === 2">兼并收购</span>
                                            <span v-else-if="scope.row.withdraw_type === 3">股权回购</span>
                                            <span v-else-if="scope.row.withdraw_type === 4">股权转让</span>
                                            <span v-else-if="scope.row.withdraw_type === 5">新三板交易</span>
                                            <span v-else-if="scope.row.withdraw_type === 6">清算</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="amount" label="退出金额">
                                        <template #default="scope">
                                            {{ scope.row.is_accurate ? scope.row.amount : scope.row.no_amount }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="quit_time" label="退出时间" />
                                    <el-table-column prop="target" label="投资方">
                                        <template #default="scope">
                                            <span class="mg-r-12" v-for="item in scope.row.investor_arr" :key="item">{{ item }}</span>
                                            <span class="mg-r-12" v-for="item in scope.row.company_arr" :key="item">{{ item }}</span>
                                            <!-- {{ scope.row.investor_arr }} {{ scope.row.company_arr }} -->
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="ret_multiple" label="回报倍数" />
                                    <el-table-column prop="status" label="状态" width="90">
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">待完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">已完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 合作机构 -->
                                <template v-else-if="nowPageType === 'cooperation-agency'">
                                    <el-table-column prop="id" label="投资机构ID" width="110" />
                                    <el-table-column prop="name" label="名称" />
                                    <el-table-column prop="country_name" label="国家" />
                                    <el-table-column prop="province_name" label="地区">
                                        <template #default="scope">
                                            {{ scope.row.province_name }} {{ scope.row.city_name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="phaseArr" label="类型" />
                                    <el-table-column prop="establish_time" label="成立时间" />
                                    <el-table-column prop="status" label="状态" width="90" >
                                        <template #default="scope">
                                            <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                            <span class="status-text success" v-else-if="scope.row.status === 2">已完善</span>
                                            <span class="status-text danger" v-else-if="scope.row.status === 3">待完善</span>
                                            <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                            <span class="status-text info" v-else>待审核</span>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 基金管理 -->
                                <template v-else-if="nowPageType === 'fund-manage'">
                                    <el-table-column prop="filing_time" label="备案时间" />
                                    <el-table-column prop="fund_name" label="名称" />
                                    <el-table-column prop="register_capital" label="注册资本" />
                                    <el-table-column prop="copartner" label="执行事务合伙人" />
                                    <el-table-column prop="establish_time" label="成立时间" />
                                    <!-- <el-table-column prop="status" label="状态" >
                                        <template #default="scope">
                                            <template v-if="scope.row.status === 1">
                                                <span class="status-text primary">已发布</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 2">
                                                <span class="status-text success">已完善</span>
                                            </template>
                                            <template v-else-if="scope.row.status === 3">
                                                <span class="status-text danger">待完善</span>
                                            </template>
                                        </template>
                                    </el-table-column> -->
                                    <el-table-column label="操作" width="90">
                                        <template #default="scope">
                                            <el-link type="primary" @click="toFundDetail(scope.row.id)">详情</el-link>
                                        </template>
                                    </el-table-column>
                                </template>
                                <!-- 相关报道 -->
                                <template v-else-if="nowPageType === 'reports'">
                                    <el-table-column type="index" width="50" label="序号" />
                                    <el-table-column prop="name" label="报道标题" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span  @click="goToReport(scope.row.id)" style="cursor: pointer;">{{scope.row.name}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="author" label="作者" width="170" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span >{{scope.row.author}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="source" label="来源" width="170" :show-overflow-tooltip="true">
                                        <template #default="scope">
                                        <span >{{scope.row.source}}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="link" label="链接" width="80">
                                        <template #default="scope">
                                        <el-link :href="scope.row.link" type="primary" target="_blank">查看链接</el-link>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="report_time" label="报道时间" width="110" />
                                    <el-table-column prop="status" label="状态" width="70">
                                        <template #default="scope">
                                        <span class="status-text primary" v-if="scope.row.status === 1">已发布</span>
                                        <span class="status-text danger" v-else-if="scope.row.status === 2">待完善</span>
                                        <span class="status-text success" v-else-if="scope.row.status === 3">已完善</span>
                                        <span class="status-text info" v-else-if="scope.row.status === 4">已撤回</span>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                            <div class="table-pagination-row mg-t-24">
                                <el-pagination
                                    background
                                    :currentPage="paginationData.current"
                                    :page-sizes="paginationData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="paginationData.total"
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                >
                                </el-pagination>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { computed, ref, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Plus } from '@element-plus/icons-vue'
import { Ajax } from '../../../../utils'
export default {
    components: {
        Plus,
        Header,
        BreadCrumb
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const breadListsRef = ref([
            {
                name: '投资机构',
                path: {
                    name: 'OrganizationInvestment'
                }
            },
            {
                name: '投资机构详情',
                path: {
                    name: 'OrganizationInvestmentDetail',
                    query: {
                        id: route.query.id
                    }
                }
            },
            {
                name: route.query.pageName
            }
        ])
        const nowPageType = computed(() => {
            /**
             * member  机构成员
             * event-investment 投资事件
             * event-exit  退出事件
             * cooperation-agency    合作机构
             * fund-manage   基金管理
             * reports  相关报道
             */
            return route.query.type
        })
        const nowPageTitle = computed(() => {
            return route.query.pageName
        });

        const tableDataRef = ref([])

        const paginationDataRef = ref({
            current: 1,
            pageSize: [10, 20, 50],
            page_size: 10,
            total: 0
        })
        const handleSizeChange = e => {
            console.log(e)
            paginationDataRef.value.page_size = e;
            getList()
        }
        const handleCurrentChange = e => {
            paginationDataRef.value.current = e;
            getList()
        }

        const getList = () => {
            let url = '';
            switch (nowPageType.value) {
                case 'reports':
                    url = '/admin/organization/report'
                    break;
                case 'fund-manage':
                    url = '/admin/organization_funds'
                    break;
                case 'event-investment':
                    url = `/admin/invest/orginvest/${route.query.id}`;
                    break;
                case 'event-exit':
                    url = `/admin/quit/orginvest/${route.query.id}`
                    break;
                case 'cooperation-agency':
                    url = `/admin/organization/cooperation/${route.query.id}`;
                    break;
                default:
                    break;
            }
            if(['event-investment', 'event-exit', 'cooperation-agency','reports'].includes(nowPageType.value)) {
                if(nowPageType.value === 'cooperation-agency') {
                    Ajax.POST({
                    url: `/admin/organization/cooperation/${route.query.id}`,
                        data: {
                            
                            page: paginationDataRef.value.current,
                            page_size: paginationDataRef.value.page_size
                        }
                    }).then(cooper => {
                        if(cooper.data.code === 200) {
                            tableDataRef.value = cooper.data.data;
                        }
                    }) 
                    return ;
                }else if(nowPageType.value === 'reports'){
                    Ajax.POST({
                        url,
                        data: {
                            id:route.query.id,
                            page: paginationDataRef.value.current,
                            page_size: paginationDataRef.value.page_size
                        }
                    }).then(res => {
                        if(res.data.code === 200) {
                            tableDataRef.value = res.data.data.list;
                            paginationDataRef.value.total = res.data.data.page_info.total;
                        }
                    })
                }else {
                    Ajax.POST({
                        url,
                        data: {
                            page: paginationDataRef.value.current,
                            page_size: paginationDataRef.value.page_size
                        }
                    }).then(res => {
                        if(res.data.code === 200) {
                            tableDataRef.value = res.data.data.data;
                            paginationDataRef.value.total = res.data.data.total;
                        }
                    })
                }
            }else {
                Ajax.GET({
                    url,
                    params: {
                        org_id: route.query.id
                    }
                }).then(res => {
                    if(res.data.code === 200) {
                        tableDataRef.value = res.data.data.list;
                        paginationDataRef.value.total = res.data.data.page_info.total
                    }
                })
            }
        }
        const toFundDetail = (fund_id) => {
            router.push({
                name: 'OrganizationInvestmentFundDetail',
                query: {
                    fund_id,
                    id: route.query.id
                }
            })
        }
        onBeforeMount(() => {
            getList();
        })

        const createFund = () => {
            router.push({
                name: 'OrganizationInvestmentCreateFund',
                query: {
                    id: route.query.id
                }
            }) 
        }
        //跳转报道页面
        const goToReport=(id)=>{
            router.push({
                name: "ReportDetail",
                query: { id: id },
            });
        }
       const showString = (string, len) => {
            return string.length > len ? string.substring(0, len) + "..." : string;
        };
        return {
            breadLists: breadListsRef,
            tableData: tableDataRef,
            paginationData: paginationDataRef,
            handleSizeChange,
            handleCurrentChange,
            nowPageType,
            nowPageTitle,
            createFund,
            toFundDetail,
            goToReport,
            showString,
        }
    }
}
</script>

<style lang="less" scoped>
    .person-detail-container {
        height: 100%;
        background-color: #fff;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .status-text {
            font-size: 14px;
            &.primary {
                color: #409eff;
            }
            &.success {
                color: #67c23a;
            }
            &.danger {
                color: #f56c6c;
            }
            &.info {
                color: #909399;
            }
        }
        .table-pagination-row {
            display: flex;
            justify-content: flex-end;
        }
    }
</style>