import { ElMessageBox } from 'element-plus'
const confirm = ({msg = '', title = '', type = 'warning', confirm, cancel}) => {
    ElMessageBox.confirm(msg, title, {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type
    }).then(() => {
        typeof confirm === 'function' && confirm()
    }).catch(() => {
        typeof cancel === 'function' && cancel()
    })
}
const prompt = ({msg = '', title = '', inputPlaceholder = '', confirm, cancel}) => {
    ElMessageBox.prompt(msg, title, {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputPlaceholder,
    }).then(res => {
        typeof confirm === 'function' && confirm(res)
    }).catch(() => {
        typeof cancel === 'function' && cancel()
    })
}
export {
    confirm,
    prompt
}