<template>
  <div class="shade_conainer">
    <div class="shade_box">
      <div class="preview_box">
        <el-button class="close_shade" type="warning" @click="closeShow"
          >关闭预览</el-button
        >
        <div class="preview_con">
          <div class="info_body">
            <div class="info-banner">
              <img class="banner" :src="showData.banner" alt="" />
              <img
                class="shoucang"
                src="../../assets/images/shoucang.png"
                alt=""
              />
              <img class="share" src="../../assets/images/share.png" alt="" />
            </div>
            <div class="info_header">
              <div class="info_title">
                {{ showData.title }}
              </div>
              <div class="author_time">
                <div class="info_author">
                  {{ showData.description }}
                </div>
              </div>
            </div>
            <div class="tag_list">
              <div class="tag" v-for="tag in showData.label_list" :key="tag.id">
                <img src="../../assets/images/tag.png" alt="" />
                <span>{{ tag.name }}</span>
              </div>
            </div>

            <div class="dashed_line"></div>
            <div class="info_content">
              <div v-html="formatRichText(showData.content)"></div>
            </div>
            <div class="icon_con_end" v-if="showData.description != ''">
              <div class="icon_img"></div>
            </div>
          </div>
          <!-- 相关专辑 -->
          <div
            class="card_container"
            v-if="showData.ablums && showData.ablums.length > 0"
          >
            <div class="card_title">
              <div class="card_label">相关专辑</div>
            </div>
            <div class="card_body">
              <div class="professor_container">
                <div
                  class="professor_box"
                  v-for="item in showData.ablums"
                  :key="item.id"
                >
                  <div class="professor_message">
                    <div class="professor_name">{{ item.title }}</div>
                    <div class="professor_introduce">
                      <span class="time">发布于{{moment(item.publish_time).format('YYYY/MM/DD')}}</span>
                      <img src="../../assets/images/shoucang.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card_footer">
              <div class="footer_icon">
                <img src="../../assets/images/godown.png" alt="" />
              </div>
            </div>
          </div>

          <!-- 相关公司 -->
          <div
            class="card_container"
            v-if="showData.companys && showData.companys.length > 0"
          >
            <div class="card_title">
              <div class="card_label">相关公司</div>
            </div>
            <div class="card_body">
              <div
                class="company-item"
                v-for="item in showData.companys"
                :key="item.id"
              >
                <img
                  src="../../assets/images/company2.png"
                  class="company-img"
                  alt=""
                />
                <div class="company-item-right">
                  <div class="company-title">{{ item.name }}</div>
                </div>
              </div>
            </div>
            <div class="card_footer">
              <div class="footer_icon">
                <img src="../../assets/images/godown.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { toRefs, defineProps, defineEmits } from "vue";
import moment from 'moment'
const props = defineProps({
  showData: {
    type: Object,
  },
  type: {
    type: String,
  },
});
console.log(props.showData);
const { showData } = toRefs(props);
const emit = defineEmits(["closeShow"]);
const closeShow = () => {
  emit("closeShow");
};
const formatRichText = (html) => {
  let newContent = html.replace(/<img[^>]*>/gi, function (match, capture) {
    match = match.replace(/style="[^"]+"/gi, "").replace(/style='[^']+'/gi, "");
    match = match.replace(/width="[^"]+"/gi, "").replace(/width='[^']+'/gi, "");
    match = match
      .replace(/height="[^"]+"/gi, "")
      .replace(/height='[^']+'/gi, "");
    return match;
  });
  newContent = newContent.replace(/<br[^>]*\/>/gi, "");
  newContent = newContent.replace(
    /\<img/gi,
    '<img style="max-width:100%;height:auto;display:block;margin-top:0;margin-bottom:0;"'
  );
  newContent = newContent.replace(
    /\<table/gi,
    '<table style="border:10px solid #000000"'
  );
  return newContent;
};
</script>

<style lang="less" scoped>
.shade_conainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  .shade_box {
    background: #fff;
    position: absolute;
    border-radius: 50px;
    left: 46%;
    top: 30px;
    .image_class {
      width: 100%;
      height: 100%;
    }
  }
  .image_class {
    width: 100%;
    height: 100%;
  }
}
.preview_box {
  background-image: url("../../assets/images/phone.png");
  background-repeat: no-repeat;
  width: 364px;
  height: 727px;
  overflow: hidden;
  .close_shade {
    position: absolute;
    right: -90px;
    top: -10px;
  }
  .preview_con {
    width: 302px;
    height: 641px;
    position: relative;
    top: 50px;
    left: 30px;
    overflow-y: auto;
    background: #f7f8fa;
    .info_body {
      background: #fff;
      padding-bottom: 20px;
      .info-banner {
        height: 200px;
        position: relative;
        margin-bottom: 25px;
        .banner {
          height: 200px;
        }
        .shoucang {
          position: absolute;
          right: 50px;
          bottom: 12px;
          width: 18px;
          height: 18px;
        }
        .share {
          position: absolute;
          right: 16px;
          bottom: 12px;
          width: 18px;
          height: 18px;
        }
      }
      .info_header {
        padding: 0 16px;
        .info_title {
          font-size: 20px;
          font-family: FZTYSJW--GB1-0, FZTYSJW--GB1;
          font-weight: normal;
          color: #444444;
          line-height: 28px;
          white-space: none;
          word-wrap: break-word;
          word-break: break-all;
        }
        .author_time {
          // display: flex;
          margin-top: 25px;

          .info_author {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            margin-right: 20px;
            word-wrap: break-word;
            word-break: break-all;
          }
          // .publish_time {
          //   font-size: 14px;
          //   font-family: PingFangSC-Regular, PingFang SC;
          //   font-weight: 400;
          //   color: #999999;
          //   line-height: 20px;
          // }
        }
      }
      .tag_list {
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;
        .tag {
          margin-top: 19px;
          padding: 3px 5px;
          display: flex;
          align-items: center;
          border: 1px solid #e3291c;
          border-radius: 1px;
          margin-right: 10px;
          img {
            width: 12px;
            height: 12px;
          }
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #e3291c;
            line-height: 11px;
            margin-left: 6px;
          }
        }
      }
      .dashed_line {
        border-bottom: 1px dashed #d6d6d6;
        padding-bottom: 29px;
      }
      .icon_con {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .icon_img {
          width: 172px;
          height: 24px;
          background-image: url("");
          background-repeat: no-repeat;
        }
      }
      .icon_con_end {
        display: flex;
        align-items: center;
        margin-top: 10px;
        justify-content: flex-end;
        .icon_img {
          width: 172px;
          height: 24px;
          background-image: url("");
          background-repeat: no-repeat;
        }
      }
      .info_introduce {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 28px;
        margin-top: 20px;
      }
      .info_content {
        font-size: 17px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 28px;
      }
      .info_handle {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        .handle_btn {
          width: 70px;
          height: 25px;
          border-radius: 15px;
          border: 1px solid #999999;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 25px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .card_container {
      margin-top: 10px;
      min-height: 150px;
      background-color: #fff;
      padding-top: 22px;
      margin-bottom: 10px;
      .card_title {
        text-align: center;
        .card_label {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #363d50;
          line-height: 25px;
          letter-spacing: 3px;
        }
        .card_label::after {
          position: relative;
          content: "";
          display: block;
          width: 81px;
          height: 10px;
          background: #f46c6c;
          opacity: 0.25;
          border-radius: 2px;
          top: -11px;
          left: 36%;
        }
      }
      .card_body {
        padding: 0 10px;
      }
      .literature_container {
        background: #ffffff;
        box-shadow: 0px 4px 24px 0px rgba(35, 92, 246, 0.1);
        border-radius: 4px;
        padding-top: 22px;
        margin-bottom: 15px;
        .literature_title {
          padding: 0 15px;
          position: relative;
          .literature_icon {
            width: 19px;
            height: 15px;
            position: absolute;
            left: 15px;
            top: 5px;
          }
          .literature_label {
            font-size: 17px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #222222;
            line-height: 25px;
            text-indent: 25px;
            text-align: left;
          }
        }
        .literature_author {
          margin-top: 16px;
          display: flex;
          flex-direction: column;
          text-align: left;
          padding: 0 15px;
        }
        .literature_author_up {
          font-size: 12px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #666666;
          line-height: 16px;
          white-space: none;
          word-wrap: break-word;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .literature_author_down {
          font-size: 12px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #666666;
          line-height: 16px;
          margin-top: 10px;
        }
        .literature_content {
          font-size: 15px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 20px;
          margin-top: 9px;
          margin-bottom: 10px;
          padding: 0 15px;
          text-align: justify;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 7;
          line-clamp: 7;
          -webkit-box-orient: vertical;
        }
      }
      .literature_handle {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ddd;
        background: #fff;
        padding: 10px 15px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .card_footer {
      padding: 9px 0;
      background: #fff;
      .footer_icon {
        text-align: center;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .professor_container {
      padding: 0 32rpx;
      .professor_box {
        height: 165px;
        background: url("../../assets/images/album_bg.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        margin-bottom: 24px;
        box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.25);
        .professor_message {
          padding: 30px 27px 11px;
          
          .professor_name {
            font-size: 19px;
            font-family: FZTYSJW--GB1-0, FZTYSJW--GB1;
            font-weight: normal;
            color: #333333;
            line-height: 28px;
          }
          .professor_introduce {
            width: 100%;
            padding: 0 22px;
            
            position: absolute;
            left: 0;
            bottom: 11px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .time{
              font-size: 13px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
            }
            img{
              width: 17px;
              height: 17px;
            }
          }
        }
      }
      .professor_box:nth-last-child(1){
        margin-bottom: 0;
      }
    }
    .company-item {
      display: -webkit-box;
      border-bottom: 1px solid #e8e8e8;
      padding: 20px 0;
      .company-img {
        width: 60px;
        height: 60px;
        background: #cccccc;
        border-radius: 8rpx;
        border: 1rpx solid #e6e6e6;
      }
      .company-item-right {
        margin-left: 15px;
        display: flex;
        align-items: center;
        height: 60px;
        .company-title {
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          max-width: 210px;
          word-break: break-all;
        }
        .company-text {
          max-width: 250px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          margin-top: 14px;
          text-overflow: -o-ellipsis-lastline;
          white-space: none;
          word-wrap: break-word;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .preview_con::-webkit-scrollbar {
    display: none;
  }
}
</style>
