<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="edit-investment-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">{{ nowPageType === 'edit-basic' ? '投资机构基本信息' : '补充信息' }}</span>
                            </div>
                        </template>
                        <div class="form-container">
                            <template v-if="nowPageType === 'edit-basic'">
                                <el-form
                                    ref="basicFormRef"
                                    :model="investmentBaseInfo"
                                    :rules="investmentBaseInfoRule"
                                    label-width="120px"
                                    class="mg-t-24"
                                >
                                    <el-form-item label="名称" prop="name" style="width:50%">
                                        <el-input v-model="investmentBaseInfo.name" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="成立时间" prop="date">
                                        <el-date-picker value-format="YYYY-MM-DD" v-model="investmentBaseInfo.establish_time" type="date" placeholder="选择日期" />
                                    </el-form-item>
                                    <el-descriptions :column="2" style="width:50%">
                                        <el-descriptions-item label-align="right">
                                            <el-form-item label="国家" prop="province">
                                                <el-select class="select w-100" placeholder="请选择"
                                                    :popper-append-to-body="false" v-model="investmentBaseInfo.country"
                                                    @change="coutryChange"
                                                    filterable
                                                    remote
                                                    :reserve-keyword="false"
                                                    :remote-method="countryRemoteMethod"
                                                >
                                                    <el-option
                                                        v-for="item in countrys"
                                                        :key="item.id"
                                                        :label="item.name"
                                                        :value="item.id"
                                                    >
                                                    {{item.name}}
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="left">
                                             <el-form-item label="地区" prop="province">
                                                <el-cascader
                                                    v-model="provinceVal"
                                                    :options="provincesArr"
                                                    @change="provinceChange"
                                                    :props="{
                                                        value: 'id',
                                                        label: 'name',
                                                        children: 'child'
                                                    }"
                                                ></el-cascader>
                                            </el-form-item>
                                        </el-descriptions-item>
                                    </el-descriptions>

                                    
                                   
                                    
                                    <el-form-item label="简介" style="width:50%">
                                        <el-input v-model="investmentBaseInfo.introduction"   show-word-limit type="text" placeholder="一句话简介50字以内"></el-input>
                                    </el-form-item>
                                    <el-form-item label="介绍" prop="profile" style="width:50%"> 
                                        <el-input v-model="investmentBaseInfo.profile" :maxlength="500" :autosize="{ minRows: 2, maxRows: 14 }" show-word-limit type="textarea" placeholder="请输入"></el-input>
                                    </el-form-item>
                                    <el-form-item label="类型" prop="phase">
                                        <el-select
                                            v-model="investmentBaseInfo.phase"
                                            multiple
                                            collapse-tags
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="phase in companyPhase"
                                                :key="phase.name"
                                                :label="phase.name" 
                                                :value="phase.id" 
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                                <el-form
                                    style="width: 50%;"
                                    ref="contentFormRef"
                                    :model="investmentContactInfo"
                                    label-width="120px"
                                    class="mg-t-24"
                                >
                                    <el-form-item label="照片" prop="avator">
                                        <el-upload
                                            class="avatar-uploader"
                                            :class="{
                                                border: !investmentContactInfo.image
                                            }"
                                            :show-file-list="false"
                                            action="#"
                                            :http-request="uploadImage"
                                            accept="image/jpg, image/jpeg, image/png"
                                            :before-upload="beforeUpload"
                                        >
                                            <div class="avatar-img-box" v-if="investmentContactInfo.image">
                                                <img :src="investmentContactInfo.image" class="avatar" />
                                            </div>
                                            <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
                                        </el-upload>
                                    </el-form-item>
                                    <el-descriptions :column="2">
                                        <el-descriptions-item label-align="right">
                                            <el-form-item label="官网" prop="unit_type">
                                                <el-input placeholder="请输入" v-model="investmentContactInfo.link" />
                                            </el-form-item>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="left">
                                            <el-form-item label="电话" prop="telephone">
                                                <el-input placeholder="请输入" v-model="investmentContactInfo.telephone"></el-input>
                                            </el-form-item>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="right">
                                            <el-form-item label="邮箱" prop="email">
                                                <el-input placeholder="请输入" v-model="investmentContactInfo.email"></el-input>
                                            </el-form-item>
                                        </el-descriptions-item>
                                        <el-descriptions-item label-align="left">
                                            <el-form-item label="手机" prop="mobile">
                                                <el-input placeholder="请输入" v-model="investmentContactInfo.mobile"></el-input>
                                            </el-form-item>
                                        </el-descriptions-item>
                                    </el-descriptions>

                                </el-form>
                            </template>
                            <!-- <template v-else>
                                <el-form
                                    style="width: 50%;"
                                    ref="contentFormRef"
                                    :model="investmentContactInfo"
                                    label-width="120px"
                                    class="mg-t-24"
                                >
                                    <el-form-item label="照片" prop="avator">
                                        <el-upload
                                            class="avatar-uploader"
                                            :class="{
                                                border: !investmentContactInfo.image
                                            }"
                                            :show-file-list="false"
                                            action="#"
                                            :http-request="uploadImage"
                                            accept="image/jpg, image/jpeg, image/png"
                                            :before-upload="beforeUpload"
                                        >
                                            <div class="avatar-img-box" v-if="investmentContactInfo.image">
                                                <img :src="investmentContactInfo.image" class="avatar" />
                                            </div>
                                            <el-icon v-else class="avatar-uploader-icon"><plus /></el-icon>
                                        </el-upload>
                                    </el-form-item>
                                    <el-form-item label="官网" prop="unit_type">
                                        <el-input placeholder="请输入" v-model="investmentContactInfo.link" />
                                    </el-form-item>
                                    <el-form-item label="联系方式">
                                    </el-form-item>
                                    <el-form-item label="电话" prop="telephone">
                                        <el-input placeholder="请输入" v-model="investmentContactInfo.telephone"></el-input>
                                    </el-form-item>
                                    <el-form-item label="邮箱" prop="email">
                                        <el-input placeholder="请输入" v-model="investmentContactInfo.email"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机" prop="mobile">
                                        <el-input placeholder="请输入" v-model="investmentContactInfo.mobile"></el-input>
                                    </el-form-item>
                                </el-form>
                            </template> -->
                        </div>
                    </el-card>
                    <div class="bottom-submit-box pd-16 mg-t-12">
                        <el-row justify="end">
                            <el-col :span="4">
                                <el-button size="small" plain @click="cancelSubmit">取消</el-button>
                                <el-button size="small" type="primary" @click="submitForm">保存</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { ref, getCurrentInstance, computed, onBeforeMount, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Plus } from '@element-plus/icons-vue'
import { Ajax, MessageTips } from '../../../../utils'
export default {
    components: {
        Plus,
        Header,
        BreadCrumb
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const breadListsRef = ref([
            {
                name: '投资机构',
                path: {
                    name: 'OrganizationInvestment'
                }
            },
            {
                name: '投资机构详情',
                path: {
                    name: 'OrganizationInvestmentDetail',
                    query: {
                        id: route.query.id
                    }
                }
            },
            {
                name: route.query.pageName
            }
        ])
        // 页面类型
        const nowPageType = computed(() => {
            return route.query.type
        })
        const { proxy } = getCurrentInstance();
        
        const investmentBaseInfoRef = ref({})
        const countrysRef = ref([]);
        const provincesArrRef = ref([]);
        const provinceValRef = ref(null);
        let regionArr = [];
        const companyPhaseRef = ref([
            {
                id: 1,
                name: '天使机构'
            },
            {
                id: 2,
                name: 'VC'
            },
            {
                id: 3,
                name: 'PE'
            },
            {
                id: 4,
                name: 'CVC'
            },
            {
                id: 5,
                name: 'FA'
            },
            {
                id: 6,
                name: 'FOF'
            },
            {
                id: 7,
                name: '金融机构'
            },
            {
                id: 8,
                name: '国资背景'
            },
            {
                id: 9,
                name: '家族办公室'
            },
            {
                id: 10,
                name: '对冲基金'
            },
            {
                id: 11,
                name: '天使个人'
            },
            {
                id: 12,
                name: '孵化器'
            },
            {
                id: 13,
                name: '其他'
            }
        ])
		onBeforeMount(async () => {
			// 国家地区信息
            const adminRegions = localStorage.getItem('adminRegions');
            if(adminRegions) {
                const regs = JSON.parse(adminRegions);
                regionArr = regs;
                countrysRef.value = regionArr.map(({id, name}) => ({
                    id,
                    name
                }))
            }else {
                const res = await Ajax.GET({
                    url: '/admin/region'
                })
                const resData = res.data;
                if(resData.code === 200) {
                    regionArr = resData.data;
                    countrysRef.value = regionArr.map(({id, name}) => ({
                        id,
                        name
                    }))
                }
            }
            
            // companyPhaseRef.value = res.data.data;
            Ajax.GET({
                url: `/admin/organization/${route.query.id}`
            }).then(res => {
                if(res.data.code === 200) {
                    const investRes = res.data.data;
                    // 基本信息
                    investmentBaseInfoRef.value.name = investRes.name;
                    investmentBaseInfoRef.value.country = investRes.country || null;
                    investmentBaseInfoRef.value.province = investRes.province || null;
                    investmentBaseInfoRef.value.city = investRes.city || null;
                    investmentBaseInfoRef.value.profile = investRes.profile;
                    investmentBaseInfoRef.value.introduction=investRes.introduction;
                    investmentBaseInfoRef.value.establish_time = investRes.establish_time;
                    if(investRes.phase) {
                        investmentBaseInfoRef.value.phase = investRes.phase.split(',');
                        investmentBaseInfoRef.value.phase = investmentBaseInfoRef.value.phase.map(Number)
                    }else {
                        investmentBaseInfoRef.value.phase = []
                    }
                    const findObj = regionArr.find(item => item.id === investRes.country);
                    if(findObj) {
                        provincesArrRef.value = findObj.child;
                    }
                    if(investRes.province && investRes.city) {
                        provinceValRef.value = [investRes.province, investRes.city]
                    }else if(investRes.province) {
                        provinceValRef.value = [investRes.province]
                    }
                    // 补充信息
                    investmentContactInfoRef.value.image = investRes.image;
                    investmentContactInfoRef.value.link = investRes.link;
                    investmentContactInfoRef.value.telephone = investRes.telephone;
                    investmentContactInfoRef.value.email = investRes.email;
                    investmentContactInfoRef.value.mobile = investRes.mobile;
                }
            })            
		})

        // 国家选择
        const coutryChange = val => {
            const findObj = regionArr.find(item => item.id === val);
            investmentBaseInfoRef.value.country = val;
            investmentBaseInfoRef.value.province = null;
            investmentBaseInfoRef.value.city = null;
            provincesArrRef.value = findObj.child;
        }
        const countryRemoteMethod = query =>{
            if (query) {
                setTimeout(() => {
                countrysRef.value = regionArr.filter((item) => {
                    return item.name.includes(query)
                })
                }, 200)
            } else {
                countrysRef.value = []
            }
        }
        // 地区选择
        const provinceChange = val => {
            investmentBaseInfoRef.value.province = val[0]
            investmentBaseInfoRef.value.city = val[1]
        }
        const handleChange = (e) => {
            console.log(e)
        }
        const investmentBaseInfoRule = {
            name: [
                {
                    required: true,
                    message: '请输入名称',
                    trigger: 'blur'
                }
            ],
            profile: [
                {
                    required: true,
                    message: '请输入简介',
                    trigger: 'blur'
                }
            ]
        }

        // 修改补充信息
        const avatarUploadSuccess = (res, file) => {
            console.log(res, file)
        } 
        const investmentContactInfoRef = ref({
            images: '',
            link: '',
            telephone: '',
            email: '',
            mobile: ''
        })
        // 限制文件大小
        const beforeUpload = file => {
            console.log(file)
            const isL1M = file.size / 1000 / 1000 <= 1;
            if(!isL1M) {
                MessageTips.error('文件上传不能大于1M')
                return false;
            }
            return true;
        }
        // 上传照片
        const uploadImage = data => {
            const file = data.file;
            let formData = new FormData();
            formData.append("image", file);
            formData.append("restrict", '1000');
            Ajax.POST({
                url: '/admin/upload/image',
                contentType: 'multipart/form-data',
                data: formData
            }).then(res => {
                if(res.data.code === 200) {
                    investmentContactInfoRef.value.image = res.data.data.url;
                }else {
                    MessageTips.error(res.data.msg)
                }
            })
        }
        // 底部提交
        const cancelSubmit = () => {
            if(nowPageType.value === 'edit-basic') {
                proxy.$refs['basicFormRef'].resetFields()
            }else {
                proxy.$refs['contentFormRef'].resetFields()
            }
            router.back();
        }
        const submitForm = () => {
            console.log('submit==')
            if(nowPageType.value === 'edit-basic') {
                proxy.$refs['basicFormRef'].validate(valid => {
                    if(valid) {
                        investmentBaseInfoRef.value.phase = investmentBaseInfoRef.value.phase.join(', ')
                        Ajax.PUT({
                            url: `/admin/organization/${route.query.id}`,
                            // data: {investmentBaseInfoRef.value, ...investmentContactInfoRef.value}
                            data: {
                                    ...investmentBaseInfoRef.value,
                                    ...investmentContactInfoRef.value
                                }
                        }).then(res => {
                            if(res.data.code === 200) {
                                MessageTips.success('修改成功')
                                router.back()
                            }else {
                                MessageTips.error(res.data.msg)
                            }
                        })
                    }else {
                        console.log('error')
                        return ;
                    }
                })
            }else {
                Ajax.POST({
                    url: '/admin/organization/add_extend',
                    data: {
                        organ_id: route.query.id,
                        ...investmentContactInfoRef.value
                    }
                }).then(res => {
                    if(res.data.code === 200) {
                        MessageTips.success('编辑成功')
                        router.back();
                    }else {
                        MessageTips.error(res.data.msg)
                    }
                })
            }
        }
        return {
            breadLists: breadListsRef,
            investmentBaseInfo: investmentBaseInfoRef,
            investmentBaseInfoRule,
            countrys: countrysRef,
            provincesArr: provincesArrRef,
            provinceVal: provinceValRef,
            companyPhase: companyPhaseRef,
            coutryChange,
            provinceChange,
            handleChange,
            cancelSubmit,
            submitForm,
            nowPageType,
            avatarUploadSuccess,
            investmentContactInfo: investmentContactInfoRef,
            beforeUpload,
            uploadImage,
            countryRemoteMethod
        }
    }
};
</script>

<style lang="less" scoped>
    .edit-investment-container {
        height: 100%;
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        .avatar-uploader {
            width: 108px;
            height: 108px;
            &.border {
                border: 1px dashed #d9d9d9;
            }
            ::v-deep(.el-upload) {
                width: 108px;
                height: 108px;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
            }
            .avatar-img-box {
                width: 100%;
                height: 100%;
                .avatar {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 108px;
                height: 108px;
                line-height: 108px;
                text-align: center;
            }
        }
    }
    // .form-container{
    //     width: 50%;
    // }
</style>