<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="album-detail-container">
          <el-card>
            <el-tabs
              v-model="activeName"
              type="card"
              @tab-click="handleTabClick"
            >
              <el-tab-pane label="基本信息" name="DetailInfo">
                <div class="basic-container">
                  <el-card class="mg-b-16">
                    <el-form
                      ref="formRef"
                      :model="DetailInfo"
                      label-width="120px"
                      class="form-box mg-t-24"
                    >
                      <el-form-item label="名称" prop="name">
                        {{ DetailInfo.title }}
                      </el-form-item>
                      <el-form-item label="数据类别" prop="type">
                        {{ DetailInfo.type === 1 ? "品牌" : "人物" }}
                      </el-form-item>
                      <el-form-item label="简介" prop="desc">
                        {{ DetailInfo.summary }}
                      </el-form-item>
                      <el-form-item label="封面图">
                        <img :src="DetailInfo.banner" alt="" class="banner" />
                      </el-form-item>
                      <el-form-item label="行业领域">
                        {{ DetailInfo.domain }}
                      </el-form-item>
                      <el-form-item label="发布时间">
                        {{ pushDate(DetailInfo.push_time) }}
                      </el-form-item>
                      <el-form-item label="首页推荐">
                        <el-radio-group v-model="DetailInfo.recommend_type">
                          <el-radio :label="2">是</el-radio>
                          <el-radio :label="1">否</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item label="推荐理由:" prop="reason">
                        {{ DetailInfo.reason }}
                      </el-form-item>
                    </el-form>
                  </el-card>
                  <div class="submit-box pd-16 mg-t-12">
                    <el-row justify="end">
                      <el-col :span="3">
                        <el-button
                          size="small"
                          type="primary"
                          @click="handleEdit"
                          >编辑</el-button
                        >
                      </el-col>
                    </el-row>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="专辑内容" name="albumContent">
                <div class="content-container">
                  <el-card
                    class="mg-b-16 content-card"
                    v-if="DetailInfo.type === 1"
                  >
                    <div class="card-title">
                      <span>品牌列表</span>
                      <el-button type="primary" @click="handleAddCompany"
                        >添加品牌</el-button
                      >
                    </div>
                    <el-table :data="ListData" border :max-height="600">
                      <el-table-column
                        width="50"
                        type="index"
                        :index="
                          (index) => {
                            return index + 1;
                          }
                        "
                        label="序号"
                      />
                      <el-table-column prop="id" label="品牌ID" />
                      <el-table-column prop="brand_name" label="品牌名称" />
                      <el-table-column prop="country_name" label="国家" />
                      <el-table-column
                        prop="province_name"
                        label="地区"
                        :formatter="showAdress"
                      />
                      <el-table-column prop="id" label="行业领域">
                        <template #default="scope">
                          <span
                            v-for="(item, i) in scope.row.trade_arr"
                            :key="i"
                            >{{ item }},</span
                          >
                        </template>
                      </el-table-column>
                      <el-table-column prop="id" label="操作">
                        <template #default="scope">
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleDel(scope.row.id)"
                            >删除</el-link
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="table-pagination-row mg-t-24">
                      <el-pagination
                        v-if="paginationData.total > ListData.length"
                        :current-page="currentPage"
                        :page-size="paginationData.size"
                        :total="paginationData.total"
                        @current-change="handleCurrentChange"
                        @pagination="getTableData"
                      />
                    </div>
                  </el-card>
                  <el-card
                    class="mg-b-16 content-card"
                    v-if="DetailInfo.type === 2"
                  >
                    <div class="card-title">
                      <span>学者列表</span>
                      <el-button type="primary" @click="handleAddStudent"
                        >添加学者</el-button
                      >
                    </div>
                    <el-table :data="StudentList" border :max-height="600">
                      <el-table-column
                        width="50"
                        type="index"
                        :index="
                          (index) => {
                            return index + 1;
                          }
                        "
                        label="序号"
                      />
                      <el-table-column prop="id" label="学者ID" />
                      <el-table-column prop="name" label="姓名" />
                      <el-table-column prop="sex" label="性别" >
                        <template #default="scope">
                          <span>{{scope.row.sex === 1 ? '男': '女'}}</span
                          >
                        </template>
                      </el-table-column>
                      <el-table-column prop="country_name" label="国家" />
                      <el-table-column
                        prop="province_name"
                        label="地区"
                        :formatter="showAdress"
                      />
                      <el-table-column prop="id" label="行业领域">
                        <template #default="scope">
                          <span
                            v-for="(item, i) in scope.row.researchLabelArr"
                            :key="i"
                            >{{ item }}</span
                          >
                        </template>
                      </el-table-column>
                      <el-table-column prop="create_time" label="发布时间">
                      </el-table-column>
                      <el-table-column prop="id" label="操作">
                        <template #default="scope">
                          <el-link
                            class="mg-r-12"
                            type="primary"
                            @click="handleDelStudent(scope.row.id)"
                            >删除</el-link
                          >
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="table-pagination-row mg-t-24">
                      <el-pagination
                        v-if="paginationData.total > StudentList.length"
                        :current-page="currentPage"
                        :page-size="paginationData.size"
                        :total="paginationData.total"
                        @current-change="handleCurrentChangeStu"
                        @pagination="getStudentTableData"
                      />
                    </div>
                  </el-card>
                </div>
              </el-tab-pane>
            </el-tabs>
            <addCompany
              v-if="isShowAddCompany"
              :isShowAddCompany="isShowAddCompany"
              :companyId="companyId"
              @closeDialog="closeDialog"
            />
            <addStudent
              v-if="isShowAddStudent"
              :isShowAddCompany="isShowAddStudent"
              :companyId="companyId"
              @closeDialog="closeStudentDialog"
            />
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, toRefs, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Plus } from "@element-plus/icons-vue";
import addCompany from "./addCompany.vue";
import addStudent from "./addStudent.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";
import { ElMessageBox } from "element-plus";

export default {
  components: {
    Plus,
    addCompany,
    addStudent,
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const companyId = ref(route.params.id);
    const breadList = ref([
      {
        name: "专辑",
        path: {
          name: "dtiAlbum",
        },
      },
      {
        name: "专辑详情",
      },
    ]);
    const getTableData = async () => {
      const ret = await Ajax.POST({
        url: "/admin/album/brand/" + route.params.id,
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        
        ListData.value = ret.data.data.items;
        paginationData.total = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };
    const getStudentTableData = async () => {
      
      const ret = await Ajax.POST({
        url: "/admin/album/personageList/" + route.params.id,
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        StudentList.value = ret.data.data.items;
        paginationData.total = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const getInfo = async () => {
      const ret = await Ajax.POST({
        url: "/admin/album/details/" + route.params.id,
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        DetailInfo.value = ret.data.data;
        if (ret.data.data.type === 1) {
          getTableData();
        } else {
          getStudentTableData();
        }
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const activeName = ref("DetailInfo");

    onMounted(() => {
      getInfo();

      console.log(route.params.tabIndex);
      if (Number(route.params.tabIndex) === 2) {
        console.log(12);
        activeName.value = "albumContent";
      }
    });

    const state = reactive({
      // activeName: "DetailInfo",
      inputInstance: {
        inputVisible: false,
        inputValue: "",
      },
      tableData: new Array(30).fill({
        id: "2222222",
      }),
      queryData: {
        size: 10,
        page: 1,
      },
    });

    const DetailInfo = ref([]);
    const ListData = ref([]);
    const StudentList = ref([]);
    const paginationData = {
      current: 1,
      size: state.queryData.size,
      total: 0,
    };

    const isShowAddCompany = ref(false);
    const isShowAddStudent = ref(false);
    const handleTabClick = (tab, event) => {
      // console.log(state.activeName, "kkkkk");
      getInfo();
    };

    // 删除标签
    const handleClose = (tag) => {
      createParams.tagList.splice(createParams.tagList.indexOf(tag), 1);
    };

    // 增加标签
    const showInput = () => {
      state.inputInstance.inputVisible = true;
      // nextTick(() => {
      //   let ipt = currentCtx.$refs.InputRef;
      //   ipt.focus();
      // });
    };

    // 确认添加标签
    const handleInputConfirm = () => {
      let {
        inputInstance,
        createParams: { tagList },
      } = state;
      if (inputInstance.inputValue) {
        tagList.push(inputInstance.inputValue);
      }
      inputInstance.inputVisible = false;
      inputInstance.inputValue = "";
    };

    // 编辑
    const handleEdit = () => {
      router.push({
        name: "dtiAlbumEdit",
        params: {
          id: route.params.id,
        },
      });
    };

    // 添加品牌
    const handleAddCompany = () => {
      isShowAddCompany.value = true;
    };

    const handleAddStudent = () => {
      isShowAddStudent.value = true;
    };

    const handleSizeChange = () => {};

    const handleCurrentChange = (val) => {
      state.queryData.page = val;
      getTableData();
    };
    const handleCurrentChangeStu = (val) => {
      state.queryData.page = val;
      getStudentTableData();
    };

    const setDel = async (id) => {
      const ret = await Ajax.POST({
        url: "/admin/album/company/del/" + route.params.id,
        data: { object_id: id },
      });
      if (ret.data.code === 200) {
        getTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const setDelStudent = async (id) => {
      const ret = await Ajax.POST({
        url: "/admin/album/delPersonage/" + route.params.id,
        data: { object_id: id },
      });
      if (ret.data.code === 200) {
        getStudentTableData();
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const handleDel = async (id, status) => {
      ElMessageBox.confirm("确认删除该主品牌体?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setDel(id);
        })
        .catch(() => {
          MessageTips.info("已删除该品牌");
        });
    };

    const handleDelStudent = (id, status) => {
      ElMessageBox.confirm("确认删除该学者?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          setDelStudent(id);
        })
        .catch(() => {
          MessageTips.info("已删除该学者");
        });
    };

    const closeDialog = () => {
      isShowAddCompany.value = false;
      getTableData();
    };

    const closeStudentDialog = () => {
      isShowAddStudent.value = false;
      getStudentTableData();
    };

    return {
      ...toRefs(state),
      breadList,
      handleTabClick,
      showInput,
      handleInputConfirm,
      handleClose,
      handleEdit,
      handleAddCompany,
      handleAddStudent,
      handleSizeChange,
      handleCurrentChange,
      handleCurrentChangeStu,
      isShowAddCompany,
      isShowAddStudent,
      DetailInfo,
      ListData,
      StudentList,
      paginationData,
      getTableData,
      getStudentTableData,
      handleDel,
      handleDelStudent,
      companyId,
      closeDialog,
      closeStudentDialog,
      activeName,
    };
  },
  methods: {
    showAdress(row, colum) {
      if (row.city_name) {
        return row.city_name;
      }
      if (row.province_name) {
        return row.province_name;
      }

      return "";
    },
    pushDate(row, colum) {
      function add0(m) {
        return m < 10 ? "0" + m : m;
      }
      if (row > 0) {
        var time = new Date(row * 1000);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        return (
          y + "-" + add0(m) + "-" + add0(d) + " " + add0(h) + ":" + add0(mm)
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  ::v-deep(.el-tabs__content) {
    flex: 1;
    overflow-y: auto;
  }
}
.basic-container {
  .form-box {
    width: 100%;
  }
  .avatar-uploader {
    width: 108px;
    height: 108px;
    ::v-deep(.el-upload) {
      width: 108px;
      height: 108px;
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 108px;
      height: 108px;
      line-height: 108px;
      text-align: center;
    }
  }
  .submit-box {
    background-color: #fff;
  }
  .banner {
    width: 200px;
  }
}
.content-container {
  height: 100%;
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
