<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="exit-ditail-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">著作基本信息</span>
                <el-button type="primary" plain @click="gotoPage()"
                  >编辑基本信息</el-button
                >
              </div>
            </template>
            <div style="width: 70%">
              <el-descriptions :column="2">
                <el-descriptions-item label="著作名:">{{
                  detail.name
                }}</el-descriptions-item>
                <el-descriptions-item label="状态:">
                  <span class="status-text primary" v-if="detail.status == 1"
                    >已发布</span
                  >
                  <span
                    class="status-text success"
                    v-else-if="detail.status == 3"
                    >已完善</span
                  >
                  <span
                    class="status-text warning"
                    v-else-if="detail.status == 2"
                    >待完善</span
                  >
                  <span class="status-text info" v-else-if="detail.status == 4"
                    >已驳回</span
                  >
                </el-descriptions-item>
                <el-descriptions-item label="作者:">
                  <span v-for="(item, index) in detail.author" :key="item.id">
                    {{ item.name
                    }}<span v-if="index != detail.author.length - 1">,</span>
                  </span>
                </el-descriptions-item>
                <el-descriptions-item label="最近编辑时间:">{{
                  detail.update_time
                }}</el-descriptions-item>
                <el-descriptions-item label="出版社:">{{
                  detail.publisher
                }}</el-descriptions-item>
                <el-descriptions-item label="发布时间:">{{
                  detail.status == 1 ? detail.publish_time : "-"
                }}</el-descriptions-item>
                <el-descriptions-item label="出版年份:">{{
                  detail.publish_year?detail.publish_year:'-'
                }}</el-descriptions-item>
              </el-descriptions>
            </div>
          </el-card>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import Header from "../../../Layout/components/Header.vue";
export default {
  components: {
    BreadCrumb,
    Header,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "著作",
        path: {
          name: "books",
        },
      },
      {
        name: "著作详情",
      },
    ]);
    const detail = ref({});
    const gotoPage = () => {
      router.push({
        path: "/books/edit",
        query: {
          id: route.query.id,
        },
      });
    };
    const getDetail = (id) => {
      Ajax.GET({
        url: `/admin/works/${id}`,
      }).then((res) => {
        if (res.data.code == 200) {
          detail.value = res.data.data;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    onMounted(() => {
      let id = route.query.id;
      getDetail(id);
    });

    return {
      breadLists,
      detail,
      gotoPage,
    };
  },
};
</script>

<style scoped lang='less'>
.exit-ditail-container {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>