<template>
  <div class="search-filter-bar-container pd-t-b-16 pd-l-r-32">
    <div class="status-box" v-if="statusFlag">
      <span class="status-title">状态：</span>
      <el-radio-group
        v-if="isControlStatus"
        :model-value="controlStatus"
        @change="statusChange"
      >
        <el-radio-button
          size="small"
          v-for="item in statusLabels"
          :key="item.value"
          :label="item.value"
          >{{ item.name }}
        </el-radio-button>
      </el-radio-group>
      <el-radio-group v-else v-model="status" @change="statusChange">
        <el-radio-button
          size="small"
          v-for="item in statusLabels"
          :key="item.value"
          :label="item.value"
          >{{ item.name }}
        </el-radio-button>
      </el-radio-group>
      <span class="status-title" style="margin-left: 40px" v-if="isReport">发布状态：</span>
      <el-radio-group v-if="isReport" v-model="pubStatus" @change="pubstatusChange">
        <el-radio-button
          size="small"
          v-for="item in publishStatus"
          :key="item.value"
          :label="item.value"
          >{{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="search-container">
      <div class="search-box">
        <div class="select-con">
          <span class="status-title" v-if="selectShow">{{selectLable}}：</span>
          <el-select v-if="selectShow" class="mg-r-12" v-model="selectValue" @change="selectChange">
            <el-option
              v-for="option in selectOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </div>
        <el-input v-if="searchInput" v-model="searchVal" :placeholder="searchPlaceholder" @keyup.enter.native="handleSearch" class="search-inp"/>
        <el-button
          type="primary"
          size="small"
          class="btn-search"
          v-if="searchInput"
          @click="handleSearch"
          >查询</el-button
        >
      </div>
      <el-upload
        v-if="batchShow"
        :action="baseUrl + batchUrl"
        :headers="{ Authorization:'Bearer ' +  adminToken }"
        name="file"
        accept=".xlsx,.csv,.xls"
        :on-success="batchOK"
        :on-error="batchError"
        :show-file-list="false"
        v-loading="upLoading"
        :on-progress="batching"
      >
        <el-button class="btn-add" type="primary">批量上传</el-button>
      </el-upload>

      <el-button v-if="moduleShow" size="small" class="btn-add" type="primary">
        <a :href="downUrl" class="down_label">下载模板</a>
      </el-button>

      <el-button v-if="exportShow" size="small" class="btn-add" type="primary" @click="exportData">
        <!-- <a :href="exportUrl" class="down_label">导出</a> -->
        导出
      </el-button>

      <el-button
        v-if="addShow"
        size="small"
        class="btn-add"
        type="primary"
        @click="addHandle"
        >新增</el-button
      >
    </div>

    <el-dialog
      title="失败列表"
      v-model="centerDialogVisible"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      center
    >
      <div class="div-info-center div-list">
        <div v-for="item in upLoadErrorList" :key="item">
          {{ item }}
        </div>
      </div>
      <div slot="footer" class="dialog-footer div-info-center">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Plus } from "@element-plus/icons-vue";
import { ref } from "vue";
import { Ajax, MessageTips } from "@/utils";

export default {
  components: {
    Plus,
  },
  props: {
    // 报告页面
    isReport: {
      type: Boolean,
      default: false,
    },
    // 搜索栏状态筛选
    isControlStatus: {
      type: Boolean,
      default: false,
    },
    controlStatus: {
      type: Number,
      default: 0,
    },
		publishStatus:{
			type: Array,
      default() {
        return []
			}
		},
    statusLabels: {
      type: Array,
      default() {
        return [
          {
            value: 0,
            name: "全部",
            num: 20,
          },
          {
            value: "publish",
            name: "已发布",
            num: 10,
          },
          {
            value: "withdraw",
            name: "已撤回",
            num: 10,
          },
        ];
      },
    },
    statusFlag: {
      type: Boolean,
      default: true,
    },
    selectShow: {
      type: Boolean,
      default: false,
    },

    // 下拉选择数据
    selectOptions: {
      type: Array,
      default() {
        return [
          {
            label: "机构",
            value: "org",
          },
          {
            label: "主体",
            value: "compan",
          },
        ];
      },
    },

    // 新增按钮是否显示
    addShow: {
      type: Boolean,
      default: true,
    },
    //批量导入按钮
    batchShow: {
      type: Boolean,
      default: false,
    },
    //批量导入地址
    batchUrl: {
      type: String,
      default: "",
    },
    //批量导入地址
    batchParams: {
      type: Object,
      default: {},
    },
    //下载模板
    moduleShow: {
      type: Boolean,
      default: false,
    },
    downUrl: {
      type: String,
      default: "",
    },
    //列表导出
    exportShow: {
      type: Boolean,
      default: false,
    },
    exportUrl: {
      type: String,
      default: "",
    },
    searchPlaceholder:{
      type: String,
      default: "请输入",
    },
    searchInput: {
      type: Boolean,
      default: true,
    },
    selectLable: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    const searchValueRef = ref("");
    const statusRef = ref(0);
		const pubStatusRef = ref(2)
    const selectValueRef = ref();
    const upLoading = ref(false);
    const centerDialogVisible = ref(false);
    const upLoadErrorList = ref([]);

    const adminToken = ref(localStorage.getItem("adminToken"));
    const baseUrl = ref(window.env.baseUrl);

    // 新增事件
    const addHandle = () => {
      ctx.emit("addClick", { a: 1 });
    };
    //批量导入事件
    const batchHandle = () => {
      ctx.emit("batchHandle", { a: 1 });
    };

    const batchError = (err, file, fileList) => {
      MessageTips.error("失败");
      upLoading.value = false;
    };

    const batchOK = (response, file, fileList) => {
      upLoading.value = false;
      if (response.code === 200) {
        centerDialogVisible.value = true;
        if (response.data.fail > 0) {
          centerDialogVisible.value = true;
          upLoadErrorList.value = response.data.error_info;
        } else {
          MessageTips.success("成功");
        }
      } else {
        MessageTips.error("失败");
      }
    };

    const batching = (event, file, fileList) => {
      upLoading.value = true;
    };
    // 查询
    const handleSearch = () => {
      if (props.selectShow) {
        if (searchValueRef.value || selectValueRef.value) {
          ctx.emit("searchClick", {
            selectValue: selectValueRef.value,
            searchValue: searchValueRef.value,
          });
        } else {
          ctx.emit("searchClick", {
            selectValue: selectValueRef.value,
            searchValue: searchValueRef.value,
          });
        }
      } else {
        // if(!searchValueRef.value) return ;
        ctx.emit("searchClick", {
          searchValue: searchValueRef.value,
        });
      }
    };
    //切换状态
    const statusChange = (status) => {
      ctx.emit("statusChange", status);
    };
		const pubstatusChange = (status) => {
			ctx.emit("pubstatusChange", status);
    }
    // 下拉
    const selectChange = (type) => {
      selectValueRef.value = type
      ctx.emit("typeChange", type);
    }

    const exportData = ()=>{
      let date=new Date();
      let year = date.getFullYear(),
      month = date.getMonth() + 1,//月份是从0开始的
      day = date.getDate();
      month=month>9?month:'0'+month
      day=day>9?day:'0'+day
      let fName=`报名列表${year}${month}${day}.xlsx`

      Ajax.GET({
        url: props.exportUrl,
        
        // responseType:'blob',
      })
      .then(res=>{
        const blob = new Blob([res.data]);
        // console.log(new Blob([res]))
        console.log(res)
        // const blob = res.data;
            const fileName = fName;
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
      });
    }
    return {
      searchVal: searchValueRef,
      status: statusRef,
			pubStatus:pubStatusRef,
      selectValue: selectValueRef,
      addHandle,
      handleSearch,
      statusChange,
			pubstatusChange,
      batchHandle,
      upLoading,
      adminToken,
      baseUrl,
      batchOK,
      batchError,
      batching,
      centerDialogVisible,
      upLoadErrorList,
      exportData,
      selectChange
    };
  },
};
</script>

<style scoped lang="less">
.div-list {
  flex-direction: column;
  margin-bottom: 15px;
}
.div-info-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-filter-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background-color: #fff;

  .status-box {
    display: flex;
    align-items: center;
    min-width: 20%;

    .status-title {
      font-size: 14px;
      margin-right: 10px;
    }

    .btn-radio {
      margin-right: 10px;
    }
  }
  .search-container {
    display: flex;

    .search-box {
      display: flex;

      .btn-search {
        margin-left: 20px;
      }
    }
    .btn-add {
      margin-left: 12px;
    }
  }
}
.down_label {
  font-size: 12px;
  color: #fff;
  text-decoration: none;
}
.search-inp {
  width: 200px;
  :deep(.el-input__inner){
    width: 200px;
  }
}

.select-con {
  display: flex;
  align-items: center;
  .status-title {
    width: 50px;
  }
  .el-select {
    :deep(.el-input__inner){
      width: 150px;
    }
  }
}
</style>
