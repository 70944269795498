<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="patent-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">专利基本信息</span>
                                <el-button type="primary" plain @click="goEditPage('PatentEdit', '编辑基本信息')">编辑基本信息</el-button>
                            </div>
                        </template>
                        <el-row>
                            <el-col>
                                <el-descriptions :column="3">
                                    <el-descriptions-item :span="1" label-align="right" label="专利名：">{{ patentInfo.name }}</el-descriptions-item>
                                    <el-descriptions-item :span="2" label-align="right" label="状态：">
                                        <span :class="`status-text ${statusClass[patentInfo.status || 0]}`">{{ statusLabels[patentInfo.status || 0] }}</span></el-descriptions-item>
                                    <el-descriptions-item :span="1" label-align="right" label="专利权人：">
                                        <span class="mg-r-12" v-for="item in patentInfo.patentee" :key="item.id">{{ item.name }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="2" label-align="right" label="最近编辑时间：">{{ patentInfo.update_time }}</el-descriptions-item>
                                    <el-descriptions-item :span="1" label-align="right" label="发明人：">
                                        <span class="mg-r-12" v-for="item in patentInfo.inventor" :key="item.id">{{ item.name }}</span>
                                    </el-descriptions-item>
                                    <el-descriptions-item :span="2" label-align="right" label="发布时间：">{{ patentInfo.publish_time }}</el-descriptions-item>
                                    <el-descriptions-item :span="3" label-align="right" label="申请号：">{{ patentInfo.apply_no }}</el-descriptions-item>
                                    <el-descriptions-item :span="3" label-align="right" label="申请日：">{{ patentInfo.apply_time }}</el-descriptions-item>
                                    <el-descriptions-item :span="3" label-align="right" label="公开号：">{{ patentInfo.announce_no }}</el-descriptions-item>
                                    <el-descriptions-item :span="3" label-align="right" label="公开日：">{{ patentInfo.announce_time }}</el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../Layout/components/Header.vue';
import BreadCrumb from '../../../components/BreadCrumb/index.vue';
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Ajax, MessageTips, configData } from '../../../utils'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const breadListsRef = ref([
            {
                name: '专利',
                path: {
                    name: 'Patent'
                }
            },
            {
                name: '专利详情'
            }
        ])
        const statusLabels = configData.statusLabel;
        const statusClass = configData.statusClass
        const router = useRouter();
        const route = useRoute();
        const patentInfoRef = ref({});
        onBeforeMount(() => {
            Ajax.GET({
                url: `/admin/patent/${route.query.id}`
            }).then(res => {
                if(res.data.code === 200) {
                    patentInfoRef.value = res.data.data;
                }
            })
        })
        // 去编辑页面
        const goEditPage = (pathName, pageName) => {
            router.push({
                name: pathName,
                query: {
                    pageName,
                    id: route.query.id
                }
            })
        }
        return {
            breadLists: breadListsRef,
            statusClass,
            statusLabels,
            patentInfo: patentInfoRef,
            goEditPage
        }
    }
}
</script>

<style lang="less" scoped>
    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    ::v-deep(.el-descriptions__content) {
        color: rgb(16, 16, 16);
    }
</style>