<template>
    <div class="layout-container-box">
        <el-header class="layout-header">
            <Header>
                <template #header-brand>
                    <BreadCrumb :breadList="breadLists" />
                </template>
            </Header>
        </el-header>
        <el-scrollbar class="layout-main-content" id="content_box">
            <div class="pd-20">
                <div class="school-detail-container">
                    <el-card class="mg-b-16">
                        <template #header>
                            <div class="card-header">
                                <span class="card-name">高校院所基本信息</span>
                                <el-button type="primary" plain @click="toEdit">编辑基本信息</el-button>
                            </div>
                        </template>
                        <div class="detail-container">
                            <el-row>
                                <el-col>
                                    <el-descriptions :column="3">
                                        <el-descriptions-item :span="1" label-align="right" label="名称：">{{ schoolInfo.name }}</el-descriptions-item>
                                        <el-descriptions-item :span="2" label-align="right" label="状态：">{{ statusLabels[schoolInfo.status || 0] }}</el-descriptions-item>
                                        <el-descriptions-item :span="1" label-align="right" label="国家：">{{ schoolInfo.country_name }}</el-descriptions-item>
                                        <el-descriptions-item :span="2" label-align="right" label="最近编辑时间：">{{ schoolInfo.update_time }}</el-descriptions-item>
                                        <el-descriptions-item :span="1" label-align="right" label="地区：">{{ schoolInfo.province_name }} {{ schoolInfo.city_name }}</el-descriptions-item>
                                        <el-descriptions-item :span="2" label-align="right" label="发布时间：">{{ schoolInfo.publish_time }}</el-descriptions-item>
                                        <el-descriptions-item :span="3" label-align="right" label="简介：">{{ schoolInfo.desc }}</el-descriptions-item>
                                        <el-descriptions-item :span="1" label-align="right" label="封面图片：">
                                        <el-image
                                            style="width: 100px"
                                            :src="schoolInfo.image"
                                            fit="cover"
                                            v-if="schoolInfo.image"
                                            ></el-image>
                                        </el-descriptions-item>
                                        <el-descriptions-item :span="3" label-align="right" label="学校特色：">{{ schoolInfo.feature_info }}</el-descriptions-item>
                                    </el-descriptions>
                                </el-col>
                            </el-row>
                        </div>
                    </el-card>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
import Header from '../../../../Layout/components/Header.vue';
import BreadCrumb from '../../../../components/BreadCrumb/index.vue';
import { useRoute, useRouter } from 'vue-router'
import { ref, onBeforeMount } from 'vue'
import { Ajax, configData } from '../../../../utils'
export default {
    components: {
        Header,
        BreadCrumb
    },
    setup() {
        const schoolInfoRef = ref({})
        const breadListsRef = ref([
            {
                name: '高校院所',
                path: {
                    name: 'OrganizationSchool'
                }
            },
            {
                name: '高校院所详情'
            }
        ])
        const statusLabels = configData.statusLabel;
        const featureList = ref([])
        onBeforeMount(() => {
            Ajax.POST({
                url: `/admin/university/feature`
            }).then(res => {
                if(res.data.code === 200) {
                    featureList.value = res.data.data
                }
            })
            Ajax.GET({
                url: `/admin/university/${route.query.id}`
            }).then(res => {
                if(res.data.code === 200) {
                    schoolInfoRef.value = res.data.data;
                    let arr = []
                    res.data.data.feature.split(',').forEach(item => {
                        featureList.value.forEach(ite => {
                            if (ite.id == item){
                                arr.push(ite.name)
                            }
                        })
                    })
                    schoolInfoRef.value.feature_info = arr.join('、')
                }
            })
        })
        const route = useRoute();
        const router = useRouter();
        const toEdit = () => {
            router.push({
                path: '/organization/school/edit',
                query: {
                    id: route.query.id
                }
            })
        }
        return {
            breadLists: breadListsRef,
            schoolInfo: schoolInfoRef,
            statusLabels,
            toEdit,
            featureList
        }
    }
};
</script>

<style lang="less" scoped>
    .school-detail-container {
        height: 100%;
        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .write-box {
            background-color: #fff;
        }
        .user-form-box {
            width: 50%;
        }
        .bottom-submit-box {
            background-color: #fff;
        }
        ::v-deep(.el-descriptions__body .el-descriptions__table .el-descriptions__cell) {
            width: 50%;
        }
    }
</style>