<template>
    <el-breadcrumb separator="/">
        <template v-for="(bread) in breadList" :key="bread.name">
            <el-breadcrumb-item v-if="bread.path" :to="{name: bread.path.name, query: bread.path.query}">
                {{ bread.name }}
            </el-breadcrumb-item>
            <el-breadcrumb-item v-else>
                {{ bread.name }}
            </el-breadcrumb-item>
            <!-- <el-breadcrumb-item>
                <a v-if="breadList.length - index - 1 > 0 && !bread.notBack" href="#" @click="goBack(breadList.length - index - 1)">{{ bread.name }}</a>
                <template v-else>{{ bread.name }}</template>
            </el-breadcrumb-item> -->
            <!-- <el-breadcrumb-item>
                {{ bread.name }}
            </el-breadcrumb-item> -->
        </template>
    </el-breadcrumb>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
    props: {
        breadList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    setup(props) {
        const router = useRouter();
        const goBack = delta => {
            if(delta === 0) return ;
            router.go(-delta)
        }
        return {
            goBack,
        }
    }
};
</script>

<style>
</style>