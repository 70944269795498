<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="person-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            @statusChange="statusChange"
            @addClick="handleClick"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="table-container pd-32">
                <el-table
                  :data="tableData"
                  border
                  :max-height="650"
                  v-loading="isLoading"
                >
                  <el-table-column prop="doi" show-overflow-tooltip label="DOI" />
                  <el-table-column prop="title" show-overflow-tooltip label="自定义标题" />
                  <el-table-column prop="author" show-overflow-tooltip label="作者">
                    <template #default="scope">
                      <span>{{ scope.row.author }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="periodical" label="期刊" />
                  <el-table-column
                    prop="report_time"
                    label="发表时间"
                    width="100"
                  />
                  <el-table-column
                    prop="update_time"
                    label="最近编辑时间"
                    width="160"
                  />
                  <el-table-column prop="operator_name" label="最近录入人姓名" width="100"/>
                  <el-table-column
                    prop="publish_time"
                    label="发布时间"
                    width="160"
                  />
                  <el-table-column prop="status" label="状态" width="80">
                    <template #default="scope">
                      <span
                        class="status-text primary"
                        v-if="scope.row.status == 1"
                        >已发布</span
                      >
                      <span
                        class="status-text danger"
                        v-if="scope.row.status == 2"
                        >待完善</span
                      >
                      <span
                        class="status-text success"
                        v-if="scope.row.status == 3"
                        >已完善</span
                      >
                      <span class="status-text" v-if="scope.row.status == 4"
                        >已撤回</span
                      >
                    </template>
                  </el-table-column>
                  <el-table-column prop="operation" label="操作" width="100">
                    <template #default="scope">
                      <el-link
                        type="primary"
                        class="mg-r-12"
                        @click="gotoPage(scope.row.id)"
                        >详情</el-link
                      >
                      <el-link
                        type="primary"
                        v-if="scope.row.status == 1"
                        @click="showDialog(scope.row.id, 4, '确定撤回该文献')"
                        >撤回</el-link
                      >
                      <el-link
                        type="primary"
                        v-if="scope.row.status == 3||scope.row.status == 4"
                        @click="showDialog(scope.row.id, 1, '确定发布该文献')"
                        >发布</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    background
                    :currentPage="paginationData.current"
                    :page-sizes="paginationData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="paginationData.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>

  <el-dialog v-model="isShowDialog" :width="400">
    <span>{{ dialogDetail.content }}</span>
    <template #footer>
      <el-button @click="isShowDialog = false">取消</el-button>
      <el-button type="primary" @click="submit()">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import SearchFilterBar from "../../../components/SearchFilterBar/index.vue";
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
export default {
  components: {
    SearchFilterBar,
    Header,
    BreadCrumb,
  },
  setup() {
    const router = useRouter();
    const breadLists = ref([{ name: "文献" }]);
    const statusLabelsRef = ref([]);
    const isShowDialog = ref(false);
    const isLoading = ref(false);
    const dialogDetail = ref({
      id: undefined,
      content: undefined,
      type: undefined,
    });
    const searchForm = ref({
      doi: undefined,
      status: undefined,
      page: 1,
      page_size: 10,
    });
    // 查询
    const handleSearch = (res) => {
      searchForm.value.doi = res.searchValue;
      getTableData("search");
    };
    // 新增
    const handleClick = (e) => {
      router.push("/literature/create");
    };
    //状态查询
    const statusChange = (status) => {
      searchForm.value.status = status == 0 ? undefined : status;
      getTableData("search");
    };
    // 表格数据
    const tableDataRef = ref([]);
    // 页码
    let paginationDataRef = ref({
      current: 1,
      pageSize: [10, 20, 50],
      total: 0,
    });
    const handleSizeChange = (e) => {
      console.log(e, "sizeChange");
      searchForm.value.page_size = e;
      getTableData();
    };
    const handleCurrentChange = (e) => {
      console.log(e, "currentChange");
      paginationDataRef.value.current = e;
      searchForm.value.page = e;
      getTableData();
    };

    const gotoPage = (id) => {
      const newpage = router.resolve({
				path:'/literature/detail', // 跳转的页面路由
				query: {
          id: id,
        },
			})
      		window.open(newpage.href, '_blank') // 打开新的窗口(跳转路径，跳转类型)
    };
    const getTableData = (type) => {
      isLoading.value = true;
      if (type) {
        searchForm.value.page = 1;
        paginationDataRef.value.current = 1;
      }
      Ajax.GET({
        url: "/admin/literature",
        params: searchForm.value,
      }).then((res) => {
        isLoading.value = false;
        if (res.data.code == 200) {
          tableDataRef.value = res.data.data.list;
          paginationDataRef.value.total = res.data.data.page_info.total;
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const showDialog = (id, type, content) => {
      dialogDetail.value.id = id;
      dialogDetail.value.type = type;
      dialogDetail.value.content = content;
      isShowDialog.value = true;
    };
    const submit = () => {
      Ajax.POST({
        url: "/admin/literature/status",
        data: {
          id: dialogDetail.value.id,
          status: dialogDetail.value.type,
        },
      }).then((res) => {
        isShowDialog.value = false;
        if (res.data.code == 200) {
          MessageTips.success("操作成功");
          getTableData();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };

    onMounted(() => {
      statusLabelsRef.value = [
        {
          name: "全部",
          value: 0,
        },
        {
          name: "已发布",
          value: 1,
        },
        {
          name: "已完善",
          value: 3,
        },
        {
          name: "待完善",
          value: 2,
        },
        {
          name: "已撤回",
          value: 4,
        },
      ];
      getTableData();
    });
    
    return {
      breadLists,
      isLoading,
      statusChange,
      handleClick,
      handleSearch,
      tableData: tableDataRef,
      paginationData: paginationDataRef,
      handleSizeChange,
      handleCurrentChange,
      statusLabels: statusLabelsRef,
      gotoPage,
      isShowDialog,
      submit,
      showDialog,
      dialogDetail,
      
    };
  },
};
</script>

<style lang="less" scoped>
.person-container {
  height: 100%;
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
  .table-con {
    height: 100%;
    max-height: calc(100% - 66px - 12px);
    background-color: #fff;
  }
  .title-row {
    display: flex;
    align-items: center;
    height: 78px;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
  }
  ::v-deep(.el-card) {
    border: none;
  }
}
</style>