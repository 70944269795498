<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadList" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <!-- @statusChange="statusChange" -->
        <div class="list-container">
          <SearchFilterBar
            :statusLabels="statusLabels"
            :addShow="false"
            :exportShow="true"
            :statusFlag="false"
            :exportUrl="exportUrl"
            :searchPlaceholder="'请输入用户名/手机号'"
            @searchClick="handleSearch"
          />
          <div class="table-con mg-t-12">
            <el-scrollbar>
              <div class="pd-32" style="background: #fff">
                <el-table :data="ListData" border>
                  <el-table-column prop="user_id" width="80" label="用户ID" />
                  <el-table-column
                    prop="real_name"
                    width="90"
                    label="用户姓名"
                  />
                  <el-table-column prop="mobile" label="手机号" />
                  <el-table-column
                    prop="source_name"
                    width="120"
                    label="渠道"
                  />
                  <el-table-column prop="workplace" label="所在公司" />
                  <el-table-column prop="industry_name" label="所在行业" />
                  <el-table-column prop="create_time" label="提交时间" />
                  <el-table-column
                    label="操作"
                    width="120"
                    fixed="right"
                    align="center"
                  >
                    <template #default="scope"
                      ><el-link
                        class="mg-r-12"
                        type="primary"
                        @click="handleDetail(scope.row.id)"
                        >详情</el-link
                      >
                      <el-link
                        class="mg-r-12"
                        type="primary"
                        @click="handleDelete(scope.row.id)"
                        >删除</el-link
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <div class="table-pagination-row mg-t-24">
                  <el-pagination
                    v-show="paginationData.total > paginationData.size"
                    :background="background"
                    v-model:current-page.sync="currentPage"
                    v-model:page-size.sync="paginationData.size"
                    :layout="layout"
                    :total="paginationData.total"
                    v-bind="$attrs"
                    @current-change="handleCurrentChange"
                    @pagination="getTableData"
                  />
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from "element-plus";
import SearchFilterBar from "@/components/SearchFilterBar/index.vue";
import { Ajax, MessageTips } from "@/utils";
import Header from "@/Layout/components/Header.vue";
import BreadCrumb from "@/components/BreadCrumb/index.vue";

export default {
  components: {
    Header,
    BreadCrumb,
    SearchFilterBar,
  },
  setup() {
    const router = useRouter();

    const state = reactive({
      breadList: [
        {
          name: "报名",
        },
      ],
      statusLabels: [
        // {
        //   value: 0,
        //   name: "全部",
        // },
        // {
        //   value: 1,
        //   name: "激活",
        // },
        // {
        //   value: 2,
        //   name: "冻结",
        // },
      ],
      queryData: {
        size: 10,
        page: 1,
        // status: 0,
        query: "",
        type: 2,
      },
      mainTableKey: 1,
    });

    const ListData = ref([]);
    const paginationData = {
      current: 1,
      size: state.queryData.size,
      total: 0,
    };
    const exportUrl = window.env.baseUrl + "admin/mini/signup/export?type=2";

    // 查询
    const handleSearch = ({ searchValue }) => {
      state.queryData.query = searchValue;
      getTableData();
    };

    const getTableData = async () => {
      const ret = await Ajax.POST({
        url: "/admin/mini/signup",
        data: state.queryData,
      });
      if (ret.data.code === 200) {
        ListData.value = ret.data.data.items;
        paginationData.total = ret.data.data.total;
      } else {
        MessageTips.error(ret.data.msg);
      }
    };

    const handleDetail = (item) => {
      router.push({
        name: "InnovaDetail",
        params: { id: item },
      });
    };
    //操作账号是否冻结
    // const changeStatus = async (id, status) => {
    //   let str = status == 1 ? "冻结" : "激活";
    //   ElMessageBox.confirm("确认" + str + "该用户?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       setStatus(id, status == 1 ? 2 : 1);
    //     })
    //     .catch(() => {
    //       MessageTips.info("已取消" + str);
    //     });
    // };

    const handleCurrentChange = (val) => {
      state.queryData.page = val;
      getTableData();
    };

    const handleDelete =  (id) => {
      ElMessageBox.confirm("确认删除" + "该用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async() => {
          const ret = await Ajax.POST({
            url: "/admin/mini/signup/deleteById",
            data: {
              id: id,
            },
          });
          if(ret.data.code === 200){
            getTableData()
          }
        })
        .catch(() => {
          MessageTips.info("已取消" + str);
        });
    };

    // const handleSizeChange = () => {};
    onMounted(() => {
      getTableData();
    });

    return {
      ...state,
      // statusChange,
      handleSearch,
      // changeStatus,
      handleDetail,
      handleCurrentChange,
      // handleSizeChange,
      getTableData,
      ListData,
      paginationData,
      exportUrl,
      handleDelete,
    };
  },
  methods: {
    setgender(row, colum) {
      return row.gender === 1 ? "男" : row.gender === 0 ? "未知" : "女";
    },
  },
};
</script>

<style lang="less" scoped>
.list-container {
  height: 100%;
  .status-text {
    font-size: 14px;
    &.primary {
      color: #409eff;
    }
    &.info {
      color: #909399;
    }
  }
  .table-pagination-row {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
