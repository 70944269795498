import { ref } from 'vue'
export default function useMenuList() {
    const menuListRef = ref([{
            index: '/index',
            title: '配置',
            icon: 'House',
        },
        {
            index: '/data',
            title: '数据',
            icon: 'DataLine',
            children: [{
                    index: '/person',
                    title: '人物',
                },
                {
                    index: '/companyData',
                    title: '公司',
                    children: [
                        {
                            index: '/company',
                            title: '主体',
                        },
                        {
                            index: '/brand',
                            title: '品牌',
                        },
                    ]
                },
                {
                    index: '/reports',
                    title: '报道',
                },
                {
                    index: '/patent',
                    title: '专利'
                },
                {
                    index: '/organization',
                    title: '机构',
                    children: [{
                            index: '/organization/investment',
                            title: '投资机构'
                        },
                        {
                            index: '/organization/school',
                            title: '高校院所'
                        },
                        {
                            index: '/organization/social',
                            title: '社会组织'
                        },
                    ]
                },
                {
                    index: '/event',
                    title: '事件',
                    children: [{
                            index: '/event/invest',
                            title: '投资事件',
                        },
                        {
                            index: '/event/exit',
                            title: '退出事件',
                        }
                    ]
                },
                {
                    index: '/literature',
                    title: '文献'
                },
                {
                    index: '/books',
                    title: '著作'
                },
                {
                    index: '/researchGroup',
                    title: '期刊课题组列表'
                }
            ]
        },
        {
            index: '/tags',
            title: '数据分类',
            icon: 'PriceTag',
        },
        {
            index: '/users',
            title: '用户',
            icon: 'User',
        },
        {
            index: '/feedbacks',
            title: '反馈',
            icon: 'Message',
        },
        {
            title: '小程序反馈',
            icon: 'Clock',
            index: '/claim',
            children: [
                {
                    index: '/claimPer',
                    title: '申请成为学者',
                },{
                index: '/claimUnit',
                title: '单位数据反馈',
            },]
        },

        {
            title: '审核',
            icon: 'Clock',
            index: '/audit',
            children: [{
                index: '/auditCom',
                title: '主体',
            },{
                index: '/auditPer',
                title: '人物',
            },{
                index: '/auditOrg',
                title: '机构',
            }]
        },
        {
            index: '/operating',
            title: '运营',
            icon: 'SetUp'
        },
        {
            index: '/hotnews',
            title: '热点',
            icon: 'MessageBox',
        },
        {
            index: '/messages',
            title: '消息',
            icon: 'Watermelon',
            children: [{
                index: '/messages',
                title: '消息推送'
            }]
        },
        {
            index: '/dti',
            title: 'DTB小程序',
            icon: 'Watermelon',
            children: [
                {
                    index: '/dtiAlbum',
                    title: '专辑'
                },
                {
                    index: '/reportsList',
                    title: '报告'
                },
                {
                    index: '/apply',
                    title: '表单'
                },
                {
                    index: '/drafts',
                    title: '草稿'
                },
                {
                    index: '/dtiUser',
                    title: '用户'
                },
                {
                    index: '/homeConfigure',
                    title: '配置'
                },
                
                
                
            ]
        },
        // {
        //     index: '/loyigo',
        //     title: '络绎GO小程序',
        //     icon: 'Watermelon',
        //     children: [
        //         {
        //             index: '/jobList',
        //             title: '职位列表'
        //         },
        //         {
        //             index: '/jobBackground',
        //             title: '职位分类封面'
        //         }   
        //     ]
        // },
        // {
        //     index: '/paper',
        //     title: '论文小程序',
        //     icon: 'Document',
        //     children: [
        //         {
        //             index: '/Periodical',
        //             title: '期刊列表'
        //         },
        //         {
        //             index: '/Literatrue',
        //             title: '文献列表'
        //         },
        //         {
        //             index: '/paperUser',
        //             title: '用户列表'
        //         },
                
        //     ]
        // }
    ]);
    return {
        menuList: menuListRef
    }
}
