<template>
  <div class="layout-container-box">
    <el-header class="layout-header">
      <Header>
        <template #header-brand>
          <BreadCrumb :breadList="breadLists" />
        </template>
      </Header>
    </el-header>
    <el-scrollbar class="layout-main-content" id="content_box">
      <div class="pd-20">
        <div class="create-person-container">
          <el-card class="mg-b-16">
            <template #header>
              <div class="card-header">
                <span class="card-name">{{ typeObj.title }}</span>
              </div>
            </template>
            <div class="form-container">
              <el-form
                ref="formRef"
                :model="detail"
                label-width="120px"
                class="user-form-box mg-t-24"
              >
                <el-form-item label="名称" required>
                  <span class="mg-l-32">{{
                    detail.name ? detail.name : "-"
                  }}</span>
                </el-form-item>
                <el-form-item label="成立日期">
                  <span class="mg-l-32">{{
                    detail.establish_time ? detail.establish_time : "-"
                  }}</span>
                </el-form-item>
                <el-form-item label="国家">
                  <span class="mg-l-32">{{
                    detail.country_name ? detail.country_name : "-"
                  }}</span>
                </el-form-item>
                <el-form-item label="地区">
                  <span class="mg-l-32">{{
                    detail.city_name ? detail.city_name : "-"
                  }}</span>
                </el-form-item>
                <el-form-item label="机构类型">
                  <span class="mg-l-32">{{
                    detail.phase ? detail.phase : "-"
                  }}</span>
                </el-form-item>
                <el-form-item label="机构简介" required class="mg-t-12">
                  <div class="mg-l-32 word-break">
                    <span>{{ detail.profile ? detail.profile : "-" }}</span>
                  </div>
                </el-form-item>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="头像信息" class="mg-t-12">
                      <img
                        v-if="detail.image"
                        class="info-img mg-l-32"
                        :src="detail.image"
                      />
                      <span class="mg-l-32" v-else>-</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="官网" class="mg-t-12">
                      <a v-if="detail.link" :href="detail.link">{{
                        detail.link
                      }}</a>
                      <span v-else>-</span>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-form-item label="联系方式" class="mg-t-12">
                  <div class="mg-l-32">
                    电话：{{ detail.telephone ? detail.telephone : "-"
                    }}<br />邮箱：{{ detail.email ? detail.email : "-"
                    }}<br />手机：{{ detail.mobile ? detail.mobile : "-"
                    }}<br />
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
          <div class="bottom-submit-box pd-16" v-if="audit_status == 1">
            <el-row justify="end">
              <el-col :span="12" class="text-right">
                <el-button plain @click="back">取消</el-button>
                <el-button plain @click="submitForm(2)" v-if="audit_status != 3"
                  >不通过</el-button
                >
                <el-button
                  type="primary"
                  @click="submitForm(3)"
                  v-if="audit_status != 3"
                  >通过</el-button
                >
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Header from "../../../Layout/components/Header.vue";
import BreadCrumb from "../../../components/BreadCrumb/index.vue";
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Ajax, MessageTips } from "../../../utils";
export default {
  components: {
    BreadCrumb,
    Header,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const breadLists = ref([
      {
        name: "审核",
        path: {
          name: "auditOrg",
        },
      },
      {
        name: "审核详情",
      },
    ]);
    const degreeObj={
      0:"~",
      1:"学士学位",
      2:"硕士学位",
      3:"博士学位"
    };
    const detail = ref({});
    const audit_status = ref();
    const submitForm = (status) => {
      Ajax.POST({
        url: "/admin/audit/status",
        data: {
          id: typeObj.value.id,
          audit_type: typeObj.value.type,
          audit_status: status,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          MessageTips.success("操作成功");
          back();
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };
    const back = () => {
      router.go(-1);
    };
    const getDetail = () => {
      Ajax.GET({
        url: url.value,
      }).then((res) => {
        if (res.data.code == 200) {
          detail.value = res.data.data;
          if (detail.value.phase) {
            console.log(detail.value.phase);
            let companyPhase = [
              {
                id: 1,
                name: "天使机构",
              },
              {
                id: 2,
                name: "VC",
              },
              {
                id: 3,
                name: "PE",
              },
              {
                id: 4,
                name: "CVC",
              },
              {
                id: 5,
                name: "FA",
              },
              {
                id: 6,
                name: "FOF",
              },
              {
                id: 7,
                name: "金融机构",
              },
              {
                id: 8,
                name: "国资背景",
              },
              {
                id: 9,
                name: "家族办公室",
              },
              {
                id: 10,
                name: "对冲基金",
              },
              {
                id: 11,
                name: "天使个人",
              },
              {
                id: 12,
                name: "孵化器",
              },
              {
                id: 13,
                name: "其他",
              },
            ];
            for (let i in companyPhase) {
              let item = companyPhase[i];
              if (item.id == detail.value.phase) {
                detail.value.phase = item.name;
                break;
              }
            }
          }
        } else {
          MessageTips.error(res.data.msg);
        }
      });
    };

    let typeObj = ref({
      type: undefined,
      id: undefined,
      title: undefined,
    });
    let url = ref("");
    onMounted(() => {
      let id = route.query.id;
      let type = route.query.type;
      audit_status.value = route.query.audit_status;
      typeObj.value.id = id;
      typeObj.value.type = type;
      if (type == 1) {
        url.value = `/admin/personage/${id}`;
        typeObj.value.title = "人物基本信息";
      } else if (type == 2) {
        url.value = `/admin/organization/${id}`;
        typeObj.value.title = "机构基本信息";
      } else if (type == 3) {
        url.value = `/admin/company/${id}`;
        typeObj.value.title = "主体基本信息";
      }
      getDetail(id);
    });
    return {
      breadLists,
      submitForm,
      typeObj,
      detail,
      back,
      audit_status,
      degreeObj
    };
  },
};
</script>

<style lang="less" scoped>
.create-person-container {
  height: 100%;
  .write-box {
    background-color: #fff;
  }
  .user-form-box {
    width: 100%;

    // padding: 0px 180px;
    .info-img {
      width: 200px;
      height: 200px;
    }
    .compang-pic{
      width: 60px;
      height: 60px;
    }
    .word-break{
      word-break: break-all;
    }
  }
  .bottom-submit-box {
    background-color: #fff;
  }
  .form-container {
    height: 100%;
    overflow: auto;
  }
}
::v-deep(.el-form-item) {
  margin-bottom: 0px;
}
</style>
